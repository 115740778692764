import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080 820" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="" >



<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M1.000000,277.000000 
	C1.000000,184.678741 1.000000,92.857483 1.000000,1.018112 
	C360.940582,1.018112 720.881165,1.018112 1080.910889,1.018112 
	C1080.910889,274.237946 1080.910889,547.475952 1080.910889,820.856934 
	C721.000061,820.856934 361.000031,820.856934 1.000000,820.856934 
	C1.000000,639.785400 1.000000,458.642700 1.000000,277.000000 
M655.023865,171.022736 
	C655.023865,171.022736 655.098938,171.036163 655.552551,170.847321 
	C655.837463,170.637344 655.985718,170.355484 656.000000,170.000000 
	C656.000000,170.000000 656.001343,169.996780 656.477112,169.817032 
	C656.633911,169.527740 656.790710,169.238449 657.104187,168.464432 
	C657.184814,168.314957 657.265381,168.165497 657.702454,167.819199 
	C657.917847,167.627029 658.030823,167.386719 658.019409,167.022934 
	C658.019409,167.022934 658.094666,167.045395 658.802856,166.929794 
	C659.524719,164.941406 660.246643,162.953003 661.058777,160.495865 
	C661.058777,160.495865 661.242249,160.055161 661.967041,159.826691 
	C664.015808,154.294647 662.693115,147.705246 659.000000,145.000000 
	C659.000000,145.000000 658.990051,145.005386 659.054382,144.290100 
	C659.724182,143.504868 660.393921,142.719635 661.807190,142.135635 
	C673.805786,145.825928 682.208191,143.841232 688.235779,135.893127 
	C693.913330,128.406525 694.495544,117.119873 688.077393,109.400887 
	C677.913025,97.176308 666.098267,86.593712 651.957275,78.313004 
	C651.759705,78.053398 651.497864,77.928146 650.860535,77.373596 
	C650.266785,77.145157 649.673035,76.916710 649.043701,76.016968 
	C647.786377,74.982254 646.616150,73.813293 645.258850,72.932640 
	C618.431763,55.526337 589.099854,44.554489 557.325317,41.637897 
	C540.051636,40.052349 522.517212,41.307487 504.213165,41.205647 
	C502.555603,41.350056 500.884247,41.403358 499.242584,41.652496 
	C473.318146,45.586842 448.857056,53.816212 426.104919,66.900551 
	C407.685089,77.493423 391.219849,90.651192 376.459473,105.892883 
	C367.384460,115.263794 366.501709,127.254486 375.004028,136.985718 
	C385.497528,148.995956 396.681335,160.429688 408.015472,171.659729 
	C410.774475,174.393402 415.286804,175.357529 419.602356,177.668030 
	C423.508698,177.668030 427.415039,177.668030 431.647125,177.668030 
	C431.804932,178.695984 432.000916,179.972687 432.107788,182.048325 
	C431.750641,185.023285 431.393524,187.998245 430.982727,191.748947 
	C431.547882,193.626556 431.674103,195.825836 432.766846,197.316742 
	C435.264618,200.724655 438.235229,203.786057 441.000000,207.000000 
	C441.000000,207.000000 441.012207,206.986160 441.029053,207.636688 
	C441.663239,208.106155 442.297424,208.575607 442.988037,208.995438 
	C442.988037,208.995438 442.954681,209.062622 442.960785,209.817551 
	C444.409760,211.803452 445.610901,214.048157 447.358826,215.721848 
	C451.092743,219.297150 455.109833,222.576752 459.448975,226.208801 
	C459.724762,226.414215 459.909027,226.682495 459.965179,227.762054 
	C461.303558,228.840790 462.641907,229.919525 464.003418,230.994537 
	C464.003418,230.994537 464.005859,231.017776 464.140411,231.476654 
	C464.320496,231.762985 464.575867,231.941910 465.120758,232.289627 
	C465.120758,232.289627 465.191345,232.631989 465.265106,233.243576 
	C465.772949,233.531082 466.280792,233.818573 467.348663,234.352890 
	C467.881531,234.644470 468.414398,234.936066 469.118683,235.477325 
	C469.118683,235.477325 469.409485,235.561890 469.522919,236.095459 
	C469.669922,236.426514 469.816895,236.757568 470.144196,237.544540 
	C470.349823,237.828781 470.627502,237.984543 471.001221,238.000000 
	C471.001221,238.000000 470.986298,238.022400 471.054565,238.706482 
	C471.783630,239.087296 472.512695,239.468124 473.643005,239.946976 
	C473.643005,239.946976 473.978790,240.187500 474.212677,240.619095 
	C474.457367,240.877075 474.756348,240.985611 475.573669,241.044998 
	C475.573669,241.044998 475.973358,241.301178 476.197479,241.675446 
	C476.416443,241.892578 476.680145,242.001175 477.469360,242.106323 
	C477.469360,242.106323 477.936920,242.259933 478.185089,242.997238 
	C482.234558,244.234772 486.286774,245.517471 490.544403,242.019547 
	C490.895172,241.934708 491.245941,241.849884 492.196564,241.775101 
	C492.441620,241.484375 492.686646,241.193665 492.979828,241.005203 
	C492.979828,241.005203 492.912689,240.913498 493.631012,240.974960 
	C494.437744,240.343872 495.244476,239.712799 495.992218,239.028748 
	C495.992218,239.028748 496.068665,239.049652 496.860352,239.063675 
	C498.743988,238.071777 500.700409,237.195190 502.495117,236.062424 
	C505.633789,234.081436 508.584869,231.793671 511.776337,229.908340 
	C515.770508,227.548782 519.927979,225.465637 524.654846,223.087570 
	C525.095764,223.113647 525.536682,223.139740 526.187134,223.448807 
	C526.462952,223.338394 526.738831,223.227997 527.918823,222.865189 
	C540.545532,221.729813 551.297913,226.019028 560.467407,234.446564 
	C568.409302,241.745850 577.134827,245.036819 588.344604,240.912918 
	C588.915100,240.387024 589.485596,239.861130 590.357788,239.014267 
	C590.357788,239.014267 590.761658,238.838242 591.454468,238.889343 
	C591.953918,238.243637 592.453430,237.597916 593.000000,237.000000 
	C593.000000,237.000000 592.951782,236.954193 593.672485,236.921722 
	C594.730347,235.948944 596.143311,235.167160 596.786560,233.971329 
	C600.134277,227.747604 601.311096,221.257416 598.683838,214.390915 
	C597.673645,211.750595 596.930054,209.012619 601.676147,208.826569 
	C601.676147,208.826569 602.003784,209.004364 602.428467,209.593231 
	C605.898438,210.868561 609.329102,213.121216 613.462036,209.787384 
	C613.814758,209.738327 614.167419,209.689270 615.329651,209.856201 
	C618.246887,210.218719 620.364868,209.332260 621.327271,205.920609 
	C621.327271,205.920609 621.707703,205.809952 622.426208,205.907639 
	C623.292358,204.956085 624.158569,204.004532 624.997437,203.003952 
	C624.997437,203.003952 625.050598,203.021973 625.695740,202.955582 
	C626.123596,202.297073 626.551453,201.638565 627.000000,201.000000 
	C627.000000,201.000000 626.980164,200.979080 627.676880,200.966492 
	C630.482056,197.750153 633.287170,194.533813 636.452942,190.778915 
	C637.362976,189.868774 638.272949,188.958618 639.797485,187.977066 
	C640.119568,187.512451 640.441650,187.047836 640.847412,186.249039 
	C640.847412,186.249039 641.066956,185.983490 641.501221,185.790939 
	C641.774963,185.591003 641.926941,185.322205 641.983276,184.984314 
	C641.983276,184.984314 641.990723,184.959244 642.685425,184.955811 
	C644.418274,182.909790 646.151184,180.863785 648.079468,178.411484 
	C648.273743,178.173248 648.512634,177.994675 649.334717,177.737717 
	C649.518738,177.430618 649.702698,177.123535 650.159119,176.368561 
	C650.287476,176.249969 650.415771,176.131378 650.785034,175.888824 
	C650.932373,175.767227 651.016663,175.611465 651.414978,174.871414 
	C652.335510,173.930710 653.256104,172.990005 654.590210,171.863831 
	C654.845947,171.670685 654.995178,171.415298 655.023865,171.022736 
M658.825439,420.154633 
	C659.587708,419.474243 660.349976,418.793854 661.614746,417.989563 
	C661.794434,417.710449 661.974121,417.431335 662.834839,417.118958 
	C664.271606,415.796326 665.708374,414.473694 667.837280,413.104858 
	C669.595947,411.430603 671.354675,409.756378 673.684692,407.929779 
	C674.188965,407.349579 674.693237,406.769409 675.838440,405.977264 
	C677.585632,404.344788 679.332764,402.712311 681.711731,400.858459 
	C684.316467,398.162140 687.127869,395.633270 689.479370,392.731689 
	C693.365662,387.936035 696.955383,382.899933 700.896118,377.928772 
	C700.896118,377.928772 700.904053,377.700012 701.227234,377.543274 
	C701.410583,377.374725 701.524902,377.168030 702.157715,376.597595 
	C702.819031,375.434692 703.480286,374.271790 704.033264,373.048065 
	C704.033264,373.048065 704.093384,373.156708 704.625183,372.965576 
	C704.747498,372.649719 704.869751,372.333862 705.206482,371.707550 
	C705.206482,371.707550 705.128296,371.338440 705.717163,371.111420 
	C706.190918,370.432739 706.664734,369.754028 707.043823,369.042023 
	C707.043823,369.042023 707.081360,369.135071 707.736084,368.875824 
	C712.828186,359.583557 717.920288,350.291321 723.185303,340.664307 
	C723.185303,340.664307 723.113647,340.294434 723.755066,339.998444 
	C725.521667,335.708954 727.288269,331.419495 729.723328,326.823120 
	C731.843079,319.892059 733.962830,312.961029 736.008667,306.005554 
	C736.008667,306.005554 736.049927,306.071594 736.626892,305.800018 
	C736.755554,305.200806 736.884216,304.601624 737.315063,303.432404 
	C737.321228,303.003235 737.327332,302.574036 737.916626,301.665283 
	C738.641052,296.843597 739.365417,292.021881 740.670715,286.936737 
	C740.814026,284.990051 740.957336,283.043396 741.750610,280.699585 
	C742.184326,278.419556 742.814697,276.157227 743.021545,273.856812 
	C745.147034,250.216827 744.941101,226.615479 739.058289,203.514648 
	C735.756470,190.548843 731.637390,177.675232 726.544678,165.312836 
	C721.080872,152.049423 703.057434,149.596603 694.497314,160.667404 
	C686.823181,170.592163 680.127686,181.273590 673.030640,191.651978 
	C673.030640,191.651978 672.992676,191.652847 672.955383,191.686890 
	C672.955383,191.686890 672.910645,191.710342 672.313599,192.046768 
	C659.265442,207.395996 643.810608,219.916946 626.337036,230.912186 
	C626.154358,231.247849 625.971680,231.583511 625.373901,231.950180 
	C625.373901,231.950180 625.020203,232.169785 624.317688,232.159180 
	C616.858765,235.971848 609.399780,239.784515 601.250305,243.764053 
	C595.255798,245.729294 589.342651,248.009155 583.247742,249.586411 
	C573.974548,251.986160 564.575256,253.898956 555.438171,255.971222 
	C550.734070,244.590683 549.859497,243.601837 541.203918,239.298187 
	C528.955383,233.208069 514.537659,238.103806 509.220428,250.745529 
	C507.435822,254.988464 505.318848,255.575974 501.351410,254.845932 
	C448.093994,245.046265 406.090302,218.007217 375.870544,172.978119 
	C369.779388,163.902023 361.545715,159.781860 350.802704,161.006744 
	C340.499481,162.181488 333.890472,168.398071 330.255341,177.734665 
	C328.213867,182.977982 326.956177,188.526474 325.015747,194.650269 
	C322.987549,205.008728 320.259003,215.291031 319.122040,225.746414 
	C317.910126,236.891098 317.737854,248.210342 318.001221,259.432495 
	C318.179321,267.022278 319.895264,274.575989 320.830536,282.984222 
	C320.928345,284.659210 321.026154,286.334198 321.127014,288.818909 
	C324.621918,305.955139 329.449066,322.666199 336.878479,338.559845 
	C353.356049,373.810089 377.239594,402.887024 409.248596,425.121582 
	C438.259003,445.273163 470.323608,457.813629 505.432343,461.623199 
	C518.543213,463.045776 531.901123,462.191528 545.624878,462.457825 
	C545.982605,462.506470 546.269470,462.386139 547.165710,462.435059 
	C549.006165,462.257294 550.846558,462.079529 553.607666,462.001801 
	C555.420654,461.793823 557.243164,461.647522 559.045105,461.368347 
	C576.371033,458.683624 593.491577,455.221436 610.065247,447.492981 
	C618.416260,443.375458 626.767212,439.257904 635.679138,435.139435 
	C642.815002,430.457306 649.950806,425.775208 657.591370,420.976257 
	C657.788391,420.711395 657.985352,420.446533 658.825439,420.154633 
M795.403259,572.960388 
	C794.659607,572.244446 793.916016,571.528564 793.155273,570.962830 
	C793.155273,570.962830 793.229675,570.831238 793.215637,570.128479 
	C789.811401,567.125732 786.407104,564.123047 782.637817,560.911316 
	C782.637817,560.911316 782.217224,560.908142 781.863708,560.322510 
	C781.029053,560.047058 780.194458,559.771667 779.467346,559.485596 
	C779.467346,559.485596 779.462158,559.377625 779.302490,558.765198 
	C777.244202,557.465149 775.316284,555.858398 773.105042,554.917969 
	C763.353699,550.770630 753.020752,550.001953 742.700928,551.200256 
	C727.816101,552.928650 715.377747,559.293518 705.793030,571.374207 
	C695.340881,584.548279 692.353271,599.620911 692.857727,615.750244 
	C693.930420,650.044434 717.943848,678.469910 758.520325,673.462219 
	C765.176147,672.640808 771.508484,669.198853 778.707275,667.080505 
	C782.768372,663.973694 787.379761,661.374512 790.795105,657.672058 
	C805.571289,641.654114 808.811035,622.184021 806.318665,601.360962 
	C805.271179,592.609436 803.229248,584.035583 797.526733,576.112732 
	C796.821899,575.285645 796.117065,574.458618 795.283020,573.550110 
	C795.283020,573.550110 795.429871,573.592224 795.403259,572.960388 
M383.274170,628.276611 
	C383.180359,628.863647 383.086548,629.450623 382.762878,630.205933 
	C382.762878,630.205933 382.842010,630.479553 382.333618,630.826843 
	C381.840332,633.636475 381.347046,636.446045 380.601898,639.796753 
	C380.675873,640.179810 380.749878,640.562866 380.522461,641.628723 
	C380.726288,644.073120 380.930115,646.517456 381.019592,649.792358 
	C381.351349,650.534607 381.683105,651.276917 381.924561,652.543518 
	C382.337921,653.449768 382.751282,654.355957 383.116119,655.960327 
	C383.388580,656.311401 383.661072,656.662537 384.018768,657.706116 
	C386.028961,660.058838 388.039154,662.411621 390.471497,665.249695 
	C391.301331,665.859070 392.131134,666.468445 392.951172,667.017761 
	C392.951172,667.017761 392.906494,667.059753 393.330383,667.596619 
	C416.271667,679.956238 438.423615,675.613770 462.237305,653.624939 
	C462.237305,659.855835 462.237305,665.530151 462.237305,671.467773 
	C470.598602,671.467773 478.322418,671.467773 486.204163,671.467773 
	C486.354950,670.576050 486.555481,669.943359 486.555420,669.310608 
	C486.553101,645.662537 486.837128,622.007996 486.329834,598.370422 
	C486.175842,591.195312 484.916626,583.633789 482.273254,577.002747 
	C475.569946,560.187317 461.936096,552.234131 444.333954,550.611877 
	C428.739319,549.174622 413.816467,550.770264 400.329865,561.156555 
	C388.332184,570.478821 383.219940,582.878174 383.512268,597.915771 
	C392.087372,597.915771 400.246124,597.915771 408.693329,597.915771 
	C408.581818,589.238525 409.267883,581.208313 417.802490,575.867126 
	C424.327972,574.953369 430.853821,573.269531 437.378876,573.272583 
	C455.720703,573.281250 462.096832,580.376709 461.633667,598.681274 
	C461.556641,601.725098 460.480774,602.613037 457.654083,602.962769 
	C448.105774,604.143921 438.516541,605.207764 429.081696,607.033508 
	C419.300323,608.926331 409.680542,611.653992 399.637421,613.860718 
	C399.127045,614.252136 398.616699,614.643616 397.307526,615.096619 
	C396.871277,615.384827 396.435028,615.672974 395.310974,616.088440 
	C392.098297,618.967651 388.885620,621.846924 385.801331,624.877136 
	C385.801331,624.877136 385.735504,624.690186 385.153717,624.984314 
	C384.685730,625.955261 384.217743,626.926208 383.274170,628.276611 
M312.911957,639.502441 
	C313.027985,640.655029 313.143982,641.807678 313.163055,643.740784 
	C314.408020,647.506470 315.652985,651.272156 316.883118,655.761108 
	C321.791412,665.827576 330.886871,669.211975 341.859711,671.085510 
	C350.277679,671.884216 358.713989,673.064697 367.625610,670.027832 
	C368.090088,669.992310 368.554565,669.956787 369.565002,670.236023 
	C371.185974,669.315918 374.138306,668.488586 374.209991,667.460510 
	C374.646484,661.197510 374.427948,654.888794 374.427948,647.933105 
	C367.204773,647.933105 360.405701,648.246765 353.645874,647.864990 
	C341.262573,647.165527 338.427124,644.007629 338.424957,631.938782 
	C338.421906,614.780396 338.424438,597.622009 338.424622,580.463623 
	C338.424652,578.519775 338.424622,576.575928 338.424622,574.034119 
	C350.857910,574.034119 362.569672,574.034119 374.199768,574.034119 
	C374.199768,566.667419 374.199768,559.909424 374.199768,552.733765 
	C362.333282,552.733765 350.740326,552.733765 338.447510,552.733765 
	C338.447510,542.637207 338.447510,532.936096 338.447510,523.407227 
	C331.329163,523.407227 324.926453,523.407227 318.568573,523.407227 
	C316.647552,533.399719 314.805237,542.982849 312.899353,552.896667 
	C306.040009,552.896667 299.624420,552.896667 293.163757,552.896667 
	C293.163757,560.134094 293.163757,566.932556 293.163757,574.199768 
	C298.464691,574.199768 303.601898,574.394592 308.714874,574.126892 
	C311.796112,573.965515 312.952484,575.172607 312.705292,578.926025 
	C312.797699,598.307495 312.890106,617.688965 312.774628,637.352173 
	C312.868317,637.782410 312.961975,638.212646 312.911957,639.502441 
M249.554718,664.916931 
	C249.743683,664.611084 249.932648,664.305237 250.545258,663.882812 
	C250.831421,663.739075 250.995956,663.508545 251.576614,663.123657 
	C255.074829,660.043579 258.573029,656.963501 261.981171,654.830505 
	C261.981171,660.284607 261.981171,665.738770 261.981171,671.501892 
	C270.247223,671.501892 277.981415,671.501892 285.902740,671.501892 
	C286.053101,670.546753 286.289032,669.750244 286.287781,668.954163 
	C286.248993,644.305725 286.283112,619.656494 286.076447,595.009277 
	C286.004089,586.385742 283.771515,578.173889 279.149353,570.815918 
	C270.644470,557.277100 257.763245,551.421021 242.298447,550.327820 
	C225.278046,549.124512 209.464371,552.042297 196.441864,564.095825 
	C195.370163,565.087769 194.819031,566.642151 193.688065,568.533936 
	C192.454239,570.406494 191.220413,572.279114 189.386703,574.488708 
	C183.293915,581.278931 183.859085,589.709717 182.864960,597.982056 
	C191.812592,597.982056 200.013397,597.982056 206.897339,597.982056 
	C208.803650,591.212769 210.504288,585.173767 212.154434,579.108276 
	C212.154434,579.108276 212.190231,579.158203 212.904770,579.044739 
	C214.175171,578.063232 215.301331,576.679138 216.738861,576.163940 
	C228.045517,572.112000 239.480408,571.724915 250.908554,575.649536 
	C255.649231,577.277527 258.918762,580.782410 259.897888,585.561829 
	C260.855743,590.237427 260.905273,595.126160 260.999878,599.928284 
	C261.018341,600.864929 259.552795,602.451355 258.534637,602.679443 
	C254.361816,603.614075 250.097000,604.137573 245.866486,604.815186 
	C232.739761,606.917603 219.376358,608.088867 206.543518,611.348572 
	C188.417053,615.952942 178.509293,626.485229 180.096741,645.888916 
	C180.816376,654.685364 184.129166,662.756348 193.406601,667.109497 
	C193.406601,667.109497 193.821136,667.101318 194.048599,667.778748 
	C199.709381,669.769958 205.370178,671.761230 211.957550,673.868164 
	C225.227539,674.723328 237.963806,673.337280 249.004578,664.999146 
	C249.004578,664.999146 249.019836,665.056580 249.554718,664.916931 
M710.273926,748.452087 
	C709.839844,748.580627 709.405823,748.709229 708.234314,749.061707 
	C708.122803,755.726196 708.011292,762.390686 707.358521,769.663147 
	C707.006714,770.390869 706.654968,771.118591 705.715332,772.194397 
	C702.483948,775.302063 698.710938,775.842102 694.722107,774.125854 
	C690.691528,772.391724 690.320557,768.701477 690.351013,764.910645 
	C690.385559,760.598633 690.540161,756.277771 690.304626,751.979309 
	C690.234985,750.707214 689.023010,749.497803 688.334534,748.259705 
	C687.526001,749.543274 686.125244,750.780457 686.020264,752.119202 
	C685.709961,756.075439 685.986572,760.073547 685.895447,764.052124 
	C685.768799,769.577820 686.235596,775.095459 691.911194,777.684753 
	C697.775085,780.359985 703.238037,779.078979 707.484375,773.890137 
	C707.612976,773.733093 708.091919,773.862732 708.266357,774.718750 
	C707.769348,778.226990 709.301147,778.746460 712.064941,777.616211 
	C712.064941,768.236328 712.064941,759.127197 712.356079,749.750488 
	C711.878174,749.413452 711.400269,749.076477 710.273926,748.452087 
M754.028931,749.768311 
	C754.028931,759.129333 754.028931,768.490356 754.028931,777.723389 
	C757.224670,778.703308 758.364380,777.858765 758.212036,774.845886 
	C757.979248,770.239624 758.060242,765.617493 758.257996,760.648193 
	C758.197266,760.090881 758.136536,759.533630 758.291260,758.516846 
	C758.296875,758.183899 758.302490,757.851013 758.849365,757.053711 
	C761.566345,752.291626 764.710083,750.899841 769.919739,752.063599 
	C774.840454,753.162659 775.144165,757.040466 775.214661,760.941650 
	C775.315674,766.533936 775.240417,772.129395 775.240417,778.147095 
	C777.045898,778.053162 778.417725,777.981750 779.435730,777.928772 
	C779.435730,770.496094 779.806213,763.515137 779.318909,756.594604 
	C778.927368,751.035278 775.213684,748.559204 769.672546,747.859009 
	C764.354553,747.187012 761.183350,750.603638 757.758911,752.385315 
	C757.683777,751.609131 757.608643,750.832886 757.769897,749.520325 
	C756.632324,749.353821 755.494751,749.187317 754.028931,749.768311 
M301.990143,750.945374 
	C301.995941,758.889832 302.121490,766.836853 301.952240,774.777588 
	C301.878143,778.252869 303.235840,778.674805 306.376984,777.552185 
	C306.376984,770.836731 306.376984,764.233948 306.866638,757.106018 
	C308.871338,751.671875 313.281433,751.395874 317.968750,752.131287 
	C322.801514,752.889526 323.042114,756.853943 323.165771,760.546631 
	C323.344177,765.871460 323.227142,771.205872 323.267303,776.536011 
	C323.270630,776.977722 323.511475,777.417603 323.753723,778.235840 
	C325.171967,778.058228 326.504456,777.891357 327.733276,777.737427 
	C327.733276,770.866211 327.866333,764.536133 327.686890,758.214966 
	C327.565582,753.941589 325.748047,750.225403 321.576782,748.719238 
	C317.211212,747.142883 312.756165,747.355469 308.794861,750.408569 
	C307.773834,751.195496 306.487244,751.637878 305.570465,751.536804 
	C305.530365,751.046692 305.490265,750.556519 305.712921,749.456482 
	C304.514923,749.643738 303.316956,749.830994 301.990143,750.945374 
M261.952942,757.314514 
	C264.471191,752.463928 268.124695,750.760071 273.337982,752.083130 
	C277.900146,753.240906 278.282532,756.980652 278.362701,760.702515 
	C278.483551,766.312988 278.394196,771.928040 278.394196,777.869812 
	C279.952393,777.869812 281.335480,777.869812 282.950470,777.869812 
	C282.950470,771.317505 283.075256,765.037964 282.907837,758.766296 
	C282.790802,754.380310 281.225159,750.430298 276.814453,748.799011 
	C272.285980,747.124207 267.741364,747.371948 263.668671,750.533020 
	C262.817169,751.193970 261.637299,751.431763 260.771698,751.028748 
	C259.957855,750.009827 259.143982,748.990906 258.330139,747.971924 
	C257.972168,748.247375 257.614197,748.522827 257.256226,748.798279 
	C257.256226,758.509399 257.256226,768.220581 257.256226,777.818909 
	C260.356110,778.756104 261.360168,777.777222 261.291412,774.788086 
	C261.161804,769.152649 261.390106,763.508972 261.952942,757.314514 
M552.941162,773.941162 
	C552.950317,775.104614 553.038330,776.273804 552.953857,777.430420 
	C552.692871,781.000732 551.621826,784.125732 547.600525,784.929260 
	C541.916016,786.065125 538.687805,784.386353 536.522278,780.093201 
	C535.931763,778.922546 533.967590,778.444702 531.950134,777.240906 
	C532.886536,785.339233 536.599792,788.683960 543.653259,789.093323 
	C551.390808,789.542480 557.127808,786.541626 557.564636,780.200867 
	C558.288513,769.694641 557.761536,759.102173 557.761536,748.588684 
	C554.361511,747.755127 553.678162,749.340332 553.475159,752.456299 
	C547.634949,747.085144 539.656860,746.320618 534.623901,750.649841 
	C529.794495,754.803894 528.730957,764.851685 532.533386,770.400391 
	C536.480408,776.160095 543.917480,777.351685 551.168579,773.429565 
	C551.679993,773.153076 552.360352,773.188843 552.941162,773.941162 
M687.632080,564.638611 
	C688.075928,563.816956 688.705566,563.044006 688.935669,562.166260 
	C692.374939,549.047791 695.767944,535.917236 699.156128,522.785461 
	C700.091858,519.158875 700.976074,515.518921 702.014343,511.362762 
	C697.869019,511.362762 694.451477,511.362762 690.281311,511.129425 
	C689.506653,511.210541 688.731934,511.291656 687.181580,511.144348 
	C684.179810,510.607483 682.655212,511.795074 681.892273,514.840698 
	C678.270508,529.297668 674.254150,543.657837 670.768433,558.146362 
	C663.384827,588.836243 656.249451,619.585815 648.977722,650.302734 
	C648.756226,651.238281 648.152283,652.083313 647.349548,653.756714 
	C646.665161,652.117798 646.289490,651.411560 646.068176,650.659912 
	C639.120728,627.066528 632.298706,603.435425 625.205811,579.885925 
	C621.810242,568.612305 617.905823,557.491821 614.350769,545.575439 
	C611.097717,535.291199 607.960938,524.966919 604.447144,514.772522 
	C603.998779,513.471558 601.633179,512.150208 600.071106,512.040283 
	C595.096313,511.690186 590.063721,512.166565 585.087646,511.825256 
	C580.962280,511.542297 579.049805,512.760437 577.783081,516.999390 
	C572.622681,534.268066 566.904297,551.369141 561.509705,568.568970 
	C555.037048,589.206055 548.622253,609.862000 542.313293,630.549622 
	C540.052307,637.963318 538.172119,645.493164 535.725281,654.399780 
	C534.847168,651.954651 534.429382,651.081116 534.217834,650.160156 
	C528.410278,624.880066 522.837646,599.543701 516.766052,574.327393 
	C511.936462,554.269470 506.537445,534.347839 501.250977,514.402832 
	C500.914185,513.132324 499.415253,511.291290 498.372162,511.232971 
	C492.750031,510.918884 487.101135,511.084351 481.578217,511.084351 
	C481.385681,511.858643 481.176208,512.208069 481.247009,512.486145 
	C494.513336,564.575989 507.776154,616.666748 521.221436,668.710449 
	C521.554932,670.001343 523.867126,671.547363 525.340820,671.640503 
	C530.817993,671.986572 536.349487,671.493408 541.823303,671.864868 
	C545.861145,672.138916 547.629761,670.810059 548.842224,666.878357 
	C560.954346,627.602661 573.271423,588.390076 585.546997,549.164795 
	C587.248596,543.727661 589.039124,538.318359 590.788452,532.896179 
	C592.429626,534.558899 593.050293,536.059875 593.537659,537.602844 
	C607.249207,581.011108 620.918884,624.432739 634.760376,667.799561 
	C635.267700,669.389038 637.366699,671.477417 638.842041,671.576538 
	C645.146240,672.000183 651.500061,671.899353 657.822571,671.650940 
	C659.052856,671.602600 660.947266,670.264832 661.291504,669.140869 
	C662.501099,665.191223 663.168213,661.075500 664.619690,656.556458 
	C667.124939,646.175049 669.630127,635.793579 672.690430,624.872742 
	C673.161926,622.666077 673.633484,620.459351 674.401672,617.969177 
	C674.558960,617.718262 674.578613,617.457153 674.917786,616.479065 
	C675.281006,614.844727 675.644226,613.210449 676.190552,611.618774 
	C676.190552,611.618774 676.038391,611.513733 676.439941,610.698608 
	C676.955383,608.382324 677.470764,606.066040 678.235413,603.725403 
	C678.235413,603.725403 678.095093,603.403320 678.536743,602.623474 
	C681.369202,590.088501 684.201721,577.553467 687.632080,564.638611 
M831.774902,569.393738 
	C831.706116,568.977539 831.637329,568.561340 831.814087,567.222717 
	C831.838196,563.227722 832.057556,559.218323 831.766235,555.246460 
	C831.679749,554.066895 830.231506,552.102722 829.280090,552.023376 
	C825.043640,551.670227 820.761108,551.870117 816.246948,551.870117 
	C816.246948,592.128723 816.246948,631.656433 816.246948,671.438843 
	C821.933044,671.438843 827.318665,671.438843 833.237183,671.438843 
	C833.237183,669.310120 833.236633,667.517578 833.237244,665.725037 
	C833.245667,641.418640 833.085510,617.110413 833.336731,592.806580 
	C833.455566,581.312073 836.156311,570.905945 849.871399,566.904114 
	C854.531738,566.782471 859.197876,566.451843 863.851135,566.582397 
	C873.689514,566.858398 879.640808,572.037964 881.229614,581.777405 
	C881.943054,586.151184 882.081238,590.618896 882.537415,595.676453 
	C885.169861,595.676453 887.622131,595.689087 890.074280,595.674255 
	C900.125122,595.613464 900.249756,595.602722 899.615479,585.358948 
	C897.808533,556.174072 878.746399,546.685791 853.636841,552.385376 
	C850.715088,553.048584 848.124023,555.168396 844.732727,556.725769 
	C839.918579,560.139465 834.974060,563.446899 833.316406,570.201843 
	C832.884094,570.341431 832.451721,570.481018 831.908997,570.209106 
	C831.908997,570.209106 831.539307,569.997375 831.774902,569.393738 
M97.530510,671.571350 
	C97.993889,671.439453 98.457268,671.307556 99.829895,671.164978 
	C119.535988,669.728455 136.748398,662.493530 150.615341,648.329102 
	C161.270447,637.445435 167.142426,624.011047 169.548859,609.011108 
	C172.647278,589.698059 171.644836,570.722839 163.184967,552.878174 
	C149.492691,523.996765 124.709167,512.421326 94.318672,511.226013 
	C75.200226,510.474060 56.028484,511.070129 36.880672,511.087250 
	C35.618580,511.088379 34.356682,511.309540 32.900433,511.446625 
	C32.900433,513.820618 32.900391,515.811462 32.900436,517.802246 
	C32.901585,566.254944 32.898331,614.707642 32.915852,663.160339 
	C32.916813,665.813538 33.090874,668.466736 33.344025,671.671265 
	C33.883404,671.652710 34.422783,671.634216 35.900127,671.693665 
	C39.384289,671.706055 42.868687,671.750671 46.352573,671.725281 
	C63.261600,671.602051 80.170494,671.460083 97.530510,671.571350 
M940.766418,651.717957 
	C950.310425,667.467957 964.692688,673.847961 982.674683,674.130493 
	C1000.476257,674.410156 1014.938049,668.574158 1025.105957,652.846375 
	C1025.825073,656.096375 1025.890137,658.857117 1025.813477,661.613892 
	C1025.527588,671.882751 1025.518433,671.619812 1035.854614,671.945374 
	C1040.660034,672.096680 1041.629150,670.366760 1041.617920,665.949890 
	C1041.492065,616.453857 1041.558716,566.957275 1041.530273,517.460876 
	C1041.529053,515.403198 1041.100830,513.345703 1040.843140,511.033020 
	C1036.591431,511.033020 1032.908081,511.347015 1029.301636,510.953918 
	C1024.739258,510.456635 1023.508911,512.478149 1023.551697,516.611084 
	C1023.677551,528.775635 1023.597534,540.942322 1023.597961,553.108215 
	C1023.598206,559.191223 1023.598083,565.274170 1023.598083,572.165588 
	C1022.246094,570.736938 1021.429260,569.804810 1020.540833,568.946838 
	C1016.615234,565.155823 1013.147461,560.639954 1008.639771,557.750244 
	C995.640808,549.416870 981.245361,549.211670 966.735413,552.655579 
	C955.513489,555.319092 946.677185,561.870300 941.266418,572.056763 
	C927.400818,598.160767 927.601196,624.610718 940.766418,651.717957 
M922.171265,617.500000 
	C922.177124,600.188171 922.183411,582.876282 922.188721,565.564453 
	C922.193787,549.085022 922.269775,532.604736 922.092407,516.127258 
	C922.074463,514.453003 920.673767,511.465607 919.668640,511.356628 
	C914.958557,510.846039 910.162354,511.129761 905.258484,511.129761 
	C905.258484,564.979492 905.258484,618.125732 905.258484,671.773254 
	C909.628052,671.773254 913.614502,671.505249 917.547729,671.848267 
	C921.519348,672.194702 922.282104,670.472046 922.250061,666.938904 
	C922.103699,650.793701 922.177979,634.646484 922.171265,617.500000 
M851.454956,770.808228 
	C848.974915,772.785461 849.340698,770.338867 849.070862,769.196472 
	C847.694397,763.369202 846.256775,757.550842 845.112366,751.676392 
	C844.457764,748.316162 842.749512,747.820679 839.572876,748.855042 
	C842.098267,758.662170 844.569824,768.260193 846.980530,777.621887 
	C851.503174,779.252075 853.845459,777.678528 854.906128,773.473816 
	C855.591492,770.757141 856.613770,768.119812 857.574585,765.479614 
	C858.939697,761.728394 860.387085,758.007080 862.156067,753.329529 
	C865.071045,761.484863 867.822937,768.281189 869.871643,775.283203 
	C870.954163,778.982849 873.379333,778.131836 875.703186,778.092773 
	C876.295288,778.082825 877.237915,777.225891 877.400085,776.598083 
	C879.788391,767.348389 882.072083,758.071655 884.547852,748.129395 
	C876.621948,748.788147 879.065857,755.137939 877.572815,758.650269 
	C875.760559,762.913574 875.277405,767.741821 873.942078,773.525879 
	C871.041931,765.363281 868.712646,758.453674 866.069336,751.666443 
	C865.541321,750.310730 863.640808,748.385498 862.678650,748.546692 
	C860.992737,748.829041 858.735474,750.197510 858.136353,751.683167 
	C855.717102,757.682434 853.839600,763.900269 851.454956,770.808228 
M368.812897,751.706665 
	C362.633026,746.923645 355.209839,746.532898 349.992004,750.716064 
	C344.892517,754.804321 343.052094,764.170532 346.014984,770.955750 
	C348.708801,777.124817 354.210388,779.827515 361.648224,778.635620 
	C368.031281,777.612854 370.875702,774.724121 371.455414,767.189209 
	C369.346222,768.635254 367.642090,769.233398 366.879761,770.430969 
	C364.381317,774.355713 360.671082,776.018738 355.724030,774.621033 
	C351.075195,773.307373 349.123871,770.030823 349.448212,764.683533 
	C356.863678,764.683533 364.107391,764.683533 372.983276,764.683533 
	C371.503235,759.948242 370.320740,756.165039 368.812897,751.706665 
M623.748962,755.473450 
	C624.614136,753.373291 625.479309,751.273071 626.378174,749.091248 
	C623.267883,747.557007 621.777161,748.323303 620.712158,751.136536 
	C618.164246,757.867004 615.370850,764.504395 612.201355,772.356628 
	C608.934509,764.638733 606.080688,758.153259 603.456787,751.576050 
	C602.349976,748.801819 600.912720,747.539795 597.469116,748.987732 
	C600.988770,757.241211 604.398682,765.307983 607.867371,773.349304 
	C610.684937,779.881226 609.180115,782.963562 602.393188,784.611328 
	C601.614868,784.800293 600.986755,785.607727 600.289001,786.128418 
	C600.961304,786.891785 601.502441,788.099182 602.328186,788.343201 
	C606.302002,789.517395 610.169312,787.472717 611.974548,783.295654 
	C615.866455,774.290100 619.668640,765.245850 623.748962,755.473450 
M662.268677,776.942261 
	C668.871521,772.173523 669.768188,765.553040 668.179871,758.239746 
	C666.904663,752.368469 662.143860,748.377930 656.271606,747.972046 
	C649.216370,747.484436 643.203064,750.577087 641.285461,756.290466 
	C639.050842,762.948730 639.194946,769.607239 644.477234,774.918457 
	C649.412537,779.880859 655.417725,779.816650 662.268677,776.942261 
M239.796890,763.444946 
	C239.612808,762.136108 239.519547,760.806824 239.229889,759.521729 
	C237.382111,751.323853 232.541183,747.590210 224.369949,748.002136 
	C217.281113,748.359619 212.158386,752.948242 211.135361,759.857117 
	C209.831985,768.659180 213.465347,775.813660 220.406708,778.113586 
	C230.196381,781.357239 237.412140,776.197205 239.796890,763.444946 
M799.392395,754.900146 
	C795.349609,764.518677 797.870361,774.024353 805.363525,777.416748 
	C812.691467,780.734375 820.416199,778.550232 824.087341,772.122559 
	C828.304565,764.738708 826.394775,754.586365 819.960571,750.184998 
	C813.519348,745.778870 805.349548,747.411499 799.392395,754.900146 
M190.883255,760.201416 
	C193.456909,760.666626 194.548721,759.845825 194.066040,757.035461 
	C193.273727,752.422302 189.216324,748.671387 184.022491,748.051758 
	C176.555634,747.160950 171.192810,750.030273 168.674118,756.263733 
	C165.718246,763.579224 167.738800,772.751709 173.369568,776.591492 
	C178.206619,779.889954 183.453781,779.838013 188.498856,777.222229 
	C192.468597,775.163940 194.517715,771.738831 193.714355,765.599731 
	C191.938461,766.959900 190.151978,767.613464 189.824707,768.704651 
	C188.522675,773.045715 185.734772,774.888916 181.381424,774.942932 
	C177.038300,774.996826 174.209503,773.008728 172.560989,769.018005 
	C170.066650,762.979675 172.581451,754.141846 177.408585,752.349854 
	C183.428406,750.115112 188.119492,752.879211 190.883255,760.201416 
M411.476532,750.983154 
	C404.411591,746.101257 395.379852,747.126160 391.005615,753.306152 
	C386.155457,760.158508 387.583221,771.954590 394.011139,776.420593 
	C398.963440,779.861450 404.361877,779.948730 409.585297,777.085938 
	C413.524231,774.927063 415.349762,771.446106 414.514221,765.163635 
	C412.591675,766.988647 411.127655,767.783142 410.591949,768.989807 
	C408.259033,774.244385 404.044464,776.200623 398.597931,774.243713 
	C393.081421,772.261597 390.815155,763.806580 393.791443,756.957397 
	C395.639832,752.703674 399.033508,751.720764 403.154144,751.973022 
	C406.807983,752.196716 409.369659,753.621338 410.430573,757.554260 
	C410.781342,758.854675 412.765106,759.714600 414.002228,760.775940 
	C414.555664,760.292419 415.109070,759.808960 415.662506,759.325439 
	C414.448151,756.714417 413.233795,754.103455 411.476532,750.983154 
M491.966187,776.656555 
	C491.972229,771.494751 491.936188,766.332397 491.994965,761.171204 
	C492.069244,754.652405 494.825562,751.824463 500.935608,751.873718 
	C506.707336,751.920349 508.953552,754.452148 509.064117,761.209961 
	C509.137665,765.704590 509.225800,770.207092 509.040985,774.695129 
	C508.904358,778.013000 510.105865,778.901672 513.413696,777.599365 
	C513.413696,774.150635 513.433105,770.669006 513.407776,767.187622 
	C513.385925,764.192017 513.505737,761.178772 513.225891,758.204956 
	C512.430542,749.754028 505.109039,745.330017 497.335297,748.658691 
	C495.414062,749.481323 493.816711,751.060303 492.363159,752.085205 
	C491.360992,750.844788 490.371277,749.619751 489.381592,748.394714 
	C488.750977,749.997009 487.612457,751.587646 487.575317,753.203552 
	C487.388702,761.328674 487.489746,769.460388 487.489746,778.278137 
	C489.187775,777.967896 490.527069,777.723267 491.966187,776.656555 
M434.700012,767.173828 
	C434.659760,776.295410 436.915344,778.733093 445.177795,778.055725 
	C446.412140,777.954590 447.546417,776.632446 448.726990,775.875061 
	C447.581665,775.304016 446.478394,774.373718 445.282623,774.233765 
	C442.446136,773.901672 439.201935,774.253113 439.089386,770.167542 
	C438.929626,764.368835 439.048828,758.562500 439.048828,752.196106 
	C441.294342,752.196106 443.123352,752.419861 444.863953,752.123840 
	C446.208649,751.895142 447.442566,751.014832 448.725739,750.424255 
	C447.356110,749.715942 446.053589,748.690186 444.599213,748.382507 
	C442.912323,748.025635 441.092041,748.299377 440.230927,748.299377 
	C439.043976,745.248901 438.185608,743.042908 437.327240,740.836914 
	C436.500916,742.981140 435.682037,745.128357 434.839417,747.266174 
	C434.725220,747.555847 434.491699,747.798462 434.313416,748.062866 
	C434.158539,747.840149 434.003632,747.617371 433.848755,747.394653 
	C432.305206,748.470215 430.761658,749.545715 429.218140,750.621277 
	C429.478210,751.053894 429.738312,751.486572 429.998413,751.919189 
	C431.416260,752.166870 432.834106,752.414490 434.700226,752.740417 
	C434.700226,757.317810 434.700226,761.782349 434.700012,767.173828 
M464.612305,755.654175 
	C464.613647,762.118530 464.697479,768.584412 464.581238,775.046692 
	C464.523865,778.238708 465.960938,778.688293 468.897461,777.639832 
	C468.897461,768.800659 468.863159,759.879517 468.915039,750.958862 
	C468.934662,747.584167 467.287506,747.612549 464.611053,748.721191 
	C464.611053,750.755127 464.611053,752.731567 464.612305,755.654175 
M146.964661,774.372986 
	C146.278000,779.716980 149.614395,778.192444 152.306030,777.802307 
	C152.306030,775.035461 152.306030,772.510254 152.306030,770.075195 
	C148.944748,769.372314 146.249466,769.166260 146.964661,774.372986 
M918.720764,773.737000 
	C918.790833,775.139526 918.860840,776.541992 918.930664,777.939636 
	C920.808838,777.939636 922.251465,777.939636 923.798706,777.939636 
	C923.798706,775.155151 923.798706,772.607361 923.798706,769.933411 
	C921.272095,770.008545 918.125549,768.367859 918.720764,773.737000 
M942.988586,769.851501 
	C936.598816,770.931946 941.324341,775.204102 939.860962,778.192688 
	C942.114197,778.070496 943.547363,777.992798 946.084473,777.855225 
	C945.230774,774.901489 944.502075,772.380188 942.988586,769.851501 
M105.015717,777.208801 
	C106.612366,777.583801 108.209023,777.958740 110.122841,778.408203 
	C110.122841,775.188477 110.122841,772.660217 110.122841,769.797791 
	C108.271271,769.894592 106.735245,769.974915 104.929558,770.069275 
	C104.929558,772.392883 104.929558,774.339417 105.015717,777.208801 
M903.173828,770.556213 
	C901.527649,770.256836 899.881470,769.957397 898.164246,769.645020 
	C898.164246,772.757751 898.164246,775.286255 898.164246,778.145996 
	C900.033997,778.045898 901.576233,777.963318 903.278198,777.872192 
	C903.278198,775.453491 903.278198,773.489929 903.173828,770.556213 
M125.792091,773.464539 
	C125.792091,774.895813 125.792091,776.327087 125.792091,778.166870 
	C127.908607,778.028198 129.447449,777.927368 130.801285,777.838684 
	C130.801285,774.889404 130.801285,772.469727 130.801285,770.050049 
	C130.366760,769.687317 129.932236,769.324585 129.497726,768.961853 
	C128.279022,770.141052 127.060318,771.320190 125.792091,773.464539 
z"/>
<path fill="#326EF4" opacity="1.000000" stroke="none" 
	d="
M664.041260,657.022766 
	C663.168213,661.075500 662.501099,665.191223 661.291504,669.140869 
	C660.947266,670.264832 659.052856,671.602600 657.822571,671.650940 
	C651.500061,671.899353 645.146240,672.000183 638.842041,671.576538 
	C637.366699,671.477417 635.267700,669.389038 634.760376,667.799561 
	C620.918884,624.432739 607.249207,581.011108 593.537659,537.602844 
	C593.050293,536.059875 592.429626,534.558899 590.788452,532.896179 
	C589.039124,538.318359 587.248596,543.727661 585.546997,549.164795 
	C573.271423,588.390076 560.954346,627.602661 548.842224,666.878357 
	C547.629761,670.810059 545.861145,672.138916 541.823303,671.864868 
	C536.349487,671.493408 530.817993,671.986572 525.340820,671.640503 
	C523.867126,671.547363 521.554932,670.001343 521.221436,668.710449 
	C507.776154,616.666748 494.513336,564.575989 481.247009,512.486145 
	C481.176208,512.208069 481.385681,511.858643 481.578217,511.084351 
	C487.101135,511.084351 492.750031,510.918884 498.372162,511.232971 
	C499.415253,511.291290 500.914185,513.132324 501.250977,514.402832 
	C506.537445,534.347839 511.936462,554.269470 516.766052,574.327393 
	C522.837646,599.543701 528.410278,624.880066 534.217834,650.160156 
	C534.429382,651.081116 534.847168,651.954651 535.725281,654.399780 
	C538.172119,645.493164 540.052307,637.963318 542.313293,630.549622 
	C548.622253,609.862000 555.037048,589.206055 561.509705,568.568970 
	C566.904297,551.369141 572.622681,534.268066 577.783081,516.999390 
	C579.049805,512.760437 580.962280,511.542297 585.087646,511.825256 
	C590.063721,512.166565 595.096313,511.690186 600.071106,512.040283 
	C601.633179,512.150208 603.998779,513.471558 604.447144,514.772522 
	C607.960938,524.966919 611.097717,535.291199 614.187378,546.147583 
	C613.936829,546.859985 613.874390,547.028564 613.755371,547.132141 
	C613.643005,547.229980 613.348816,547.322083 613.342590,547.309998 
	C612.531372,545.729797 611.514221,544.210999 610.985168,542.538025 
	C608.658142,535.180054 606.669373,527.708862 604.132263,520.427063 
	C603.199036,517.748596 601.407104,513.709106 599.458923,513.361572 
	C594.013428,512.390198 588.281677,513.023560 582.381836,513.023560 
	C582.441284,519.687012 575.467896,524.361267 578.869751,531.286743 
	C579.010742,531.573730 578.652832,532.298706 578.324646,532.594788 
	C571.556396,538.701416 571.824463,547.873657 568.495300,555.472229 
	C567.786133,557.090942 568.950684,559.530640 569.252014,561.592041 
	C569.742737,561.528809 570.233459,561.465576 570.724243,561.402344 
	C570.834534,559.832642 570.593018,558.115784 571.168335,556.740601 
	C571.615112,555.672363 573.080688,555.030273 574.093384,554.198730 
	C574.509766,555.258484 575.567627,556.644897 575.224854,557.317932 
	C574.106262,559.514160 572.296021,561.349976 571.096252,563.515320 
	C569.973328,565.541992 568.600098,567.832214 568.663452,569.967102 
	C568.703064,571.303528 571.195618,572.567200 572.587585,573.863464 
	C573.859314,572.382568 575.690125,571.101562 576.303711,569.385315 
	C578.672363,562.760193 580.666748,556.000610 582.772095,549.282227 
	C584.205200,544.709106 585.945923,540.191956 586.878662,535.515991 
	C587.553833,532.131287 589.003357,530.519348 592.496277,530.438965 
	C593.925293,533.879822 595.620728,537.239136 596.733704,540.781494 
	C599.754150,550.394958 602.414856,560.121216 605.424683,569.738159 
	C610.353516,585.486633 615.453064,601.181641 620.466797,616.903625 
	C625.126099,631.514160 629.632874,646.174988 634.465027,660.727966 
	C637.566833,670.069580 637.776367,670.000000 647.712830,670.000000 
	C649.377563,670.000000 651.071655,669.799133 652.701599,670.037354 
	C657.744995,670.774353 659.913757,668.219055 660.997070,663.661011 
	C661.549194,661.338318 662.997864,659.228699 664.041260,657.022766 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M399.990082,614.016541 
	C409.680542,611.653992 419.300323,608.926331 429.081696,607.033508 
	C438.516541,605.207764 448.105774,604.143921 457.654083,602.962769 
	C460.480774,602.613037 461.556641,601.725098 461.633667,598.681274 
	C462.096832,580.376709 455.720703,573.281250 437.378876,573.272583 
	C430.853821,573.269531 424.327972,574.953369 417.372040,575.902100 
	C416.941589,575.937012 416.865204,575.854614 416.958282,575.505737 
	C422.140228,573.254578 427.229095,571.352417 432.099091,569.532043 
	C426.925232,565.747314 426.935730,565.497375 432.167450,560.703003 
	C434.621552,558.454041 436.987122,556.108459 440.006592,553.219421 
	C431.261566,552.927734 423.171478,552.479980 415.326141,555.720947 
	C410.650513,557.652466 405.877502,559.348267 401.107178,561.108521 
	C401.066101,561.066956 400.983795,560.984070 400.983795,560.984070 
	C413.816467,550.770264 428.739319,549.174622 444.333954,550.611877 
	C461.936096,552.234131 475.569946,560.187317 482.273254,577.002747 
	C484.916626,583.633789 486.175842,591.195312 486.329834,598.370422 
	C486.837128,622.007996 486.553101,645.662537 486.555420,669.310608 
	C486.555481,669.943359 486.354950,670.576050 486.204163,671.467773 
	C478.322418,671.467773 470.598602,671.467773 462.237305,671.467773 
	C462.237305,665.530151 462.237305,659.855835 462.237305,653.624939 
	C438.423615,675.613770 416.271667,679.956238 393.538788,667.307861 
	C396.463654,667.663940 399.224884,668.165344 401.884033,668.994019 
	C404.539093,669.821472 407.047882,671.695862 409.697601,671.858276 
	C418.948425,672.425293 428.222504,672.377441 436.980072,668.536926 
	C438.922119,667.685303 441.793976,667.372620 442.617157,665.903625 
	C444.022003,663.396912 444.572632,660.160217 444.497498,657.255676 
	C444.479218,656.548706 440.653564,655.464417 438.542999,655.372864 
	C430.939819,655.043030 423.261261,655.607910 415.728119,654.775879 
	C409.880188,654.130127 405.495361,650.692993 403.595459,644.442383 
	C401.777405,638.460754 404.703857,633.886658 407.379944,628.970581 
	C411.434601,627.621948 415.031403,625.794189 418.834106,625.109009 
	C428.845154,623.305176 438.948120,622.013733 449.008118,620.476746 
	C450.584686,620.235840 452.127960,619.777344 453.944916,619.360168 
	C453.280731,615.873779 451.955353,615.507812 449.190582,617.193115 
	C447.354584,618.312378 445.039490,618.645691 442.938843,619.330750 
	C442.700409,618.718750 442.462006,618.106689 442.223572,617.494629 
	C444.004639,616.623352 446.109253,616.089233 447.471497,614.781677 
	C448.715820,613.587219 449.784149,611.530945 449.720551,609.901611 
	C449.681122,608.890930 447.421417,607.217285 446.098297,607.156921 
	C443.007812,607.016052 439.859467,607.533325 436.773132,608.025635 
	C426.770996,609.621155 416.445557,609.634766 406.612427,614.010498 
	C402.820587,615.025452 399.415070,616.029907 396.004333,616.766113 
	C395.998993,616.318909 395.998871,616.140015 395.998779,615.961182 
	C396.435028,615.672974 396.871277,615.384827 397.933044,615.037598 
	C399.035736,614.657959 399.512909,614.337280 399.990082,614.016541 
M407.981903,648.293579 
	C414.214325,655.895569 438.048187,654.647644 448.780090,648.313354 
	C457.918457,642.919617 463.041351,631.588135 460.879791,621.280579 
	C456.169861,621.887939 451.410522,622.397766 446.686127,623.132202 
	C436.209106,624.760925 425.654938,626.067932 415.309845,628.321350 
	C405.175659,630.528748 402.734131,637.660095 407.981903,648.293579 
z"/>
<path fill="#2F74F6" opacity="1.000000" stroke="none" 
	d="
M664.330444,656.789612 
	C662.997864,659.228699 661.549194,661.338318 660.997070,663.661011 
	C659.913757,668.219055 657.744995,670.774353 652.701599,670.037354 
	C651.071655,669.799133 649.377563,670.000000 647.712830,670.000000 
	C637.776367,670.000000 637.566833,670.069580 634.465027,660.727966 
	C629.632874,646.174988 625.126099,631.514160 620.466797,616.903625 
	C615.453064,601.181641 610.353516,585.486633 605.424683,569.738159 
	C602.414856,560.121216 599.754150,550.394958 596.733704,540.781494 
	C595.620728,537.239136 593.925293,533.879822 592.496277,530.438965 
	C589.003357,530.519348 587.553833,532.131287 586.878662,535.515991 
	C585.945923,540.191956 584.205200,544.709106 582.772095,549.282227 
	C580.666748,556.000610 578.672363,562.760193 576.303711,569.385315 
	C575.690125,571.101562 573.859314,572.382568 572.587585,573.863464 
	C571.195618,572.567200 568.703064,571.303528 568.663452,569.967102 
	C568.600098,567.832214 569.973328,565.541992 571.096252,563.515320 
	C572.296021,561.349976 574.106262,559.514160 575.224854,557.317932 
	C575.567627,556.644897 574.509766,555.258484 574.093384,554.198730 
	C573.080688,555.030273 571.615112,555.672363 571.168335,556.740601 
	C570.593018,558.115784 570.834534,559.832642 570.724243,561.402344 
	C570.233459,561.465576 569.742737,561.528809 569.252014,561.592041 
	C568.950684,559.530640 567.786133,557.090942 568.495300,555.472229 
	C571.824463,547.873657 571.556396,538.701416 578.324646,532.594788 
	C578.652832,532.298706 579.010742,531.573730 578.869751,531.286743 
	C575.467896,524.361267 582.441284,519.687012 582.381836,513.023560 
	C588.281677,513.023560 594.013428,512.390198 599.458923,513.361572 
	C601.407104,513.709106 603.199036,517.748596 604.132263,520.427063 
	C606.669373,527.708862 608.658142,535.180054 610.985168,542.538025 
	C611.514221,544.210999 612.531372,545.729797 613.342590,547.309998 
	C613.348816,547.322083 613.643005,547.229980 613.755371,547.132141 
	C613.874390,547.028564 613.936829,546.859985 614.127930,546.510864 
	C617.905823,557.491821 621.810242,568.612305 625.205811,579.885925 
	C632.298706,603.435425 639.120728,627.066528 646.068176,650.659912 
	C646.289490,651.411560 646.665161,652.117798 647.349548,653.756714 
	C648.152283,652.083313 648.756226,651.238281 648.977722,650.302734 
	C656.249451,619.585815 663.384827,588.836243 670.768433,558.146362 
	C674.254150,543.657837 678.270508,529.297668 681.892273,514.840698 
	C682.655212,511.795074 684.179810,510.607483 687.494751,511.578186 
	C686.185791,514.496887 683.787170,516.776123 683.065918,519.499634 
	C677.969482,538.743713 673.247070,558.086609 668.327209,577.378052 
	C667.481567,580.693604 668.156250,582.800232 671.174194,583.058960 
	C671.333862,586.374268 670.254272,590.735596 671.853699,592.230591 
	C676.990540,597.031738 676.098694,602.184937 674.952637,607.898621 
	C673.790588,613.691772 673.056763,619.570740 672.135376,625.412170 
	C669.630127,635.793579 667.124939,646.175049 664.330444,656.789612 
M576.613464,548.328613 
	C577.061951,549.402161 577.510498,550.475647 577.958984,551.549194 
	C578.429871,551.391235 578.900696,551.233337 579.371582,551.075378 
	C578.519043,548.459229 577.666565,545.843079 576.814026,543.226929 
	C576.197083,543.405396 575.580200,543.583801 574.963257,543.762268 
	C575.325073,545.156921 575.686829,546.551514 576.613464,548.328613 
M582.093079,527.377441 
	C582.892822,527.691528 583.692566,528.005615 584.492310,528.319702 
	C584.489685,527.438721 584.487061,526.557678 584.484436,525.676697 
	C583.686584,525.995422 582.888733,526.314209 582.093079,527.377441 
z"/>
<path fill="#1029CA" opacity="1.000000" stroke="none" 
	d="
M33.184361,671.119934 
	C33.090874,668.466736 32.916813,665.813538 32.915852,663.160339 
	C32.898331,614.707642 32.901585,566.254944 32.900436,517.802246 
	C32.900391,515.811462 32.900433,513.820618 32.900433,511.446625 
	C34.356682,511.309540 35.618580,511.088379 36.880672,511.087250 
	C56.028484,511.070129 75.200226,510.474060 94.318672,511.226013 
	C124.709167,512.421326 149.492691,523.996765 163.184967,552.878174 
	C171.644836,570.722839 172.647278,589.698059 169.548859,609.011108 
	C167.142426,624.011047 161.270447,637.445435 150.615341,648.329102 
	C136.748398,662.493530 119.535988,669.728455 99.150902,671.061829 
	C98.007744,671.080688 97.543587,671.202698 97.079422,671.324707 
	C80.170494,671.460083 63.261600,671.602051 46.352573,671.725281 
	C42.868687,671.750671 39.384289,671.706055 35.271088,671.468140 
	C34.156151,671.201782 33.670258,671.160828 33.184361,671.119934 
M129.060944,544.116577 
	C128.211014,543.015259 127.593170,541.407715 126.473648,540.894409 
	C118.993172,537.464661 111.761047,533.044128 102.868050,533.279358 
	C101.630249,532.618225 100.392441,531.957031 99.151413,531.294128 
	C100.968826,531.014648 102.743675,530.757507 104.512749,530.465332 
	C106.336731,530.164185 108.187820,529.953430 109.969604,529.484680 
	C114.814537,528.210266 116.491676,522.840149 113.483414,518.928528 
	C110.464363,515.002869 105.805000,514.734680 101.722885,514.182129 
	C94.555473,513.211975 87.238647,513.345703 79.049049,513.013062 
	C65.593071,513.008789 52.135078,513.131653 38.682392,512.918823 
	C34.896339,512.858948 33.911366,514.093689 33.933052,517.760620 
	C34.083332,543.176575 34.026173,568.593628 34.021381,594.946289 
	C34.016537,618.202454 34.100754,641.459290 33.922127,664.714111 
	C33.891136,668.748718 34.865376,670.175049 39.135849,670.097778 
	C53.749371,669.833313 68.370331,669.980225 83.919716,669.993347 
	C92.620987,668.999146 101.322258,668.005005 110.682800,667.007263 
	C111.122581,667.004028 111.562355,667.000793 112.754700,666.911560 
	C116.500519,665.606018 120.246338,664.300476 124.713539,662.924805 
	C134.563339,659.051086 142.757385,652.955444 149.454330,644.709839 
	C149.021423,644.209290 148.694000,643.858459 148.397293,643.483276 
	C145.288422,639.552490 139.269485,636.414795 144.766068,630.116760 
	C145.025513,629.819580 145.096176,628.956055 144.867691,628.694641 
	C141.307083,624.622559 143.947922,620.650818 144.815430,616.567261 
	C145.114655,615.158752 144.270767,613.507263 143.965042,611.038635 
	C143.972351,599.829529 144.016220,588.620239 143.976059,577.411316 
	C143.940109,567.374451 140.750977,558.182983 135.648621,549.728882 
	C134.351883,547.580322 131.402649,546.429016 129.060944,544.116577 
z"/>
<path fill="#1FAFF2" opacity="1.000000" stroke="none" 
	d="
M940.557983,651.416748 
	C927.601196,624.610718 927.400818,598.160767 941.266418,572.056763 
	C946.677185,561.870300 955.513489,555.319092 966.735413,552.655579 
	C981.245361,549.211670 995.640808,549.416870 1008.639771,557.750244 
	C1013.147461,560.639954 1016.615234,565.155823 1020.540833,568.946838 
	C1021.429260,569.804810 1022.246094,570.736938 1023.598083,572.165588 
	C1023.598083,565.274170 1023.598206,559.191223 1023.597961,553.108215 
	C1023.597534,540.942322 1023.677551,528.775635 1023.551697,516.611084 
	C1023.508911,512.478149 1024.739258,510.456635 1029.301636,510.953918 
	C1032.908081,511.347015 1036.591431,511.033020 1040.843140,511.033020 
	C1041.100830,513.345703 1041.529053,515.403198 1041.530273,517.460876 
	C1041.558716,566.957275 1041.492065,616.453857 1041.617920,665.949890 
	C1041.629150,670.366760 1040.660034,672.096680 1035.854614,671.945374 
	C1025.518433,671.619812 1025.527588,671.882751 1025.813477,661.613892 
	C1025.890137,658.857117 1025.825073,656.096375 1025.105957,652.846375 
	C1014.938049,668.574158 1000.476257,674.410156 982.674683,674.130493 
	C964.692688,673.847961 950.310425,667.467957 940.557983,651.416748 
M1002.657654,557.350403 
	C992.227356,552.716736 981.510254,551.875549 970.341431,554.290771 
	C959.579773,556.617981 952.077515,563.494873 945.277466,571.395630 
	C944.052917,572.818420 943.929626,575.189148 943.134033,577.623535 
	C941.761169,579.630676 939.863586,582.194885 938.252441,584.927917 
	C937.907227,585.513611 938.599365,586.710876 938.813660,587.626343 
	C939.817383,587.377197 941.038208,587.404663 941.780334,586.822327 
	C943.413635,585.540649 944.805176,583.950867 946.485962,582.299683 
	C947.433228,589.372375 947.192139,589.708618 941.352417,590.194580 
	C941.352417,592.267639 941.352417,594.324219 941.352417,596.815674 
	C944.129822,594.671387 947.257812,593.313538 948.640015,590.939331 
	C950.669983,587.452454 950.896729,582.917664 952.919006,579.423950 
	C955.036316,575.766174 957.808289,571.920532 961.285889,569.735718 
	C968.384216,565.276062 976.597900,563.800964 985.943054,563.984375 
	C998.833984,562.774353 1009.594116,567.733459 1019.207397,576.971741 
	C1019.993042,570.166687 1011.176575,560.718628 1002.657654,557.350403 
M1020.544556,652.622192 
	C1021.861572,650.950134 1023.178650,649.278137 1025.475464,646.362305 
	C1026.555420,649.682861 1027.718262,651.868347 1027.904663,654.134277 
	C1028.245361,658.272644 1027.935913,662.460815 1028.062378,666.623169 
	C1028.093872,667.659119 1028.676270,669.522278 1029.138672,669.565002 
	C1032.262695,669.853699 1035.520142,670.187500 1038.509888,669.512512 
	C1039.380127,669.316101 1039.929565,665.880737 1039.932861,663.928894 
	C1040.012207,616.601868 1040.033447,569.274719 1039.971802,521.947693 
	C1039.958374,511.688141 1041.663940,513.296204 1030.991333,512.916809 
	C1027.055176,512.776855 1025.840942,513.966858 1025.896729,517.961975 
	C1026.114990,533.623596 1025.790649,549.293823 1026.094604,564.952576 
	C1026.205200,570.651062 1025.271484,575.358704 1019.667175,578.286377 
	C1026.761841,589.988464 1024.587402,602.648132 1025.058472,614.984314 
	C1025.545532,627.738708 1025.313599,640.251221 1016.360901,651.269775 
	C1011.553589,656.788696 1004.770996,659.039124 998.188721,660.537415 
	C988.730103,662.690369 978.725098,659.377136 969.033264,663.159119 
	C966.325745,664.215637 965.057556,664.836487 963.993286,667.676758 
	C966.137207,668.568726 968.025024,669.926880 970.003052,670.074280 
	C977.173706,670.608398 984.400085,671.251892 991.558167,670.897644 
	C1002.456543,670.358521 1011.252563,665.014832 1018.416077,656.975952 
	C1019.308472,655.974426 1019.498718,654.347107 1020.544556,652.622192 
M1023.379639,629.638367 
	C1023.080688,616.886230 1023.612793,604.043640 1022.236877,591.408813 
	C1020.990723,579.965027 1013.535950,572.392822 1002.554443,568.482056 
	C994.650757,565.667358 986.584778,565.549988 978.424011,566.631348 
	C962.826843,568.698242 953.989685,577.037903 950.896179,592.490784 
	C948.389893,605.010254 948.131165,617.514343 950.527893,630.118713 
	C953.860901,647.647156 961.110107,655.786316 978.605225,658.374634 
	C999.002930,661.392334 1019.278259,654.665955 1023.379639,629.638367 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M193.017578,666.966064 
	C184.129166,662.756348 180.816376,654.685364 180.096741,645.888916 
	C178.509293,626.485229 188.417053,615.952942 206.543518,611.348572 
	C219.376358,608.088867 232.739761,606.917603 245.866486,604.815186 
	C250.097000,604.137573 254.361816,603.614075 258.534637,602.679443 
	C259.552795,602.451355 261.018341,600.864929 260.999878,599.928284 
	C260.905273,595.126160 260.855743,590.237427 259.897888,585.561829 
	C258.918762,580.782410 255.649231,577.277527 250.908554,575.649536 
	C239.480408,571.724915 228.045517,572.112000 216.738861,576.163940 
	C215.301331,576.679138 214.175171,578.063232 212.489990,578.719849 
	C213.360535,572.260376 218.580582,572.808777 223.346497,571.922668 
	C225.471558,571.524963 227.229263,570.914246 228.999496,570.875488 
	C234.827881,570.747864 240.660797,570.827515 246.491989,570.827515 
	C246.742523,570.369385 246.993073,569.911255 247.243622,569.453125 
	C242.926804,570.231018 240.667542,569.473145 242.803940,565.151855 
	C245.223572,565.151855 247.510696,565.151855 249.797821,565.151855 
	C249.422043,563.738098 248.916290,563.418091 248.425385,563.076782 
	C247.018188,562.098389 245.617004,561.111328 244.213470,560.127747 
	C245.259918,558.848755 246.390976,557.628052 247.324585,556.271362 
	C248.006027,555.281250 248.427429,554.112183 249.360123,553.011841 
	C270.443420,557.844971 283.583679,574.301270 283.998108,595.278015 
	C284.443024,617.794739 284.740570,640.315918 284.796082,662.835938 
	C284.801422,664.999329 285.524048,668.998596 280.930664,668.650818 
	C277.372467,667.373840 273.899780,666.484924 270.427063,665.596069 
	C270.382050,665.217224 270.337067,664.838440 270.292053,664.459656 
	C272.011841,663.750549 273.731628,663.041504 275.474365,662.322937 
	C278.978851,651.359375 275.899841,640.780090 274.523529,629.312317 
	C270.100433,632.839111 270.102234,632.975708 273.938538,641.215454 
	C271.320679,641.789917 268.605743,643.194397 266.409363,642.619141 
	C264.557678,642.134094 263.308228,639.349976 261.757294,637.542847 
	C259.780457,640.360657 258.296814,644.011169 255.590454,645.985596 
	C251.143661,649.229614 245.904099,651.386963 240.592377,654.010315 
	C236.122757,654.682861 232.059555,655.347534 227.538025,656.008728 
	C222.095428,655.302368 217.079834,654.755554 212.152832,653.767212 
	C211.144119,653.564880 210.467331,651.707703 209.953232,650.741577 
	C222.240601,656.271484 234.084961,654.429993 245.528778,649.531677 
	C256.318939,644.913025 263.063080,634.655212 260.360809,621.064575 
	C246.132111,623.134644 231.745926,624.548828 217.682022,627.499207 
	C207.346329,629.667480 205.706558,630.950500 205.216934,640.445557 
	C205.049026,643.701599 207.989212,647.117920 209.120605,650.599182 
	C207.430847,650.028015 205.570175,649.652649 204.916687,648.557129 
	C199.216934,639.002502 203.351547,628.927917 214.399963,625.951843 
	C216.417786,625.596741 218.036301,625.298828 219.650726,624.980347 
	C223.323898,624.255493 227.726257,624.532532 230.485580,622.538635 
	C234.090073,619.934204 237.236404,620.039001 241.125916,620.406372 
	C243.682648,620.647888 246.368744,619.519714 249.197952,619.257812 
	C250.204590,619.601501 251.010162,619.692200 251.815750,619.782959 
	C251.831818,619.520325 251.847900,619.257751 251.863968,618.995117 
	C250.911148,618.995117 249.958313,618.995117 249.278366,618.709229 
	C252.634033,617.914612 255.716843,617.405823 257.800659,617.061890 
	C258.067627,612.701050 258.255249,609.636047 258.454315,606.384216 
	C252.962982,606.646912 247.464142,606.688110 242.014114,607.224915 
	C234.624100,607.952637 227.169876,608.589844 219.934952,610.153320 
	C214.139069,611.405762 208.628998,613.980835 202.675415,615.996826 
	C197.760284,616.771301 194.091599,619.015442 190.547989,622.099854 
	C184.478577,627.382690 180.106354,641.517700 182.521942,649.111023 
	C184.161194,654.263977 186.492096,658.771545 190.684311,662.353760 
	C191.893173,663.386719 192.263062,665.401428 193.017578,666.966064 
M263.563690,618.846741 
	C266.107910,614.631653 265.570984,607.147766 262.838959,604.945068 
	C262.269012,608.812805 261.593658,612.844482 261.165161,616.902100 
	C261.101349,617.506592 262.198822,618.233765 263.563690,618.846741 
M250.608154,571.793579 
	C250.213425,571.788757 249.818710,571.783936 249.423981,571.779175 
	C249.423004,571.942627 249.410751,572.248108 249.422668,572.249084 
	C249.815475,572.280212 250.210571,572.282166 250.608154,571.793579 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M673.000366,191.621216 
	C680.127686,181.273590 686.823181,170.592163 694.497314,160.667404 
	C703.057434,149.596603 721.080872,152.049423 726.544678,165.312836 
	C731.637390,177.675232 735.756470,190.548843 739.058289,203.514648 
	C744.941101,226.615479 745.147034,250.216827 743.021545,273.856812 
	C742.814697,276.157227 742.184326,278.419556 741.381836,280.856201 
	C741.013062,281.012848 740.925659,280.928741 740.949097,280.484528 
	C741.036133,278.046967 741.166443,276.053192 741.152466,274.060364 
	C741.058289,260.668274 741.454041,247.243927 740.622559,233.896576 
	C740.127319,225.946503 739.253052,226.040756 730.947693,225.977386 
	C728.307312,225.957245 725.635376,225.855682 723.045837,225.402954 
	C721.964600,225.213928 720.805542,224.191223 720.131409,223.231720 
	C718.902832,221.483124 718.009583,219.498917 716.976379,217.613007 
	C716.127869,218.927383 716.055664,219.890381 716.138977,220.839752 
	C717.077148,231.529526 718.182861,242.208038 718.864380,252.913712 
	C718.989136,254.873383 717.414490,256.941254 716.501526,258.567383 
	C717.222290,235.898834 714.472290,214.149292 707.248657,192.191208 
	C705.610229,194.434052 704.631348,195.955139 703.473450,197.324905 
	C700.104614,201.309921 696.664062,205.234360 693.179504,208.788055 
	C693.605652,206.898483 693.766296,205.151108 694.661682,203.952820 
	C698.195740,199.223389 698.027161,196.467850 693.712952,192.162476 
	C692.688416,191.139984 691.870728,189.876221 691.112244,188.629547 
	C690.172974,187.085648 689.394409,185.443970 688.121155,183.042603 
	C688.630432,182.413361 689.810059,180.955917 691.201294,179.236923 
	C689.934998,178.241470 688.823547,177.367813 687.093018,176.007416 
	C686.687439,178.409332 686.380859,180.224869 686.074280,182.040421 
	C685.788330,181.693237 685.502380,181.346039 685.216431,180.998856 
	C683.613159,182.434525 682.028564,183.891800 680.402283,185.300873 
	C677.950317,187.425339 675.469177,189.516144 673.000366,191.621216 
M738.963928,222.716324 
	C738.718384,222.512100 738.472839,222.307877 738.227234,222.103638 
	C738.232300,222.702759 738.237366,223.301865 738.242493,223.901001 
	C738.483398,223.685974 738.724304,223.470963 738.963928,222.716324 
z"/>
<path fill="#18A7F6" opacity="1.000000" stroke="none" 
	d="
M922.172119,618.000000 
	C922.177979,634.646484 922.103699,650.793701 922.250061,666.938904 
	C922.282104,670.472046 921.519348,672.194702 917.547729,671.848267 
	C913.614502,671.505249 909.628052,671.773254 905.258484,671.773254 
	C905.258484,618.125732 905.258484,564.979492 905.258484,511.129761 
	C910.162354,511.129761 914.958557,510.846039 919.668640,511.356628 
	C920.673767,511.465607 922.074463,514.453003 922.092407,516.127258 
	C922.269775,532.604736 922.193787,549.085022 922.188721,565.564453 
	C922.183411,582.876282 922.177124,600.188171 922.172119,618.000000 
z"/>
<path fill="#181B9C" opacity="1.000000" stroke="none" 
	d="
M705.128296,371.338440 
	C705.128296,371.338440 705.206482,371.707550 704.876465,371.986084 
	C704.395386,372.561981 704.244385,372.859344 704.093384,373.156708 
	C704.093384,373.156708 704.033264,373.048065 703.751526,373.066528 
	C702.323730,373.734467 701.177612,374.383911 699.895386,374.724792 
	C699.139587,370.203430 698.547729,365.986206 697.881714,361.780792 
	C697.703308,360.654358 697.227112,359.577576 697.009827,358.454376 
	C696.041626,353.449188 693.941528,352.384277 689.522705,354.705933 
	C685.366821,356.889435 683.045898,355.176392 682.545166,350.447998 
	C682.350891,348.614044 680.360291,346.800995 678.790405,345.435364 
	C678.502075,345.184570 676.300354,347.133270 674.681030,347.903259 
	C670.170776,343.268463 666.222961,338.501221 661.640808,334.451752 
	C659.378479,332.452423 657.843750,328.413696 653.480103,329.612274 
	C653.450439,329.549744 653.335144,329.473175 653.497437,329.127869 
	C650.912109,322.179504 647.350220,319.570984 641.483887,319.931152 
	C641.108032,319.775299 640.960327,319.610931 640.893738,319.017761 
	C639.247986,316.779205 637.588684,314.908813 636.301208,313.019989 
	C637.329956,312.173859 638.705933,311.130829 638.532471,310.551727 
	C636.816467,304.820709 634.573608,303.755890 628.301941,306.069702 
	C626.977905,300.525726 625.671387,295.054962 624.365479,289.585632 
	C615.985962,287.641663 610.539856,283.697571 609.237000,278.008209 
	C611.661865,278.833008 613.701843,279.757660 615.839478,280.148529 
	C616.790771,280.322479 617.938782,279.420715 619.390137,279.010071 
	C622.902527,279.643829 626.022278,280.274719 629.096741,280.926331 
	C629.051453,280.947021 629.085022,280.853241 629.091919,281.220276 
	C631.392761,283.254761 633.494751,285.342712 636.023071,286.496765 
	C640.461609,288.522675 643.387817,293.703369 649.130310,292.889313 
	C649.080444,292.907654 649.119690,292.808899 649.104980,293.170227 
	C651.520874,295.644196 653.950195,297.758209 656.382263,299.869080 
	C661.009460,303.885223 666.189270,307.033905 669.818420,312.470551 
	C672.734985,316.839661 677.782104,319.897003 682.193359,323.111664 
	C684.431580,324.742737 685.347046,329.077240 689.584167,327.357300 
	C689.611023,327.419769 689.744324,327.446686 689.541260,327.798279 
	C691.199219,330.578278 692.898132,333.170715 695.053467,335.301331 
	C695.502380,335.745087 697.640381,334.480011 699.217590,333.892700 
	C699.706116,333.584351 699.897583,333.326721 700.373901,333.050690 
	C703.089661,335.162109 705.441162,337.232910 708.466431,339.897003 
	C708.401550,345.114349 709.117737,345.433594 715.303345,342.619598 
	C716.551819,342.051636 718.416809,342.838806 719.960571,343.356537 
	C715.868469,350.760498 711.751709,357.775024 707.819092,364.891357 
	C707.162781,366.079132 707.307007,367.709320 707.081360,369.135071 
	C707.081360,369.135071 707.043823,369.042023 706.728821,369.081390 
	C705.985291,369.859985 705.556824,370.599213 705.128296,371.338440 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M782.217224,560.908142 
	C782.217224,560.908142 782.637817,560.911316 782.826172,561.368286 
	C786.419617,564.827209 789.824646,567.829224 793.229675,570.831238 
	C793.229675,570.831238 793.155273,570.962830 793.111816,571.281006 
	C793.855530,572.263489 794.642700,572.927856 795.429871,573.592224 
	C795.429871,573.592224 795.283020,573.550110 795.219360,574.007202 
	C795.983765,575.244812 796.811951,576.025391 797.640137,576.805908 
	C803.229248,584.035583 805.271179,592.609436 806.318665,601.360962 
	C808.811035,622.184021 805.571289,641.654114 790.795105,657.672058 
	C787.379761,661.374512 782.768372,663.973694 778.127441,666.761780 
	C775.573364,667.019409 773.364502,667.213196 771.672852,668.250488 
	C767.972595,670.519348 764.638306,670.018616 760.329956,667.615417 
	C762.359314,665.872681 763.975647,664.484619 765.790710,662.925903 
	C763.933899,659.588440 765.982361,658.655457 768.893494,657.437134 
	C776.480774,654.262146 784.405396,651.132751 784.922668,640.958252 
	C784.939209,640.956970 784.952271,640.930054 785.310913,640.902466 
	C787.084045,637.859802 789.010010,634.976257 789.825867,631.806946 
	C793.663208,616.899109 793.013733,602.060974 788.166321,587.453674 
	C787.870422,586.562195 786.474182,586.035889 785.598267,585.293213 
	C785.606567,585.246704 785.700256,585.234192 785.798950,584.834717 
	C786.092041,582.113892 785.922424,580.056702 782.828613,580.095093 
	C782.868103,580.041870 782.851135,580.173279 782.896240,579.784851 
	C782.322021,575.897888 781.404175,572.698364 776.876465,573.172302 
	C776.990112,573.115417 776.784302,573.264587 776.812500,572.887390 
	C776.085205,572.183838 775.329712,571.857483 774.550537,571.490356 
	C774.526855,571.449463 774.601807,571.505920 774.627686,571.121826 
	C768.525024,565.563843 760.960144,564.608398 753.465576,564.131226 
	C748.247620,563.798950 742.952271,564.654053 737.693176,565.004578 
	C734.168701,565.239441 732.256042,564.234863 735.151733,558.999512 
	C732.203308,560.177490 730.689026,560.782410 729.174805,561.387390 
	C729.900696,562.664612 730.626587,563.941772 731.391479,565.287598 
	C728.630127,568.704468 725.268677,569.423950 722.186279,566.864563 
	C721.579834,569.088623 720.970032,571.081055 720.508240,573.107178 
	C720.218140,574.379639 720.150391,575.702759 719.829590,577.088745 
	C719.676208,577.174377 719.326660,577.140137 718.937622,577.103210 
	C717.596497,577.783020 716.263611,578.297668 715.757507,579.251099 
	C713.412292,583.669067 711.296631,588.208801 709.580322,591.719727 
	C707.294861,590.983398 705.173828,589.543823 703.988098,590.099487 
	C702.309387,590.886292 700.844055,592.967102 700.152283,594.818054 
	C699.189453,597.394348 699.026917,600.269775 698.521851,603.017151 
	C697.880981,602.901611 697.240051,602.786072 696.599182,602.670471 
	C695.590942,598.091248 696.829285,593.693176 699.134949,589.649170 
	C701.293457,585.863281 703.978760,582.377686 706.377319,578.840881 
	C707.042847,579.665955 707.978943,580.826416 708.915039,581.986877 
	C709.426208,581.873352 709.937439,581.759827 710.448608,581.646362 
	C710.448608,580.047791 710.756775,578.374146 710.388794,576.865295 
	C709.425781,572.916260 709.829529,569.884827 713.825806,567.610779 
	C717.352051,565.604126 719.484253,561.777344 723.831726,560.486816 
	C729.549072,558.789612 734.835754,555.449585 740.607788,554.168152 
	C752.734436,551.475830 764.579834,553.029663 775.750854,558.822693 
	C776.801331,559.367432 778.216370,559.209351 779.462158,559.377625 
	C779.462158,559.377625 779.467346,559.485596 779.451294,559.870422 
	C780.362549,560.472900 781.289917,560.690491 782.217224,560.908142 
M769.971008,663.155518 
	C769.664917,664.046570 769.358826,664.937683 769.052734,665.828735 
	C769.769470,665.798645 770.486267,665.768555 771.203064,665.738464 
	C771.010437,664.867859 770.817810,663.997192 769.971008,663.155518 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M784.906128,640.959473 
	C784.405396,651.132751 776.480774,654.262146 768.893494,657.437134 
	C765.982361,658.655457 763.933899,659.588440 765.790710,662.925903 
	C763.975647,664.484619 762.359314,665.872681 760.329956,667.615417 
	C764.638306,670.018616 767.972595,670.519348 771.672852,668.250488 
	C773.364502,667.213196 775.573364,667.019409 777.769043,666.705566 
	C771.508484,669.198853 765.176147,672.640808 758.520325,673.462219 
	C717.943848,678.469910 693.930420,650.044434 692.857727,615.750244 
	C692.353271,599.620911 695.340881,584.548279 705.793030,571.374207 
	C715.377747,559.293518 727.816101,552.928650 742.700928,551.200256 
	C753.020752,550.001953 763.353699,550.770630 773.105042,554.917969 
	C775.316284,555.858398 777.244202,557.465149 779.382324,559.071411 
	C778.216370,559.209351 776.801331,559.367432 775.750854,558.822693 
	C764.579834,553.029663 752.734436,551.475830 740.607788,554.168152 
	C734.835754,555.449585 729.549072,558.789612 723.831726,560.486816 
	C719.484253,561.777344 717.352051,565.604126 713.825806,567.610779 
	C709.829529,569.884827 709.425781,572.916260 710.388794,576.865295 
	C710.756775,578.374146 710.448608,580.047791 710.448608,581.646362 
	C709.937439,581.759827 709.426208,581.873352 708.915039,581.986877 
	C707.978943,580.826416 707.042847,579.665955 706.377319,578.840881 
	C703.978760,582.377686 701.293457,585.863281 699.134949,589.649170 
	C696.829285,593.693176 695.590942,598.091248 696.599182,602.670471 
	C697.240051,602.786072 697.880981,602.901611 698.521851,603.017151 
	C699.026917,600.269775 699.189453,597.394348 700.152283,594.818054 
	C700.844055,592.967102 702.309387,590.886292 703.988098,590.099487 
	C705.173828,589.543823 707.294861,590.983398 709.580322,591.719727 
	C711.296631,588.208801 713.412292,583.669067 715.757507,579.251099 
	C716.263611,578.297668 717.596497,577.783020 718.877930,577.468750 
	C710.966675,591.742493 708.984924,606.804993 710.895142,622.539917 
	C712.227112,633.511536 715.351929,643.782532 724.563293,650.914734 
	C737.344482,660.810974 751.763428,659.079651 766.183594,656.152161 
	C774.959595,654.370544 779.949707,647.612305 784.906128,640.959473 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M848.932312,566.776062 
	C836.156311,570.905945 833.455566,581.312073 833.336731,592.806580 
	C833.085510,617.110413 833.245667,641.418640 833.237244,665.725037 
	C833.236633,667.517578 833.237183,669.310120 833.237183,671.438843 
	C827.318665,671.438843 821.933044,671.438843 816.246948,671.438843 
	C816.246948,631.656433 816.246948,592.128723 816.246948,551.870117 
	C820.761108,551.870117 825.043640,551.670227 829.280090,552.023376 
	C830.231506,552.102722 831.679749,554.066895 831.766235,555.246460 
	C832.057556,559.218323 831.838196,563.227722 831.548157,567.837036 
	C831.367920,568.966675 831.453613,569.481995 831.539307,569.997375 
	C831.539307,569.997375 831.908997,570.209106 832.169800,570.627441 
	C834.435303,574.568542 836.025940,572.254211 837.791382,570.734009 
	C840.976624,567.991211 843.856567,564.248962 848.932312,566.776062 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M652.084045,78.744041 
	C666.098267,86.593712 677.913025,97.176308 688.077393,109.400887 
	C694.495544,117.119873 693.913330,128.406525 688.235779,135.893127 
	C682.208191,143.841232 673.805786,145.825928 661.299561,141.733582 
	C652.053345,133.290085 643.314697,125.248619 635.004395,117.144989 
	C638.774902,117.785133 642.117004,118.487427 645.567688,119.212555 
	C646.445496,110.821815 646.445496,110.821815 653.920654,111.451683 
	C648.981567,107.640907 644.987122,104.637184 641.080078,101.523682 
	C636.668213,98.007896 632.334961,94.393478 628.331787,90.853043 
	C643.307983,101.766792 657.919800,112.649467 672.322021,123.376030 
	C672.630127,118.466728 662.085205,108.690994 636.119507,89.846916 
	C643.389282,89.541031 647.236694,95.053734 653.308105,98.667664 
	C656.306580,96.623474 660.111145,94.029724 664.651489,90.934410 
	C664.091919,90.354004 662.915405,89.055275 661.656067,87.842499 
	C658.485291,84.789009 655.277161,81.774300 652.084045,78.744041 
z"/>
<path fill="#19168E" opacity="1.000000" stroke="none" 
	d="
M407.015900,629.012146 
	C404.703857,633.886658 401.777405,638.460754 403.595459,644.442383 
	C405.495361,650.692993 409.880188,654.130127 415.728119,654.775879 
	C423.261261,655.607910 430.939819,655.043030 438.542999,655.372864 
	C440.653564,655.464417 444.479218,656.548706 444.497498,657.255676 
	C444.572632,660.160217 444.022003,663.396912 442.617157,665.903625 
	C441.793976,667.372620 438.922119,667.685303 436.980072,668.536926 
	C428.222504,672.377441 418.948425,672.425293 409.697601,671.858276 
	C407.047882,671.695862 404.539093,669.821472 401.884033,668.994019 
	C399.224884,668.165344 396.463654,667.663940 393.326843,667.039429 
	C392.906494,667.059753 392.951172,667.017761 392.946167,666.703857 
	C391.991425,665.585205 391.041626,664.780396 390.063660,663.605042 
	C388.959442,660.221191 388.190674,656.906555 383.933533,657.013611 
	C383.661072,656.662537 383.388580,656.311401 383.149384,655.345215 
	C383.129944,653.817871 383.077209,652.905640 383.016876,651.619995 
	C383.003876,650.499817 382.998535,649.752930 382.995697,648.539062 
	C382.946136,642.207886 382.894073,636.343689 382.842010,630.479553 
	C382.842010,630.479553 382.762878,630.205933 383.202271,630.074768 
	C384.111969,629.300476 384.582306,628.657410 385.317200,627.768799 
	C385.632996,626.578918 385.684235,625.634521 385.735504,624.690186 
	C385.735504,624.690186 385.801331,624.877136 386.121460,624.879395 
	C389.630890,622.265930 392.820221,619.650146 396.009521,617.034424 
	C399.415070,616.029907 402.820587,615.025452 406.818909,614.259155 
	C410.358856,614.281738 413.306000,614.066223 416.567505,613.827637 
	C416.078522,616.417542 415.766541,618.069885 415.362427,620.210144 
	C413.145813,618.533203 411.526672,617.308228 408.770508,615.223083 
	C408.084747,620.612427 407.550323,624.812256 407.015900,629.012146 
M439.450714,662.912476 
	C439.736450,662.090881 440.022217,661.269287 440.307953,660.447632 
	C439.568115,660.510986 438.828308,660.574280 438.088470,660.637634 
	C438.307434,661.404663 438.526398,662.171631 439.450714,662.912476 
M404.049042,616.161804 
	C404.199951,616.414795 404.301483,616.796997 404.517609,616.879578 
	C404.688263,616.944824 404.999786,616.641541 405.248749,616.501892 
	C404.999451,616.361816 404.750183,616.221802 404.049042,616.161804 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M654.176636,172.049301 
	C653.256104,172.990005 652.335510,173.930710 651.046204,175.132294 
	C650.409546,175.551422 650.365112,175.757950 650.544128,176.012772 
	C650.415771,176.131378 650.287476,176.249969 649.754639,176.663406 
	C649.165527,177.264099 648.980896,177.569946 648.796204,177.875793 
	C648.512634,177.994675 648.273743,178.173248 647.658203,178.701782 
	C645.488159,180.981140 643.739441,182.970200 641.990723,184.959244 
	C641.990723,184.959244 641.983276,184.984314 641.714111,185.028976 
	C641.318970,185.376923 641.192993,185.680206 641.066956,185.983490 
	C641.066956,185.983490 640.847412,186.249039 640.516602,186.483917 
	C639.851501,187.162033 639.517273,187.605255 639.182983,188.048462 
	C638.272949,188.958618 637.362976,189.868774 635.952393,191.101791 
	C632.627991,194.609482 629.804077,197.794281 626.980164,200.979080 
	C626.980164,200.979080 627.000000,201.000000 626.675354,201.030212 
	C625.917358,201.714294 625.483948,202.368134 625.050598,203.021973 
	C625.050598,203.021973 624.997437,203.003952 624.688843,203.073578 
	C623.489380,204.032135 622.598511,204.921051 621.707703,205.809952 
	C621.707703,205.809952 621.327271,205.920609 620.850220,206.060837 
	C618.422180,207.347443 616.471191,208.493820 614.520142,209.640198 
	C614.167419,209.689270 613.814758,209.738327 612.789429,209.842010 
	C608.745789,209.599197 605.374817,209.301773 602.003784,209.004364 
	C602.003784,209.004364 601.676147,208.826569 601.313171,208.515808 
	C590.928955,200.518112 580.907776,192.831192 571.304443,185.092285 
	C574.722473,186.099823 577.699463,187.232529 580.730835,188.193542 
	C582.809937,188.852692 585.120483,189.961411 587.024536,189.530426 
	C588.088928,189.289505 588.534790,186.316330 589.668335,183.591232 
	C591.530823,184.448395 593.713440,185.628922 596.021606,186.461212 
	C597.978394,187.166779 600.080200,187.469818 602.472046,187.998566 
	C603.885986,188.800705 604.889832,189.653641 606.015381,190.292221 
	C610.190796,192.661026 615.986450,188.979950 616.021484,183.788147 
	C616.029297,183.593796 616.101379,183.211502 616.359009,183.207123 
	C616.781799,183.155197 616.946960,183.107651 617.474976,183.048325 
	C619.571472,181.025269 621.305176,179.013992 623.136536,176.913269 
	C623.234192,176.823837 623.498108,176.801727 623.837646,176.727692 
	C624.374451,176.256866 624.571655,175.860062 624.757874,175.335114 
	C624.746826,175.206985 624.912292,175.009979 625.234131,174.974915 
	C626.084961,174.342514 626.614014,173.745163 627.435181,173.056854 
	C628.194153,172.352417 628.661072,171.738937 629.427002,171.047333 
	C630.190308,170.351883 630.654602,169.734543 631.413208,169.058044 
	C632.177612,168.648315 632.647766,168.297745 633.403076,168.120163 
	C635.798279,169.838272 637.908264,171.383377 640.155518,173.028992 
	C642.156128,171.494431 643.987915,170.102280 645.805847,168.692230 
	C649.286743,165.992371 652.755798,163.277298 656.237183,160.578125 
	C658.834473,158.564392 658.463989,151.532028 655.758240,149.683441 
	C654.518677,152.763016 653.266785,155.873077 651.955322,159.131180 
	C645.923889,155.204849 646.839966,152.638275 656.248474,145.945511 
	C652.824951,142.119965 649.382263,138.430191 646.142639,134.569931 
	C644.962830,133.164093 644.266968,131.352188 643.686890,129.765350 
	C649.012329,134.871902 654.001160,139.938644 658.990051,145.005386 
	C658.990051,145.005386 659.000000,145.000000 659.000122,145.402161 
	C659.747620,150.554596 660.494934,155.304886 661.242249,160.055161 
	C661.242249,160.055161 661.058777,160.495865 660.731873,160.918121 
	C659.634888,163.242065 658.864746,165.143723 658.094666,167.045395 
	C658.094666,167.045395 658.019409,167.022934 657.797119,167.095367 
	C657.279114,167.391342 657.202820,167.674088 657.345947,168.016052 
	C657.265381,168.165497 657.184814,168.314957 656.781921,168.793976 
	C656.306885,169.414612 656.154114,169.705704 656.001343,169.996796 
	C656.001343,169.996780 656.000000,170.000000 655.761841,170.082367 
	C655.382141,170.455231 655.240540,170.745712 655.098999,171.036179 
	C655.098938,171.036163 655.023865,171.022736 654.793579,171.114288 
	C654.256042,171.405243 654.127197,171.686386 654.176636,172.049301 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M587.696167,240.810577 
	C577.134827,245.036819 568.409302,241.745850 560.467407,234.446564 
	C551.297913,226.019028 540.545532,221.729813 527.204285,222.934555 
	C526.319092,223.057892 526.148376,223.111847 525.977600,223.165833 
	C525.536682,223.139740 525.095764,223.113647 523.950317,223.096649 
	C518.859009,224.440048 514.472290,225.774353 510.048706,227.065094 
	C510.011902,227.021545 509.936554,226.935928 509.957458,226.614563 
	C509.986786,225.863342 509.995239,225.433502 510.250305,224.999756 
	C510.496979,224.995850 510.990234,224.986160 511.312439,224.964493 
	C512.423889,224.296707 513.213196,223.650589 514.402710,222.995575 
	C531.355469,217.802017 546.910706,219.775223 560.926880,230.664124 
	C562.088562,227.632248 563.160828,225.831711 563.376404,223.933853 
	C563.529785,222.583145 562.817688,220.858231 561.914124,219.787979 
	C561.505554,219.304108 559.660217,219.888504 558.526306,220.203049 
	C557.417236,220.510696 555.859314,221.771545 555.413757,221.427765 
	C553.956116,220.302979 552.915405,218.637711 551.077271,216.407104 
	C559.222717,216.882080 559.358154,216.767761 558.365967,211.993378 
	C560.242859,212.233978 561.736816,212.471329 563.187744,212.743271 
	C563.144714,212.777863 563.221130,212.724014 563.281494,213.078003 
	C564.563477,213.945618 565.785095,214.459244 567.006104,214.983887 
	C567.005554,214.994919 567.026611,214.987549 567.055786,215.321686 
	C567.782410,216.036209 568.479797,216.416595 569.186768,216.846390 
	C569.196350,216.895782 569.137573,216.814102 569.238403,217.105194 
	C569.894897,217.591705 570.450562,217.787125 571.004272,217.990356 
	C571.002441,217.998169 571.017273,217.992462 571.043396,218.320801 
	C571.738831,219.071014 572.408142,219.492889 573.064331,219.937439 
	C573.051270,219.960114 573.088745,219.906662 573.088501,220.262161 
	C574.296875,221.781738 575.418579,223.075394 576.790649,223.995895 
	C577.051636,224.170975 578.303101,222.869553 579.114868,221.947693 
	C579.163269,221.446930 579.194275,221.247879 579.511597,220.986633 
	C579.449402,220.307877 579.100891,219.691315 578.681152,218.964294 
	C578.609863,218.853851 578.702148,218.607697 578.683411,218.263580 
	C578.095825,217.633270 577.526917,217.347076 576.974060,217.034241 
	C576.990051,217.007599 576.939514,217.043961 576.923035,216.712677 
	C576.244202,215.946808 575.581909,215.512222 574.964233,215.056976 
	C575.008850,215.036346 574.924133,215.092712 574.897583,214.733551 
	C574.106262,213.983093 573.341492,213.591827 572.327637,213.133926 
	C571.719910,212.718170 571.361389,212.369034 571.008789,211.685272 
	C570.994202,210.905151 570.973572,210.459625 571.313660,209.971252 
	C573.627563,207.106888 575.580750,204.285370 576.643127,202.750732 
	C575.067932,200.413757 573.197876,198.802124 573.491455,197.877777 
	C574.910339,193.410339 571.598938,192.587997 568.990540,190.930084 
	C566.210144,189.162811 563.623352,187.090836 561.309814,185.155518 
	C572.523621,189.941666 582.627258,195.863983 590.953979,204.480621 
	C596.456055,210.174225 599.487854,216.991821 598.076599,224.825134 
	C597.317383,229.039124 594.719788,232.921936 592.951782,236.954193 
	C592.951782,236.954193 593.000000,237.000000 592.668579,237.036606 
	C591.812012,237.661560 591.286804,238.249908 590.761658,238.838242 
	C590.761658,238.838242 590.357788,239.014267 589.901611,239.135818 
	C588.862305,239.775101 588.279236,240.292847 587.696167,240.810577 
z"/>
<path fill="#1FAFF2" opacity="1.000000" stroke="none" 
	d="
M652.020630,78.528519 
	C655.277161,81.774300 658.485291,84.789009 661.656067,87.842499 
	C662.915405,89.055275 664.091919,90.354004 664.651489,90.934410 
	C660.111145,94.029724 656.306580,96.623474 653.308105,98.667664 
	C647.236694,95.053734 643.389282,89.541031 635.822083,89.679230 
	C625.510864,84.589951 615.970398,78.804504 605.845032,74.347656 
	C596.499939,70.234276 586.527466,67.546227 577.281311,64.148209 
	C582.712463,64.939041 587.683777,65.874451 592.677734,66.666939 
	C597.340454,67.406860 601.979675,73.230179 606.787231,66.772163 
	C607.124817,66.318726 608.605957,66.690041 609.553711,66.723289 
	C611.142639,66.779037 612.730286,66.871391 614.318481,66.948334 
	C614.664062,66.440979 615.009583,65.933624 615.355164,65.426262 
	C614.197693,65.268204 613.040222,65.110138 611.882751,64.952072 
	C612.192078,64.529381 612.501465,64.106682 612.810791,63.683990 
	C614.425049,63.239162 616.039368,62.794334 618.125488,62.219482 
	C617.339539,61.322140 617.027466,60.651878 616.521912,60.437023 
	C611.280090,58.209347 606.104065,55.767727 600.719299,53.949490 
	C593.142761,51.391109 585.511414,48.809116 577.709473,47.149914 
	C567.566528,44.992886 557.233215,43.731514 546.515381,42.062645 
	C532.704346,42.358562 519.359802,42.676495 505.928131,42.766838 
	C505.594116,42.119949 505.347260,41.700645 505.100403,41.281342 
	C522.517212,41.307487 540.051636,40.052349 557.325317,41.637897 
	C589.099854,44.554489 618.431763,55.526337 645.258850,72.932640 
	C646.616150,73.813293 647.786377,74.982254 649.187622,76.665512 
	C649.945007,77.521782 650.558411,77.729515 651.171814,77.937248 
	C651.497864,77.928146 651.759705,78.053398 652.020630,78.528519 
z"/>
<path fill="#1027C6" opacity="1.000000" stroke="none" 
	d="
M193.212097,667.037781 
	C192.263062,665.401428 191.893173,663.386719 190.684311,662.353760 
	C186.492096,658.771545 184.161194,654.263977 182.521942,649.111023 
	C180.106354,641.517700 184.478577,627.382690 190.547989,622.099854 
	C194.091599,619.015442 197.760284,616.771301 202.820221,616.337891 
	C203.833771,621.189880 206.800949,623.131653 210.933502,624.052979 
	C212.044724,624.300659 212.981781,625.329468 213.998779,625.999695 
	C203.351547,628.927917 199.216934,639.002502 204.916687,648.557129 
	C205.570175,649.652649 207.430847,650.028015 209.064728,650.711182 
	C209.397659,650.685120 209.637543,650.613342 209.637543,650.613342 
	C210.467331,651.707703 211.144119,653.564880 212.152832,653.767212 
	C217.079834,654.755554 222.095428,655.302368 227.529404,656.390259 
	C225.984573,661.846130 223.990036,666.916931 221.984818,672.213867 
	C221.974152,672.439941 221.941147,672.890991 221.493225,672.907471 
	C218.346451,672.906311 215.647598,672.888672 212.695496,672.616821 
	C210.550964,671.846558 208.655029,671.346680 206.769318,670.810913 
	C202.450623,669.583679 198.136887,668.338928 193.821136,667.101318 
	C193.821136,667.101318 193.406601,667.109497 193.212097,667.037781 
z"/>
<path fill="#181B9C" opacity="1.000000" stroke="none" 
	d="
M316.897919,655.037842 
	C315.652985,651.272156 314.408020,647.506470 313.448669,643.016479 
	C313.807587,620.855286 313.880920,599.418396 313.960754,577.599792 
	C313.627075,575.491516 313.286865,573.764893 313.273743,571.891846 
	C318.110779,571.129150 320.873657,573.830322 321.001923,578.952698 
	C321.329620,592.040344 321.549011,605.132996 322.130554,618.209412 
	C322.215790,620.125305 323.898926,623.474609 325.087769,623.587830 
	C331.808258,624.227905 332.424194,628.258057 332.094421,633.661316 
	C331.889832,637.013367 332.490814,640.484619 337.138489,641.312256 
	C340.192627,644.069397 343.104584,646.516785 346.005737,649.342224 
	C344.844543,651.530273 343.694153,653.340332 342.287384,655.553772 
	C345.547058,655.879578 348.599854,656.184753 351.652679,656.489929 
	C351.661713,657.025513 351.670776,657.561096 351.679840,658.096680 
	C349.898438,658.403015 348.043884,658.495728 346.355682,659.074036 
	C344.567322,659.686768 342.937134,660.761169 341.237823,661.633911 
	C341.407227,662.109619 341.576599,662.585327 341.746002,663.061035 
	C344.188843,663.061035 346.652313,662.887329 349.061188,663.173279 
	C349.731506,663.252869 350.240265,664.693115 350.823181,665.509155 
	C350.068451,665.976929 349.305176,666.844788 348.560760,666.828857 
	C347.031647,666.796265 345.512604,666.290527 343.989380,665.979614 
	C347.915710,669.043152 351.645203,668.639526 355.655884,664.024414 
	C355.820190,666.877686 355.910095,668.438843 355.571777,670.006348 
	C353.430206,670.010620 351.716827,670.008545 349.741425,669.721436 
	C347.642365,669.296753 345.805359,669.157166 343.598114,669.021362 
	C342.487701,669.018372 341.747528,669.011597 341.004150,668.613892 
	C340.296021,667.251404 339.032135,665.392700 338.965698,665.434509 
	C333.442688,668.914734 329.709076,664.788696 325.816071,662.220642 
	C322.644196,660.128235 319.856171,657.454041 316.897919,655.037842 
M317.000702,582.924500 
	C317.000702,596.387451 317.000702,609.850342 317.000702,623.313293 
	C317.433655,623.303589 317.866608,623.293884 318.299561,623.284180 
	C318.299561,608.758179 318.299561,594.232117 318.299561,579.706116 
	C317.993042,579.695740 317.686523,579.685364 317.380005,579.674988 
	C317.254578,580.465454 317.129181,581.255920 317.000702,582.924500 
M339.118408,651.275940 
	C338.575226,651.468018 338.032043,651.660156 337.488831,651.852234 
	C337.616089,652.130798 337.743347,652.409424 337.870605,652.687927 
	C338.429749,652.362244 338.988861,652.036499 339.118408,651.275940 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M849.401855,566.840088 
	C843.856567,564.248962 840.976624,567.991211 837.791382,570.734009 
	C836.025940,572.254211 834.435303,574.568542 832.224976,570.833191 
	C832.451721,570.481018 832.884094,570.341431 833.984375,570.059448 
	C838.417114,566.108032 842.203552,562.319641 845.893433,558.439392 
	C846.116089,558.205200 845.568665,557.238647 845.379639,556.612915 
	C848.124023,555.168396 850.715088,553.048584 853.636841,552.385376 
	C878.746399,546.685791 897.808533,556.174072 899.615479,585.358948 
	C900.249756,595.602722 900.125122,595.613464 890.074280,595.674255 
	C887.622131,595.689087 885.169861,595.676453 882.537415,595.676453 
	C882.081238,590.618896 881.943054,586.151184 881.229614,581.777405 
	C879.640808,572.037964 873.689514,566.858398 863.851135,566.582397 
	C859.197876,566.451843 854.531738,566.782471 849.401855,566.840088 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M672.412903,625.142456 
	C673.056763,619.570740 673.790588,613.691772 674.952637,607.898621 
	C676.098694,602.184937 676.990540,597.031738 671.853699,592.230591 
	C670.254272,590.735596 671.333862,586.374268 671.174194,583.058960 
	C668.156250,582.800232 667.481567,580.693604 668.327209,577.378052 
	C673.247070,558.086609 677.969482,538.743713 683.065918,519.499634 
	C683.787170,516.776123 686.185791,514.496887 687.882629,511.692383 
	C688.731934,511.291656 689.506653,511.210541 690.773315,511.597778 
	C692.809204,512.377258 694.355103,512.954041 695.896484,512.942139 
	C698.754883,512.920044 699.419678,514.460144 698.851135,516.754700 
	C697.194458,523.440613 695.307007,530.069580 693.675415,536.761292 
	C691.383545,546.161316 689.240723,555.597656 687.034180,565.018494 
	C684.201721,577.553467 681.369202,590.088501 678.313232,603.072632 
	C678.089661,603.521790 677.986206,603.749756 677.986206,603.749756 
	C677.470764,606.066040 676.955383,608.382324 676.255127,611.115112 
	C676.070374,611.531616 676.007385,611.576111 676.007385,611.576111 
	C675.644226,613.210449 675.281006,614.844727 674.579102,616.986938 
	C674.125671,617.734924 674.080505,617.987549 674.104980,618.252686 
	C673.633484,620.459351 673.161926,622.666077 672.412903,625.142456 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M321.123932,288.009186 
	C321.026154,286.334198 320.928345,284.659210 320.913666,282.096497 
	C320.661285,272.807892 320.325775,264.407013 320.445007,256.003784 
	C328.782593,256.584229 338.423004,253.989700 339.726532,266.229279 
	C341.112701,265.505280 342.541473,264.758972 343.979553,264.454285 
	C344.143799,267.199432 344.111938,269.533844 344.486847,271.801056 
	C345.932098,280.540222 347.507355,289.257843 348.692932,298.044189 
	C348.036804,299.152435 347.721893,300.200012 347.406952,301.247559 
	C348.275604,301.163208 349.144257,301.078888 350.282074,301.225525 
	C350.726837,302.624176 350.902435,303.791840 350.652344,304.973511 
	C346.578033,303.902771 342.929413,302.818024 339.417053,301.773804 
	C339.078156,299.640717 338.825653,298.051392 338.482635,295.892487 
	C336.892120,296.452576 335.607788,296.904846 334.427246,297.320587 
	C334.427246,300.010834 334.427246,302.266846 334.427246,305.898560 
	C333.263214,303.730225 333.021149,302.603180 332.326782,302.098724 
	C328.633820,299.415924 326.953186,296.831360 330.457458,292.426086 
	C331.957825,290.539978 331.207428,287.743683 327.383575,288.710571 
	C326.280151,288.989563 325.093719,288.940277 323.667114,288.916687 
	C322.633911,288.530975 321.878906,288.270081 321.123932,288.009186 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M737.333496,302.144867 
	C737.327332,302.574036 737.321228,303.003235 736.911377,303.888794 
	C736.355103,304.920685 736.202515,305.496124 736.049927,306.071594 
	C736.049927,306.071594 736.008667,306.005554 735.714539,306.164001 
	C733.954285,309.213776 732.488220,312.105103 730.772705,314.822449 
	C730.162720,313.964569 729.802124,313.280670 729.283630,312.297211 
	C728.304016,313.868713 727.469055,315.208160 726.600159,316.602112 
	C724.893616,315.189301 724.242615,314.322998 723.386414,313.999176 
	C720.007629,312.721313 717.757019,311.195404 717.493347,306.818481 
	C717.331116,304.124847 714.573364,301.587585 712.876160,298.765991 
	C712.576538,298.285156 712.322144,298.100372 712.050781,297.616516 
	C712.726501,294.489075 713.359619,291.742706 714.046448,288.605469 
	C714.485229,284.555145 714.870300,280.895660 715.520935,277.119843 
	C715.959290,277.289062 716.132141,277.574646 716.553101,277.395782 
	C716.872009,275.624298 716.942871,274.317261 717.310059,272.966827 
	C717.924194,272.517273 718.242065,272.111084 718.559937,271.704926 
	C718.047852,271.464478 717.535828,271.224030 717.023438,270.655396 
	C717.025208,269.889679 717.027222,269.452118 717.243774,268.901520 
	C717.724731,268.582703 717.901184,268.317139 717.987915,267.617798 
	C717.990173,266.495880 717.992065,265.747955 718.244202,265.177765 
	C718.796570,265.905243 719.098572,266.454987 719.444275,267.084106 
	C720.048401,265.033539 720.626526,263.071320 721.473450,260.196747 
	C725.047485,263.797211 729.603760,266.252075 730.218994,269.462219 
	C731.410950,275.681549 734.931030,278.672760 739.959167,281.420471 
	C739.975464,282.874847 739.980408,283.932526 739.704834,285.246857 
	C739.087524,286.745544 738.575989,287.969604 738.445618,289.232971 
	C738.002319,293.529419 737.693604,297.839813 737.333496,302.144867 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M325.345367,193.937469 
	C326.956177,188.526474 328.213867,182.977982 330.255341,177.734665 
	C333.890472,168.398071 340.499481,162.181488 350.802704,161.006744 
	C361.545715,159.781860 369.779388,163.902023 375.870544,172.978119 
	C406.090302,218.007217 448.093994,245.046265 501.351410,254.845932 
	C505.318848,255.575974 507.435822,254.988464 509.220428,250.745529 
	C514.537659,238.103806 528.955383,233.208069 541.203918,239.298187 
	C549.859497,243.601837 550.734070,244.590683 555.438171,255.971222 
	C564.575256,253.898956 573.974548,251.986160 583.247742,249.586411 
	C589.342651,248.009155 595.255798,245.729294 601.491821,243.879730 
	C601.486816,244.361694 601.240234,244.728012 600.975952,245.410080 
	C600.839111,246.190735 600.816589,247.006699 600.586975,247.069855 
	C592.010193,249.428894 583.405090,251.685013 574.837402,254.075577 
	C574.084106,254.285751 573.559143,255.314423 572.549438,255.914139 
	C568.450623,256.064911 564.673584,255.925705 561.023376,256.540039 
	C554.904297,257.569916 552.828308,257.032043 550.361450,251.317474 
	C545.217285,239.400894 538.506836,238.216370 527.220276,239.226120 
	C521.364258,239.750015 515.678772,242.737534 512.652039,249.142593 
	C511.905212,250.003418 511.147827,250.660660 511.096466,251.369064 
	C510.748749,256.167206 507.968842,257.621216 503.636505,256.964722 
	C499.733551,256.373352 495.847321,255.671234 491.588196,255.005768 
	C490.490601,254.977585 489.758545,254.961594 488.813690,254.684937 
	C487.726166,254.279938 486.851501,254.135574 485.586182,253.934265 
	C479.119751,251.865707 473.115814,249.593399 466.953735,247.894669 
	C452.442139,243.894180 439.585419,236.245850 427.338684,228.143234 
	C415.544647,220.340118 404.698517,210.760925 394.682922,200.718414 
	C386.209259,192.222000 379.501068,181.960983 372.044800,172.454819 
	C365.321686,163.883362 356.100098,160.574387 346.225433,164.201019 
	C338.971619,166.865112 333.877899,172.161072 331.959045,180.223312 
	C331.946991,180.454391 331.917358,180.916199 331.673706,181.102997 
	C330.961304,182.199310 330.492493,183.108810 329.994446,184.384552 
	C329.596466,186.164490 329.227783,187.578201 328.640015,189.001846 
	C328.278748,189.337006 328.136505,189.662231 327.986389,190.339111 
	C327.613586,191.782806 327.248657,192.874847 326.627197,193.967392 
	C326.028931,193.957764 325.687134,193.947617 325.345367,193.937469 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M673.015503,191.636612 
	C675.469177,189.516144 677.950317,187.425339 680.402283,185.300873 
	C682.028564,183.891800 683.613159,182.434525 685.216431,180.998856 
	C685.502380,181.346039 685.788330,181.693237 686.074280,182.040421 
	C686.380859,180.224869 686.687439,178.409332 687.093018,176.007416 
	C688.823547,177.367813 689.934998,178.241470 691.201294,179.236923 
	C689.810059,180.955917 688.630432,182.413361 688.121155,183.042603 
	C689.394409,185.443970 690.172974,187.085648 691.112244,188.629547 
	C691.870728,189.876221 692.688416,191.139984 693.712952,192.162476 
	C698.027161,196.467850 698.195740,199.223389 694.661682,203.952820 
	C693.766296,205.151108 693.605652,206.898483 693.156128,208.767853 
	C693.204956,209.142151 693.263184,209.154175 692.985046,209.229584 
	C692.532471,209.631042 692.358032,209.957062 692.169312,210.213654 
	C692.154968,210.144211 692.291809,210.181168 692.008667,210.256210 
	C691.570190,210.670547 691.414795,211.009827 691.239746,211.282944 
	C691.220154,211.216766 691.346191,211.273071 691.143677,211.261353 
	C690.826904,211.317215 690.712769,211.384796 690.598572,211.452377 
	C690.765259,211.641190 690.931946,211.830017 691.084595,212.366058 
	C686.218750,217.440430 681.366882,222.167603 676.400513,226.483734 
	C677.791809,223.361801 679.297607,220.650909 680.190552,219.043304 
	C675.525940,213.365326 671.330933,208.258972 666.999146,202.986130 
	C664.570557,204.859802 662.099792,206.331558 660.207153,208.346390 
	C658.256165,210.423401 656.904907,213.056015 655.209106,215.385757 
	C654.758484,216.004944 654.052002,216.765045 653.385498,216.849548 
	C646.346863,217.742065 642.524841,223.753723 636.688354,227.167816 
	C633.592407,228.886322 630.799500,230.453400 627.756226,232.016846 
	C627.339111,232.018173 627.172241,232.023148 627.003174,232.019379 
	C627.000977,232.010635 626.985718,232.001419 626.975586,231.676132 
	C626.937500,231.135681 626.909546,230.920532 626.881592,230.705368 
	C643.810608,219.916946 659.265442,207.395996 672.708740,192.066574 
	C673.227356,192.141373 673.350891,192.196365 673.474426,192.251343 
	C673.313843,192.051865 673.153259,191.852356 672.992676,191.652847 
	C672.992676,191.652847 673.030640,191.651978 673.015503,191.636612 
M686.432983,189.360855 
	C686.165466,189.874390 685.898010,190.387924 685.630493,190.901443 
	C686.164978,191.123672 686.699463,191.345901 687.233948,191.568146 
	C687.141724,190.922363 687.049500,190.276596 686.432983,189.360855 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M312.946655,572.038269 
	C313.286865,573.764893 313.627075,575.491516 313.707397,577.609497 
	C313.278229,578.005615 313.108917,578.010376 312.939636,578.015137 
	C312.952484,575.172607 311.796112,573.965515 308.714874,574.126892 
	C303.601898,574.394592 298.464691,574.199768 293.163757,574.199768 
	C293.163757,566.932556 293.163757,560.134094 293.163757,552.896667 
	C299.624420,552.896667 306.040009,552.896667 312.899353,552.896667 
	C314.805237,542.982849 316.647552,533.399719 318.568573,523.407227 
	C324.926453,523.407227 331.329163,523.407227 338.447510,523.407227 
	C338.447510,532.936096 338.447510,542.637207 338.447510,552.733765 
	C350.740326,552.733765 362.333282,552.733765 374.199768,552.733765 
	C374.199768,559.909424 374.199768,566.667419 374.199768,574.034119 
	C362.569672,574.034119 350.857910,574.034119 338.424622,574.034119 
	C338.424622,576.575928 338.424652,578.519775 338.424622,580.463623 
	C338.424438,597.622009 338.421906,614.780396 338.424957,631.938782 
	C338.427124,644.007629 341.262573,647.165527 353.645874,647.864990 
	C360.405701,648.246765 367.204773,647.933105 374.427948,647.933105 
	C374.427948,654.888794 374.646484,661.197510 374.209991,667.460510 
	C374.138306,668.488586 371.185974,669.315918 369.351318,669.722778 
	C372.384857,666.591797 374.755463,654.942749 372.493042,651.600037 
	C371.899902,650.723755 370.332336,650.142883 369.173096,650.072632 
	C361.460175,649.605164 353.736633,649.313232 346.016541,648.964233 
	C343.104584,646.516785 340.192627,644.069397 337.143005,640.843262 
	C336.997711,619.685364 336.988495,599.306091 336.983307,578.926880 
	C336.981567,572.046326 336.986176,572.046326 344.452881,571.994507 
	C350.067596,572.006897 355.211792,572.011353 360.356018,572.011841 
	C372.067169,572.013000 371.826752,572.006042 372.127930,560.292053 
	C372.249786,555.553284 370.209991,553.934448 366.116272,554.046875 
	C359.076263,554.240234 352.042480,554.661926 344.615234,554.940491 
	C338.976746,554.614014 336.238434,552.447144 336.599121,546.593079 
	C336.923279,541.331360 336.104187,536.008972 336.068726,530.711426 
	C336.050110,527.928833 335.120361,526.779541 332.292877,526.932007 
	C329.304108,527.093262 326.237488,526.610413 323.327911,527.116516 
	C321.788605,527.384277 319.594543,528.937927 319.273285,530.286682 
	C317.861938,536.211914 316.829498,542.248962 316.052277,548.295898 
	C315.556213,552.155396 313.743683,553.993347 309.942230,553.999695 
	C305.945648,554.006409 301.916565,553.708740 297.969757,554.151123 
	C296.554688,554.309753 294.347382,556.134277 294.216309,557.378967 
	C293.820251,561.140137 294.012878,565.024353 294.526978,568.785400 
	C294.694702,570.012329 296.498260,571.884338 297.650208,571.958313 
	C302.729797,572.284485 307.844604,572.062012 312.946655,572.038269 
M331.158295,526.036621 
	C332.437592,525.964966 333.716858,525.893311 334.996155,525.821655 
	C334.990417,525.637451 334.984650,525.453308 334.978882,525.269104 
	C330.653564,525.269104 326.328217,525.269104 322.002899,525.269104 
	C321.999817,525.524963 321.996735,525.780762 321.993652,526.036621 
	C324.754822,526.036621 327.515961,526.036621 331.158295,526.036621 
z"/>
<path fill="#19168E" opacity="1.000000" stroke="none" 
	d="
M628.006531,232.020493 
	C630.799500,230.453400 633.592407,228.886322 636.691467,227.554413 
	C638.696167,232.034744 640.015076,236.500809 642.290039,240.410614 
	C643.137390,241.866852 646.187927,242.136337 648.295593,242.726532 
	C649.464722,243.053909 650.761292,242.926300 651.859436,243.303421 
	C649.800415,245.396301 647.882385,247.189529 645.603882,249.026245 
	C643.529541,250.101166 640.225403,249.926285 641.759644,253.603790 
	C641.643738,253.698013 641.345764,253.718719 640.992554,253.502930 
	C637.780823,253.139313 635.446716,253.738022 635.095215,257.125732 
	C635.084656,257.077301 635.175293,257.117462 634.761230,257.083466 
	C631.765503,257.294189 629.159485,257.406494 626.619446,257.875977 
	C625.977905,257.994537 625.547119,259.253876 624.600220,259.992310 
	C620.673218,260.859253 617.165405,261.722900 615.045715,262.244781 
	C611.864380,259.649261 609.436951,257.668793 606.903687,255.602036 
	C607.372620,254.925934 608.236694,253.680023 609.092896,252.445526 
	C607.844604,251.256180 606.871704,250.329269 605.898865,249.402374 
	C606.349182,249.274399 606.799500,249.146423 607.249817,249.018448 
	C607.166382,247.347778 607.082947,245.677109 607.202759,243.724854 
	C611.316650,241.578354 615.385315,239.975891 619.079590,237.751724 
	C621.355225,236.381668 623.059448,234.062714 625.020203,232.169800 
	C625.020203,232.169785 625.373901,231.950180 625.880005,231.964966 
	C626.585999,231.986969 626.785828,231.994202 626.985718,232.001419 
	C626.985718,232.001419 627.000977,232.010635 627.091797,232.183502 
	C627.457275,232.244415 627.731873,232.132462 628.006531,232.020493 
z"/>
<path fill="#2343D5" opacity="1.000000" stroke="none" 
	d="
M321.125488,288.414062 
	C321.878906,288.270081 322.633911,288.530975 323.679077,289.328247 
	C326.907806,309.363220 333.822571,327.572449 342.591309,345.054779 
	C351.377716,362.572357 362.424408,378.745300 375.757629,393.074341 
	C389.978668,408.357513 406.163452,421.494110 424.229797,432.091644 
	C442.984741,443.093109 463.014893,451.183960 484.169312,456.105042 
	C501.493378,460.135071 519.160156,461.787109 537.436951,461.013733 
	C540.256165,461.044495 542.630188,461.064484 545.033630,461.407898 
	C545.090820,461.946014 545.118652,462.160736 545.146423,462.375427 
	C531.901123,462.191528 518.543213,463.045776 505.432343,461.623199 
	C470.323608,457.813629 438.259003,445.273163 409.248596,425.121582 
	C377.239594,402.887024 353.356049,373.810089 336.878479,338.559845 
	C329.449066,322.666199 324.621918,305.955139 321.125488,288.414062 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M504.656799,41.243492 
	C505.347260,41.700645 505.594116,42.119949 505.515533,42.775883 
	C503.099152,43.343639 500.962616,43.503738 498.927979,44.046047 
	C495.894257,44.854641 492.932404,45.932800 489.503601,46.916058 
	C485.668060,47.597870 482.268829,48.259876 478.518921,48.929344 
	C477.108154,49.265003 476.048096,49.593197 474.566803,49.930069 
	C472.099976,50.400478 469.948029,50.596989 468.026398,51.367722 
	C456.991547,55.793697 445.715576,59.761524 435.098541,65.044609 
	C425.153198,69.993439 415.420044,75.705528 406.523010,82.330116 
	C397.546387,89.013985 389.703705,97.222954 381.381104,104.782372 
	C375.413788,110.202469 370.478760,116.383957 372.093231,124.993858 
	C373.446289,132.209885 378.154755,137.632675 383.973267,142.403351 
	C391.852661,151.186539 399.631104,159.678833 407.553436,168.034744 
	C409.071594,169.636017 411.155762,170.700684 412.989319,172.011505 
	C412.999786,172.010086 412.984375,172.028931 413.130615,172.313507 
	C414.188995,173.056702 415.101166,173.515335 416.425659,173.999161 
	C422.040222,175.931839 426.909882,175.237122 431.252991,171.850235 
	C434.491241,169.324921 437.437561,166.423508 440.692444,163.921906 
	C442.280518,162.701385 444.276154,162.011139 445.956757,161.389542 
	C444.295990,163.197372 442.728241,164.660355 441.242126,166.201996 
	C435.139740,172.532547 428.494690,177.733124 418.992950,177.135284 
	C415.286804,175.357529 410.774475,174.393402 408.015472,171.659729 
	C396.681335,160.429688 385.497528,148.995956 375.004028,136.985718 
	C366.501709,127.254486 367.384460,115.263794 376.459473,105.892883 
	C391.219849,90.651192 407.685089,77.493423 426.104919,66.900551 
	C448.857056,53.816212 473.318146,45.586842 499.242584,41.652496 
	C500.884247,41.403358 502.555603,41.350056 504.656799,41.243492 
z"/>
<path fill="#19168E" opacity="1.000000" stroke="none" 
	d="
M712.980957,298.980133 
	C714.573364,301.587585 717.331116,304.124847 717.493347,306.818481 
	C717.757019,311.195404 720.007629,312.721313 723.386414,313.999176 
	C724.242615,314.322998 724.893616,315.189301 726.600159,316.602112 
	C727.469055,315.208160 728.304016,313.868713 729.283630,312.297211 
	C729.802124,313.280670 730.162720,313.964569 730.766418,315.243835 
	C730.329468,319.220184 729.649414,322.601196 728.620361,326.048584 
	C726.552124,330.841492 724.832886,335.567963 723.113647,340.294434 
	C723.113647,340.294434 723.185303,340.664307 722.812012,340.946991 
	C721.624939,341.820190 720.811279,342.410706 719.997559,343.001221 
	C718.416809,342.838806 716.551819,342.051636 715.303345,342.619598 
	C709.117737,345.433594 708.401550,345.114349 708.466431,339.897003 
	C705.441162,337.232910 703.089661,335.162109 700.510498,332.716064 
	C703.038086,329.305939 704.817322,325.905396 704.315308,321.639709 
	C704.298828,321.499573 704.452271,321.344025 704.511108,321.189789 
	C707.335510,313.786987 710.158142,306.383514 712.980957,298.980133 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M222.984894,571.971680 
	C218.580582,572.808777 213.360535,572.260376 212.132721,578.776611 
	C212.190231,579.158203 212.154434,579.108276 211.801315,579.108337 
	C210.362228,580.034912 208.679367,580.767822 208.304031,581.924805 
	C207.248291,585.179199 206.826614,588.633423 205.955368,591.956482 
	C205.589951,593.350220 204.775436,595.726013 204.073853,595.759705 
	C197.847504,596.059570 191.600693,595.934021 184.947205,595.934021 
	C185.142914,593.153687 184.979782,591.119934 185.475708,589.262146 
	C186.830811,584.185669 188.463715,579.183350 189.986588,574.151672 
	C191.220413,572.279114 192.454239,570.406494 194.221863,568.242920 
	C196.363602,566.914673 197.967117,565.870483 199.580475,564.841858 
	C202.699936,562.852966 205.824966,560.872742 208.962921,559.270996 
	C207.941849,560.958191 207.024872,562.386292 205.839340,563.537964 
	C204.312149,565.021606 202.569809,566.283752 200.920135,567.641296 
	C201.215149,568.173401 201.510178,568.705505 201.805191,569.237549 
	C203.972626,568.608521 206.858444,566.782288 208.174484,567.572021 
	C212.326523,570.063599 216.411987,569.725403 220.778793,569.296326 
	C221.420334,569.233337 222.244980,571.033508 222.984894,571.971680 
M207.154129,572.393982 
	C207.797012,572.653809 208.439896,572.913696 209.082794,573.173584 
	C209.176178,572.626404 209.269547,572.079163 209.362915,571.531982 
	C208.682358,571.613525 208.001816,571.695068 207.154129,572.393982 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M851.608521,770.422852 
	C853.839600,763.900269 855.717102,757.682434 858.136353,751.683167 
	C858.735474,750.197510 860.992737,748.829041 862.678650,748.546692 
	C863.640808,748.385498 865.541321,750.310730 866.069336,751.666443 
	C868.712646,758.453674 871.041931,765.363281 873.942078,773.525879 
	C875.277405,767.741821 875.760559,762.913574 877.572815,758.650269 
	C879.065857,755.137939 876.621948,748.788147 884.547852,748.129395 
	C882.072083,758.071655 879.788391,767.348389 877.400085,776.598083 
	C877.237915,777.225891 876.295288,778.082825 875.703186,778.092773 
	C873.379333,778.131836 870.954163,778.982849 869.871643,775.283203 
	C867.822937,768.281189 865.071045,761.484863 862.156067,753.329529 
	C860.387085,758.007080 858.939697,761.728394 857.574585,765.479614 
	C856.613770,768.119812 855.591492,770.757141 854.906128,773.473816 
	C853.845459,777.678528 851.503174,779.252075 846.980530,777.621887 
	C844.569824,768.260193 842.098267,758.662170 839.572876,748.855042 
	C842.749512,747.820679 844.457764,748.316162 845.112366,751.676392 
	C846.256775,757.550842 847.694397,763.369202 849.070862,769.196472 
	C849.340698,770.338867 848.974915,772.785461 851.608521,770.422852 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M208.947678,558.888916 
	C205.824966,560.872742 202.699936,562.852966 199.580475,564.841858 
	C197.967117,565.870483 196.363602,566.914673 194.390823,567.943481 
	C194.819031,566.642151 195.370163,565.087769 196.441864,564.095825 
	C209.464371,552.042297 225.278046,549.124512 242.298447,550.327820 
	C257.763245,551.421021 270.644470,557.277100 279.149353,570.815918 
	C283.771515,578.173889 286.004089,586.385742 286.076447,595.009277 
	C286.283112,619.656494 286.248993,644.305725 286.287781,668.954163 
	C286.289032,669.750244 286.053101,670.546753 285.902740,671.501892 
	C277.981415,671.501892 270.247223,671.501892 261.981171,671.501892 
	C261.981171,665.738770 261.981171,660.284607 262.043091,654.332397 
	C262.104980,653.834351 262.136810,653.783752 262.442322,653.843506 
	C263.160095,653.945984 263.572296,653.988647 263.992035,654.354248 
	C264.019348,655.107361 264.039185,655.537598 264.036377,656.231689 
	C264.016510,656.672180 264.019318,656.848755 264.007782,657.484741 
	C263.991547,660.732910 263.726105,663.558167 264.103699,666.294678 
	C264.281403,667.582336 265.681305,669.713562 266.551331,669.725586 
	C271.368866,669.792175 276.193787,669.323914 281.016174,669.038879 
	C285.524048,668.998596 284.801422,664.999329 284.796082,662.835938 
	C284.740570,640.315918 284.443024,617.794739 283.998108,595.278015 
	C283.583679,574.301270 270.443420,557.844971 248.935974,552.993530 
	C239.404083,552.997437 230.624496,552.371460 222.006973,553.280945 
	C217.506714,553.755981 213.291580,556.932556 208.947678,558.888916 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M659.022217,144.647736 
	C654.001160,139.938644 649.012329,134.871902 643.653870,129.765182 
	C643.284302,129.725204 643.219727,129.738815 643.174194,129.433807 
	C642.446167,128.730423 641.763672,128.332047 641.052612,127.961349 
	C641.024048,127.989037 641.055542,127.916084 640.993530,127.582916 
	C639.310974,126.132782 637.690430,125.015823 636.030518,123.929893 
	C635.991028,123.960930 636.086121,123.928947 635.943176,123.620201 
	C632.953979,121.431618 630.107666,119.551781 627.267456,117.657913 
	C627.273499,117.643875 627.293091,117.620377 627.265137,117.281372 
	C624.525818,115.149223 621.814575,113.356071 618.901001,111.302254 
	C607.485413,105.923767 596.272095,100.805954 584.866638,95.483154 
	C584.125854,95.187996 583.577148,95.097832 582.730774,94.790977 
	C581.248718,94.289093 580.064392,94.003891 578.663452,93.594574 
	C578.162537,93.598000 577.878174,93.725533 577.392517,93.530861 
	C576.479858,93.090874 575.768494,92.973091 575.030823,92.869629 
	C575.004456,92.883949 575.055725,92.915154 574.843750,92.625092 
	C572.748535,91.930458 570.865295,91.525902 568.744873,90.964233 
	C568.137756,90.831337 567.767761,90.855560 567.085205,90.620819 
	C563.826660,89.889389 560.880859,89.416924 557.780151,88.863663 
	C557.625305,88.782860 557.295471,88.897743 556.984131,88.650421 
	C554.859863,88.162430 553.047058,87.921768 550.875000,87.489441 
	C548.029114,87.274254 545.542297,87.250725 542.733032,87.117958 
	C541.711670,85.810097 541.012878,84.611481 540.610046,83.329895 
	C541.272034,83.063530 541.638062,82.880135 542.455566,82.783478 
	C556.908875,85.622696 570.910767,88.375168 585.132935,91.416641 
	C592.862244,94.517326 600.371216,97.329010 607.911743,100.131271 
	C607.943359,100.121849 607.917175,100.061340 607.966125,100.323456 
	C608.331604,100.723007 608.648132,100.860443 608.975952,101.001526 
	C608.987183,101.005173 608.979553,101.027626 609.169189,101.304810 
	C611.206482,102.417183 613.054138,103.252365 614.910400,104.068375 
	C614.919006,104.049210 614.946960,104.080391 615.077637,104.395065 
	C620.676941,107.931763 626.145691,111.153770 631.674072,114.360237 
	C631.733765,114.344696 631.668701,114.239899 631.636597,114.548401 
	C632.052795,115.246117 632.500977,115.635323 632.966553,116.014679 
	C632.983826,116.004837 632.972412,116.042809 633.047058,116.294785 
	C633.424866,116.690071 633.728027,116.833374 634.147461,117.081406 
	C634.263733,117.186134 634.576050,117.207146 634.576050,117.207153 
	C643.314697,125.248619 652.053345,133.290085 660.927856,141.632965 
	C660.393921,142.719635 659.724182,143.504868 659.022217,144.647736 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M346.005737,649.342224 
	C353.736633,649.313232 361.460175,649.605164 369.173096,650.072632 
	C370.332336,650.142883 371.899902,650.723755 372.493042,651.600037 
	C374.755463,654.942749 372.384857,666.591797 369.078369,669.565430 
	C368.554565,669.956787 368.090088,669.992310 367.006531,669.691040 
	C362.924957,669.569458 359.462494,669.784729 356.000000,670.000000 
	C355.910095,668.438843 355.820190,666.877686 355.655884,664.024414 
	C351.645203,668.639526 347.915710,669.043152 343.989380,665.979614 
	C345.512604,666.290527 347.031647,666.796265 348.560760,666.828857 
	C349.305176,666.844788 350.068451,665.976929 350.823181,665.509155 
	C350.240265,664.693115 349.731506,663.252869 349.061188,663.173279 
	C346.652313,662.887329 344.188843,663.061035 341.746002,663.061035 
	C341.576599,662.585327 341.407227,662.109619 341.237823,661.633911 
	C342.937134,660.761169 344.567322,659.686768 346.355682,659.074036 
	C348.043884,658.495728 349.898438,658.403015 351.679840,658.096680 
	C351.670776,657.561096 351.661713,657.025513 351.652679,656.489929 
	C348.599854,656.184753 345.547058,655.879578 342.287384,655.553772 
	C343.694153,653.340332 344.844543,651.530273 346.005737,649.342224 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M368.975586,752.044189 
	C370.320740,756.165039 371.503235,759.948242 372.983276,764.683533 
	C364.107391,764.683533 356.863678,764.683533 349.448212,764.683533 
	C349.123871,770.030823 351.075195,773.307373 355.724030,774.621033 
	C360.671082,776.018738 364.381317,774.355713 366.879761,770.430969 
	C367.642090,769.233398 369.346222,768.635254 371.455414,767.189209 
	C370.875702,774.724121 368.031281,777.612854 361.648224,778.635620 
	C354.210388,779.827515 348.708801,777.124817 346.014984,770.955750 
	C343.052094,764.170532 344.892517,754.804321 349.992004,750.716064 
	C355.209839,746.532898 362.633026,746.923645 368.975586,752.044189 
M365.443909,754.182617 
	C361.764954,750.757812 357.245514,750.786560 353.428284,752.877808 
	C351.150604,754.125671 350.100189,757.613647 347.987457,760.885376 
	C355.384552,760.885376 361.251923,760.885376 367.922760,760.885376 
	C367.129883,758.494202 366.529663,756.684082 365.443909,754.182617 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M419.297668,177.401657 
	C428.494690,177.733124 435.139740,172.532547 441.242126,166.201996 
	C442.728241,164.660355 444.295990,163.197372 445.941742,161.378510 
	C446.057251,161.058807 446.057037,161.071320 446.379211,160.977509 
	C448.832214,158.951202 450.962982,157.018707 453.040344,155.070374 
	C452.986877,155.054504 453.082123,155.113266 453.401062,155.085495 
	C454.503296,154.390640 455.286591,153.723541 456.032532,153.035522 
	C455.995178,153.014603 456.051331,153.079422 456.379730,153.050140 
	C457.566650,152.133102 458.425171,151.245361 459.284576,150.316925 
	C459.285461,150.276230 459.206848,150.255051 459.534363,150.269577 
	C460.621216,149.584427 461.380554,148.884766 462.156677,148.192627 
	C462.173462,148.200165 462.202179,148.223175 462.539612,148.262527 
	C463.955750,147.578720 465.034485,146.855560 466.119934,146.150726 
	C466.126648,146.169052 466.164429,146.178940 466.515015,146.172363 
	C469.729797,144.491058 472.593964,142.816299 475.598938,141.207062 
	C475.739716,141.272583 475.976593,141.071747 476.328918,141.070984 
	C478.176178,140.125412 479.671112,139.180618 481.101562,138.139984 
	C481.037079,138.044159 481.130798,138.255341 481.472595,138.345795 
	C482.581757,138.011520 483.349152,137.586792 484.128723,137.203400 
	C484.140930,137.244751 484.226898,137.251587 484.598022,137.287720 
	C487.496155,136.254257 490.023193,135.184647 492.621826,134.245773 
	C492.693359,134.376495 492.983917,134.310135 493.333099,134.333160 
	C494.127655,134.226791 494.573029,134.097412 495.342041,133.997772 
	C496.224426,133.733795 496.783203,133.440063 497.706726,133.183563 
	C500.342804,132.556076 502.614136,131.891327 505.338440,131.257462 
	C510.845154,130.585709 515.898865,129.883072 520.927612,129.128693 
	C520.902588,129.076950 520.815247,129.151703 520.881104,129.540039 
	C520.892090,131.225555 520.837219,132.522751 520.831482,133.862411 
	C520.880554,133.904892 520.934570,133.786865 520.491577,133.742004 
	C515.644714,134.212784 511.115509,134.247101 506.865417,135.353485 
	C499.961548,137.150696 493.240417,139.649887 486.362549,141.737457 
	C486.282959,141.622162 486.014221,141.701706 485.667725,141.687241 
	C484.502319,142.038483 483.683350,142.404175 482.586914,142.766907 
	C481.837677,143.024399 481.365906,143.284836 480.538055,143.613037 
	C478.944916,144.403152 477.707886,145.125534 476.350586,145.781342 
	C476.230286,145.714783 476.012146,145.882217 475.680969,145.914932 
	C474.539551,146.597473 473.729309,147.247299 472.905640,147.905594 
	C472.892242,147.914078 472.892761,147.880219 472.542969,147.866669 
	C470.402222,148.872940 468.611237,149.892776 466.536072,150.922272 
	C465.814819,151.255905 465.377747,151.579910 464.685211,151.979889 
	C464.260742,152.348831 464.091736,152.641769 463.927399,152.910431 
	C463.932068,152.886169 463.882812,152.881973 463.560730,152.894348 
	C462.741302,153.513779 462.243958,154.120819 461.763306,154.797897 
	C461.779999,154.867935 461.818939,154.729294 461.461487,154.691589 
	C460.214325,155.372421 459.324615,156.090942 458.341309,156.761902 
	C458.247711,156.714325 458.079651,156.840210 457.779236,156.882645 
	C457.296021,157.261261 457.113220,157.597443 456.924622,157.901382 
	C456.918823,157.869141 456.853363,157.866623 456.533752,157.864410 
	C455.719147,158.469818 455.224091,159.077438 454.739319,159.732880 
	C454.749603,159.780701 454.820709,159.713593 454.486725,159.668335 
	C453.645660,160.036697 453.138580,160.450302 452.458527,160.891251 
	C452.285583,160.918594 452.037323,161.165619 451.729797,161.211548 
	C450.589691,162.141922 449.757141,163.026352 448.948792,163.957092 
	C448.973053,164.003418 448.911896,163.914627 448.613892,163.972321 
	C447.843994,164.646362 447.372131,165.262711 446.883118,165.902954 
	C446.865906,165.926819 446.860016,165.868225 446.537842,165.889404 
	C445.158112,166.937531 444.100555,167.964493 442.752625,169.083832 
	C441.969299,169.784393 441.476349,170.392578 440.700745,171.081635 
	C439.966400,171.775833 439.514648,172.389145 439.030579,173.000397 
	C438.998260,172.998322 439.003357,173.062897 438.728149,173.147583 
	C438.007324,173.827103 437.561707,174.421951 436.908752,175.201263 
	C436.449982,175.871216 436.198578,176.356674 435.655273,177.015427 
	C434.307892,178.542267 433.252380,179.895828 432.196899,181.249390 
	C432.000916,179.972687 431.804932,178.695984 431.647125,177.668030 
	C427.415039,177.668030 423.508698,177.668030 419.297668,177.401657 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M623.628296,755.845459 
	C619.668640,765.245850 615.866455,774.290100 611.974548,783.295654 
	C610.169312,787.472717 606.302002,789.517395 602.328186,788.343201 
	C601.502441,788.099182 600.961304,786.891785 600.289001,786.128418 
	C600.986755,785.607727 601.614868,784.800293 602.393188,784.611328 
	C609.180115,782.963562 610.684937,779.881226 607.867371,773.349304 
	C604.398682,765.307983 600.988770,757.241211 597.469116,748.987732 
	C600.912720,747.539795 602.349976,748.801819 603.456787,751.576050 
	C606.080688,758.153259 608.934509,764.638733 612.201355,772.356628 
	C615.370850,764.504395 618.164246,757.867004 620.712158,751.136536 
	C621.777161,748.323303 623.267883,747.557007 626.378174,749.091248 
	C625.479309,751.273071 624.614136,753.373291 623.628296,755.845459 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M661.923462,777.098389 
	C655.417725,779.816650 649.412537,779.880859 644.477234,774.918457 
	C639.194946,769.607239 639.050842,762.948730 641.285461,756.290466 
	C643.203064,750.577087 649.216370,747.484436 656.271606,747.972046 
	C662.143860,748.377930 666.904663,752.368469 668.179871,758.239746 
	C669.768188,765.553040 668.871521,772.173523 661.923462,777.098389 
M664.475525,764.356140 
	C664.371216,760.186768 664.665894,755.130371 660.323792,753.476318 
	C656.851257,752.153442 651.685730,751.885803 648.640503,753.592285 
	C643.562622,756.438049 644.306824,762.381042 645.090027,767.571838 
	C645.708374,771.670593 648.242004,774.002991 652.255432,774.771118 
	C659.060547,776.073425 662.926331,773.027222 664.475525,764.356140 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M239.768402,763.866638 
	C237.412140,776.197205 230.196381,781.357239 220.406708,778.113586 
	C213.465347,775.813660 209.831985,768.659180 211.135361,759.857117 
	C212.158386,752.948242 217.281113,748.359619 224.369949,748.002136 
	C232.541183,747.590210 237.382111,751.323853 239.229889,759.521729 
	C239.519547,760.806824 239.612808,762.136108 239.768402,763.866638 
M218.023422,754.487061 
	C217.413559,755.466187 216.605499,756.374268 216.225250,757.435669 
	C213.201141,765.876465 216.310089,773.712708 223.192078,774.770081 
	C227.551773,775.439819 231.550354,774.540649 233.896698,770.301086 
	C236.373825,765.825195 235.509399,757.314331 232.432709,754.240173 
	C229.386673,751.196777 223.825348,751.056641 218.023422,754.487061 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M799.551636,754.565918 
	C805.349548,747.411499 813.519348,745.778870 819.960571,750.184998 
	C826.394775,754.586365 828.304565,764.738708 824.087341,772.122559 
	C820.416199,778.550232 812.691467,780.734375 805.363525,777.416748 
	C797.870361,774.024353 795.349609,764.518677 799.551636,754.565918 
M802.165833,759.225525 
	C802.088684,761.219849 801.864624,763.221375 801.961060,765.207275 
	C802.285034,771.877197 806.553955,775.537231 813.125793,774.908691 
	C819.276001,774.320435 821.945190,770.556580 821.771912,762.717041 
	C821.638123,756.659607 819.488403,753.164795 815.303040,752.200500 
	C809.050537,750.760010 805.272095,752.573914 802.165833,759.225525 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M190.571991,760.062073 
	C188.119492,752.879211 183.428406,750.115112 177.408585,752.349854 
	C172.581451,754.141846 170.066650,762.979675 172.560989,769.018005 
	C174.209503,773.008728 177.038300,774.996826 181.381424,774.942932 
	C185.734772,774.888916 188.522675,773.045715 189.824707,768.704651 
	C190.151978,767.613464 191.938461,766.959900 193.714355,765.599731 
	C194.517715,771.738831 192.468597,775.163940 188.498856,777.222229 
	C183.453781,779.838013 178.206619,779.889954 173.369568,776.591492 
	C167.738800,772.751709 165.718246,763.579224 168.674118,756.263733 
	C171.192810,750.030273 176.555634,747.160950 184.022491,748.051758 
	C189.216324,748.671387 193.273727,752.422302 194.066040,757.035461 
	C194.548721,759.845825 193.456909,760.666626 190.571991,760.062073 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M411.747986,751.237793 
	C413.233795,754.103455 414.448151,756.714417 415.662506,759.325439 
	C415.109070,759.808960 414.555664,760.292419 414.002228,760.775940 
	C412.765106,759.714600 410.781342,758.854675 410.430573,757.554260 
	C409.369659,753.621338 406.807983,752.196716 403.154144,751.973022 
	C399.033508,751.720764 395.639832,752.703674 393.791443,756.957397 
	C390.815155,763.806580 393.081421,772.261597 398.597931,774.243713 
	C404.044464,776.200623 408.259033,774.244385 410.591949,768.989807 
	C411.127655,767.783142 412.591675,766.988647 414.514221,765.163635 
	C415.349762,771.446106 413.524231,774.927063 409.585297,777.085938 
	C404.361877,779.948730 398.963440,779.861450 394.011139,776.420593 
	C387.583221,771.954590 386.155457,760.158508 391.005615,753.306152 
	C395.379852,747.126160 404.411591,746.101257 411.747986,751.237793 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M491.916260,777.067566 
	C490.527069,777.723267 489.187775,777.967896 487.489746,778.278137 
	C487.489746,769.460388 487.388702,761.328674 487.575317,753.203552 
	C487.612457,751.587646 488.750977,749.997009 489.381592,748.394714 
	C490.371277,749.619751 491.360992,750.844788 492.363159,752.085205 
	C493.816711,751.060303 495.414062,749.481323 497.335297,748.658691 
	C505.109039,745.330017 512.430542,749.754028 513.225891,758.204956 
	C513.505737,761.178772 513.385925,764.192017 513.407776,767.187622 
	C513.433105,770.669006 513.413696,774.150635 513.413696,777.599365 
	C510.105865,778.901672 508.904358,778.013000 509.040985,774.695129 
	C509.225800,770.207092 509.137665,765.704590 509.064117,761.209961 
	C508.953552,754.452148 506.707336,751.920349 500.935608,751.873718 
	C494.825562,751.824463 492.069244,754.652405 491.994965,761.171204 
	C491.936188,766.332397 491.972229,771.494751 491.916260,777.067566 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M554.169373,752.052063 
	C553.678162,749.340332 554.361511,747.755127 557.761536,748.588684 
	C557.761536,759.102173 558.288513,769.694641 557.564636,780.200867 
	C557.127808,786.541626 551.390808,789.542480 543.653259,789.093323 
	C536.599792,788.683960 532.886536,785.339233 531.950134,777.240906 
	C533.967590,778.444702 535.931763,778.922546 536.522278,780.093201 
	C538.687805,784.386353 541.916016,786.065125 547.600525,784.929260 
	C551.621826,784.125732 552.692871,781.000732 552.953857,777.430420 
	C553.038330,776.273804 552.950317,775.104614 553.089844,773.206421 
	C553.472351,771.619995 553.706177,770.768250 554.000732,769.695496 
	C552.670166,769.794861 551.742554,769.864197 550.960938,769.594482 
	C554.682739,765.316895 553.755676,760.758057 553.028076,755.928284 
	C553.491516,754.448730 553.830444,753.250366 554.169373,752.052063 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M593.312134,236.937958 
	C594.719788,232.921936 597.317383,229.039124 598.076599,224.825134 
	C599.487854,216.991821 596.456055,210.174225 590.953979,204.480621 
	C582.627258,195.863983 572.523621,189.941666 561.034180,185.009720 
	C559.989014,184.816238 559.577271,184.772171 558.883911,184.494781 
	C553.060242,183.116226 547.518311,181.970947 541.793579,180.682373 
	C541.207397,180.313034 540.803955,180.087021 540.376404,179.520950 
	C540.379822,178.500854 540.407349,177.820816 540.605713,177.098267 
	C540.776611,177.055786 541.009644,176.791824 541.454468,176.818604 
	C544.292908,176.938126 546.686646,177.030869 549.204041,177.196594 
	C549.327759,177.269577 549.574402,177.122467 549.873108,177.384552 
	C553.742188,178.512863 557.312500,179.379059 560.942505,180.180542 
	C561.002075,180.115829 560.850342,180.204941 561.001709,180.521790 
	C564.409485,182.258362 567.665894,183.678070 570.913940,185.108246 
	C570.905640,185.118698 570.886536,185.144272 570.886536,185.144257 
	C580.907776,192.831192 590.928955,200.518112 601.127686,208.497086 
	C596.930054,209.012619 597.673645,211.750595 598.683838,214.390915 
	C601.311096,221.257416 600.134277,227.747604 596.786560,233.971329 
	C596.143311,235.167160 594.730347,235.948944 593.312134,236.937958 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M221.995483,671.987793 
	C223.990036,666.916931 225.984573,661.846130 227.987732,656.393738 
	C232.059555,655.347534 236.122757,654.682861 240.600754,654.374695 
	C242.073380,656.471741 243.131226,658.212280 243.795502,659.305298 
	C247.095169,658.835144 249.619553,658.475464 252.111786,658.488953 
	C251.732697,660.305115 251.385773,661.748108 251.038849,663.191162 
	C250.995956,663.508545 250.831421,663.739075 250.074799,664.027649 
	C249.409515,664.467224 249.214691,664.761902 249.019852,665.056580 
	C249.019836,665.056580 249.004578,664.999146 248.634995,664.985962 
	C245.190353,666.007874 242.115295,667.042969 238.810730,667.790771 
	C234.883865,666.966248 231.781006,667.450806 230.570419,671.940063 
	C228.444534,671.988892 226.743332,672.014954 224.661133,672.027100 
	C223.518570,672.004639 222.757019,671.996216 221.995483,671.987793 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M434.700134,766.710388 
	C434.700226,761.782349 434.700226,757.317810 434.700226,752.740417 
	C432.834106,752.414490 431.416260,752.166870 429.998413,751.919189 
	C429.738312,751.486572 429.478210,751.053894 429.218140,750.621277 
	C430.761658,749.545715 432.305206,748.470215 433.848755,747.394653 
	C434.003632,747.617371 434.158539,747.840149 434.313416,748.062866 
	C434.491699,747.798462 434.725220,747.555847 434.839417,747.266174 
	C435.682037,745.128357 436.500916,742.981140 437.327271,740.836914 
	C438.185608,743.042908 439.043976,745.248901 440.230927,748.299377 
	C441.092041,748.299377 442.912323,748.025635 444.599213,748.382507 
	C446.053589,748.690186 447.356110,749.715942 448.725708,750.424194 
	C447.442566,751.014832 446.208649,751.895142 444.863953,752.123840 
	C443.123352,752.419861 441.294342,752.196106 439.048828,752.196106 
	C439.048828,758.562500 438.929626,764.368835 439.089386,770.167542 
	C439.201935,774.253113 442.446136,773.901672 445.282623,774.233765 
	C446.478394,774.373718 447.581665,775.304016 448.726990,775.875061 
	C447.546417,776.632446 446.412140,777.954590 445.177795,778.055725 
	C436.915344,778.733093 434.659760,776.295410 434.700134,766.710388 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M553.822266,752.254150 
	C553.830444,753.250366 553.491516,754.448730 552.735718,755.722290 
	C548.580994,751.713989 542.000122,750.524963 538.126038,753.233276 
	C534.979492,755.432617 533.747925,762.368835 535.685608,766.976746 
	C537.289856,770.791626 542.240479,772.572449 547.603821,771.190735 
	C548.708862,770.906006 549.746338,770.359375 550.815002,769.933472 
	C551.742554,769.864197 552.670166,769.794861 554.000732,769.695496 
	C553.706177,770.768250 553.472351,771.619995 553.100342,772.775513 
	C552.360352,773.188843 551.679993,773.153076 551.168579,773.429565 
	C543.917480,777.351685 536.480408,776.160095 532.533386,770.400391 
	C528.730957,764.851685 529.794495,754.803894 534.623901,750.649841 
	C539.656860,746.320618 547.634949,747.085144 553.822266,752.254150 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M708.407471,773.858765 
	C708.091919,773.862732 707.612976,773.733093 707.484375,773.890137 
	C703.238037,779.078979 697.775085,780.359985 691.911194,777.684753 
	C686.235596,775.095459 685.768799,769.577820 685.895447,764.052124 
	C685.986572,760.073547 685.709961,756.075439 686.020264,752.119202 
	C686.125244,750.780457 687.526001,749.543274 688.334534,748.259705 
	C689.023010,749.497803 690.234985,750.707214 690.304626,751.979309 
	C690.540161,756.277771 690.385559,760.598633 690.351013,764.910645 
	C690.320557,768.701477 690.691528,772.391724 694.722107,774.125854 
	C698.710938,775.842102 702.483948,775.302063 706.344727,772.039673 
	C707.451965,772.542847 707.929749,773.200745 708.407471,773.858765 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M757.437622,753.099792 
	C761.183350,750.603638 764.354553,747.187012 769.672546,747.859009 
	C775.213684,748.559204 778.927368,751.035278 779.318909,756.594604 
	C779.806213,763.515137 779.435730,770.496094 779.435730,777.928772 
	C778.417725,777.981750 777.045898,778.053162 775.240417,778.147095 
	C775.240417,772.129395 775.315674,766.533936 775.214661,760.941650 
	C775.144165,757.040466 774.840454,753.162659 769.919739,752.063599 
	C764.710083,750.899841 761.566345,752.291626 758.467834,756.963745 
	C757.870117,755.615784 757.653870,754.357788 757.437622,753.099792 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M305.322632,752.238586 
	C306.487244,751.637878 307.773834,751.195496 308.794861,750.408569 
	C312.756165,747.355469 317.211212,747.142883 321.576782,748.719238 
	C325.748047,750.225403 327.565582,753.941589 327.686890,758.214966 
	C327.866333,764.536133 327.733276,770.866211 327.733276,777.737427 
	C326.504456,777.891357 325.171967,778.058228 323.753723,778.235840 
	C323.511475,777.417603 323.270630,776.977722 323.267303,776.536011 
	C323.227142,771.205872 323.344177,765.871460 323.165771,760.546631 
	C323.042114,756.853943 322.801514,752.889526 317.968750,752.131287 
	C313.281433,751.395874 308.871338,751.671875 306.498657,757.016846 
	C305.861359,755.364624 305.592010,753.801636 305.322632,752.238586 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M260.608734,751.864502 
	C261.637299,751.431763 262.817169,751.193970 263.668671,750.533020 
	C267.741364,747.371948 272.285980,747.124207 276.814453,748.799011 
	C281.225159,750.430298 282.790802,754.380310 282.907837,758.766296 
	C283.075256,765.037964 282.950470,771.317505 282.950470,777.869812 
	C281.335480,777.869812 279.952393,777.869812 278.394196,777.869812 
	C278.394196,771.928040 278.483551,766.312988 278.362701,760.702515 
	C278.282532,756.980652 277.900146,753.240906 273.337982,752.083130 
	C268.124695,750.760071 264.471191,752.463928 261.586060,757.225098 
	C261.015686,755.378601 260.812195,753.621582 260.608734,751.864502 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M416.979797,575.978210 
	C409.267883,581.208313 408.581818,589.238525 408.693329,597.915771 
	C400.246124,597.915771 392.087372,597.915771 383.512268,597.915771 
	C383.219940,582.878174 388.332184,570.478821 400.656830,561.070312 
	C400.983795,560.984070 401.066101,561.066956 401.053253,561.457825 
	C399.381958,564.042114 397.861084,566.357849 396.039612,568.406311 
	C390.207703,574.964844 386.319275,582.486023 385.184784,591.178711 
	C384.953186,592.953247 386.602814,596.601746 387.467499,596.627563 
	C393.211578,596.798828 399.344116,597.253418 404.567719,595.404785 
	C406.843994,594.599182 407.273895,588.569031 408.533813,584.908752 
	C408.695068,584.440308 408.842194,583.961182 408.932678,583.475220 
	C409.758789,579.037415 412.363068,576.460815 416.865204,575.854614 
	C416.865204,575.854614 416.941589,575.937012 416.979797,575.978210 
z"/>
<path fill="#213AC3" opacity="1.000000" stroke="none" 
	d="
M325.180542,194.293869 
	C325.687134,193.947617 326.028931,193.957764 326.654297,194.372253 
	C325.300629,202.192719 323.663361,209.608871 322.015442,217.470352 
	C321.341553,225.620239 320.678284,233.324783 320.003723,241.469940 
	C319.995575,244.268723 319.998779,246.626892 319.989807,249.423660 
	C319.981873,251.910202 319.986053,253.958145 319.990234,256.006104 
	C320.325775,264.407013 320.661285,272.807892 320.960083,281.677307 
	C319.895264,274.575989 318.179321,267.022278 318.001221,259.432495 
	C317.737854,248.210342 317.910126,236.891098 319.122040,225.746414 
	C320.259003,215.291031 322.987549,205.008728 325.180542,194.293869 
z"/>
<path fill="#213AC3" opacity="1.000000" stroke="none" 
	d="
M545.385620,462.416626 
	C545.118652,462.160736 545.090820,461.946014 545.469360,461.380554 
	C550.270386,460.375397 554.665161,459.721039 559.385315,459.055634 
	C560.146240,459.026306 560.581665,459.007965 561.352844,458.991211 
	C562.474060,458.683472 563.259583,458.374115 564.477112,458.057861 
	C569.266296,457.223114 573.666504,456.572327 577.971008,455.527252 
	C585.008545,453.818665 591.983826,451.853333 599.351440,449.959534 
	C602.806641,448.940308 605.895813,447.957947 609.049927,447.143280 
	C609.240906,447.517212 609.392639,447.702240 609.570251,447.866058 
	C593.491577,455.221436 576.371033,458.683624 559.045105,461.368347 
	C557.243164,461.647522 555.420654,461.793823 552.717468,461.965149 
	C550.046692,461.984589 548.266052,462.040710 546.485474,462.096802 
	C546.269470,462.386139 545.982605,462.506470 545.385620,462.416626 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M464.611694,755.181030 
	C464.611053,752.731567 464.611053,750.755127 464.611053,748.721191 
	C467.287506,747.612549 468.934662,747.584167 468.915039,750.958862 
	C468.863159,759.879517 468.897461,768.800659 468.897461,777.639832 
	C465.960938,778.688293 464.523865,778.238708 464.581238,775.046692 
	C464.697479,768.584412 464.613647,762.118530 464.611694,755.181030 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M189.686646,574.320190 
	C188.463715,579.183350 186.830811,584.185669 185.475708,589.262146 
	C184.979782,591.119934 185.142914,593.153687 184.947205,595.934021 
	C191.600693,595.934021 197.847504,596.059570 204.073853,595.759705 
	C204.775436,595.726013 205.589951,593.350220 205.955368,591.956482 
	C206.826614,588.633423 207.248291,585.179199 208.304031,581.924805 
	C208.679367,580.767822 210.362228,580.034912 211.826569,579.121582 
	C210.504288,585.173767 208.803650,591.212769 206.897339,597.982056 
	C200.013397,597.982056 191.812592,597.982056 182.864960,597.982056 
	C183.859085,589.709717 183.293915,581.278931 189.686646,574.320190 
z"/>
<path fill="#2F74F6" opacity="1.000000" stroke="none" 
	d="
M687.333130,564.828552 
	C689.240723,555.597656 691.383545,546.161316 693.675415,536.761292 
	C695.307007,530.069580 697.194458,523.440613 698.851135,516.754700 
	C699.419678,514.460144 698.754883,512.920044 695.896484,512.942139 
	C694.355103,512.954041 692.809204,512.377258 691.149658,511.714417 
	C694.451477,511.362762 697.869019,511.362762 702.014343,511.362762 
	C700.976074,515.518921 700.091858,519.158875 699.156128,522.785461 
	C695.767944,535.917236 692.374939,549.047791 688.935669,562.166260 
	C688.705566,563.044006 688.075928,563.816956 687.333130,564.828552 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M460.001770,227.013657 
	C459.909027,226.682495 459.724762,226.414215 459.192871,225.757126 
	C453.609375,219.891174 448.282043,214.476898 442.954681,209.062622 
	C442.954681,209.062622 442.988037,208.995438 442.913147,208.711639 
	C442.229614,207.947296 441.620911,207.466721 441.012207,206.986145 
	C441.012207,206.986160 441.000000,207.000000 440.981079,206.650269 
	C438.350189,201.856598 435.738220,197.412643 433.467255,193.021271 
	C434.528015,193.700668 435.325531,194.258163 435.955688,194.964920 
	C443.982635,203.967896 451.985596,212.992249 460.025391,222.339111 
	C461.034729,223.779404 462.013397,224.891769 463.161011,226.248474 
	C463.884613,226.667801 464.439240,226.842804 465.131042,227.321899 
	C466.837555,229.749268 468.406860,231.872513 469.738983,234.138062 
	C469.316956,234.596115 469.132111,234.911880 468.947266,235.227661 
	C468.414398,234.936066 467.881531,234.644470 467.024384,233.934418 
	C466.197174,233.221298 465.694275,232.926636 465.191345,232.631989 
	C465.191345,232.631989 465.120758,232.289627 464.990112,231.892456 
	C464.574921,231.336105 464.290405,231.176941 464.005859,231.017776 
	C464.005859,231.017776 464.003418,230.994537 463.900848,230.687897 
	C462.532776,229.258713 461.267273,228.136185 460.001770,227.013657 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M260.690216,751.446655 
	C260.812195,753.621582 261.015686,755.378601 261.344421,757.502197 
	C261.390106,763.508972 261.161804,769.152649 261.291412,774.788086 
	C261.360168,777.777222 260.356110,778.756104 257.256226,777.818909 
	C257.256226,768.220581 257.256226,758.509399 257.256226,748.798279 
	C257.614197,748.522827 257.972168,748.247375 258.330139,747.971924 
	C259.143982,748.990906 259.957855,750.009827 260.690216,751.446655 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M305.446533,751.887695 
	C305.592010,753.801636 305.861359,755.364624 306.253845,757.279419 
	C306.376984,764.233948 306.376984,770.836731 306.376984,777.552185 
	C303.235840,778.674805 301.878143,778.252869 301.952240,774.777588 
	C302.121490,766.836853 301.995941,758.889832 302.388062,750.467041 
	C303.674072,750.014587 304.562103,750.040466 305.450165,750.066406 
	C305.490265,750.556519 305.530365,751.046692 305.446533,751.887695 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M757.598267,752.742554 
	C757.653870,754.357788 757.870117,755.615784 758.197205,757.195923 
	C758.302490,757.851013 758.296875,758.183899 757.959045,758.892273 
	C757.753906,759.845825 757.880920,760.424011 758.007996,761.002136 
	C758.060242,765.617493 757.979248,770.239624 758.212036,774.845886 
	C758.364380,777.858765 757.224670,778.703308 754.028931,777.723389 
	C754.028931,768.490356 754.028931,759.129333 754.518066,749.479614 
	C755.849243,749.479492 756.691345,749.768127 757.533447,750.056763 
	C757.608643,750.832886 757.683777,751.609131 757.598267,752.742554 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M477.936920,242.259933 
	C477.936920,242.259933 477.469360,242.106323 477.136597,241.836487 
	C476.599701,241.250748 476.322876,241.162247 475.973358,241.301178 
	C475.973358,241.301178 475.573669,241.044998 475.237793,240.761017 
	C474.594208,240.380524 474.286499,240.284012 473.978790,240.187500 
	C473.978790,240.187500 473.643005,239.946976 473.345459,239.598206 
	C472.360687,238.840408 471.673492,238.431412 470.986298,238.022400 
	C470.986298,238.022400 471.001221,238.000000 470.932190,237.752548 
	C470.563385,237.366272 470.263641,237.227448 469.963898,237.088623 
	C469.816895,236.757568 469.669922,236.426514 469.633362,235.679688 
	C469.743835,235.263901 470.051758,234.947479 470.372253,234.965790 
	C471.121246,234.995392 471.549774,235.006714 472.175171,235.289520 
	C475.593658,238.315643 478.321930,240.978317 483.587280,240.373947 
	C488.160461,239.849014 494.194275,238.451599 496.996399,234.566727 
	C500.154144,230.188858 504.398956,227.402512 509.936554,226.935928 
	C509.936554,226.935928 510.011902,227.021545 509.879395,227.355560 
	C505.187469,231.476273 500.628082,235.262955 496.068665,239.049652 
	C496.068665,239.049652 495.992218,239.028748 495.689270,239.044708 
	C494.561798,239.678284 493.737244,240.295898 492.912689,240.913513 
	C492.912689,240.913498 492.979828,241.005203 492.688873,241.023560 
	C492.130859,241.282974 491.863800,241.524033 491.596741,241.765076 
	C491.245941,241.849884 490.895172,241.934708 489.902008,242.046692 
	C485.485382,242.135880 481.711151,242.197906 477.936920,242.259933 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M708.336914,774.288696 
	C707.929749,773.200745 707.451965,772.542847 706.638672,771.865601 
	C706.654968,771.118591 707.006714,770.390869 707.921631,769.139771 
	C708.647034,762.023560 708.809387,755.430725 708.971741,748.837830 
	C709.405823,748.709229 709.839844,748.580627 710.705994,748.873657 
	C711.447021,749.536194 711.755981,749.777161 712.064941,750.018066 
	C712.064941,759.127197 712.064941,768.236328 712.064941,777.616211 
	C709.301147,778.746460 707.769348,778.226990 708.336914,774.288696 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M316.890503,655.399475 
	C319.856171,657.454041 322.644196,660.128235 325.816071,662.220642 
	C329.709076,664.788696 333.442688,668.914734 338.965698,665.434509 
	C339.032135,665.392700 340.296021,667.251404 341.003845,668.929932 
	C341.019318,670.058350 341.031952,670.479736 341.044556,670.901123 
	C330.886871,669.211975 321.791412,665.827576 316.890503,655.399475 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M700.670105,377.965149 
	C696.955383,382.899933 693.365662,387.936035 689.479370,392.731689 
	C687.127869,395.633270 684.316467,398.162140 681.313110,400.886780 
	C680.914551,400.915100 680.749207,400.750336 680.833679,400.411438 
	C682.298340,397.727417 683.678528,395.382294 685.425659,393.033875 
	C687.283325,391.871765 688.989014,390.904022 690.222290,389.516357 
	C693.248657,386.111084 696.057373,382.512329 699.184937,378.891663 
	C699.834229,378.516357 700.252197,378.240753 700.670105,377.965149 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M496.464508,239.056671 
	C500.628082,235.262955 505.187469,231.476273 509.916199,227.399124 
	C514.472290,225.774353 518.859009,224.440048 523.630371,223.184448 
	C519.927979,225.465637 515.770508,227.548782 511.776337,229.908340 
	C508.584869,231.793671 505.633789,234.081436 502.495117,236.062424 
	C500.700409,237.195190 498.743988,238.071777 496.464508,239.056671 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M609.817749,447.679504 
	C609.392639,447.702240 609.240906,447.517212 609.215698,446.834747 
	C609.990540,445.920746 610.638794,445.435242 611.342957,445.052795 
	C616.901855,442.033630 622.471375,439.034180 628.429932,436.021362 
	C630.859192,435.651428 632.896118,435.288757 634.979553,434.979431 
	C635.026062,435.032837 635.118164,435.140350 635.118164,435.140381 
	C626.767212,439.257904 618.416260,443.375458 609.817749,447.679504 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M719.960571,343.356537 
	C720.811279,342.410706 721.624939,341.820190 722.725586,341.114380 
	C717.920288,350.291321 712.828186,359.583557 707.408691,369.005432 
	C707.307007,367.709320 707.162781,366.079132 707.819092,364.891357 
	C711.751709,357.775024 715.868469,350.760498 719.960571,343.356537 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M146.934982,773.997559 
	C146.249466,769.166260 148.944748,769.372314 152.306030,770.075195 
	C152.306030,772.510254 152.306030,775.035461 152.306030,777.802307 
	C149.614395,778.192444 146.278000,779.716980 146.934982,773.997559 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M239.040222,668.078125 
	C242.115295,667.042969 245.190353,666.007874 248.659073,665.003418 
	C237.963806,673.337280 225.227539,674.723328 211.667221,673.595581 
	C211.900848,673.172363 212.424805,673.021729 212.948761,672.871094 
	C215.647598,672.888672 218.346451,672.906311 221.836731,672.934753 
	C223.432816,672.644043 224.237488,672.342590 225.042145,672.041077 
	C226.743332,672.014954 228.444534,671.988892 230.935303,671.948059 
	C234.163330,670.648254 236.601776,669.363159 239.040222,668.078125 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M312.822449,578.470581 
	C313.108917,578.010376 313.278229,578.005615 313.700867,577.991089 
	C313.880920,599.418396 313.807587,620.855286 313.497131,642.626221 
	C313.143982,641.807678 313.027985,640.655029 312.963257,638.812500 
	C313.003845,637.771912 312.993164,637.421143 312.982483,637.070435 
	C312.890106,617.688965 312.797699,598.307495 312.822449,578.470581 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M657.086670,421.093109 
	C649.950806,425.775208 642.815002,430.457306 635.398682,435.139893 
	C635.118164,435.140350 635.026062,435.032837 635.053833,434.637207 
	C641.040039,430.149231 646.998474,426.056885 653.281494,421.840698 
	C654.719604,421.447510 655.833130,421.178101 656.980835,420.955444 
	C657.015015,421.002228 657.086670,421.093109 657.086670,421.093109 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M918.719788,773.332886 
	C918.125549,768.367859 921.272095,770.008545 923.798706,769.933411 
	C923.798706,772.607361 923.798706,775.155151 923.798706,777.939636 
	C922.251465,777.939636 920.808838,777.939636 918.930664,777.939636 
	C918.860840,776.541992 918.790833,775.139526 918.719788,773.332886 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M624.668945,232.164490 
	C623.059448,234.062714 621.355225,236.381668 619.079590,237.751724 
	C615.385315,239.975891 611.316650,241.578354 606.818420,243.733795 
	C604.485107,244.380981 602.739441,244.737656 600.993713,245.094330 
	C601.240234,244.728012 601.486816,244.361694 601.837158,243.796295 
	C609.399780,239.784515 616.858765,235.971848 624.668945,232.164490 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M341.452148,670.993286 
	C341.031952,670.479736 341.019318,670.058350 341.007019,669.320923 
	C341.747528,669.011597 342.487701,669.018372 343.868256,669.299316 
	C346.340210,669.717834 348.171844,669.862122 350.003448,670.006409 
	C351.716827,670.008545 353.430206,670.010620 355.571777,670.006348 
	C359.462494,669.784729 362.924957,669.569458 366.663910,669.616821 
	C358.713989,673.064697 350.277679,671.884216 341.452148,670.993286 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M943.380981,769.855225 
	C944.502075,772.380188 945.230774,774.901489 946.084473,777.855225 
	C943.547363,777.992798 942.114197,778.070496 939.860962,778.192688 
	C941.324341,775.204102 936.598816,770.931946 943.380981,769.855225 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M442.957733,209.440094 
	C448.282043,214.476898 453.609375,219.891174 458.973816,225.641464 
	C455.109833,222.576752 451.092743,219.297150 447.358826,215.721848 
	C445.610901,214.048157 444.409760,211.803452 442.957733,209.440094 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M104.972641,776.747314 
	C104.929558,774.339417 104.929558,772.392883 104.929558,770.069275 
	C106.735245,769.974915 108.271271,769.894592 110.122841,769.797791 
	C110.122841,772.660217 110.122841,775.188477 110.122841,778.408203 
	C108.209023,777.958740 106.612366,777.583801 104.972641,776.747314 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M845.056152,556.669312 
	C845.568665,557.238647 846.116089,558.205200 845.893433,558.439392 
	C842.203552,562.319641 838.417114,566.108032 834.303162,569.934753 
	C834.974060,563.446899 839.918579,560.139465 845.056152,556.669312 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M382.587830,630.653198 
	C382.894073,636.343689 382.946136,642.207886 382.685852,648.535217 
	C381.960327,648.986206 381.547150,648.973999 381.133972,648.961853 
	C380.930115,646.517456 380.726288,644.073120 380.933044,641.166626 
	C381.507446,640.161011 381.671295,639.617554 381.835114,639.074097 
	C381.507996,639.134644 381.180878,639.195190 380.853760,639.255676 
	C381.347046,636.446045 381.840332,633.636475 382.587830,630.653198 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M212.695511,672.616882 
	C212.424805,673.021729 211.900848,673.172363 211.203934,673.537720 
	C205.370178,671.761230 199.709381,669.769958 193.934875,667.440002 
	C198.136887,668.338928 202.450623,669.583679 206.769318,670.810913 
	C208.655029,671.346680 210.550964,671.846558 212.695511,672.616882 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M708.603027,748.949768 
	C708.809387,755.430725 708.647034,762.023560 708.192261,768.835815 
	C708.011292,762.390686 708.122803,755.726196 708.603027,748.949768 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M903.226013,771.041260 
	C903.278198,773.489929 903.278198,775.453491 903.278198,777.872192 
	C901.576233,777.963318 900.033997,778.045898 898.164246,778.145996 
	C898.164246,775.286255 898.164246,772.757751 898.164246,769.645020 
	C899.881470,769.957397 901.527649,770.256836 903.226013,771.041260 
z"/>
<path fill="#0A0A0A" opacity="1.000000" stroke="none" 
	d="
M125.816849,772.981934 
	C127.060318,771.320190 128.279022,770.141052 129.497726,768.961853 
	C129.932236,769.324585 130.366760,769.687317 130.801285,770.050049 
	C130.801285,772.469727 130.801285,774.889404 130.801285,777.838684 
	C129.447449,777.927368 127.908607,778.028198 125.792091,778.166870 
	C125.792091,776.327087 125.792091,774.895813 125.816849,772.981934 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M728.969360,325.982178 
	C729.649414,322.601196 730.329468,319.220184 731.015869,315.417816 
	C732.488220,312.105103 733.954285,309.213776 735.751465,306.176208 
	C733.962830,312.961029 731.843079,319.892059 729.370361,326.689453 
	C729.001282,326.364594 728.985291,326.173370 728.969360,325.982178 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M432.152344,181.648865 
	C433.252380,179.895828 434.307892,178.542267 435.677307,177.444641 
	C434.901123,182.196228 433.810974,186.691864 432.434296,191.208649 
	C431.777313,191.144257 431.406830,191.058731 431.036377,190.973206 
	C431.393524,187.998245 431.750641,185.023285 432.152344,181.648865 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M431.009552,191.361084 
	C431.406830,191.058731 431.777313,191.144257 432.425781,191.519592 
	C432.844604,192.195816 432.985443,192.582260 433.126251,192.968704 
	C435.738220,197.412643 438.350189,201.856598 440.987976,206.644363 
	C438.235229,203.786057 435.264618,200.724655 432.766846,197.316742 
	C431.674103,195.825836 431.547882,193.626556 431.009552,191.361084 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M251.307739,663.157410 
	C251.385773,661.748108 251.732697,660.305115 252.475098,658.467529 
	C254.904770,656.709778 256.938934,655.346619 259.254333,653.870117 
	C260.402649,653.765808 261.269714,653.774780 262.136810,653.783752 
	C262.136810,653.783752 262.104980,653.834351 262.088135,653.858887 
	C258.573029,656.963501 255.074829,660.043579 251.307739,663.157410 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M396.004303,616.766113 
	C392.820221,619.650146 389.630890,622.265930 386.057251,624.803955 
	C388.885620,621.846924 392.098297,618.967651 395.654877,616.024780 
	C395.998871,616.140015 395.998993,616.318909 396.004303,616.766113 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M728.620361,326.048584 
	C728.985291,326.173370 729.001282,326.364594 729.036133,326.842926 
	C727.288269,331.419495 725.521667,335.708954 723.434326,340.146423 
	C724.832886,335.567963 726.552124,330.841492 728.620361,326.048584 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M793.222656,570.479858 
	C789.824646,567.829224 786.419617,564.827209 783.008667,561.472778 
	C786.407104,564.123047 789.811401,567.125732 793.222656,570.479858 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M661.604614,159.940918 
	C660.494934,155.304886 659.747620,150.554596 658.996582,145.406952 
	C662.693115,147.705246 664.015808,154.294647 661.604614,159.940918 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M737.625061,301.905090 
	C737.693604,297.839813 738.002319,293.529419 738.445618,289.232971 
	C738.575989,287.969604 739.087524,286.745544 739.717285,285.615692 
	C740.036743,286.218658 740.063232,286.709442 740.089783,287.200195 
	C739.365417,292.021881 738.641052,296.843597 737.625061,301.905090 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M627.328491,200.972778 
	C629.804077,197.794281 632.627991,194.609482 635.772095,191.371063 
	C633.287170,194.533813 630.482056,197.750153 627.328491,200.972778 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M383.976135,657.359863 
	C388.190674,656.906555 388.959442,660.221191 390.038635,663.801636 
	C390.041809,664.368774 390.049347,664.764343 390.049347,664.764343 
	C388.039154,662.411621 386.028961,660.058838 383.976135,657.359863 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M680.997253,400.997467 
	C679.332764,402.712311 677.585632,404.344788 675.434021,406.002106 
	C675.029602,406.026947 674.862671,405.863739 674.955933,405.536377 
	C676.949158,403.722809 678.849182,402.236572 680.749207,400.750305 
	C680.749207,400.750336 680.914551,400.915100 680.997253,400.997467 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M478.061005,242.628586 
	C481.711151,242.197906 485.485382,242.135880 489.662018,242.175064 
	C486.286774,245.517471 482.234558,244.234772 478.061005,242.628586 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M642.338074,184.957520 
	C643.739441,182.970200 645.488159,180.981140 647.560425,178.904938 
	C646.151184,180.863785 644.418274,182.909790 642.338074,184.957520 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M673.113342,408.082153 
	C671.354675,409.756378 669.595947,411.430603 667.432617,413.066895 
	C667.027954,413.028900 666.911377,412.906342 666.974854,412.567902 
	C668.931091,410.796753 670.823853,409.364075 672.808960,407.987061 
	C672.901306,408.042694 673.113342,408.082153 673.113342,408.082153 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M602.216125,209.298798 
	C605.374817,209.301773 608.745789,209.599197 612.546143,209.957230 
	C609.329102,213.121216 605.898438,210.868561 602.216125,209.298798 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M700.783081,377.946960 
	C700.252197,378.240753 699.834229,378.516357 699.203491,378.543304 
	C699.337585,377.207550 699.684570,376.120453 700.031555,375.033386 
	C701.177612,374.383911 702.323730,373.734467 703.805664,373.096924 
	C703.480286,374.271790 702.819031,375.434692 701.694946,376.831177 
	C701.025391,377.226166 700.916016,377.437927 700.904053,377.700012 
	C700.904053,377.700012 700.896118,377.928772 700.783081,377.946960 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M614.924927,209.748199 
	C616.471191,208.493820 618.422180,207.347443 620.709229,206.199997 
	C620.364868,209.332260 618.246887,210.218719 614.924927,209.748199 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M667.086548,413.089966 
	C665.708374,414.473694 664.271606,415.796326 662.438721,417.081604 
	C662.042542,417.044281 661.925049,416.943176 661.984314,416.595581 
	C663.666138,415.134094 665.288757,414.020203 666.911377,412.906311 
	C666.911377,412.906342 667.027954,413.028900 667.086548,413.089966 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M658.448730,166.987595 
	C658.864746,165.143723 659.634888,163.242065 660.686768,161.152496 
	C660.246643,162.953003 659.524719,164.941406 658.448730,166.987595 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M740.380249,287.068481 
	C740.063232,286.709442 740.036743,286.218658 739.997803,285.359070 
	C739.980408,283.932526 739.975464,282.874847 740.204041,281.397491 
	C740.437561,280.977844 740.925659,280.928741 740.925659,280.928741 
	C740.925659,280.928741 741.013062,281.012848 741.056885,281.054779 
	C740.957336,283.043396 740.814026,284.990051 740.380249,287.068481 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M459.983459,227.387848 
	C461.267273,228.136185 462.532776,229.258713 463.889282,230.689743 
	C462.641907,229.919525 461.303558,228.840790 459.983459,227.387848 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M305.581543,749.761414 
	C304.562103,750.040466 303.674072,750.014587 302.452515,750.003418 
	C303.316956,749.830994 304.514923,749.643738 305.581543,749.761414 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M390.260437,665.007019 
	C390.049347,664.764343 390.041809,664.368774 390.066833,664.172241 
	C391.041626,664.780396 391.991425,665.585205 392.951080,666.733887 
	C392.131134,666.468445 391.301331,665.859070 390.260437,665.007019 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M383.024506,651.993347 
	C383.077209,652.905640 383.129944,653.817871 383.173645,654.996216 
	C382.751282,654.355957 382.337921,653.449768 382.137451,652.274231 
	C382.575134,652.003662 382.799835,651.999817 383.024506,651.993347 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M622.066956,205.858795 
	C622.598511,204.921051 623.489380,204.032135 624.702454,203.098114 
	C624.158569,204.004532 623.292358,204.956085 622.066956,205.858795 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M757.651672,749.788574 
	C756.691345,749.768127 755.849243,749.479492 754.682190,749.105835 
	C755.494751,749.187317 756.632324,749.353821 757.651672,749.788574 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M383.016876,651.619995 
	C382.799835,651.999817 382.575134,652.003662 382.182617,652.012085 
	C381.683105,651.276917 381.351349,650.534607 381.076782,649.377075 
	C381.547150,648.973999 381.960327,648.986206 382.683350,649.002258 
	C382.998535,649.752930 383.003876,650.499817 383.016876,651.619995 
z"/>
<path fill="#2343D5" opacity="1.000000" stroke="none" 
	d="
M546.825562,462.265930 
	C548.266052,462.040710 550.046692,461.984589 552.257141,461.915100 
	C550.846558,462.079529 549.006165,462.257294 546.825562,462.265930 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M385.444611,624.837280 
	C385.684235,625.634521 385.632996,626.578918 384.991028,627.741150 
	C384.183441,627.938416 383.966583,627.917786 383.749756,627.897156 
	C384.217743,626.926208 384.685730,625.955261 385.444611,624.837280 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M797.583435,576.459351 
	C796.811951,576.025391 795.983765,575.244812 795.283936,574.047852 
	C796.117065,574.458618 796.821899,575.285645 797.583435,576.459351 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M795.416565,573.276306 
	C794.642700,572.927856 793.855530,572.263489 793.120361,571.205872 
	C793.916016,571.528564 794.659607,572.244446 795.416565,573.276306 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M661.112244,418.113464 
	C660.349976,418.793854 659.587708,419.474243 658.441833,420.107178 
	C658.058228,420.059692 657.928650,419.943420 658.002563,419.608887 
	C659.029907,418.819427 659.983276,418.364502 660.978394,417.962341 
	C661.020203,418.015137 661.112244,418.113464 661.112244,418.113464 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M493.271851,240.944244 
	C493.737244,240.295898 494.561798,239.678284 495.718781,239.071198 
	C495.244476,239.712799 494.437744,240.343872 493.271851,240.944244 
z"/>
<path fill="#1F1F1F" opacity="1.000000" stroke="none" 
	d="
M712.210510,749.884277 
	C711.755981,749.777161 711.447021,749.536194 711.030151,749.017395 
	C711.400269,749.076477 711.878174,749.413452 712.210510,749.884277 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M383.511963,628.086914 
	C383.966583,627.917786 384.183441,627.938416 384.726440,627.986694 
	C384.582306,628.657410 384.111969,629.300476 383.317169,629.990601 
	C383.086548,629.450623 383.180359,628.863647 383.511963,628.086914 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M399.813751,613.938599 
	C399.512909,614.337280 399.035736,614.657959 398.332428,615.006836 
	C398.616699,614.643616 399.127045,614.252136 399.813751,613.938599 
z"/>
<path fill="#262626" opacity="1.000000" stroke="none" 
	d="
M758.132996,760.825195 
	C757.880920,760.424011 757.753906,759.845825 757.851318,759.122009 
	C758.136536,759.533630 758.197266,760.090881 758.132996,760.825195 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M471.020447,238.364441 
	C471.673492,238.431412 472.360687,238.840408 473.144836,239.549179 
	C472.512695,239.468124 471.783630,239.087296 471.020447,238.364441 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M588.020386,240.861755 
	C588.279236,240.292847 588.862305,239.775101 589.750732,239.296295 
	C589.485596,239.861130 588.915100,240.387024 588.020386,240.861755 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M591.108032,238.863800 
	C591.286804,238.249908 591.812012,237.661560 592.645020,237.012695 
	C592.453430,237.597916 591.953918,238.243637 591.108032,238.863800 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M625.373169,202.988770 
	C625.483948,202.368134 625.917358,201.714294 626.665039,201.020248 
	C626.551453,201.638565 626.123596,202.297073 625.373169,202.988770 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M441.020630,207.311417 
	C441.620911,207.466721 442.229614,207.947296 442.884949,208.736450 
	C442.297424,208.575607 441.663239,208.106155 441.020630,207.311417 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M675.113525,406.108093 
	C674.693237,406.769409 674.188965,407.349579 673.399048,408.005981 
	C673.113342,408.082153 672.901306,408.042694 672.937744,407.693359 
	C673.603638,406.850555 674.233154,406.357147 674.862671,405.863708 
	C674.862671,405.863739 675.029602,406.026947 675.113525,406.108093 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M782.040466,560.615356 
	C781.289917,560.690491 780.362549,560.472900 779.397522,559.875732 
	C780.194458,559.771667 781.029053,560.047058 782.040466,560.615356 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M705.422729,371.224915 
	C705.556824,370.599213 705.985291,369.859985 706.776123,369.098053 
	C706.664734,369.754028 706.190918,370.432739 705.422729,371.224915 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M380.727844,639.526245 
	C381.180878,639.195190 381.507996,639.134644 381.835114,639.074097 
	C381.671295,639.617554 381.507446,640.161011 381.083740,640.825195 
	C380.749878,640.562866 380.675873,640.179810 380.727844,639.526245 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M639.490234,188.012772 
	C639.517273,187.605255 639.851501,187.162033 640.474731,186.651031 
	C640.441650,187.047836 640.119568,187.512451 639.490234,188.012772 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M312.878540,637.211304 
	C312.993164,637.421143 313.003845,637.771912 313.035095,638.382751 
	C312.961975,638.212646 312.868317,637.782410 312.878540,637.211304 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M97.304962,671.447998 
	C97.543587,671.202698 98.007744,671.080688 98.696274,671.067139 
	C98.457268,671.307556 97.993889,671.439453 97.304962,671.447998 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M33.264191,671.395630 
	C33.670258,671.160828 34.156151,671.201782 34.802109,671.429199 
	C34.422783,671.634216 33.883404,671.652710 33.264191,671.395630 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M651.016174,77.655426 
	C650.558411,77.729515 649.945007,77.521782 649.205444,77.001160 
	C649.673035,76.916710 650.266785,77.145157 651.016174,77.655426 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M736.338379,305.935791 
	C736.202515,305.496124 736.355103,304.920685 736.760254,304.173828 
	C736.884216,304.601624 736.755554,305.200806 736.338379,305.935791 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M465.228210,232.937775 
	C465.694275,232.926636 466.197174,233.221298 466.744354,233.811005 
	C466.280792,233.818573 465.772949,233.531082 465.228210,232.937775 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M831.657104,569.695557 
	C831.453613,569.481995 831.367920,568.966675 831.425354,568.298218 
	C831.637329,568.561340 831.706116,568.977539 831.657104,569.695557 
z"/>
<path fill="#2F74F6" opacity="1.000000" stroke="none" 
	d="
M674.253296,618.110962 
	C674.080505,617.987549 674.125671,617.734924 674.350464,617.340332 
	C674.578613,617.457153 674.558960,617.718262 674.253296,618.110962 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M526.082397,223.307312 
	C526.148376,223.111847 526.319092,223.057892 526.752197,223.060760 
	C526.738831,223.227997 526.462952,223.338394 526.082397,223.307312 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M464.073120,231.247223 
	C464.290405,231.176941 464.574921,231.336105 464.882996,231.754333 
	C464.575867,231.941910 464.320496,231.762985 464.073120,231.247223 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M626.975586,231.676132 
	C626.785828,231.994202 626.585999,231.986969 626.087585,231.949463 
	C625.971680,231.583511 626.154358,231.247849 626.609314,230.808777 
	C626.909546,230.920532 626.937500,231.135681 626.975586,231.676132 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M469.032959,235.352478 
	C469.132111,234.911880 469.316956,234.596115 469.756714,234.376129 
	C470.011658,234.471893 470.051758,234.947479 470.051758,234.947479 
	C470.051758,234.947479 469.743835,235.263901 469.576660,235.412903 
	C469.409485,235.561890 469.118683,235.477325 469.032959,235.352478 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M470.054047,237.316589 
	C470.263641,237.227448 470.563385,237.366272 470.920227,237.758453 
	C470.627502,237.984543 470.349823,237.828781 470.054047,237.316589 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M474.095734,240.403290 
	C474.286499,240.284012 474.594208,240.380524 475.005768,240.710876 
	C474.756348,240.985611 474.457367,240.877075 474.095734,240.403290 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M476.085419,241.488312 
	C476.322876,241.162247 476.599701,241.250748 476.896210,241.783936 
	C476.680145,242.001175 476.416443,241.892578 476.085419,241.488312 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M491.896667,241.770096 
	C491.863800,241.524033 492.130859,241.282974 492.664795,240.972427 
	C492.686646,241.193665 492.441620,241.484375 491.896667,241.770096 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M704.359253,373.061157 
	C704.244385,372.859344 704.395386,372.561981 704.769226,372.141296 
	C704.869751,372.333862 704.747498,372.649719 704.359253,373.061157 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M701.065674,377.621643 
	C700.916016,377.437927 701.025391,377.226166 701.401245,376.993958 
	C701.524902,377.168030 701.410583,377.374725 701.065674,377.621643 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M662.098145,417.098267 
	C661.974121,417.431335 661.794434,417.710449 661.363525,418.051514 
	C661.112244,418.113464 661.020203,418.015137 661.095947,417.757599 
	C661.372742,417.246765 661.623840,417.061127 661.925049,416.943176 
	C661.925049,416.943176 662.042542,417.044281 662.098145,417.098267 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M658.120300,420.120667 
	C657.985352,420.446533 657.788391,420.711395 657.338989,421.034668 
	C657.086670,421.093109 657.015015,421.002228 657.097656,420.751343 
	C657.379517,420.247253 657.628967,420.061584 657.928650,419.943420 
	C657.928650,419.943420 658.058228,420.059692 658.120300,420.120667 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M641.284058,185.887207 
	C641.192993,185.680206 641.318970,185.376923 641.701050,185.029099 
	C641.926941,185.322205 641.774963,185.591003 641.284058,185.887207 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M249.287292,664.986755 
	C249.214691,664.761902 249.409515,664.467224 249.862976,664.085938 
	C249.932648,664.305237 249.743683,664.611084 249.287292,664.986755 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M656.239258,169.906921 
	C656.154114,169.705704 656.306885,169.414612 656.703613,169.036346 
	C656.790710,169.238449 656.633911,169.527740 656.239258,169.906921 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M655.325806,170.941757 
	C655.240540,170.745712 655.382141,170.455231 655.760498,170.083252 
	C655.985718,170.355484 655.837463,170.637344 655.325806,170.941757 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M654.383423,171.956573 
	C654.127197,171.686386 654.256042,171.405243 654.800537,171.151779 
	C654.995178,171.415298 654.845947,171.670685 654.383423,171.956573 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M650.664551,175.950806 
	C650.365112,175.757950 650.409546,175.551422 650.857666,175.407364 
	C651.016663,175.611465 650.932373,175.767227 650.664551,175.950806 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M649.065430,177.806763 
	C648.980896,177.569946 649.165527,177.264099 649.618408,176.887344 
	C649.702698,177.123535 649.518738,177.430618 649.065430,177.806763 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M657.524170,167.917633 
	C657.202820,167.674088 657.279114,167.391342 657.808105,167.133057 
	C658.030823,167.386719 657.917847,167.627029 657.524170,167.917633 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M672.973999,191.669861 
	C673.153259,191.852356 673.313843,192.051865 673.474426,192.251343 
	C673.350891,192.196365 673.227356,192.141373 673.007202,191.898361 
	C672.910645,191.710342 672.955383,191.686890 672.973999,191.669861 
z"/>
<path fill="#2F74F6" opacity="1.000000" stroke="none" 
	d="
M676.098999,611.597412 
	C676.007385,611.576111 676.070374,611.531616 676.054382,611.522705 
	C676.038391,611.513733 676.190552,611.618774 676.098999,611.597412 
z"/>
<path fill="#2F74F6" opacity="1.000000" stroke="none" 
	d="
M678.110840,603.737549 
	C677.986206,603.749756 678.089661,603.521790 678.092407,603.462524 
	C678.095093,603.403320 678.235413,603.725403 678.110840,603.737549 
z"/>
<path fill="#F9FAFC" opacity="1.000000" stroke="none" 
	d="
M407.806488,647.942871 
	C402.734131,637.660095 405.175659,630.528748 415.309845,628.321350 
	C425.654938,626.067932 436.209106,624.760925 446.686127,623.132202 
	C451.410522,622.397766 456.169861,621.887939 460.879791,621.280579 
	C463.041351,631.588135 457.918457,642.919617 448.780090,648.313354 
	C438.048187,654.647644 414.214325,655.895569 407.806488,647.942871 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M416.958282,575.505737 
	C412.363068,576.460815 409.758789,579.037415 408.932678,583.475220 
	C408.842194,583.961182 408.695068,584.440308 408.533813,584.908752 
	C407.273895,588.569031 406.843994,594.599182 404.567719,595.404785 
	C399.344116,597.253418 393.211578,596.798828 387.467499,596.627563 
	C386.602814,596.601746 384.953186,592.953247 385.184784,591.178711 
	C386.319275,582.486023 390.207703,574.964844 396.039612,568.406311 
	C397.861084,566.357849 399.381958,564.042114 401.094330,561.499390 
	C405.877502,559.348267 410.650513,557.652466 415.326141,555.720947 
	C423.171478,552.479980 431.261566,552.927734 440.006592,553.219421 
	C436.987122,556.108459 434.621552,558.454041 432.167450,560.703003 
	C426.935730,565.497375 426.925232,565.747314 432.099091,569.532043 
	C427.229095,571.352417 422.140228,573.254578 416.958282,575.505737 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M407.379944,628.970581 
	C407.550323,624.812256 408.084747,620.612427 408.770508,615.223083 
	C411.526672,617.308228 413.145813,618.533203 415.362427,620.210144 
	C415.766541,618.069885 416.078522,616.417542 416.567505,613.827637 
	C413.306000,614.066223 410.358856,614.281738 407.205261,614.248657 
	C416.445557,609.634766 426.770996,609.621155 436.773132,608.025635 
	C439.859467,607.533325 443.007812,607.016052 446.098297,607.156921 
	C447.421417,607.217285 449.681122,608.890930 449.720551,609.901611 
	C449.784149,611.530945 448.715820,613.587219 447.471497,614.781677 
	C446.109253,616.089233 444.004639,616.623352 442.223572,617.494629 
	C442.462006,618.106689 442.700409,618.718750 442.938843,619.330750 
	C445.039490,618.645691 447.354584,618.312378 449.190582,617.193115 
	C451.955353,615.507812 453.280731,615.873779 453.944916,619.360168 
	C452.127960,619.777344 450.584686,620.235840 449.008118,620.476746 
	C438.948120,622.013733 428.845154,623.305176 418.834106,625.109009 
	C415.031403,625.794189 411.434601,627.621948 407.379944,628.970581 
z"/>
<path fill="#326EF4" opacity="1.000000" stroke="none" 
	d="
M576.331055,548.137390 
	C575.686829,546.551514 575.325073,545.156921 574.963257,543.762268 
	C575.580200,543.583801 576.197083,543.405396 576.814026,543.226929 
	C577.666565,545.843079 578.519043,548.459229 579.371582,551.075378 
	C578.900696,551.233337 578.429871,551.391235 577.958984,551.549194 
	C577.510498,550.475647 577.061951,549.402161 576.331055,548.137390 
z"/>
<path fill="#326EF4" opacity="1.000000" stroke="none" 
	d="
M582.091980,527.005249 
	C582.888733,526.314209 583.686584,525.995422 584.484436,525.676697 
	C584.487061,526.557678 584.489685,527.438721 584.492310,528.319702 
	C583.692566,528.005615 582.892822,527.691528 582.091980,527.005249 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M82.988457,669.970215 
	C68.370331,669.980225 53.749371,669.833313 39.135849,670.097778 
	C34.865376,670.175049 33.891136,668.748718 33.922127,664.714111 
	C34.100754,641.459290 34.016537,618.202454 34.419498,594.379517 
	C35.210133,592.804993 35.902676,591.810730 35.948334,590.787598 
	C36.362583,581.504211 36.569546,572.210876 37.052971,562.931763 
	C37.207439,559.966797 38.006996,557.035400 38.511398,554.088684 
	C39.054138,554.083862 39.596878,554.079041 40.139618,554.074158 
	C40.425983,555.164307 40.909035,556.245361 40.959591,557.346252 
	C41.081760,560.006348 40.632717,562.740784 41.088356,565.326355 
	C41.810230,569.422607 41.977352,574.393433 44.433796,577.188538 
	C46.887199,579.980164 48.108109,582.211548 48.046249,585.728943 
	C47.913109,593.299500 48.160732,593.469849 56.150410,595.450073 
	C56.455547,613.194214 56.761059,630.507874 57.042305,648.221436 
	C57.467495,649.685364 57.916950,650.749329 58.366417,651.813354 
	C58.937801,651.173279 59.509178,650.533203 60.543667,649.878113 
	C67.318398,649.863281 73.630020,649.863403 79.945007,650.286255 
	C78.765175,651.471924 77.643257,652.742432 76.386276,652.894104 
	C73.607582,653.229614 70.725029,652.733459 67.955276,653.103699 
	C66.256348,653.330750 64.687759,654.533081 63.060646,655.297485 
	C63.235092,655.558899 63.409538,655.820312 63.583984,656.081726 
	C66.821175,655.650757 70.058365,655.219849 73.492378,654.762756 
	C73.492378,656.732849 73.837433,657.996399 73.437668,658.946655 
	C70.289452,666.429932 75.064560,666.313965 80.080765,666.002258 
	C83.144196,665.811829 83.050262,667.892761 82.988457,669.970215 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M56.150780,595.019531 
	C48.160732,593.469849 47.913109,593.299500 48.046249,585.728943 
	C48.108109,582.211548 46.887199,579.980164 44.433796,577.188538 
	C41.977352,574.393433 41.810230,569.422607 41.088356,565.326355 
	C40.632717,562.740784 41.081760,560.006348 40.959591,557.346252 
	C40.909035,556.245361 40.425983,555.164307 40.139618,554.074158 
	C39.596878,554.079041 39.054138,554.083862 38.511398,554.088684 
	C38.006996,557.035400 37.207439,559.966797 37.052971,562.931763 
	C36.569546,572.210876 36.362583,581.504211 35.948334,590.787598 
	C35.902676,591.810730 35.210133,592.804993 34.426609,593.911499 
	C34.026173,568.593628 34.083332,543.176575 33.933052,517.760620 
	C33.911366,514.093689 34.896339,512.858948 38.682392,512.918823 
	C52.135078,513.131653 65.593071,513.008789 79.522583,513.480347 
	C79.998123,516.100220 79.887138,518.260376 80.029037,520.403687 
	C80.233704,523.495300 79.825989,527.028381 81.228844,529.490295 
	C82.077568,530.979797 85.858322,530.785583 88.310844,531.392029 
	C88.589195,531.460876 88.796745,531.816040 89.024414,532.040894 
	C89.011711,532.043274 88.996262,532.061279 88.528725,532.041809 
	C78.783279,531.773743 69.505936,531.485229 60.226364,531.354187 
	C59.503742,531.343994 58.765553,532.436401 58.021301,533.335632 
	C58.015377,534.080627 58.022755,534.506409 58.038445,535.204102 
	C58.074776,535.654602 58.102787,535.833191 57.868401,536.328247 
	C57.120934,556.102966 56.635857,575.561279 56.150780,595.019531 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M83.454086,669.981812 
	C83.050262,667.892761 83.144196,665.811829 80.080765,666.002258 
	C75.064560,666.313965 70.289452,666.429932 73.437668,658.946655 
	C73.837433,657.996399 73.492378,656.732849 73.492378,654.762756 
	C70.058365,655.219849 66.821175,655.650757 63.583984,656.081726 
	C63.409538,655.820312 63.235092,655.558899 63.060646,655.297485 
	C64.687759,654.533081 66.256348,653.330750 67.955276,653.103699 
	C70.725029,652.733459 73.607582,653.229614 76.386276,652.894104 
	C77.643257,652.742432 78.765175,651.471924 80.410477,650.275513 
	C84.425369,649.813660 87.978157,649.785278 92.013824,649.814697 
	C99.989273,648.919983 107.481834,647.967468 115.018265,647.366028 
	C114.545998,649.239441 114.029869,650.761719 113.513741,652.283997 
	C113.017715,652.287476 112.521690,652.290955 112.025658,652.294434 
	C113.307449,653.866028 114.589233,655.437683 116.807831,658.158020 
	C114.268753,659.278809 112.373085,660.450989 110.314774,660.943726 
	C107.400276,661.641479 104.863159,662.170349 106.760941,666.015259 
	C106.243973,666.144531 105.967674,666.261047 105.691368,666.377563 
	C105.778770,666.501282 105.866180,666.624939 105.953598,666.748657 
	C106.302063,666.498352 106.650513,666.248047 107.339218,665.999634 
	C108.460815,666.338013 109.242165,666.674438 110.023521,667.010864 
	C101.322258,668.005005 92.620987,668.999146 83.454086,669.981812 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M110.353157,667.009094 
	C109.242165,666.674438 108.460815,666.338013 107.339737,666.000793 
	C107.000000,666.000000 107.001610,666.002441 107.001610,666.002441 
	C104.863159,662.170349 107.400276,661.641479 110.314774,660.943726 
	C112.373085,660.450989 114.268753,659.278809 116.807831,658.158020 
	C114.589233,655.437683 113.307449,653.866028 112.025658,652.294434 
	C112.521690,652.290955 113.017715,652.287476 113.513741,652.283997 
	C114.029869,650.761719 114.545998,649.239441 115.352226,647.211609 
	C126.966919,643.417175 135.429245,636.807983 139.662476,625.544800 
	C141.329865,621.108398 142.533951,616.497986 143.949036,611.966797 
	C144.270767,613.507263 145.114655,615.158752 144.815430,616.567261 
	C143.947922,620.650818 141.307083,624.622559 144.867691,628.694641 
	C145.096176,628.956055 145.025513,629.819580 144.766068,630.116760 
	C139.269485,636.414795 145.288422,639.552490 148.397293,643.483276 
	C148.694000,643.858459 149.021423,644.209290 149.454330,644.709839 
	C142.757385,652.955444 134.563339,659.051086 124.097038,662.678223 
	C119.174362,662.746826 114.075241,661.068604 112.002136,666.997559 
	C111.562355,667.000793 111.122581,667.004028 110.353157,667.009094 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M89.037125,532.038513 
	C88.796745,531.816040 88.589195,531.460876 88.310844,531.392029 
	C85.858322,530.785583 82.077568,530.979797 81.228844,529.490295 
	C79.825989,527.028381 80.233704,523.495300 80.029037,520.403687 
	C79.887138,518.260376 79.998123,516.100220 79.990257,513.482361 
	C87.238647,513.345703 94.555473,513.211975 101.722885,514.182129 
	C105.805000,514.734680 110.464363,515.002869 113.483414,518.928528 
	C116.491676,522.840149 114.814537,528.210266 109.969604,529.484680 
	C108.187820,529.953430 106.336731,530.164185 104.512749,530.465332 
	C102.743675,530.757507 100.968826,531.014648 99.151413,531.294128 
	C100.392441,531.957031 101.630249,532.618225 102.504257,533.633423 
	C97.772682,533.337891 93.404900,532.688232 89.037125,532.038513 
M97.827164,529.247986 
	C97.881874,529.412842 97.936584,529.577698 97.991287,529.742554 
	C98.044678,529.576721 98.098076,529.410950 97.827164,529.247986 
z"/>
<path fill="#213AC3" opacity="1.000000" stroke="none" 
	d="
M143.957031,611.502686 
	C142.533951,616.497986 141.329865,621.108398 139.662476,625.544800 
	C135.429245,636.807983 126.966919,643.417175 115.308365,646.860535 
	C107.481834,647.967468 99.989273,648.919983 91.745789,649.591736 
	C82.869507,649.248169 74.742722,649.252014 66.619705,649.078979 
	C64.391266,649.031555 62.174107,648.454407 60.422840,648.072083 
	C64.052277,648.021606 67.215752,648.135559 70.368111,647.996826 
	C83.291451,647.428223 96.324333,647.634277 109.102768,645.940063 
	C123.625504,644.014709 133.932648,635.687378 138.890198,621.359680 
	C142.886414,609.810242 143.157242,597.893860 142.934067,585.909241 
	C142.652908,570.811584 139.771851,556.564270 129.000610,545.003906 
	C129.069550,544.940491 129.212692,544.819519 129.212692,544.819519 
	C131.402649,546.429016 134.351883,547.580322 135.648621,549.728882 
	C140.750977,558.182983 143.940109,567.374451 143.976059,577.411316 
	C144.016220,588.620239 143.972351,599.829529 143.957031,611.502686 
z"/>
<path fill="#213AC3" opacity="1.000000" stroke="none" 
	d="
M89.024414,532.040894 
	C93.404900,532.688232 97.772682,533.337891 102.563126,533.985474 
	C111.761047,533.044128 118.993172,537.464661 126.473648,540.894409 
	C127.593170,541.407715 128.211014,543.015259 129.136810,544.468018 
	C129.212692,544.819519 129.069550,544.940491 128.665985,544.909058 
	C122.749023,542.270264 117.479759,538.678284 111.676666,537.239746 
	C94.030128,532.865173 75.965118,534.689331 58.030132,534.932129 
	C58.022755,534.506409 58.015377,534.080627 58.486160,533.331299 
	C66.089081,533.009155 73.215492,533.100159 80.337807,532.968750 
	C83.228554,532.915405 86.110374,532.378235 88.996262,532.061279 
	C88.996262,532.061279 89.011711,532.043274 89.024414,532.040894 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M112.378418,666.954590 
	C114.075241,661.068604 119.174362,662.746826 123.736343,662.713257 
	C120.246338,664.300476 116.500519,665.606018 112.378418,666.954590 
z"/>
<path fill="#FBFDFE" opacity="1.000000" stroke="none" 
	d="
M1023.295654,630.060608 
	C1019.278259,654.665955 999.002930,661.392334 978.605225,658.374634 
	C961.110107,655.786316 953.860901,647.647156 950.527893,630.118713 
	C948.131165,617.514343 948.389893,605.010254 950.896179,592.490784 
	C953.989685,577.037903 962.826843,568.698242 978.424011,566.631348 
	C986.584778,565.549988 994.650757,565.667358 1002.554443,568.482056 
	C1013.535950,572.392822 1020.990723,579.965027 1022.236877,591.408813 
	C1023.612793,604.043640 1023.080688,616.886230 1023.295654,630.060608 
z"/>
<path fill="#0DBEFA" opacity="1.000000" stroke="none" 
	d="
M1016.977112,650.988831 
	C1025.313599,640.251221 1025.545532,627.738708 1025.058472,614.984314 
	C1024.587402,602.648132 1026.761841,589.988464 1019.667175,578.286377 
	C1025.271484,575.358704 1026.205200,570.651062 1026.094604,564.952576 
	C1025.790649,549.293823 1026.114990,533.623596 1025.896729,517.961975 
	C1025.840942,513.966858 1027.055176,512.776855 1030.991333,512.916809 
	C1041.663940,513.296204 1039.958374,511.688141 1039.971802,521.947693 
	C1040.033447,569.274719 1040.012207,616.601868 1039.932861,663.928894 
	C1039.929565,665.880737 1039.380127,669.316101 1038.509888,669.512512 
	C1035.520142,670.187500 1032.262695,669.853699 1029.138672,669.565002 
	C1028.676270,669.522278 1028.093872,667.659119 1028.062378,666.623169 
	C1027.935913,662.460815 1028.245361,658.272644 1027.904663,654.134277 
	C1027.718262,651.868347 1026.555420,649.682861 1025.475464,646.362305 
	C1023.178650,649.278137 1021.861572,650.950134 1019.963379,652.776733 
	C1018.580566,652.283752 1017.778809,651.636292 1016.977112,650.988831 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M985.024780,563.975159 
	C976.597900,563.800964 968.384216,565.276062 961.285889,569.735718 
	C957.808289,571.920532 955.036316,575.766174 952.919006,579.423950 
	C950.896729,582.917664 950.669983,587.452454 948.640015,590.939331 
	C947.257812,593.313538 944.129822,594.671387 941.352417,596.815674 
	C941.352417,594.324219 941.352417,592.267639 941.352417,590.194580 
	C947.192139,589.708618 947.433228,589.372375 946.485962,582.299683 
	C944.805176,583.950867 943.413635,585.540649 941.780334,586.822327 
	C941.038208,587.404663 939.817383,587.377197 938.813660,587.626343 
	C938.599365,586.710876 937.907227,585.513611 938.252441,584.927917 
	C939.863586,582.194885 941.761169,579.630676 943.134033,577.623535 
	C943.929626,575.189148 944.052917,572.818420 945.277466,571.395630 
	C952.077515,563.494873 959.579773,556.617981 970.341431,554.290771 
	C981.510254,551.875549 992.227356,552.716736 1002.505737,557.837646 
	C998.675049,560.850342 993.043335,558.205627 990.120789,562.940674 
	C989.889160,563.315979 988.513855,562.878052 987.714111,563.038208 
	C986.792114,563.222839 985.919067,563.652161 985.024780,563.975159 
M949.126770,573.775269 
	C948.808838,575.295044 948.490906,576.814758 947.899353,579.642273 
	C952.936035,574.302917 953.968506,571.175720 952.146973,568.512085 
	C951.243164,570.099548 950.377136,571.620605 949.126770,573.775269 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M1016.669006,651.129272 
	C1017.778809,651.636292 1018.580566,652.283752 1019.698303,652.970459 
	C1019.498718,654.347107 1019.308472,655.974426 1018.416077,656.975952 
	C1011.252563,665.014832 1002.456543,670.358521 991.558167,670.897644 
	C984.400085,671.251892 977.173706,670.608398 970.003052,670.074280 
	C968.025024,669.926880 966.137207,668.568726 963.993286,667.676758 
	C965.057556,664.836487 966.325745,664.215637 969.033264,663.159119 
	C978.725098,659.377136 988.730103,662.690369 998.188721,660.537415 
	C1004.770996,659.039124 1011.553589,656.788696 1016.669006,651.129272 
M976.646240,668.218506 
	C975.833862,667.157471 975.021545,666.096375 974.209167,665.035339 
	C973.723633,665.590698 973.238098,666.146118 972.752502,666.701477 
	C973.879517,667.365540 975.006531,668.029663 976.646240,668.218506 
M991.515930,662.089539 
	C989.459106,662.485657 987.402283,662.881775 985.345398,663.277893 
	C985.486877,663.787048 985.628418,664.296204 985.769897,664.805359 
	C987.854248,664.028259 989.938660,663.251221 991.515930,662.089539 
z"/>
<path fill="#07C0FD" opacity="1.000000" stroke="none" 
	d="
M985.483887,563.979736 
	C985.919067,563.652161 986.792114,563.222839 987.714111,563.038208 
	C988.513855,562.878052 989.889160,563.315979 990.120789,562.940674 
	C993.043335,558.205627 998.675049,560.850342 1002.680603,558.158813 
	C1011.176575,560.718628 1019.993042,570.166687 1019.207397,576.971741 
	C1009.594116,567.733459 998.833984,562.774353 985.483887,563.979736 
M1003.318848,562.890198 
	C1005.195984,565.556519 1005.261108,565.566650 1008.920410,563.352661 
	C1006.922058,562.957703 1005.370972,562.651123 1003.318848,562.890198 
z"/>
<path fill="#F9FAFC" opacity="1.000000" stroke="none" 
	d="
M209.453552,650.573120 
	C207.989212,647.117920 205.049026,643.701599 205.216934,640.445557 
	C205.706558,630.950500 207.346329,629.667480 217.682022,627.499207 
	C231.745926,624.548828 246.132111,623.134644 260.360809,621.064575 
	C263.063080,634.655212 256.318939,644.913025 245.528778,649.531677 
	C234.084961,654.429993 222.240601,656.271484 209.953217,650.741577 
	C209.637543,650.613342 209.397659,650.685120 209.453552,650.573120 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M214.399963,625.951843 
	C212.981781,625.329468 212.044724,624.300659 210.933502,624.052979 
	C206.800949,623.131653 203.833771,621.189880 203.136902,616.309998 
	C208.628998,613.980835 214.139069,611.405762 219.934952,610.153320 
	C227.169876,608.589844 234.624100,607.952637 242.014114,607.224915 
	C247.464142,606.688110 252.962982,606.646912 258.454315,606.384216 
	C258.255249,609.636047 258.067627,612.701050 257.800659,617.061890 
	C255.716843,617.405823 252.634033,617.914612 249.275635,618.711670 
	C249.000046,619.000000 248.996902,619.004883 248.996902,619.004883 
	C246.368744,619.519714 243.682648,620.647888 241.125916,620.406372 
	C237.236404,620.039001 234.090073,619.934204 230.485580,622.538635 
	C227.726257,624.532532 223.323898,624.255493 219.650726,624.980347 
	C218.036301,625.298828 216.417786,625.596741 214.399963,625.951843 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M208.962921,559.270935 
	C213.291580,556.932556 217.506714,553.755981 222.006973,553.280945 
	C230.624496,552.371460 239.404083,552.997437 248.540573,553.004517 
	C248.427429,554.112183 248.006027,555.281250 247.324585,556.271362 
	C246.390976,557.628052 245.259918,558.848755 244.213470,560.127747 
	C245.617004,561.111328 247.018188,562.098389 248.425385,563.076782 
	C248.916290,563.418091 249.422043,563.738098 249.797821,565.151855 
	C247.510696,565.151855 245.223572,565.151855 242.803940,565.151855 
	C240.667542,569.473145 242.926804,570.231018 247.243622,569.453125 
	C246.993073,569.911255 246.742523,570.369385 246.491989,570.827515 
	C240.660797,570.827515 234.827881,570.747864 228.999496,570.875488 
	C227.229263,570.914246 225.471558,571.524963 223.346512,571.922668 
	C222.244980,571.033508 221.420334,569.233337 220.778793,569.296326 
	C216.411987,569.725403 212.326523,570.063599 208.174484,567.572021 
	C206.858444,566.782288 203.972626,568.608521 201.805191,569.237549 
	C201.510178,568.705505 201.215149,568.173401 200.920135,567.641296 
	C202.569809,566.283752 204.312149,565.021606 205.839340,563.537964 
	C207.024872,562.386292 207.941849,560.958191 208.962921,559.270935 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M258.973114,653.983398 
	C256.938934,655.346619 254.904770,656.709778 252.507263,658.094421 
	C249.619553,658.475464 247.095169,658.835144 243.795502,659.305298 
	C243.131226,658.212280 242.073380,656.471741 241.007156,654.366821 
	C245.904099,651.386963 251.143661,649.229614 255.590454,645.985596 
	C258.296814,644.011169 259.780457,640.360657 261.757294,637.542847 
	C263.308228,639.349976 264.557678,642.134094 266.409363,642.619141 
	C268.605743,643.194397 271.320679,641.789917 273.938538,641.215454 
	C270.102234,632.975708 270.100433,632.839111 274.523529,629.312317 
	C275.899841,640.780090 278.978851,651.359375 275.474365,662.322937 
	C273.731628,663.041504 272.011841,663.750549 270.292053,664.459656 
	C270.337067,664.838440 270.382050,665.217224 270.427063,665.596069 
	C273.899780,666.484924 277.372467,667.373840 280.930664,668.650818 
	C276.193787,669.323914 271.368866,669.792175 266.551331,669.725586 
	C265.681305,669.713562 264.281403,667.582336 264.103699,666.294678 
	C263.726105,663.558167 263.991547,660.732910 264.172577,657.389648 
	C264.415894,656.727112 264.560638,656.567749 264.530151,656.519836 
	C264.401550,656.317749 264.220734,656.148804 264.059021,655.967773 
	C264.039185,655.537598 264.019348,655.107361 264.235107,654.162231 
	C264.378296,652.156250 264.285919,650.665222 264.191376,649.138611 
	C259.418060,650.278259 259.418060,650.278259 258.973114,653.983398 
M266.182434,649.111572 
	C267.568726,649.977234 268.928528,650.898193 270.393341,651.599976 
	C270.506805,651.654358 271.948181,649.712219 271.766327,649.477539 
	C270.835388,648.276245 269.670654,647.158875 268.357361,646.423401 
	C268.050018,646.251282 266.848389,647.676025 266.182434,649.111572 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M263.161377,618.875488 
	C262.198822,618.233765 261.101349,617.506592 261.165161,616.902100 
	C261.593658,612.844482 262.269012,608.812805 262.838959,604.945068 
	C265.570984,607.147766 266.107910,614.631653 263.161377,618.875488 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M249.002777,618.997559 
	C249.958313,618.995117 250.911148,618.995117 251.863968,618.995117 
	C251.847900,619.257751 251.831818,619.520325 251.815750,619.782959 
	C251.010162,619.692200 250.204590,619.601501 249.197952,619.257812 
	C248.996902,619.004883 249.000046,619.000000 249.002777,618.997559 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M250.606750,572.041016 
	C250.210571,572.282166 249.815475,572.280212 249.422668,572.249084 
	C249.410751,572.248108 249.423004,571.942627 249.423981,571.779175 
	C249.818710,571.783936 250.213425,571.788757 250.606750,572.041016 
z"/>
<path fill="#FBFBFC" opacity="1.000000" stroke="none" 
	d="
M676.515015,226.894791 
	C681.366882,222.167603 686.218750,217.440430 691.214966,212.202316 
	C691.359314,211.691360 691.346191,211.273071 691.346191,211.273071 
	C691.346191,211.273071 691.220154,211.216766 691.512207,211.200256 
	C691.966797,210.849548 692.129333,210.515366 692.291809,210.181168 
	C692.291809,210.181168 692.154968,210.144211 692.448730,210.140137 
	C692.916016,209.808762 693.089600,209.481461 693.263184,209.154175 
	C693.263184,209.154175 693.204956,209.142151 693.228271,209.162354 
	C696.664062,205.234360 700.104614,201.309921 703.473450,197.324905 
	C704.631348,195.955139 705.610229,194.434052 707.248657,192.191208 
	C714.472290,214.149292 717.222290,235.898834 716.344849,258.975830 
	C716.291016,261.517822 716.271179,263.259521 716.127075,265.402954 
	C715.720642,267.775757 715.173767,269.754242 715.228882,271.715820 
	C715.286682,273.772400 715.922058,275.812744 716.304993,277.860199 
	C716.132141,277.574646 715.959290,277.289062 715.211792,277.298523 
	C713.953735,281.350281 713.270325,285.106995 712.335327,289.148712 
	C711.569336,292.239563 711.054932,295.045441 710.368652,298.155823 
	C705.741516,310.256775 701.286194,322.053223 696.528076,333.740295 
	C694.064880,331.569489 691.904602,329.508087 689.744324,327.446686 
	C689.744324,327.446686 689.611023,327.419769 689.455933,327.067566 
	C684.626892,322.008606 680.296875,316.883850 675.201111,312.689453 
	C666.766968,305.747162 657.839355,299.404297 649.119690,292.808899 
	C649.119690,292.808899 649.080444,292.907654 648.983765,292.570801 
	C642.286438,288.440338 635.685730,284.646790 629.085022,280.853241 
	C629.085022,280.853241 629.051453,280.947021 628.956604,280.592957 
	C623.255981,277.792877 617.650146,275.346893 612.031799,272.940552 
	C612.019226,272.980194 612.093750,272.943146 611.909424,272.591431 
	C610.935120,271.588928 610.145203,270.938171 609.487793,270.396667 
	C614.962036,267.707550 620.129517,265.169098 625.674194,262.584534 
	C629.092712,260.731415 632.133972,258.924438 635.175293,257.117432 
	C635.175293,257.117462 635.084656,257.077301 635.442749,257.100067 
	C637.649109,255.988129 639.497437,254.853409 641.345764,253.718704 
	C641.345764,253.718719 641.643738,253.698013 642.053162,253.545013 
	C643.677612,252.361053 644.892700,251.330109 646.477417,250.288483 
	C650.262146,247.870804 653.677063,245.463776 657.055054,243.040253 
	C657.018066,243.023743 657.038452,243.102112 657.299316,243.020645 
	C657.768677,242.654022 657.977173,242.368851 658.125854,242.073639 
	C658.066040,242.063599 658.074341,242.184586 658.360657,242.106720 
	C658.880066,241.719879 659.113159,241.410934 659.519287,241.144165 
	C659.692200,241.186325 659.992676,240.995331 660.334412,240.955765 
	C663.181213,238.658813 665.686218,236.401428 668.144409,234.128479 
	C668.097534,234.112915 668.112610,234.210556 668.388184,234.136383 
	C668.864441,233.756989 669.065186,233.451752 669.201233,233.138947 
	C669.136536,233.131363 669.179810,233.254166 669.525391,233.232208 
	C671.295166,231.841980 672.719421,230.473740 674.087769,229.071747 
	C674.031860,229.037994 674.106873,229.144897 674.372925,229.042572 
	C675.262329,228.258484 675.885620,227.576721 676.494629,226.882111 
	C676.480225,226.869278 676.515076,226.894806 676.515015,226.894791 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M716.251343,265.001251 
	C716.271179,263.259521 716.291016,261.517822 716.467529,259.367645 
	C717.414490,256.941254 718.989136,254.873383 718.864380,252.913712 
	C718.182861,242.208038 717.077148,231.529526 716.138977,220.839752 
	C716.055664,219.890381 716.127869,218.927383 716.976379,217.613007 
	C718.009583,219.498917 718.902832,221.483124 720.131409,223.231720 
	C720.805542,224.191223 721.964600,225.213928 723.045837,225.402954 
	C725.635376,225.855682 728.307312,225.957245 730.947693,225.977386 
	C739.253052,226.040756 740.127319,225.946503 740.622559,233.896576 
	C741.454041,247.243927 741.058289,260.668274 741.152466,274.060364 
	C741.166443,276.053192 741.036133,278.046967 740.949097,280.484528 
	C740.925659,280.928741 740.437561,280.977844 740.192688,281.000793 
	C734.931030,278.672760 731.410950,275.681549 730.218994,269.462219 
	C729.603760,266.252075 725.047485,263.797211 721.473450,260.196747 
	C720.626526,263.071320 720.048401,265.033539 719.444275,267.084106 
	C719.098572,266.454987 718.796570,265.905243 717.953857,265.177307 
	C717.025940,264.999786 716.638672,265.000519 716.251343,265.001251 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M738.964539,222.986130 
	C738.724304,223.470963 738.483398,223.685974 738.242432,223.901001 
	C738.237366,223.301865 738.232300,222.702759 738.227295,222.103638 
	C738.472839,222.307877 738.718384,222.512100 738.964539,222.986130 
z"/>
<path fill="#F9FAFC" opacity="1.000000" stroke="none" 
	d="
M640.880188,319.385864 
	C640.960327,319.610931 641.108032,319.775299 641.513306,320.178650 
	C645.580566,323.476624 649.457886,326.474915 653.335144,329.473175 
	C653.335144,329.473175 653.450439,329.549744 653.603882,329.905518 
	C662.543823,339.826569 671.330383,349.391846 680.086548,359.294586 
	C680.026672,360.081512 679.997192,360.530945 679.674316,361.126221 
	C677.878723,363.351929 676.376587,365.431885 674.604004,367.648590 
	C674.161621,368.143433 673.989624,368.501526 673.881348,368.894470 
	C673.945068,368.929321 673.859253,368.812225 673.544556,368.943604 
	C669.661560,372.962341 666.432861,377.244812 662.458923,380.660187 
	C652.113342,389.551544 641.440796,398.062408 630.542114,406.794800 
	C618.470276,412.614563 606.754944,418.364532 594.733032,423.990479 
	C593.987793,423.831329 593.549072,423.796204 593.144653,423.615601 
	C593.178955,423.470154 593.176575,423.171234 593.487305,423.044128 
	C594.093323,422.325195 594.388672,421.733429 594.785767,421.101440 
	C594.887512,421.061279 594.913330,420.843994 595.215637,420.699707 
	C597.462891,417.149200 599.407837,413.743011 601.619995,410.121490 
	C603.280396,406.943970 604.673645,403.981720 606.328979,400.873779 
	C606.774963,400.170258 606.958679,399.612457 607.400879,398.830353 
	C620.170959,371.963409 627.581665,343.853333 631.834717,314.217346 
	C634.983093,316.016296 637.931641,317.701080 640.880188,319.385864 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M512.968079,248.962677 
	C515.678772,242.737534 521.364258,239.750015 527.220276,239.226120 
	C538.506836,238.216370 545.217285,239.400894 550.361450,251.317474 
	C552.828308,257.032043 554.904297,257.569916 561.023376,256.540039 
	C564.673584,255.925705 568.450623,256.064911 572.947144,255.970810 
	C577.369446,256.076263 581.015259,256.076263 584.320679,256.076263 
	C584.955627,258.690521 584.805603,261.085999 585.910339,262.063812 
	C589.403442,265.155640 594.025818,267.121887 594.725708,272.598633 
	C594.786194,273.071686 596.106689,273.734406 596.825500,273.714478 
	C600.895813,273.601837 604.961121,273.307922 609.410950,273.063721 
	C610.560425,273.015533 611.327087,272.979340 612.093750,272.943146 
	C612.093750,272.943146 612.019226,272.980194 612.020264,273.315796 
	C614.346741,275.436646 616.672119,277.221924 618.997559,279.007202 
	C617.938782,279.420715 616.790771,280.322479 615.839478,280.148529 
	C613.701843,279.757660 611.661865,278.833008 609.237000,278.008209 
	C610.539856,283.697571 615.985962,287.641663 624.365479,289.585632 
	C625.671387,295.054962 626.977905,300.525726 628.301941,306.069702 
	C634.573608,303.755890 636.816467,304.820709 638.532471,310.551727 
	C638.705933,311.130829 637.329956,312.173859 635.892944,313.018066 
	C628.661194,311.842865 629.090027,317.656769 626.818054,321.218750 
	C622.081604,320.981598 617.544739,320.655670 613.087524,319.874695 
	C611.727966,319.636444 609.723328,318.310333 609.512024,317.197876 
	C608.918823,314.074860 609.195740,310.796600 608.947632,307.593689 
	C608.827515,306.043365 608.299988,304.524597 607.960327,302.595703 
	C608.241943,298.780701 606.422302,297.113708 603.312073,296.205505 
	C591.846436,292.857697 580.411987,289.403229 568.944702,285.694092 
	C568.273071,285.286377 567.622498,285.173370 566.639893,285.054230 
	C565.865051,285.036560 565.422119,285.024994 564.975037,284.626617 
	C563.637573,283.663147 562.246399,282.491241 560.981262,282.614380 
	C555.898438,283.109039 553.730591,281.832947 552.899658,277.173981 
	C549.713257,276.420349 545.416809,278.541565 543.630798,273.619202 
	C543.437805,273.087433 540.903931,273.297852 539.464111,273.358215 
	C538.226013,273.410156 536.999512,273.739624 535.840942,273.934082 
	C535.101318,271.821411 534.700195,270.062286 533.898376,268.510101 
	C532.101562,265.031677 529.804993,261.785828 528.261597,258.208130 
	C525.995422,252.954819 522.383118,250.695572 516.654602,251.131226 
	C515.487488,251.220001 514.200073,249.726685 512.968079,248.962677 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M679.967773,360.980408 
	C679.997192,360.530945 680.026672,360.081512 680.335693,359.079468 
	C678.737427,355.040192 676.859680,351.553467 674.981873,348.066772 
	C676.300354,347.133270 678.502075,345.184570 678.790405,345.435364 
	C680.360291,346.800995 682.350891,348.614044 682.545166,350.447998 
	C683.045898,355.176392 685.366821,356.889435 689.522705,354.705933 
	C693.941528,352.384277 696.041626,353.449188 697.009827,358.454376 
	C697.227112,359.577576 697.703308,360.654358 697.881714,361.780792 
	C698.547729,365.986206 699.139587,370.203430 699.895386,374.724792 
	C699.684570,376.120453 699.337585,377.207550 698.972168,378.643005 
	C696.057373,382.512329 693.248657,386.111084 690.222290,389.516357 
	C688.989014,390.904022 687.283325,391.871765 685.120178,392.884521 
	C681.376221,388.757080 679.581909,394.666473 676.231262,393.534607 
	C675.576843,391.842804 675.087219,389.557281 673.894714,387.730560 
	C672.052124,384.907959 673.642822,380.160370 669.025635,378.623413 
	C670.664307,375.121429 672.261780,371.966827 673.859253,368.812225 
	C673.859253,368.812225 673.945068,368.929321 674.193359,368.939606 
	C674.651367,368.953461 674.861206,368.957062 675.410767,368.916443 
	C677.156311,366.241608 678.562073,363.611023 679.967773,360.980408 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M627.048218,320.955109 
	C629.090027,317.656769 628.661194,311.842865 635.521118,313.036438 
	C637.588684,314.908813 639.247986,316.779205 640.893738,319.017761 
	C637.931641,317.701080 634.983093,316.016296 631.834717,314.217346 
	C627.581665,343.853333 620.170959,371.963409 607.111755,398.634552 
	C606.426147,398.200684 606.043945,397.638824 606.185974,397.290588 
	C609.032227,390.307983 612.335510,383.482941 614.725464,376.350922 
	C617.280823,368.725067 618.986450,360.814453 621.326843,352.778503 
	C623.300171,345.846313 625.288635,339.218231 626.608704,332.459595 
	C627.335510,328.738129 626.938538,324.797119 627.048218,320.955109 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M649.104980,293.170227 
	C657.839355,299.404297 666.766968,305.747162 675.201111,312.689453 
	C680.296875,316.883850 684.626892,322.008606 689.429077,327.005096 
	C685.347046,329.077240 684.431580,324.742737 682.193359,323.111664 
	C677.782104,319.897003 672.734985,316.839661 669.818420,312.470551 
	C666.189270,307.033905 661.009460,303.885223 656.382263,299.869080 
	C653.950195,297.758209 651.520874,295.644196 649.104980,293.170227 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M696.830872,333.849701 
	C701.286194,322.053223 705.741516,310.256775 710.735107,298.197784 
	C711.518250,297.956024 711.763245,297.976776 712.008179,297.997559 
	C712.322144,298.100372 712.576538,298.285156 712.876160,298.765991 
	C710.158142,306.383514 707.335510,313.786987 704.511108,321.189789 
	C704.452271,321.344025 704.298828,321.499573 704.315308,321.639709 
	C704.817322,325.905396 703.038086,329.305939 700.146240,332.675415 
	C699.897583,333.326721 699.706116,333.584351 698.854797,333.882599 
	C697.793213,333.938080 697.312073,333.893890 696.830872,333.849701 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M674.681030,347.903259 
	C676.859680,351.553467 678.737427,355.040192 680.366089,358.742004 
	C671.330383,349.391846 662.543823,339.826569 653.633545,329.968018 
	C657.843750,328.413696 659.378479,332.452423 661.640808,334.451752 
	C666.222961,338.501221 670.170776,343.268463 674.681030,347.903259 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M619.390137,279.010071 
	C616.672119,277.221924 614.346741,275.436646 612.032837,273.276123 
	C617.650146,275.346893 623.255981,277.792877 629.001892,280.572266 
	C626.022278,280.274719 622.902527,279.643829 619.390137,279.010071 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M629.091919,281.220276 
	C635.685730,284.646790 642.286438,288.440338 649.033691,292.552429 
	C643.387817,293.703369 640.461609,288.522675 636.023071,286.496765 
	C633.494751,285.342712 631.392761,283.254761 629.091919,281.220276 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M653.497437,329.127869 
	C649.457886,326.474915 645.580566,323.476624 641.673828,320.230835 
	C647.350220,319.570984 650.912109,322.179504 653.497437,329.127869 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M696.528076,333.740295 
	C697.312073,333.893890 697.793213,333.938080 698.637207,333.992371 
	C697.640381,334.480011 695.502380,335.745087 695.053467,335.301331 
	C692.898132,333.170715 691.199219,330.578278 689.541260,327.798279 
	C691.904602,329.508087 694.064880,331.569489 696.528076,333.740295 
z"/>
<path fill="#FBFDFE" opacity="1.000000" stroke="none" 
	d="
M784.922668,640.958252 
	C779.949707,647.612305 774.959595,654.370544 766.183594,656.152161 
	C751.763428,659.079651 737.344482,660.810974 724.563293,650.914734 
	C715.351929,643.782532 712.227112,633.511536 710.895142,622.539917 
	C708.984924,606.804993 710.966675,591.742493 719.266968,577.505737 
	C719.326660,577.140137 719.676208,577.174377 720.176819,577.028503 
	C729.771790,567.284058 741.579346,565.741516 753.712402,566.758179 
	C760.758667,567.348694 767.644043,569.859314 774.601807,571.505920 
	C774.601807,571.505920 774.526855,571.449463 774.611694,571.785095 
	C775.392517,572.502014 776.088379,572.883301 776.784302,573.264587 
	C776.784302,573.264587 776.990112,573.115417 776.959473,573.488892 
	C778.902893,575.965942 780.877014,578.069641 782.851135,580.173279 
	C782.851135,580.173279 782.868103,580.041870 782.867981,580.427124 
	C783.812012,582.286255 784.756104,583.760254 785.700256,585.234192 
	C785.700256,585.234192 785.606567,585.246704 785.592651,585.666016 
	C787.082886,594.665588 789.068481,603.195984 789.965088,611.839294 
	C791.006226,621.875305 788.181213,631.496460 784.952271,640.930054 
	C784.952271,640.930054 784.939209,640.956970 784.922668,640.958252 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M774.627686,571.121826 
	C767.644043,569.859314 760.758667,567.348694 753.712402,566.758179 
	C741.579346,565.741516 729.771790,567.284058 720.330200,576.942871 
	C720.150391,575.702759 720.218140,574.379639 720.508240,573.107178 
	C720.970032,571.081055 721.579834,569.088623 722.186279,566.864563 
	C725.268677,569.423950 728.630127,568.704468 731.391479,565.287598 
	C730.626587,563.941772 729.900696,562.664612 729.174805,561.387390 
	C730.689026,560.782410 732.203308,560.177490 735.151733,558.999512 
	C732.256042,564.234863 734.168701,565.239441 737.693176,565.004578 
	C742.952271,564.654053 748.247620,563.798950 753.465576,564.131226 
	C760.960144,564.608398 768.525024,565.563843 774.627686,571.121826 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M785.310913,640.902466 
	C788.181213,631.496460 791.006226,621.875305 789.965088,611.839294 
	C789.068481,603.195984 787.082886,594.665588 785.584351,585.712524 
	C786.474182,586.035889 787.870422,586.562195 788.166321,587.453674 
	C793.013733,602.060974 793.663208,616.899109 789.825867,631.806946 
	C789.010010,634.976257 787.084045,637.859802 785.310913,640.902466 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M782.896240,579.784851 
	C780.877014,578.069641 778.902893,575.965942 776.845825,573.545776 
	C781.404175,572.698364 782.322021,575.897888 782.896240,579.784851 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M770.298096,663.141052 
	C770.817810,663.997192 771.010437,664.867859 771.203003,665.738464 
	C770.486267,665.768555 769.769470,665.798645 769.052734,665.828735 
	C769.358826,664.937683 769.664917,664.046570 770.298096,663.141052 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M785.798950,584.834717 
	C784.756104,583.760254 783.812012,582.286255 782.828491,580.480347 
	C785.922424,580.056702 786.092041,582.113892 785.798950,584.834717 
z"/>
<path fill="#2983F3" opacity="1.000000" stroke="none" 
	d="
M776.812500,572.887390 
	C776.088379,572.883301 775.392517,572.502014 774.635437,571.825928 
	C775.329712,571.857483 776.085205,572.183838 776.812500,572.887390 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M576.831360,64.230408 
	C586.527466,67.546227 596.499939,70.234276 605.845032,74.347656 
	C615.970398,78.804504 625.510864,84.589951 635.603882,89.963669 
	C662.085205,108.690994 672.630127,118.466728 672.322021,123.376030 
	C657.919800,112.649467 643.307983,101.766792 628.227661,90.804718 
	C627.759155,90.725319 627.559021,90.841812 627.416687,90.534973 
	C626.527283,89.819412 625.780212,89.410690 625.025024,88.990829 
	C625.016968,88.979683 624.992004,88.968033 624.918091,88.749634 
	C624.634644,88.251740 624.360046,88.152870 623.834473,88.109703 
	C623.648499,87.984779 623.204285,87.927200 623.023987,87.661194 
	C622.247437,87.228058 621.651123,87.060921 621.020691,86.937149 
	C620.986511,86.980515 621.066589,86.906715 620.913574,86.607323 
	C619.531006,85.812897 618.301392,85.317856 616.864929,84.550735 
	C614.128967,83.159004 611.599915,82.039360 609.077026,80.907669 
	C609.083130,80.895622 609.093689,80.870712 608.921753,80.568085 
	C599.086365,76.768349 589.598816,72.630318 579.719849,69.918190 
	C563.213379,65.386589 546.410950,62.175842 529.053711,63.679390 
	C528.637451,62.575966 528.245056,61.294262 527.891479,61.304890 
	C520.835876,61.516869 513.745544,61.527561 506.744110,62.310642 
	C500.153931,63.047722 493.661133,64.646187 487.113342,65.796654 
	C486.792114,65.853088 486.354614,65.247871 486.232422,64.682556 
	C497.993835,62.626663 509.494080,60.838978 521.460449,59.105137 
	C527.739502,59.294502 533.591064,59.052216 539.357300,59.643112 
	C551.880310,60.926430 564.359680,62.635254 576.860107,64.187988 
	C576.863220,64.205849 576.831360,64.230400 576.831360,64.230408 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M623.204285,87.927200 
	C623.204285,87.927200 623.648499,87.984779 623.950562,88.285324 
	C624.454224,88.799973 624.700684,88.927361 624.992004,88.968033 
	C624.992004,88.968033 625.016968,88.979683 625.073364,89.353912 
	C625.939514,90.099365 626.749268,90.470589 627.559021,90.841812 
	C627.559021,90.841812 627.759155,90.725319 627.863281,90.773643 
	C632.334961,94.393478 636.668213,98.007896 641.080078,101.523682 
	C644.987122,104.637184 648.981567,107.640907 653.920654,111.451683 
	C646.445496,110.821815 646.445496,110.821815 645.567688,119.212555 
	C642.117004,118.487427 638.774902,117.785133 635.004395,117.144989 
	C634.576050,117.207146 634.263733,117.186134 634.031494,116.863846 
	C633.588928,116.266991 633.313354,116.100746 632.972412,116.042809 
	C632.972412,116.042809 632.983826,116.004837 632.960999,115.696777 
	C632.515015,115.005783 632.091858,114.622841 631.668701,114.239899 
	C631.668701,114.239899 631.733765,114.344696 631.779053,113.981262 
	C629.219604,110.877327 627.000671,107.505814 623.885132,105.600494 
	C621.491211,104.136475 617.967957,104.519035 614.946960,104.080391 
	C614.946960,104.080391 614.919006,104.049210 614.920288,103.732155 
	C613.951599,102.282738 612.981750,101.150375 612.008118,99.631851 
	C612.641174,97.691994 613.255188,96.128494 613.921143,94.587410 
	C614.587036,93.046310 615.304932,91.527618 616.000000,89.999550 
	C616.000000,90.000000 616.000000,90.001045 616.344849,89.946518 
	C617.132263,88.923340 617.574768,87.954689 618.398743,86.989136 
	C619.542358,86.963722 620.304443,86.935219 621.066589,86.906715 
	C621.066589,86.906715 620.986511,86.980515 621.177490,87.222160 
	C621.980469,87.618271 622.592346,87.772736 623.204285,87.927200 
M640.413025,115.483673 
	C640.413025,115.483673 640.522339,115.418945 640.413025,115.483673 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M439.098022,662.925537 
	C438.526398,662.171631 438.307434,661.404663 438.088470,660.637573 
	C438.828308,660.574280 439.568115,660.510986 440.307953,660.447632 
	C440.022217,661.269287 439.736450,662.090881 439.098022,662.925537 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M404.274963,616.121765 
	C404.750183,616.221802 404.999451,616.361816 405.248779,616.501953 
	C404.999786,616.641541 404.688263,616.944824 404.517609,616.879578 
	C404.301483,616.796997 404.199951,616.414795 404.274963,616.121765 
z"/>
<path fill="#FDFEFD" opacity="1.000000" stroke="none" 
	d="
M616.101379,183.211502 
	C616.101379,183.211502 616.029297,183.593796 615.703735,183.913773 
	C614.313843,185.273956 613.213135,186.279785 612.193115,187.361771 
	C610.288330,189.382446 608.621643,189.695053 606.370667,187.532883 
	C593.072327,174.759613 577.824036,165.212936 559.774719,159.781616 
	C558.624634,159.384354 557.686768,159.279648 556.557495,159.177399 
	C556.366089,159.179871 556.009155,159.041458 555.828125,158.701111 
	C548.137634,157.320663 540.630737,156.260651 533.110229,155.307877 
	C532.784363,155.266602 532.333618,156.211380 531.625854,156.814148 
	C530.884766,157.067856 530.458740,157.204239 529.807922,157.217438 
	C529.413269,157.074921 529.243408,157.055603 528.684692,157.007523 
	C522.541321,158.051041 520.883911,160.418213 521.142822,167.405792 
	C521.148499,167.608688 521.037170,167.999039 520.743286,168.281067 
	C519.984253,172.411621 519.291199,176.252518 519.162231,180.112274 
	C519.107971,181.736237 520.245483,183.399994 520.909668,185.352203 
	C521.006958,186.076111 521.035889,186.493500 521.024536,187.330872 
	C521.722351,189.468445 522.460571,191.186035 523.383606,193.037354 
	C523.795837,193.387238 523.945618,193.647903 524.003906,194.354141 
	C525.437012,196.822922 526.883972,198.890656 528.500366,201.200455 
	C527.308105,201.663544 525.645020,202.309525 523.663574,203.028839 
	C522.910583,203.115295 522.476074,203.128418 521.847900,202.864410 
	C520.103699,203.095779 518.553040,203.604309 516.836914,204.180786 
	C516.671509,204.248734 516.314636,204.224747 515.940491,204.144836 
	C514.720032,204.434448 513.873718,204.803970 512.736755,205.392212 
	C506.704865,208.451889 500.963623,211.292847 494.949768,214.170197 
	C494.450439,214.481064 494.223755,214.755508 493.863586,215.122467 
	C493.730133,215.214996 493.410217,215.271393 493.029541,215.208023 
	C490.948273,216.099701 489.253021,217.064346 487.545685,218.007172 
	C485.747101,219.000381 483.937012,219.972794 481.891998,220.851074 
	C481.455292,220.467468 481.258545,220.187057 481.057068,219.549072 
	C479.088837,217.075241 477.125366,214.959000 475.069031,212.555618 
	C474.374664,211.790771 473.773193,211.313049 473.069672,210.535431 
	C472.297577,209.837250 471.627533,209.438950 470.971497,209.031036 
	C470.985535,209.021423 470.967987,209.038940 470.943176,208.859741 
	C470.624817,208.602478 470.331207,208.524414 469.888489,208.462341 
	C469.739380,208.478333 469.451782,208.563431 469.349365,208.353668 
	C469.067902,207.857864 468.810242,207.701950 468.445801,207.738068 
	C468.417755,207.799988 468.402100,207.664917 468.355469,207.374176 
	C467.872955,206.720047 467.437073,206.356659 467.000580,205.996674 
	C467.000000,206.000076 467.006683,205.998932 466.951782,205.725632 
	C466.594910,204.986557 466.292969,204.520782 465.999939,203.685791 
	C464.243164,198.931595 461.612030,195.415558 456.582184,194.010773 
	C456.154022,193.722626 455.984985,193.490433 455.922150,192.827484 
	C455.387360,190.799484 454.815399,189.152008 454.400146,187.955917 
	C455.342194,186.000534 456.100983,184.425598 456.933838,182.915405 
	C457.007965,182.980148 456.852966,182.861328 457.193726,182.870972 
	C458.002350,182.229492 458.470184,181.578384 458.966858,180.964325 
	C458.995758,181.001389 458.926575,180.937164 459.280426,180.938385 
	C463.039520,178.751083 466.513580,176.658615 469.800262,174.304535 
	C470.593414,173.736450 470.766602,172.302872 471.591522,171.189697 
	C475.571472,169.989822 479.784058,169.807709 481.580872,165.160980 
	C484.033386,164.726913 486.936859,165.898483 487.580597,162.171036 
	C489.298431,161.383209 490.644714,160.665253 492.239502,159.781372 
	C493.229614,159.343842 493.971191,159.072250 495.138062,158.874680 
	C498.374939,157.948517 501.186554,156.948318 504.176849,155.881622 
	C504.355560,155.815125 504.736908,155.817307 505.202179,155.889679 
	C513.748901,155.248520 521.857239,154.737000 529.891479,153.668274 
	C531.721924,153.424805 533.295959,151.254684 535.124207,149.739166 
	C535.736328,149.272781 536.212219,149.038559 537.073975,148.836273 
	C541.565735,145.979416 543.016479,140.124329 541.084778,133.587250 
	C541.037964,131.783875 541.049927,130.393845 541.332764,128.731888 
	C540.637817,124.682007 539.671997,120.904037 538.531128,116.989471 
	C538.145996,116.630898 538.014648,116.369354 537.990173,115.696350 
	C537.885376,113.475769 538.156860,111.154839 534.790405,112.778625 
	C534.362976,112.804703 534.193420,112.833679 533.939880,112.768578 
	C533.855835,112.674507 533.606140,112.710815 533.515991,112.456467 
	C533.244324,111.891739 533.062683,111.581367 532.734497,110.924065 
	C526.019775,107.004562 519.459351,107.380013 512.549683,110.715973 
	C511.459137,110.844482 510.723419,110.872475 509.722198,110.638809 
	C505.310150,110.940437 501.163544,111.503716 497.012939,111.650322 
	C496.992767,104.841042 501.918213,107.739540 504.932190,106.972267 
	C506.555450,106.559029 508.107269,105.865227 509.691345,105.298019 
	C508.990997,104.060356 508.290649,102.822685 508.022614,102.349007 
	C510.154266,99.922951 511.945374,97.610847 514.030396,95.604210 
	C515.507202,94.182968 517.381531,93.174751 519.337402,92.237442 
	C521.061218,95.177002 522.526123,97.861305 524.222595,100.643890 
	C524.891174,100.994339 525.328186,101.246506 525.770691,101.652847 
	C525.776245,101.807014 526.012146,102.005836 525.967285,102.258743 
	C525.942688,102.681725 525.963074,102.851799 526.076172,103.259750 
	C526.462219,103.650162 526.755371,103.802696 527.025513,103.969833 
	C527.002441,103.984444 527.049744,103.946182 527.084839,104.319077 
	C533.715515,109.061096 540.799805,108.761536 548.369690,106.874115 
	C549.111084,106.931778 549.532227,106.998299 550.048584,107.431221 
	C556.463745,108.849136 562.783813,109.900658 569.324097,110.975853 
	C569.544312,110.999519 569.962830,111.144852 570.137390,111.429306 
	C572.214783,112.115318 574.117615,112.516861 576.353760,112.958237 
	C577.113220,113.133904 577.539490,113.269730 578.119141,113.572525 
	C578.272583,113.739494 578.687195,113.926765 578.687195,113.926765 
	C578.687195,113.926765 579.142151,113.921883 579.412170,113.951431 
	C580.155212,114.230415 580.628174,114.479843 581.248901,115.071800 
	C583.300476,115.923378 585.204468,116.432434 587.331055,116.995293 
	C587.553711,117.049103 587.951843,117.275749 588.085205,117.465286 
	C588.464600,117.878120 588.752441,117.969040 589.035156,117.939514 
	C588.988159,117.951447 589.072327,117.902077 589.112122,118.275589 
	C590.487183,119.030319 591.822388,119.411530 593.177185,119.853561 
	C593.196716,119.914375 593.197083,119.786568 593.186035,120.148247 
	C595.804077,121.547272 598.433289,122.584618 601.369751,123.737488 
	C602.074097,124.034485 602.471008,124.215950 602.954285,124.721146 
	C605.007080,126.046249 606.973633,127.047623 608.967468,128.024872 
	C608.994812,128.000732 608.965271,128.067352 609.092041,128.304413 
	C609.544800,128.662598 609.870789,128.783707 610.489685,128.960999 
	C611.560059,129.606506 612.337402,130.195801 613.118652,131.147003 
	C615.103027,132.652496 617.083435,133.796097 619.028381,134.961639 
	C618.992859,134.983566 619.057190,134.926819 619.044617,135.307068 
	C620.297852,136.522873 621.563599,137.358429 622.922974,138.523895 
	C624.632874,139.953415 626.249146,141.053024 628.001221,142.343781 
	C628.340698,142.777695 628.580444,142.977081 628.938477,143.469604 
	C630.993469,145.217163 632.966431,146.628174 635.214172,148.181244 
	C636.314087,149.232605 637.139160,150.141922 637.966370,151.413864 
	C639.008362,152.830780 640.048218,153.885040 641.046082,154.970032 
	C641.004089,155.000763 641.076660,154.926529 641.028687,155.223541 
	C641.008057,155.719254 641.035278,155.917969 641.053284,156.379974 
	C640.968140,156.985352 640.892212,157.327438 640.550049,157.826904 
	C640.037292,158.527832 639.790710,159.071381 639.661377,159.619843 
	C639.778564,159.624771 639.559265,159.541306 639.285889,159.565094 
	C638.670715,159.723450 638.328979,159.858017 637.757568,160.078857 
	C637.244873,160.360611 637.063904,160.625809 636.779419,161.198273 
	C635.413635,162.639877 634.253601,163.843903 632.758545,165.064911 
	C631.958923,165.734695 631.494385,166.387512 631.014893,167.020172 
	C631.000000,167.000000 631.040344,167.029724 630.702881,167.050354 
	C629.952637,167.762314 629.539856,168.453644 629.068542,169.078201 
	C629.010010,169.011429 629.145569,169.126175 628.797607,169.119324 
	C628.020752,169.801147 627.591858,170.489838 627.086609,171.094543 
	C627.010193,171.010544 627.178711,171.162842 626.828979,171.144165 
	C626.043884,171.811890 625.608459,172.498306 625.162842,173.195572 
	C625.152710,173.206436 625.190247,173.224197 624.833130,173.190979 
	C622.973267,175.090881 621.470520,177.024017 619.972290,178.973450 
	C619.976807,178.989761 619.954529,178.973099 619.725586,179.062103 
	C619.209106,179.346573 619.035034,179.615524 618.987305,179.979172 
	C619.000000,180.000366 618.957642,179.974792 618.727356,180.065750 
	C618.208191,180.357010 618.037842,180.631012 617.993042,180.989502 
	C618.000000,181.000259 617.978516,180.986359 617.742493,181.076233 
	C617.339539,181.448578 617.172668,181.731018 617.002869,182.006729 
	C617.000000,182.000000 617.013428,182.004333 616.767517,182.122894 
	C616.381531,182.564804 616.241455,182.888153 616.101379,183.211502 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M530.032776,157.340607 
	C530.458740,157.204239 530.884766,157.067856 531.872314,156.955444 
	C532.615479,157.032761 532.796997,157.086136 533.082764,157.290726 
	C533.464661,157.318115 533.742432,157.194275 534.430054,157.017120 
	C536.211853,157.023239 537.583740,157.082657 539.074707,157.516800 
	C543.167480,158.549942 547.280212,158.765884 551.085144,159.961319 
	C558.413269,162.263687 565.597656,165.039917 572.786499,167.765045 
	C584.199341,172.091431 593.251892,179.897110 602.117859,187.950974 
	C600.080200,187.469818 597.978394,187.166779 596.021606,186.461212 
	C593.713440,185.628922 591.530823,184.448395 589.668335,183.591232 
	C588.534790,186.316330 588.088928,189.289505 587.024536,189.530426 
	C585.120483,189.961411 582.809937,188.852692 580.730835,188.193542 
	C577.699463,187.232529 574.722473,186.099823 571.304443,185.092285 
	C570.886536,185.144272 570.905640,185.118698 570.892334,184.746124 
	C567.536072,182.984009 564.193237,181.594467 560.850342,180.204941 
	C560.850342,180.204941 561.002075,180.115829 560.792480,179.833374 
	C556.913391,178.741440 553.243896,177.931961 549.574402,177.122467 
	C549.574402,177.122467 549.327759,177.269577 548.980469,176.910431 
	C546.099182,176.859421 543.384888,171.452759 541.009644,176.791824 
	C541.009644,176.791824 540.776611,177.055786 540.221069,177.087616 
	C539.179260,177.621048 538.692871,178.122635 538.206543,178.624237 
	C538.937866,179.036484 539.669189,179.448730 540.400513,179.860992 
	C540.803955,180.087021 541.207397,180.313034 541.867981,181.086945 
	C547.463074,183.005142 552.797058,184.391388 558.151367,185.694626 
	C558.396484,185.754288 558.823364,185.067291 559.165588,184.728088 
	C559.577271,184.772171 559.989014,184.816238 560.676392,185.006104 
	C563.623352,187.090836 566.210144,189.162811 568.990540,190.930084 
	C571.598938,192.587997 574.910339,193.410339 573.491455,197.877777 
	C573.197876,198.802124 575.067932,200.413757 576.643127,202.750732 
	C575.580750,204.285370 573.627563,207.106888 571.013733,209.819214 
	C564.419434,205.311615 558.167847,201.652649 550.417603,201.232452 
	C550.294556,201.271103 550.070679,201.142853 549.891479,200.798492 
	C546.249634,199.647949 542.810852,198.289993 539.319763,198.138428 
	C528.585754,197.672440 522.683228,192.265594 523.069092,181.655426 
	C523.325134,174.612411 524.675598,167.609177 525.583923,160.594940 
	C525.631653,160.601730 525.724731,160.626907 526.067871,160.725433 
	C527.618286,159.662827 528.825500,158.501724 530.032776,157.340607 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M585.058716,95.688141 
	C596.272095,100.805954 607.485413,105.923767 618.965820,111.674141 
	C620.638428,116.021339 623.526917,117.486488 627.293091,117.620377 
	C627.293091,117.620377 627.273499,117.643875 627.291016,118.020615 
	C630.234375,120.241211 633.160217,122.085075 636.086121,123.928940 
	C636.086121,123.928947 635.991028,123.960930 636.038635,124.295517 
	C637.742676,125.725433 639.399109,126.820755 641.055542,127.916077 
	C641.055542,127.916084 641.024048,127.989037 641.065552,128.305283 
	C641.811279,128.993973 642.515503,129.366394 643.219727,129.738815 
	C643.219727,129.738815 643.284302,129.725204 643.317261,129.725372 
	C644.266968,131.352188 644.962830,133.164093 646.142639,134.569931 
	C649.382263,138.430191 652.824951,142.119965 656.248474,145.945511 
	C646.839966,152.638275 645.923889,155.204849 651.955322,159.131180 
	C653.266785,155.873077 654.518677,152.763016 655.758240,149.683441 
	C658.463989,151.532028 658.834473,158.564392 656.237183,160.578125 
	C652.755798,163.277298 649.286743,165.992371 645.805847,168.692230 
	C643.987915,170.102280 642.156128,171.494431 640.155518,173.028992 
	C637.908264,171.383377 635.798279,169.838272 633.399841,167.921341 
	C633.111389,167.549530 633.143494,167.152069 633.439087,167.102310 
	C634.134766,167.022629 634.534790,166.992722 635.281677,167.024338 
	C637.491821,167.353592 640.086914,168.516602 641.072754,167.710815 
	C643.445129,165.771774 646.151123,163.141052 646.739075,160.368668 
	C647.693909,155.866455 644.528809,152.168106 640.757080,151.901489 
	C640.521179,151.923035 640.049500,151.966003 640.024292,151.981781 
	C639.999084,151.997574 640.025513,151.944351 639.942078,151.722672 
	C639.677856,151.223053 639.427734,151.039703 639.071777,150.956360 
	C639.035278,150.961731 639.055237,150.890274 639.049316,150.525970 
	C637.012268,148.455078 634.981018,146.748505 632.966370,145.034103 
	C632.982910,145.026276 632.968079,145.049454 632.978821,144.677856 
	C631.355896,138.639938 626.419006,137.132324 621.662903,135.358688 
	C621.620300,135.373978 621.532349,135.395172 621.559082,135.051575 
	C617.401794,130.192276 612.881836,126.223389 606.316406,125.356674 
	C606.201721,125.316360 605.992432,125.184280 605.965576,124.805573 
	C603.495789,123.351425 601.052734,122.275986 598.458313,121.194321 
	C598.306885,121.188087 598.043518,121.037949 598.000488,120.673965 
	C594.631836,118.534546 591.306152,116.759102 587.985107,114.594246 
	C589.111023,106.829720 590.883301,105.996162 597.241577,109.770943 
	C599.186890,110.925873 601.216064,111.939659 602.882935,112.842606 
	C602.882935,109.932556 602.882935,107.583603 602.882935,104.590820 
	C600.180969,104.329643 596.819153,104.089638 593.487305,103.632278 
	C592.450745,103.490005 591.495056,102.759094 590.502319,102.297653 
	C590.975159,101.610542 591.447998,100.923424 591.384216,101.016121 
	C589.235046,99.205887 587.146912,97.447006 585.058716,95.688141 
M646.223083,139.327866 
	C645.620789,139.585541 645.018494,139.843216 644.416199,140.100922 
	C644.907410,140.597763 645.398621,141.094620 645.889832,141.591476 
	C646.154419,140.992447 646.419006,140.393417 646.223083,139.327866 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M602.472046,187.998566 
	C593.251892,179.897110 584.199341,172.091431 572.786499,167.765045 
	C565.597656,165.039917 558.413269,162.263687 551.085144,159.961319 
	C547.280212,158.765884 543.167480,158.549942 539.510254,157.453140 
	C545.220886,157.690323 550.614990,158.365891 556.009155,159.041458 
	C556.009155,159.041458 556.366089,159.179871 556.821045,159.405746 
	C558.179688,159.779129 559.083313,159.926651 559.986938,160.074158 
	C577.824036,165.212936 593.072327,174.759613 606.370667,187.532883 
	C608.621643,189.695053 610.288330,189.382446 612.193115,187.361771 
	C613.213135,186.279785 614.313843,185.273956 615.695923,184.108124 
	C615.986450,188.979950 610.190796,192.661026 606.015381,190.292221 
	C604.889832,189.653641 603.885986,188.800705 602.472046,187.998566 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M619.967712,178.957123 
	C621.470520,177.024017 622.973267,175.090881 624.799805,173.490265 
	C625.053162,174.218506 624.982727,174.614243 624.912292,175.009979 
	C624.912292,175.009979 624.746826,175.206985 624.474976,175.483139 
	C623.968140,176.106766 623.733154,176.454239 623.498108,176.801727 
	C623.498108,176.801727 623.234192,176.823837 622.835815,176.996002 
	C621.614258,177.764481 620.790955,178.360809 619.967712,178.957123 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M619.972290,178.973450 
	C620.790955,178.360809 621.614258,177.764481 622.738159,177.085419 
	C621.305176,179.013992 619.571472,181.025269 617.452637,182.784027 
	C617.049377,182.355774 617.031372,182.180054 617.013428,182.004333 
	C617.013428,182.004333 617.000000,182.000000 617.240356,181.916656 
	C617.646606,181.550980 617.812561,181.268677 617.978516,180.986359 
	C617.978516,180.986359 618.000000,181.000259 618.237366,180.917694 
	C618.635681,180.548355 618.796692,180.261566 618.957642,179.974792 
	C618.957642,179.974792 619.000000,180.000366 619.231750,179.911194 
	C619.627197,179.539032 619.790894,179.256073 619.954529,178.973114 
	C619.954529,178.973099 619.976807,178.989761 619.972290,178.973450 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M633.143494,167.152069 
	C633.143494,167.152069 633.111389,167.549530 633.114624,167.748352 
	C632.647766,168.297745 632.177612,168.648315 631.325806,168.971710 
	C630.944153,168.944519 630.769592,168.771698 630.833374,168.483887 
	C630.944885,167.807281 630.992615,167.418503 631.040344,167.029724 
	C631.040344,167.029724 631.000000,167.000000 631.301636,167.007782 
	C631.972229,166.915115 632.341187,166.814667 632.819092,166.823029 
	C632.928101,166.931839 633.143494,167.152069 633.143494,167.152069 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M625.234131,174.974915 
	C624.982727,174.614243 625.053162,174.218506 625.156921,173.523468 
	C625.190247,173.224197 625.152710,173.206436 625.446533,173.167755 
	C626.109924,173.035995 626.479370,172.942902 626.922729,172.924011 
	C626.996582,172.998230 627.143066,173.147797 627.143066,173.147797 
	C626.614014,173.745163 626.084961,174.342514 625.234131,174.974915 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M631.031494,169.030869 
	C630.654602,169.734543 630.190308,170.351883 629.346558,170.967743 
	C628.967102,170.966278 628.806519,170.806763 628.876953,170.528656 
	C629.013489,169.875778 629.079529,169.500977 629.145630,169.126175 
	C629.145569,169.126175 629.010010,169.011429 629.348999,169.052429 
	C630.048462,168.986191 630.409058,168.878937 630.769592,168.771698 
	C630.769592,168.771698 630.944153,168.944519 631.031494,169.030869 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M629.047546,171.045868 
	C628.661072,171.738937 628.194153,172.352417 627.435181,173.056854 
	C627.143066,173.147797 626.996582,172.998230 626.985962,172.643661 
	C627.043091,171.913681 627.110901,171.538254 627.178711,171.162842 
	C627.178711,171.162842 627.010193,171.010544 627.366943,171.068481 
	C628.084656,171.019867 628.445557,170.913315 628.806519,170.806763 
	C628.806519,170.806763 628.967102,170.966278 629.047546,171.045868 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M623.837646,176.727692 
	C623.733154,176.454239 623.968140,176.106766 624.486084,175.611267 
	C624.571655,175.860062 624.374451,176.256866 623.837646,176.727692 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M616.767517,182.122894 
	C617.031372,182.180054 617.049377,182.355774 617.089783,182.795807 
	C616.946960,183.107651 616.781799,183.155197 616.359009,183.207123 
	C616.241455,182.888153 616.381531,182.564804 616.767517,182.122894 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M510.990234,224.986160 
	C510.990234,224.986160 510.496979,224.995850 509.880310,224.960541 
	C504.914368,223.729538 504.769592,220.480148 505.151642,216.822708 
	C505.317871,215.231049 504.998749,213.588669 504.985016,211.629028 
	C506.718658,210.514542 508.367340,209.740372 510.067902,209.323425 
	C510.914398,210.006027 511.819702,210.760208 512.485962,210.588196 
	C517.321106,209.339905 522.071167,207.751923 526.923035,206.582092 
	C530.214600,205.788483 533.627808,205.498947 537.406189,204.977142 
	C543.043091,205.894577 548.260437,206.818832 553.607178,207.713867 
	C553.736633,207.684647 553.960876,207.826660 554.115295,208.116272 
	C554.871643,208.568146 555.473572,208.730423 556.091309,208.891617 
	C556.107056,208.890549 556.126892,208.865952 556.085388,209.226578 
	C556.690186,210.388184 557.336548,211.189163 557.982910,211.990143 
	C559.358154,216.767761 559.222717,216.882080 551.077271,216.407104 
	C552.915405,218.637711 553.956116,220.302979 555.413757,221.427765 
	C555.859314,221.771545 557.417236,220.510696 558.526306,220.203049 
	C559.660217,219.888504 561.505554,219.304108 561.914124,219.787979 
	C562.817688,220.858231 563.529785,222.583145 563.376404,223.933853 
	C563.160828,225.831711 562.088562,227.632248 560.926880,230.664124 
	C546.910706,219.775223 531.355469,217.802017 514.139282,222.789902 
	C513.317627,221.729218 513.159729,220.865295 513.358948,219.961548 
	C515.158569,219.473831 516.601135,219.025955 518.382690,218.472824 
	C515.814636,217.419922 513.521545,216.025742 512.631104,220.072464 
	C511.839203,221.759613 511.414703,223.372879 510.990234,224.986160 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M504.900085,211.969315 
	C504.998749,213.588669 505.317871,215.231049 505.151642,216.822708 
	C504.769592,220.480148 504.914368,223.729538 509.633667,224.964447 
	C509.995239,225.433502 509.986786,225.863342 509.957458,226.614563 
	C504.398956,227.402512 500.154144,230.188858 496.996399,234.566727 
	C494.194275,238.451599 488.160461,239.849014 483.587280,240.373947 
	C478.321930,240.978317 475.593658,238.315643 472.546967,235.276733 
	C478.023529,233.116287 472.992737,231.339706 472.570618,229.632797 
	C471.983856,227.260101 470.171417,224.918900 470.407867,222.728790 
	C470.526184,221.632965 474.023010,220.901901 475.835938,220.320496 
	C471.407776,226.767487 472.298065,228.732849 481.041504,230.784225 
	C481.041504,228.371979 481.041504,226.145615 481.355835,223.900299 
	C482.142517,223.518616 482.614807,223.155869 483.425079,222.826981 
	C484.663757,222.806122 485.564453,222.751389 486.872192,222.746201 
	C487.766968,222.008987 488.254730,221.222244 488.761475,220.316879 
	C488.780426,220.198257 488.965179,220.044678 489.203003,219.940689 
	C489.639343,219.560791 489.837891,219.284882 490.162659,218.923355 
	C490.288849,218.837738 490.592865,218.812607 490.959351,218.842346 
	C491.859894,218.214310 492.393951,217.556534 492.917023,216.901428 
	C492.906067,216.904114 492.884735,216.876984 493.261780,216.918884 
	C497.392578,215.296967 501.146332,213.633133 504.900085,211.969315 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M553.960876,207.826660 
	C553.960876,207.826660 553.736633,207.684647 553.352661,207.431351 
	C543.744995,202.759491 533.875916,202.772079 523.981934,202.955505 
	C525.645020,202.309525 527.308105,201.663544 528.500366,201.200455 
	C526.883972,198.890656 525.437012,196.822922 524.318115,194.491882 
	C528.550171,196.101624 532.291687,198.582809 536.402710,199.681839 
	C540.776306,200.851059 545.499878,200.711136 550.070679,201.142853 
	C550.070679,201.142853 550.294556,201.271103 550.686035,201.491043 
	C557.719299,205.147293 564.361084,208.583603 571.002869,212.019913 
	C571.361389,212.369034 571.719910,212.718170 572.466858,213.424713 
	C573.544922,214.219009 574.234497,214.655869 574.924133,215.092712 
	C574.924133,215.092712 575.008850,215.036346 575.067322,215.334442 
	C575.730347,216.103012 576.334961,216.573486 576.939514,217.043961 
	C576.939514,217.043961 576.990051,217.007599 577.075439,217.326599 
	C577.674622,217.966293 578.188416,218.286987 578.702148,218.607697 
	C578.702148,218.607697 578.609863,218.853851 578.690918,219.306946 
	C578.923157,220.189621 579.074280,220.619232 579.225342,221.048828 
	C579.194275,221.247879 579.163269,221.446930 578.815918,221.990845 
	C576.695984,221.526001 574.892395,220.716339 573.088745,219.906662 
	C573.088745,219.906662 573.051270,219.960114 572.963074,219.660400 
	C572.255676,218.904617 571.636475,218.448532 571.017273,217.992462 
	C571.017273,217.992462 571.002441,217.998169 570.880615,217.718781 
	C570.218384,217.230972 569.677979,217.022537 569.137573,216.814102 
	C569.137573,216.814102 569.196350,216.895782 569.120361,216.547913 
	C568.371765,215.795883 567.699158,215.391708 567.026611,214.987549 
	C567.026611,214.987549 567.005554,214.994919 566.869263,214.680603 
	C565.562378,213.818878 564.391724,213.271439 563.221130,212.724014 
	C563.221130,212.724014 563.144714,212.777863 563.064453,212.435120 
	C560.698364,211.016907 558.412659,209.941422 556.126892,208.865952 
	C556.126892,208.865952 556.107056,208.890549 555.935181,208.616119 
	C555.162476,208.170029 554.561646,207.998337 553.960876,207.826660 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M571.008789,211.685303 
	C564.361084,208.583603 557.719299,205.147293 550.809082,201.452393 
	C558.167847,201.652649 564.419434,205.311615 570.653076,209.862061 
	C570.973572,210.459625 570.994202,210.905151 571.008789,211.685303 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M556.085388,209.226578 
	C558.412659,209.941422 560.698364,211.016907 563.107422,212.400543 
	C561.736816,212.471329 560.242859,212.233978 558.365967,211.993378 
	C557.336548,211.189163 556.690186,210.388184 556.085388,209.226578 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M573.088501,220.262161 
	C574.892395,220.716339 576.695984,221.526001 578.798584,222.292542 
	C578.303101,222.869553 577.051636,224.170975 576.790649,223.995895 
	C575.418579,223.075394 574.296875,221.781738 573.088501,220.262161 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M513.001892,220.001373 
	C513.159729,220.865295 513.317627,221.729218 513.739014,222.798798 
	C513.213196,223.650589 512.423889,224.296707 511.312408,224.964493 
	C511.414703,223.372879 511.839203,221.759613 512.631836,220.073181 
	C513.000000,220.000000 513.001892,220.001373 513.001892,220.001373 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M563.281494,213.078003 
	C564.391724,213.271439 565.562378,213.818878 566.869812,214.669586 
	C565.785095,214.459244 564.563477,213.945618 563.281494,213.078003 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M579.511658,220.986633 
	C579.074280,220.619232 578.923157,220.189621 578.762207,219.417389 
	C579.100891,219.691315 579.449402,220.307877 579.511658,220.986633 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M567.055786,215.321686 
	C567.699158,215.391708 568.371765,215.795883 569.110718,216.498535 
	C568.479797,216.416595 567.782410,216.036209 567.055786,215.321686 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M574.897583,214.733551 
	C574.234497,214.655869 573.544922,214.219009 572.716064,213.491364 
	C573.341492,213.591827 574.106262,213.983093 574.897583,214.733551 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M571.043396,218.320786 
	C571.636475,218.448532 572.255676,218.904617 572.976196,219.637741 
	C572.408142,219.492889 571.738831,219.071014 571.043396,218.320786 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M576.923035,216.712677 
	C576.334961,216.573486 575.730347,216.103012 575.022705,215.355072 
	C575.581909,215.512222 576.244202,215.946808 576.923035,216.712677 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M578.683411,218.263580 
	C578.188416,218.286987 577.674622,217.966293 577.059448,217.353241 
	C577.526917,217.347076 578.095825,217.633270 578.683411,218.263580 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M569.238403,217.105194 
	C569.677979,217.022537 570.218384,217.230972 570.882446,217.710968 
	C570.450562,217.787125 569.894897,217.591705 569.238403,217.105194 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M546.981812,42.084656 
	C557.233215,43.731514 567.566528,44.992886 577.709473,47.149914 
	C585.511414,48.809116 593.142761,51.391109 600.719299,53.949490 
	C606.104065,55.767727 611.280090,58.209347 616.521912,60.437023 
	C617.027466,60.651878 617.339539,61.322140 618.125488,62.219482 
	C616.039368,62.794334 614.425049,63.239162 612.810791,63.683990 
	C612.501465,64.106682 612.192078,64.529381 611.882751,64.952072 
	C613.040222,65.110138 614.197693,65.268204 615.355164,65.426262 
	C615.009583,65.933624 614.664062,66.440979 614.318481,66.948334 
	C612.730286,66.871391 611.142639,66.779037 609.553711,66.723289 
	C608.605957,66.690041 607.124817,66.318726 606.787231,66.772163 
	C601.979675,73.230179 597.340454,67.406860 592.677734,66.666939 
	C587.683777,65.874451 582.712463,64.939041 577.281311,64.148209 
	C576.831360,64.230400 576.863220,64.205849 576.907959,63.831490 
	C576.737854,62.877361 576.578796,61.819191 576.300171,61.786732 
	C564.870056,60.455307 553.429260,59.216129 541.984375,57.566669 
	C541.500183,55.273037 541.021301,53.378010 540.548645,51.507820 
	C538.692078,51.097530 537.045349,50.733620 535.398621,50.369709 
	C536.481689,48.767609 537.752869,48.469524 538.932617,47.959782 
	C542.049316,46.613125 546.114075,46.522320 546.981812,42.084656 
z"/>
<path fill="#07C0FD" opacity="1.000000" stroke="none" 
	d="
M546.515381,42.062645 
	C546.114075,46.522320 542.049316,46.613125 538.932617,47.959782 
	C537.752869,48.469524 536.481689,48.767609 535.398621,50.369709 
	C537.045349,50.733620 538.692078,51.097530 540.548645,51.507820 
	C541.021301,53.378010 541.500183,55.273037 541.536133,57.573982 
	C538.400757,57.961201 535.708252,57.942501 533.010498,57.521442 
	C532.969849,54.845604 533.305237,52.202518 529.457947,53.532192 
	C529.645020,55.080765 529.817505,56.508770 529.541016,57.948689 
	C526.398743,57.970375 523.705505,57.980148 520.554199,57.989143 
	C511.061981,58.983662 502.027740,59.978954 492.995819,60.582851 
	C493.612762,54.309669 499.209869,55.936558 503.422760,53.929356 
	C501.134155,53.204170 499.759491,52.768589 498.231018,52.284271 
	C499.279877,50.958382 500.035522,50.003139 500.791168,49.047901 
	C500.667145,48.641819 500.543152,48.235733 500.419128,47.829651 
	C498.412445,48.066128 496.388000,48.577911 494.404907,48.449696 
	C492.889465,48.351715 491.426361,47.443855 489.939972,46.896252 
	C492.932404,45.932800 495.894257,44.854641 498.927979,44.046047 
	C500.962616,43.503738 503.099152,43.343639 505.602692,43.003471 
	C519.359802,42.676495 532.704346,42.358562 546.515381,42.062645 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M224.661133,672.027100 
	C224.237488,672.342590 223.432816,672.644043 222.284653,672.918274 
	C221.941147,672.890991 221.974152,672.439941 221.984818,672.213867 
	C222.757019,671.996216 223.518570,672.004639 224.661133,672.027100 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M313.273743,571.891846 
	C307.844604,572.062012 302.729797,572.284485 297.650208,571.958313 
	C296.498260,571.884338 294.694702,570.012329 294.526978,568.785400 
	C294.012878,565.024353 293.820251,561.140137 294.216309,557.378967 
	C294.347382,556.134277 296.554688,554.309753 297.969757,554.151123 
	C301.916565,553.708740 305.945648,554.006409 309.942230,553.999695 
	C313.743683,553.993347 315.556213,552.155396 316.052277,548.295898 
	C316.829498,542.248962 317.861938,536.211914 319.273285,530.286682 
	C319.594543,528.937927 321.788605,527.384277 323.327911,527.116516 
	C326.237488,526.610413 329.304108,527.093262 332.292877,526.932007 
	C335.120361,526.779541 336.050110,527.928833 336.068726,530.711426 
	C336.104187,536.008972 336.923279,541.331360 336.599121,546.593079 
	C336.238434,552.447144 338.976746,554.614014 344.613098,555.345642 
	C344.363922,557.839111 343.725983,559.880127 342.713257,563.120056 
	C341.148926,560.352478 340.486359,559.180298 339.007416,556.563782 
	C338.268829,561.433289 337.523499,564.851746 337.388550,568.294128 
	C337.355042,569.148193 339.193481,570.267822 340.363831,570.920349 
	C341.434875,571.517578 342.765930,571.648376 343.982391,571.984741 
	C336.986176,572.046326 336.981567,572.046326 336.983307,578.926880 
	C336.988495,599.306091 336.997711,619.685364 337.000854,640.533508 
	C332.490814,640.484619 331.889832,637.013367 332.094421,633.661316 
	C332.424194,628.258057 331.808258,624.227905 325.087769,623.587830 
	C323.898926,623.474609 322.215790,620.125305 322.130554,618.209412 
	C321.549011,605.132996 321.329620,592.040344 321.001923,578.952698 
	C320.873657,573.830322 318.110779,571.129150 313.273743,571.891846 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M317.002228,582.485474 
	C317.129181,581.255920 317.254578,580.465454 317.380005,579.674988 
	C317.686523,579.685364 317.993042,579.695740 318.299561,579.706116 
	C318.299561,594.232117 318.299561,608.758179 318.299561,623.284180 
	C317.866608,623.293884 317.433655,623.303589 317.000702,623.313293 
	C317.000702,609.850342 317.000702,596.387451 317.002228,582.485474 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M349.741425,669.721436 
	C348.171844,669.862122 346.340210,669.717834 344.238464,669.295532 
	C345.805359,669.157166 347.642365,669.296753 349.741425,669.721436 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M339.333191,651.493347 
	C338.988861,652.036499 338.429749,652.362244 337.870605,652.687988 
	C337.743347,652.409424 337.616089,652.130798 337.488831,651.852295 
	C338.032043,651.660156 338.575226,651.468018 339.333191,651.493347 
z"/>
<path fill="#1027C6" opacity="1.000000" stroke="none" 
	d="
M330.023682,184.018311 
	C330.492493,183.108810 330.961304,182.199310 331.879028,181.365051 
	C333.043182,181.403000 333.758453,181.365707 334.473694,181.328400 
	C333.639496,180.883026 332.805267,180.437622 331.971069,179.992233 
	C333.877899,172.161072 338.971619,166.865112 346.225433,164.201019 
	C356.100098,160.574387 365.321686,163.883362 372.044800,172.454819 
	C379.501068,181.960983 386.209259,192.222000 394.682922,200.718414 
	C404.698517,210.760925 415.544647,220.340118 427.338684,228.143234 
	C439.585419,236.245850 452.442139,243.894180 466.953735,247.894669 
	C473.115814,249.593399 479.119751,251.865707 485.557983,254.289932 
	C485.619507,255.280991 485.413116,255.939056 485.003174,256.425690 
	C480.466827,261.810577 480.714020,262.952911 487.823944,264.325287 
	C492.437592,265.215851 496.620300,265.815613 497.318024,271.681488 
	C497.422913,272.563141 499.391724,273.223022 500.646729,274.087769 
	C499.424652,274.817719 498.456848,275.395782 496.548157,276.535858 
	C499.956024,276.922546 502.209900,277.178284 504.463745,277.434021 
	C497.382294,280.330048 497.382294,280.330048 496.393372,284.561615 
	C492.709381,285.443726 488.887268,286.203796 485.158173,287.284668 
	C477.747894,289.432465 470.388794,291.757019 463.003937,293.593994 
	C463.211456,290.170868 462.710815,288.879364 458.849213,289.280151 
	C455.054871,289.673889 451.063293,288.167480 448.000000,287.639893 
	C448.000000,283.239685 448.000000,280.119843 448.139221,276.946045 
	C448.185608,276.928040 448.092804,276.964020 447.601929,277.000854 
	C445.292999,277.456757 443.142426,277.495300 441.510101,278.434479 
	C430.943787,284.514069 420.490387,290.789978 409.657532,297.043335 
	C406.873474,299.052277 404.431854,301.019470 401.682373,303.127563 
	C400.591827,303.834991 399.818451,304.414917 399.024933,304.965820 
	C395.252747,307.584564 391.475128,310.195374 387.543121,312.464447 
	C406.216644,293.810364 428.613647,281.039642 453.650269,270.436615 
	C412.552307,253.817413 380.179504,227.972488 354.715851,191.989044 
	C339.781891,241.538666 343.235046,288.283966 365.799561,334.323395 
	C370.587006,328.993835 374.518341,324.281830 378.827026,319.944702 
	C381.472290,317.281921 384.695129,315.192932 387.740997,313.191467 
	C387.304626,314.322876 386.859100,315.171112 386.254974,315.885193 
	C382.845673,319.915009 379.398254,323.912567 375.618469,327.965698 
	C373.330505,329.749847 371.259521,331.368011 369.474152,333.256256 
	C366.454163,336.450317 364.264099,336.385895 362.393982,332.497345 
	C360.478699,328.514832 358.874176,324.380188 357.202545,320.283966 
	C355.124115,315.190887 353.116333,310.068970 351.078033,304.959503 
	C350.902435,303.791840 350.726837,302.624176 350.276245,300.890137 
	C349.678864,299.543701 349.356476,298.763611 349.034119,297.983521 
	C347.507355,289.257843 345.932098,280.540222 344.486847,271.801056 
	C344.111938,269.533844 344.143799,267.199432 343.990234,263.984314 
	C343.675354,254.952652 343.010101,246.826462 343.152161,238.714386 
	C343.267456,232.128799 344.351685,225.560165 345.052002,218.605820 
	C347.501160,210.166397 349.835754,202.094498 349.945496,192.681915 
	C346.230194,195.782272 339.558472,194.193314 338.434418,190.267487 
	C337.087677,185.563766 333.893738,184.646957 330.023682,184.018311 
M481.824554,255.245926 
	C481.879944,255.412781 481.935333,255.579636 481.990723,255.746490 
	C482.044708,255.578629 482.098663,255.410751 481.824554,255.245926 
z"/>
<path fill="#0331FC" opacity="1.000000" stroke="none" 
	d="
M350.652344,304.973511 
	C353.116333,310.068970 355.124115,315.190887 357.202545,320.283966 
	C358.874176,324.380188 360.478699,328.514832 362.393982,332.497345 
	C364.264099,336.385895 366.454163,336.450317 369.474152,333.256256 
	C371.259521,331.368011 373.330505,329.749847 375.752930,328.284607 
	C377.764435,331.652100 374.944183,331.440308 373.467133,332.450134 
	C372.527344,333.092682 372.061676,334.428650 371.383423,335.453674 
	C372.660187,335.895325 374.070068,336.930023 375.191193,336.678345 
	C380.747772,335.430908 388.230530,338.752472 390.865387,344.317047 
	C388.129120,347.763489 385.543762,350.912903 382.693207,354.289246 
	C381.268402,357.627930 378.892761,360.825500 381.779205,364.020844 
	C389.547607,372.620697 396.987610,381.604156 405.464050,389.457367 
	C418.837341,401.847473 434.196045,411.602570 450.574249,419.609406 
	C455.831818,422.179626 461.504852,423.900024 467.032166,426.365967 
	C468.650330,427.179016 470.199585,427.784790 471.805084,428.048218 
	C473.308868,428.295013 475.665253,427.549561 476.281097,428.325043 
	C480.408813,433.522797 485.945099,433.319611 491.632843,433.014435 
	C495.404449,432.812103 497.291290,434.734680 496.961975,438.452789 
	C496.602783,442.508484 495.959686,446.539032 495.347290,451.304810 
	C500.673401,450.619629 506.487701,449.871674 512.302002,449.123688 
	C512.336121,449.573914 512.370239,450.024109 512.404358,450.474335 
	C510.790710,450.846375 509.177094,451.218414 507.563477,451.590454 
	C507.561615,451.851959 507.559784,452.113495 507.557953,452.375031 
	C509.277313,452.808929 510.996674,453.242828 513.007812,453.750366 
	C512.163940,455.295532 511.545807,456.427460 510.554504,458.242645 
	C516.329773,458.847626 521.835938,459.425415 527.342407,460.000793 
	C530.558655,460.336884 533.775330,460.669037 536.991821,461.002930 
	C519.160156,461.787109 501.493378,460.135071 484.169312,456.105042 
	C463.014893,451.183960 442.984741,443.093109 424.229797,432.091644 
	C406.163452,421.494110 389.978668,408.357513 375.757629,393.074341 
	C362.424408,378.745300 351.377716,362.572357 342.591309,345.054779 
	C333.822571,327.572449 326.907806,309.363220 323.957275,289.453064 
	C325.093719,288.940277 326.280151,288.989563 327.383575,288.710571 
	C331.207428,287.743683 331.957825,290.539978 330.457458,292.426086 
	C326.953186,296.831360 328.633820,299.415924 332.326782,302.098724 
	C333.021149,302.603180 333.263214,303.730225 334.427246,305.898560 
	C334.427246,302.266846 334.427246,300.010834 334.427246,297.320587 
	C335.607788,296.904846 336.892120,296.452576 338.482635,295.892487 
	C338.825653,298.051392 339.078156,299.640717 339.417053,301.773804 
	C342.929413,302.818024 346.578033,303.902771 350.652344,304.973511 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M345.006226,218.984024 
	C344.351685,225.560165 343.267456,232.128799 343.152161,238.714386 
	C343.010101,246.826462 343.675354,254.952652 343.980988,263.542725 
	C342.541473,264.758972 341.112701,265.505280 339.726532,266.229279 
	C338.423004,253.989700 328.782593,256.584229 320.445007,256.003784 
	C319.986053,253.958145 319.981873,251.910202 320.274597,249.160522 
	C320.386047,245.982315 320.200531,243.505814 320.015015,241.029327 
	C320.678284,233.324783 321.341553,225.620239 322.371002,217.425781 
	C325.002625,217.642853 327.278900,218.317291 329.529449,219.068802 
	C330.918152,219.532532 332.436310,220.802917 333.604095,220.517090 
	C337.610931,219.536407 337.680328,221.829559 337.679413,224.601028 
	C337.678955,225.960922 337.963928,227.320923 338.119751,228.680878 
	C338.605225,228.613495 339.090698,228.546097 339.576172,228.478714 
	C340.045868,226.570892 340.803894,224.679916 340.916565,222.751236 
	C341.079163,219.967850 342.355774,218.844223 345.006226,218.984024 
z"/>
<path fill="#0331FC" opacity="1.000000" stroke="none" 
	d="
M348.692932,298.044189 
	C349.356476,298.763611 349.678864,299.543701 350.007080,300.659180 
	C349.144257,301.078888 348.275604,301.163208 347.406952,301.247559 
	C347.721893,300.200012 348.036804,299.152435 348.692932,298.044189 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M716.127136,265.402954 
	C716.638672,265.000519 717.025940,264.999786 717.703552,264.999512 
	C717.992065,265.747955 717.990173,266.495880 717.773254,267.731079 
	C717.288025,268.421387 717.111694,268.686768 717.029297,269.014557 
	C717.027222,269.452118 717.025208,269.889679 717.014587,270.993225 
	C717.008606,272.109558 717.011108,272.559875 717.013672,273.010193 
	C716.942871,274.317261 716.872009,275.624298 716.553101,277.395782 
	C715.922058,275.812744 715.286682,273.772400 715.228882,271.715820 
	C715.173767,269.754242 715.720642,267.775757 716.127136,265.402954 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M712.586914,288.863739 
	C713.270325,285.106995 713.953735,281.350281 714.946289,277.414856 
	C714.870300,280.895660 714.485229,284.555145 713.694580,288.576721 
	C713.054993,288.913818 712.820984,288.888794 712.586914,288.863739 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M712.335327,289.148712 
	C712.820984,288.888794 713.054993,288.913818 713.640869,288.967590 
	C713.359619,291.742706 712.726501,294.489075 712.050781,297.616516 
	C711.763245,297.976776 711.518250,297.956024 710.906921,297.893280 
	C711.054932,295.045441 711.569336,292.239563 712.335327,289.148712 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M717.310059,272.966827 
	C717.011108,272.559875 717.008606,272.109558 717.014893,271.321411 
	C717.535828,271.224030 718.047852,271.464478 718.559937,271.704926 
	C718.242065,272.111084 717.924194,272.517273 717.310059,272.966827 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M717.243774,268.901520 
	C717.111694,268.686768 717.288025,268.421387 717.772949,268.105072 
	C717.901184,268.317139 717.724731,268.582703 717.243774,268.901520 
z"/>
<path fill="#1223B4" opacity="1.000000" stroke="none" 
	d="
M463.008545,294.008514 
	C470.388794,291.757019 477.747894,289.432465 485.158173,287.284668 
	C488.887268,286.203796 492.709381,285.443726 496.393372,284.561615 
	C497.382294,280.330048 497.382294,280.330048 504.463745,277.434021 
	C502.209900,277.178284 499.956024,276.922546 496.548157,276.535858 
	C498.456848,275.395782 499.424652,274.817719 500.646729,274.087769 
	C499.391724,273.223022 497.422913,272.563141 497.318024,271.681488 
	C496.620300,265.815613 492.437592,265.215851 487.823944,264.325287 
	C480.714020,262.952911 480.466827,261.810577 485.003174,256.425690 
	C485.413116,255.939056 485.619507,255.280991 485.948608,254.346863 
	C486.851501,254.135574 487.726166,254.279938 488.977966,254.948090 
	C490.221313,255.320557 491.087524,255.169250 491.953735,255.017944 
	C495.847321,255.671234 499.733551,256.373352 503.636505,256.964722 
	C507.968842,257.621216 510.748749,256.167206 511.096466,251.369064 
	C511.147827,250.660660 511.905212,250.003418 512.652039,249.142609 
	C514.200073,249.726685 515.487488,251.220001 516.654602,251.131226 
	C522.383118,250.695572 525.995422,252.954819 528.261597,258.208130 
	C529.804993,261.785828 532.101562,265.031677 533.898376,268.510101 
	C534.700195,270.062286 535.101318,271.821411 535.840942,273.934082 
	C536.999512,273.739624 538.226013,273.410156 539.464111,273.358215 
	C540.903931,273.297852 543.437805,273.087433 543.630798,273.619202 
	C545.416809,278.541565 549.713257,276.420349 552.899658,277.173981 
	C553.730591,281.832947 555.898438,283.109039 560.981262,282.614380 
	C562.246399,282.491241 563.637573,283.663147 564.580383,284.628693 
	C563.135437,285.003235 562.080933,284.988892 560.931030,284.812866 
	C560.552795,284.750488 560.270020,284.849792 559.607666,284.802124 
	C556.605530,284.391510 553.967346,284.232574 551.363342,283.844543 
	C543.534790,282.678040 542.869568,283.140930 543.034180,291.227112 
	C543.249939,301.823914 543.657959,312.416779 543.613342,322.935852 
	C542.501404,321.121307 541.319275,319.435333 541.102234,317.633148 
	C540.515137,312.758087 540.548035,307.804871 539.881470,302.944885 
	C539.657471,301.311371 537.554626,298.420258 537.180969,298.553040 
	C532.579163,300.188629 531.613892,296.364410 529.533997,294.039124 
	C528.673462,293.076996 527.198181,292.664764 526.001221,292.001221 
	C526.000000,292.000000 525.997559,291.998047 525.992432,291.615173 
	C524.894653,289.853729 523.802002,288.475159 522.709351,287.096588 
	C521.739990,288.490601 520.180664,289.782623 519.918579,291.298859 
	C519.288086,294.946320 518.988220,298.703491 519.049622,302.408173 
	C519.235107,313.596619 519.656128,324.781128 519.696167,336.270020 
	C518.965881,344.135254 518.248352,351.694611 518.163696,359.260986 
	C518.083618,366.431519 518.879150,373.608948 518.896851,380.784302 
	C518.913513,387.539032 518.417480,394.294983 518.116943,401.514832 
	C518.044006,411.300690 518.001221,420.622101 517.767212,429.950073 
	C517.575989,429.956573 517.193420,429.965942 517.119263,429.505768 
	C517.045166,380.955536 517.045166,332.865448 517.045166,284.443115 
	C497.558472,286.082153 479.396393,290.027802 461.626465,296.936096 
	C460.893616,296.588501 460.443329,296.286255 460.003510,295.740234 
	C460.013977,295.496399 460.014282,295.008331 460.007141,295.004211 
	C460.000000,295.000092 460.012878,295.013458 460.331635,294.988739 
	C461.436432,294.645508 462.222473,294.326996 463.008545,294.008514 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M345.052002,218.605820 
	C342.355774,218.844223 341.079163,219.967850 340.916565,222.751236 
	C340.803894,224.679916 340.045868,226.570892 339.576172,228.478714 
	C339.090698,228.546097 338.605225,228.613495 338.119751,228.680878 
	C337.963928,227.320923 337.678955,225.960922 337.679413,224.601028 
	C337.680328,221.829559 337.610931,219.536407 333.604095,220.517090 
	C332.436310,220.802917 330.918152,219.532532 329.529449,219.068802 
	C327.278900,218.317291 325.002625,217.642853 322.381653,216.980438 
	C323.663361,209.608871 325.300629,202.192719 326.910828,194.371735 
	C327.248657,192.874847 327.613586,191.782806 328.196350,190.236389 
	C328.686493,189.588470 328.834808,189.325104 328.859070,188.991913 
	C329.227783,187.578201 329.596466,186.164490 329.994446,184.384552 
	C333.893738,184.646957 337.087677,185.563766 338.434418,190.267487 
	C339.558472,194.193314 346.230194,195.782272 349.945496,192.681915 
	C349.835754,202.094498 347.501160,210.166397 345.052002,218.605820 
z"/>
<path fill="#181B9C" opacity="1.000000" stroke="none" 
	d="
M609.028137,273.075714 
	C604.961121,273.307922 600.895813,273.601837 596.825500,273.714478 
	C596.106689,273.734406 594.786194,273.071686 594.725708,272.598633 
	C594.025818,267.121887 589.403442,265.155640 585.910339,262.063812 
	C584.805603,261.085999 584.955627,258.690521 584.320679,256.076263 
	C581.015259,256.076263 577.369446,256.076263 573.325928,256.019592 
	C573.559143,255.314423 574.084106,254.285751 574.837402,254.075577 
	C583.405090,251.685013 592.010193,249.428894 600.586975,247.069855 
	C600.816589,247.006699 600.839111,246.190735 600.975952,245.410080 
	C602.739441,244.737656 604.485107,244.380981 606.615173,244.015381 
	C607.082947,245.677109 607.166382,247.347778 607.249817,249.018448 
	C606.799500,249.146423 606.349182,249.274399 605.898865,249.402374 
	C606.871704,250.329269 607.844604,251.256180 609.092896,252.445526 
	C608.236694,253.680023 607.372620,254.925934 606.903687,255.602036 
	C609.436951,257.668793 611.864380,259.649261 615.045715,262.244781 
	C617.165405,261.722900 620.673218,260.859253 624.590210,260.243042 
	C624.992615,260.657562 624.985779,260.824615 624.646851,261.140900 
	C619.908081,263.180939 615.294678,264.724152 611.208313,267.153748 
	C609.804260,267.988525 609.716919,271.037964 609.028137,273.075714 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M331.959045,180.223312 
	C332.805267,180.437622 333.639496,180.883026 334.473694,181.328400 
	C333.758453,181.365707 333.043182,181.403000 332.122650,181.178253 
	C331.917358,180.916199 331.946991,180.454391 331.959045,180.223312 
z"/>
<path fill="#1027C6" opacity="1.000000" stroke="none" 
	d="
M491.588196,255.005768 
	C491.087524,255.169250 490.221313,255.320557 489.190796,255.208725 
	C489.758545,254.961594 490.490601,254.977585 491.588196,255.005768 
z"/>
<path fill="#213AC3" opacity="1.000000" stroke="none" 
	d="
M328.640015,189.001846 
	C328.834808,189.325104 328.686493,189.588470 328.204224,189.884735 
	C328.136505,189.662231 328.278748,189.337006 328.640015,189.001846 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M659.992676,240.995331 
	C659.992676,240.995331 659.692200,241.186325 659.231689,241.222534 
	C658.538940,241.567352 658.306641,241.875961 658.074341,242.184570 
	C658.074341,242.184586 658.066040,242.063599 657.867554,242.157227 
	C657.458862,242.534592 657.248657,242.818344 657.038452,243.102112 
	C657.038452,243.102112 657.018066,243.023743 656.630737,243.022186 
	C654.829102,243.015015 653.414795,243.009384 652.000427,243.003769 
	C650.761292,242.926300 649.464722,243.053909 648.295593,242.726532 
	C646.187927,242.136337 643.137390,241.866852 642.290039,240.410614 
	C640.015076,236.500809 638.696167,232.034744 636.994446,227.403015 
	C642.524841,223.753723 646.346863,217.742065 653.385498,216.849548 
	C654.052002,216.765045 654.758484,216.004944 655.209106,215.385757 
	C656.904907,213.056015 658.256165,210.423401 660.207153,208.346390 
	C662.099792,206.331558 664.570557,204.859802 666.999146,202.986130 
	C671.330933,208.258972 675.525940,213.365326 680.190552,219.043304 
	C679.297607,220.650909 677.791809,223.361801 676.400574,226.483749 
	C676.515076,226.894806 676.480225,226.869278 676.159607,226.663239 
	C675.261597,227.353088 674.684204,228.248978 674.106812,229.144882 
	C674.106873,229.144897 674.031860,229.037994 673.706177,229.039017 
	C671.980225,230.444748 670.580017,231.849457 669.179810,233.254166 
	C669.179810,233.254166 669.136536,233.131363 668.923706,233.207733 
	C668.511475,233.592911 668.312073,233.901733 668.112610,234.210556 
	C668.112610,234.210556 668.097534,234.112915 667.767456,234.086578 
	C664.899292,234.680542 662.361145,235.300842 659.992676,235.879669 
	C659.992676,237.623108 659.992676,239.309219 659.992676,240.995331 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M686.695129,189.495834 
	C687.049500,190.276596 687.141724,190.922363 687.233948,191.568146 
	C686.699463,191.345901 686.164978,191.123672 685.630493,190.901459 
	C685.898010,190.387924 686.165466,189.874390 686.695129,189.495834 
z"/>
<path fill="#1E1580" opacity="1.000000" stroke="none" 
	d="
M627.756226,232.016846 
	C627.731873,232.132462 627.457275,232.244415 627.093994,232.192245 
	C627.172241,232.023148 627.339111,232.018173 627.756226,232.016846 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M692.985046,209.229584 
	C693.089600,209.481461 692.916016,209.808762 692.463013,210.209564 
	C692.358032,209.957062 692.532471,209.631042 692.985046,209.229584 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M692.008667,210.256210 
	C692.129333,210.515366 691.966797,210.849548 691.531860,211.266418 
	C691.414795,211.009827 691.570190,210.670547 692.008667,210.256210 
z"/>
<path fill="#240E60" opacity="1.000000" stroke="none" 
	d="
M691.143677,211.261353 
	C691.346191,211.273071 691.359314,211.691360 691.229004,211.855103 
	C690.931946,211.830017 690.765259,211.641190 690.598572,211.452377 
	C690.712769,211.384796 690.826904,211.317215 691.143677,211.261353 
z"/>
<path fill="#19168E" opacity="1.000000" stroke="none" 
	d="
M344.452881,571.994507 
	C342.765930,571.648376 341.434875,571.517578 340.363831,570.920349 
	C339.193481,570.267822 337.355042,569.148193 337.388550,568.294128 
	C337.523499,564.851746 338.268829,561.433289 339.007416,556.563782 
	C340.486359,559.180298 341.148926,560.352478 342.713257,563.120056 
	C343.725983,559.880127 344.363922,557.839111 345.003998,555.392944 
	C352.042480,554.661926 359.076263,554.240234 366.116272,554.046875 
	C370.209991,553.934448 372.249786,555.553284 372.127930,560.292053 
	C371.826752,572.006042 372.067169,572.013000 360.356018,572.011841 
	C355.211792,572.011353 350.067596,572.006897 344.452881,571.994507 
z"/>
<path fill="#171999" opacity="1.000000" stroke="none" 
	d="
M330.717712,526.036621 
	C327.515961,526.036621 324.754822,526.036621 321.993652,526.036621 
	C321.996735,525.780762 321.999817,525.524963 322.002899,525.269104 
	C326.328217,525.269104 330.653564,525.269104 334.978882,525.269104 
	C334.984650,525.453308 334.990417,525.637451 334.996155,525.821655 
	C333.716858,525.893311 332.437592,525.964966 330.717712,526.036621 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M624.978882,260.991669 
	C624.985779,260.824615 624.992615,260.657562 625.009521,260.239746 
	C625.547119,259.253876 625.977905,257.994537 626.619446,257.875977 
	C629.159485,257.406494 631.765503,257.294189 634.761230,257.083435 
	C632.133972,258.924438 629.092712,260.731415 625.586121,262.176453 
	C625.073425,261.540253 625.026123,261.265961 624.978882,260.991669 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M651.859436,243.303421 
	C653.414795,243.009384 654.829102,243.015015 656.667725,243.038696 
	C653.677063,245.463776 650.262146,247.870804 646.440247,249.959564 
	C646.010315,249.421783 645.987366,249.202271 645.964355,248.982758 
	C647.882385,247.189529 649.800415,245.396301 651.859436,243.303421 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M640.992554,253.502914 
	C639.497437,254.853409 637.649109,255.988129 635.453308,257.148499 
	C635.446716,253.738022 637.780823,253.139313 640.992554,253.502914 
z"/>
<path fill="#251774" opacity="1.000000" stroke="none" 
	d="
M645.603882,249.026260 
	C645.987366,249.202271 646.010315,249.421783 646.070557,249.970215 
	C644.892700,251.330109 643.677612,252.361053 642.169067,253.450806 
	C640.225403,249.926285 643.529541,250.101166 645.603882,249.026260 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M517.193420,429.965942 
	C517.193420,429.965942 517.575989,429.956573 518.076660,430.119751 
	C519.666504,431.234375 520.755737,432.185791 521.844971,433.137207 
	C522.255737,432.815125 522.666565,432.493073 523.077332,432.170990 
	C522.420227,430.907898 521.763062,429.644836 521.096924,428.364441 
	C525.359680,425.598938 528.426758,426.202301 531.680786,430.392883 
	C534.906006,434.546387 537.604736,439.552917 541.750183,442.438629 
	C547.313110,446.311096 547.966003,445.940643 545.581116,453.466125 
	C550.701538,453.466125 555.562805,453.466125 560.424072,453.466125 
	C560.398315,453.855927 560.372620,454.245728 560.346863,454.635529 
	C557.701050,454.898346 555.055298,455.161163 552.409485,455.423981 
	C552.359314,455.865051 552.309143,456.306122 552.259033,456.747192 
	C554.525940,457.520355 556.792908,458.293518 559.059875,459.066650 
	C554.665161,459.721039 550.270386,460.375397 545.439941,461.057129 
	C542.630188,461.064484 540.256165,461.044495 537.436951,461.013733 
	C533.775330,460.669037 530.558655,460.336884 527.342407,460.000793 
	C521.835938,459.425415 516.329773,458.847626 510.554504,458.242645 
	C511.545807,456.427460 512.163940,455.295532 513.007812,453.750366 
	C510.996674,453.242828 509.277313,452.808929 507.557953,452.375031 
	C507.559784,452.113495 507.561615,451.851959 507.563477,451.590454 
	C509.177094,451.218414 510.790710,450.846375 512.404358,450.474335 
	C512.370239,450.024109 512.336121,449.573914 512.302002,449.123688 
	C506.487701,449.871674 500.673401,450.619629 495.347290,451.304810 
	C495.959686,446.539032 496.602783,442.508484 496.961975,438.452789 
	C497.291290,434.734680 495.404449,432.812103 491.632843,433.014435 
	C485.945099,433.319611 480.408813,433.522797 476.281097,428.325043 
	C475.665253,427.549561 473.308868,428.295013 471.805084,428.048218 
	C470.199585,427.784790 468.650330,427.179016 467.409515,426.307007 
	C468.472443,424.295685 469.674103,422.713898 469.721741,421.098114 
	C469.743652,420.355133 467.503265,419.545471 466.213745,418.480530 
	C466.095154,417.802765 466.051575,417.410522 466.000000,416.645142 
	C461.115265,406.167633 455.690796,396.282318 451.535034,385.889679 
	C448.211945,377.579346 446.431915,368.652008 444.330627,360.038086 
	C445.714386,361.286804 447.850067,362.951111 447.572723,363.630829 
	C445.836761,367.885468 448.784851,370.875275 450.187622,374.101868 
	C450.917206,375.780151 453.598480,378.034332 454.959229,377.769897 
	C461.206696,376.555847 464.474060,381.069794 469.565430,384.009064 
	C466.760712,386.483978 464.416962,388.552155 461.258148,391.339508 
	C466.610901,392.161072 471.647247,392.384583 473.847015,397.575134 
	C473.973175,397.872894 475.918060,397.895721 476.053589,397.602020 
	C476.486237,396.664673 476.849396,395.382446 476.533020,394.481384 
	C475.980743,392.908630 474.902252,391.520660 473.829010,389.687439 
	C475.784180,389.815582 476.921692,389.890106 478.359863,390.130554 
	C480.124603,392.889587 481.520020,395.524506 483.066895,398.067322 
	C486.348816,403.462341 489.355804,409.076477 493.144287,414.095978 
	C496.488708,418.527100 500.545288,422.487030 504.658691,426.248993 
	C507.575928,428.916931 511.070404,430.974457 514.445984,433.082245 
	C514.726135,433.257172 516.068359,431.731079 516.944580,430.726807 
	C517.045898,430.294189 517.119629,430.130066 517.193420,429.965942 
z"/>
<path fill="#04C9FC" opacity="1.000000" stroke="none" 
	d="
M383.922424,142.064987 
	C378.154755,137.632675 373.446289,132.209885 372.093231,124.993858 
	C370.478760,116.383957 375.413788,110.202469 381.381104,104.782372 
	C389.703705,97.222954 397.546387,89.013985 406.523010,82.330116 
	C415.420044,75.705528 425.153198,69.993439 435.098541,65.044609 
	C445.715576,59.761524 456.991547,55.793697 468.026398,51.367722 
	C469.948029,50.596989 472.099976,50.400478 474.570282,50.322521 
	C473.843994,53.257362 472.692963,55.808426 471.945953,57.464012 
	C473.110443,60.339432 474.065582,62.697971 474.773560,65.323029 
	C471.033844,66.297440 467.315430,66.469376 464.096405,67.826164 
	C457.642914,70.546288 451.419983,73.832985 445.204285,77.081665 
	C443.934814,77.745148 443.115265,79.269493 441.996643,80.432144 
	C441.904999,80.467285 441.718079,80.532356 441.307922,80.395554 
	C437.027283,82.073463 432.978729,83.593010 429.338623,85.789558 
	C425.992065,87.809013 423.085205,90.557228 419.622559,92.999489 
	C418.457458,93.964394 417.654449,94.914207 416.484253,95.908882 
	C415.021729,96.905396 413.926361,97.857048 412.491821,98.871658 
	C411.057587,99.912895 409.962463,100.891174 408.514648,101.909691 
	C406.404663,103.596123 404.647400,105.242317 402.591644,106.962997 
	C401.808655,107.640594 401.324158,108.243706 400.487244,108.894272 
	C398.081848,110.954781 396.028839,112.967842 393.758240,115.090027 
	C393.264923,115.391647 393.069153,115.645805 392.743286,116.077225 
	C392.270966,116.391197 392.077942,116.642662 391.622742,117.014496 
	C390.807922,118.006866 390.324463,118.931969 389.538544,119.944351 
	C388.818298,120.693382 388.400543,121.355141 387.819061,122.099899 
	C387.593506,122.287102 387.454956,122.448647 387.482788,122.485855 
	C387.620544,122.670135 387.802216,122.821594 387.981659,123.347229 
	C388.985596,124.817154 389.976257,125.924477 391.023285,127.378464 
	C392.062988,129.465836 393.046326,131.206528 393.882416,133.283539 
	C392.935852,134.729507 392.329376,136.082367 391.298309,136.899704 
	C388.950348,138.761047 386.394043,140.359619 383.922424,142.064987 
z"/>
<path fill="#10B8F8" opacity="1.000000" stroke="none" 
	d="
M492.983917,134.310135 
	C492.983917,134.310135 492.693359,134.376495 492.136719,134.221069 
	C489.178314,135.241394 484.404938,130.943466 484.226898,137.251587 
	C484.226898,137.251587 484.140930,137.244751 483.771332,137.152130 
	C482.644745,137.458115 481.887787,137.856720 481.130798,138.255341 
	C481.130798,138.255341 481.037079,138.044159 480.721802,138.064880 
	C478.929871,139.080994 477.453247,140.076370 475.976593,141.071747 
	C475.976593,141.071747 475.739716,141.272583 475.140228,141.170715 
	C471.771515,140.312119 469.028778,139.423782 466.221039,138.858231 
	C464.955292,138.603271 463.559967,138.991745 462.287537,139.083664 
	C463.952454,142.130737 465.058441,144.154831 466.164429,146.178940 
	C466.164429,146.178940 466.126648,146.169052 465.726685,146.107941 
	C464.285217,146.772263 463.243683,147.497711 462.202179,148.223175 
	C462.202179,148.223175 462.173462,148.200165 461.778717,148.134979 
	C460.658264,148.798203 459.932556,149.526627 459.206848,150.255066 
	C459.206848,150.255051 459.285461,150.276230 458.922363,150.224365 
	C457.723267,151.141464 456.887299,152.110443 456.051331,153.079422 
	C456.051331,153.079422 455.995178,153.014603 455.666687,153.012314 
	C454.586151,153.711105 453.834137,154.412201 453.082123,155.113281 
	C453.082123,155.113266 452.986877,155.054504 452.663879,155.037750 
	C449.214874,155.966553 446.379211,157.213684 446.057037,161.071320 
	C446.057037,161.071320 446.057251,161.058807 446.072266,161.069824 
	C444.276154,162.011139 442.280518,162.701385 440.692444,163.921906 
	C437.437561,166.423508 434.491241,169.324921 431.252991,171.850235 
	C426.909882,175.237122 422.040222,175.931839 416.451233,173.674927 
	C416.251343,172.437042 416.438202,171.548569 416.708069,170.265640 
	C415.181824,170.988358 414.083099,171.508636 412.984375,172.028931 
	C412.984375,172.028931 412.999786,172.010086 412.992493,171.636002 
	C411.998322,169.060562 411.011414,166.859222 410.024506,164.657867 
	C410.401001,164.358597 410.777527,164.059326 411.154053,163.760056 
	C412.557800,164.718338 413.851257,165.987320 415.400024,166.536926 
	C416.646881,166.979416 418.202423,166.552063 419.621002,166.510635 
	C418.841339,165.348785 418.081696,164.172852 417.277069,163.028549 
	C416.203918,161.502380 414.678406,160.138443 414.121399,158.449493 
	C413.576416,156.796982 414.003326,154.823929 414.345581,153.029968 
	C416.207581,154.613388 417.685303,156.205643 419.297211,157.647995 
	C419.516968,157.844681 420.406464,157.292953 421.326477,157.074738 
	C422.121124,157.074615 422.575226,157.087524 423.229584,157.334900 
	C424.995483,156.090561 426.561096,154.611755 428.405334,153.069580 
	C429.124420,152.673508 429.564880,152.340805 430.268158,151.884399 
	C431.029480,151.183975 431.527954,150.607239 432.364685,149.984558 
	C434.168274,148.327484 435.633636,146.716339 437.380676,145.048553 
	C438.104614,144.660294 438.546875,144.328674 439.336243,143.955139 
	C442.171631,141.655975 444.659943,139.398727 447.512146,137.102295 
	C451.769104,135.179260 456.658386,134.622757 458.326111,129.288895 
	C458.974152,129.069351 459.356262,128.980515 460.126617,128.932175 
	C463.946411,127.223663 467.377991,125.474655 470.809082,123.825417 
	C470.808594,123.925186 470.844086,123.729027 471.184082,123.793289 
	C472.018982,123.454681 472.513824,123.051819 473.106140,122.600937 
	C473.203613,122.552925 473.398010,122.649963 473.778625,122.739090 
	C475.123444,122.146713 476.087677,121.465218 477.137268,120.704208 
	C477.222626,120.624695 477.453552,120.591507 477.870911,120.703819 
	C479.258118,120.677299 480.244049,120.608124 481.195343,120.388824 
	C490.460114,118.253113 499.719116,116.092323 508.977905,114.320137 
	C508.067444,115.733559 507.334747,117.354012 506.217499,117.687988 
	C502.757935,118.722122 499.142700,119.225334 495.607361,120.020950 
	C494.133850,120.352554 492.709442,120.902069 491.262695,121.352486 
	C491.357239,121.740265 491.451752,122.128044 491.546295,122.515823 
	C493.258698,122.873047 494.971100,123.230263 497.931763,123.847885 
	C496.123962,127.670525 494.553925,130.990326 492.983917,134.310135 
z"/>
<path fill="#07C0FD" opacity="1.000000" stroke="none" 
	d="
M414.003632,152.991577 
	C414.003326,154.823929 413.576416,156.796982 414.121399,158.449493 
	C414.678406,160.138443 416.203918,161.502380 417.277069,163.028549 
	C418.081696,164.172852 418.841339,165.348785 419.621002,166.510635 
	C418.202423,166.552063 416.646881,166.979416 415.400024,166.536926 
	C413.851257,165.987320 412.557800,164.718338 411.154053,163.760056 
	C410.777527,164.059326 410.401001,164.358597 410.024506,164.657867 
	C411.011414,166.859222 411.998322,169.060562 412.982025,171.637421 
	C411.155762,170.700684 409.071594,169.636017 407.553436,168.034744 
	C399.631104,159.678833 391.852661,151.186539 383.973267,142.403351 
	C386.394043,140.359619 388.950348,138.761047 391.298309,136.899704 
	C392.329376,136.082367 392.935852,134.729507 394.221985,133.335571 
	C396.753967,134.417450 398.799103,135.783554 400.939148,137.454758 
	C401.708405,138.161316 402.382782,138.562790 403.032745,138.984436 
	C403.008362,139.004608 403.036102,138.947952 403.049347,139.282135 
	C403.721985,140.066879 404.381409,140.517410 405.020386,140.983978 
	C405.000000,141.000000 405.032135,140.959091 405.044739,141.289734 
	C405.705627,142.083908 406.353943,142.547440 407.214813,143.120087 
	C407.696564,143.423294 407.877747,143.679581 408.050903,144.233551 
	C408.313812,144.770401 408.577667,144.957748 408.948547,145.029007 
	C408.974640,145.026947 408.946716,145.073059 408.946198,145.434235 
	C410.631653,148.194122 412.317657,150.592850 414.003632,152.991577 
z"/>
<path fill="#04C9FC" opacity="1.000000" stroke="none" 
	d="
M489.503601,46.916054 
	C491.426361,47.443855 492.889465,48.351715 494.404907,48.449696 
	C496.388000,48.577911 498.412445,48.066128 500.419128,47.829651 
	C500.543152,48.235733 500.667145,48.641819 500.791168,49.047901 
	C500.035522,50.003139 499.279877,50.958382 498.231018,52.284271 
	C499.759491,52.768589 501.134155,53.204170 503.422760,53.929356 
	C499.209869,55.936558 493.612762,54.309669 492.664185,60.595879 
	C491.887634,60.999832 491.445038,60.999355 490.953918,60.622402 
	C489.495667,59.523823 488.085999,58.801723 486.676300,58.079620 
	C486.119232,59.399845 485.562164,60.720070 484.674225,62.188484 
	C482.231445,62.907066 480.119537,63.477463 477.955414,63.700043 
	C476.339050,61.463951 475.727814,60.221596 478.158386,57.922115 
	C480.424103,55.778610 482.940399,52.232533 478.869629,48.921883 
	C482.268829,48.259876 485.668060,47.597870 489.503601,46.916054 
M482.818451,57.541412 
	C482.387207,57.589252 481.955994,57.637093 481.524750,57.684925 
	C481.702576,58.056046 481.880371,58.427166 482.058167,58.798283 
	C482.440796,58.510612 482.823456,58.222942 482.818451,57.541412 
z"/>
<path fill="#07C0FD" opacity="1.000000" stroke="none" 
	d="
M478.518921,48.929348 
	C482.940399,52.232533 480.424103,55.778610 478.158386,57.922115 
	C475.727814,60.221596 476.339050,61.463951 477.744568,63.953033 
	C476.730835,64.721390 475.875793,64.888947 475.020721,65.056511 
	C474.065582,62.697971 473.110443,60.339432 471.945953,57.464012 
	C472.692963,55.808426 473.843994,53.257362 474.991547,50.313843 
	C476.048096,49.593197 477.108154,49.265003 478.518921,48.929348 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M413.130615,172.313507 
	C414.083099,171.508636 415.181824,170.988358 416.708069,170.265640 
	C416.438202,171.548569 416.251343,172.437042 416.038879,173.649734 
	C415.101166,173.515335 414.188995,173.056702 413.130615,172.313507 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M207.237701,572.085327 
	C208.001816,571.695068 208.682358,571.613525 209.362915,571.531982 
	C209.269547,572.079163 209.176178,572.626404 209.082794,573.173584 
	C208.439896,572.913696 207.797012,572.653809 207.237701,572.085327 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M259.254333,653.870117 
	C259.418060,650.278259 259.418060,650.278259 264.191376,649.138611 
	C264.285919,650.665222 264.378296,652.156250 264.227600,653.839294 
	C263.572296,653.988647 263.160095,653.945984 262.442322,653.843506 
	C261.269714,653.774780 260.402649,653.765808 259.254333,653.870117 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M264.036377,656.231689 
	C264.220734,656.148804 264.401550,656.317749 264.530151,656.519836 
	C264.560638,656.567749 264.415894,656.727112 264.186951,656.930298 
	C264.019318,656.848755 264.016510,656.672180 264.036377,656.231689 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M526.012146,102.005836 
	C526.012146,102.005836 525.776245,101.807014 525.828613,101.279724 
	C525.174194,99.894684 524.467529,99.036926 523.722778,98.193008 
	C523.684814,98.206841 523.603882,98.208466 523.726196,97.777023 
	C523.565979,91.751953 522.804199,86.131638 523.113708,80.570961 
	C523.574463,72.293083 524.397888,66.427734 535.854492,65.358948 
	C542.180908,64.768745 548.677124,65.749985 555.074951,66.284126 
	C558.110229,66.537537 561.180603,67.134346 564.078247,68.064308 
	C567.181458,69.060272 570.100220,70.631081 572.669678,71.965302 
	C570.268372,72.124176 568.296814,72.268753 566.325256,72.413322 
	C566.244263,73.168739 566.163269,73.924149 566.082275,74.679565 
	C571.200867,75.915100 576.319519,77.150642 580.938660,78.265610 
	C579.862000,78.939613 578.061218,79.517319 577.137024,80.751434 
	C574.648254,84.074913 570.456543,85.266563 566.453918,83.702774 
	C564.230469,82.834091 561.805237,82.332619 559.425232,82.047089 
	C555.335205,81.556404 551.210938,81.170776 547.098022,81.139854 
	C545.404053,81.127121 543.702332,82.146683 542.004150,82.696716 
	C541.638062,82.880135 541.272034,83.063530 540.403564,83.031364 
	C536.252075,82.442154 536.258179,84.604416 538.121216,86.306038 
	C539.486084,87.552597 542.014832,87.524910 544.436157,88.045082 
	C546.222046,88.048027 547.596558,88.071053 548.967163,88.470726 
	C547.534546,89.969452 546.105774,91.091545 544.689697,92.203697 
	C551.087219,94.007690 552.878906,93.415504 554.434814,89.074219 
	C555.659180,88.998024 556.477295,88.947876 557.295471,88.897736 
	C557.295471,88.897743 557.625305,88.782860 558.009155,89.143661 
	C560.257263,90.013931 562.121399,90.523415 563.930542,91.374832 
	C562.989868,93.497971 562.104248,95.279167 561.218628,97.060356 
	C561.562012,97.472069 561.905457,97.883789 562.248840,98.295502 
	C558.964050,98.693573 555.679199,99.091637 552.160583,99.518044 
	C552.470459,101.551971 552.734314,103.283813 552.530029,105.016724 
	C548.952820,104.997536 545.822815,105.196198 542.739929,104.901367 
	C538.920654,104.536118 535.135132,103.793861 531.345886,103.150139 
	C529.554871,102.845886 527.789246,102.391945 526.012146,102.005836 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M542.455566,82.783463 
	C543.702332,82.146683 545.404053,81.127121 547.098022,81.139854 
	C551.210938,81.170776 555.335205,81.556404 559.425232,82.047089 
	C561.805237,82.332619 564.230469,82.834091 566.453918,83.702774 
	C570.456543,85.266563 574.648254,84.074913 577.137024,80.751434 
	C578.061218,79.517319 579.862000,78.939613 580.938660,78.265610 
	C576.319519,77.150642 571.200867,75.915100 566.082275,74.679565 
	C566.163269,73.924149 566.244263,73.168739 566.325256,72.413322 
	C568.296814,72.268753 570.268372,72.124176 572.978699,71.962540 
	C574.131958,71.937927 574.546387,71.930382 575.015686,72.255722 
	C577.463013,73.385468 579.816040,74.391525 582.271484,74.855202 
	C583.035278,74.999435 584.076538,73.674667 585.335571,73.156540 
	C591.124329,75.520226 596.568298,77.749771 601.892944,80.266129 
	C601.784363,81.245193 601.794922,81.937454 601.805481,82.629707 
	C602.874390,82.084473 603.943298,81.539246 605.420288,80.994881 
	C606.916870,80.954063 608.005249,80.912384 609.093689,80.870712 
	C609.093689,80.870712 609.083130,80.895622 609.097412,81.253815 
	C610.158569,82.793022 611.205566,83.974052 612.236206,85.136696 
	C611.025452,86.793953 609.574951,88.699501 608.217773,90.669273 
	C607.496033,91.716766 606.935669,92.875473 606.302490,93.983994 
	C607.002380,94.312836 607.857605,95.071091 608.371216,94.885223 
	C609.616943,94.434387 610.719299,93.567963 611.850403,92.824928 
	C613.248901,91.906281 614.618164,90.943069 616.000000,89.999100 
	C615.304932,91.527618 614.587036,93.046310 613.921143,94.587410 
	C613.255188,96.128494 612.641174,97.691994 611.708740,99.752647 
	C610.601929,100.515617 609.790710,100.771622 608.979553,101.027626 
	C608.979553,101.027626 608.987183,101.005173 608.869934,100.779007 
	C608.537720,100.281059 608.259216,100.117226 607.917175,100.061340 
	C607.917175,100.061340 607.943359,100.121849 607.907654,99.768127 
	C602.201172,93.848389 594.309509,93.094612 587.457031,90.097130 
	C586.871216,89.840889 585.772888,90.756439 584.912659,91.127640 
	C570.910767,88.375168 556.908875,85.622696 542.455566,82.783463 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M552.998169,105.015656 
	C552.734314,103.283813 552.470459,101.551971 552.160583,99.518044 
	C555.679199,99.091637 558.964050,98.693573 562.248840,98.295502 
	C561.905457,97.883789 561.562012,97.472069 561.218628,97.060356 
	C562.104248,95.279167 562.989868,93.497971 564.355042,91.361908 
	C565.688965,90.964615 566.543396,90.922195 567.397827,90.879784 
	C567.767761,90.855560 568.137756,90.831337 568.839966,91.328903 
	C571.133362,92.205505 573.094543,92.560333 575.055725,92.915154 
	C575.055725,92.915154 575.004456,92.883949 575.232849,93.178787 
	C576.172119,93.600098 576.882996,93.726570 577.593872,93.853058 
	C577.878174,93.725533 578.162537,93.598000 578.812622,93.922333 
	C580.461731,94.585365 581.745056,94.796516 583.028442,95.007675 
	C583.577148,95.097832 584.125854,95.187996 584.866638,95.483147 
	C587.146912,97.447006 589.235046,99.205887 591.384216,101.016121 
	C591.447998,100.923424 590.975159,101.610542 590.502319,102.297653 
	C591.495056,102.759094 592.450745,103.490005 593.487305,103.632278 
	C596.819153,104.089638 600.180969,104.329643 602.882935,104.590820 
	C602.882935,107.583603 602.882935,109.932556 602.882935,112.842606 
	C601.216064,111.939659 599.186890,110.925873 597.241577,109.770943 
	C590.883301,105.996162 589.111023,106.829720 587.626343,114.595924 
	C586.548035,114.947189 585.833130,114.907364 585.004761,114.534744 
	C580.114197,110.744080 574.704529,109.709595 568.952637,110.029083 
	C568.966125,110.020348 568.956604,110.058243 568.768860,109.755943 
	C563.386841,107.974312 558.192505,106.494980 552.998169,105.015656 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M585.132935,91.416641 
	C585.772888,90.756439 586.871216,89.840889 587.457031,90.097130 
	C594.309509,93.094612 602.201172,93.848389 607.876099,99.777542 
	C600.371216,97.329010 592.862244,94.517326 585.132935,91.416641 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M615.077637,104.395065 
	C617.967957,104.519035 621.491211,104.136475 623.885132,105.600494 
	C627.000671,107.505814 629.219604,110.877327 631.719360,113.996811 
	C626.145691,111.153770 620.676941,107.931763 615.077637,104.395065 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M544.024780,88.065170 
	C542.014832,87.524910 539.486084,87.552597 538.121216,86.306038 
	C536.258179,84.604416 536.252075,82.442154 540.107666,83.114334 
	C541.012878,84.611481 541.711670,85.810097 542.851013,87.301270 
	C543.499084,87.808281 543.743469,87.965401 544.024780,88.065170 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M635.943176,123.620193 
	C633.160217,122.085075 630.234375,120.241211 627.284912,118.034653 
	C630.107666,119.551781 632.953979,121.431618 635.943176,123.620193 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M627.265137,117.281372 
	C623.526917,117.486488 620.638428,116.021339 619.168091,111.934807 
	C621.814575,113.356071 624.525818,115.149223 627.265137,117.281372 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M609.169189,101.304810 
	C609.790710,100.771622 610.601929,100.515617 611.712463,100.138817 
	C612.981750,101.150375 613.951599,102.282738 614.911682,103.751328 
	C613.054138,103.252365 611.206482,102.417183 609.169189,101.304810 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M640.993530,127.582909 
	C639.399109,126.820755 637.742676,125.725433 636.078125,124.264481 
	C637.690430,125.015823 639.310974,126.132782 640.993530,127.582909 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M567.085205,90.620819 
	C566.543396,90.922195 565.688965,90.964615 564.410034,91.019958 
	C562.121399,90.523415 560.257263,90.013931 558.164062,89.224457 
	C560.880859,89.416924 563.826660,89.889389 567.085205,90.620819 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M544.436157,88.045090 
	C543.743469,87.965401 543.499084,87.808281 543.173584,87.410507 
	C545.542297,87.250725 548.029114,87.274254 550.808105,87.586853 
	C551.100281,87.875916 550.962280,88.063431 550.630371,88.046387 
	C549.856018,88.050926 549.413513,88.072502 548.971008,88.094086 
	C547.596558,88.071053 546.222046,88.048027 544.436157,88.045090 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M574.843750,92.625092 
	C573.094543,92.560333 571.133362,92.205505 569.077148,91.486015 
	C570.865295,91.525902 572.748535,91.930458 574.843750,92.625092 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M582.730774,94.790985 
	C581.745056,94.796516 580.461731,94.585365 579.029175,94.046448 
	C580.064392,94.003891 581.248718,94.289093 582.730774,94.790985 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M556.984131,88.650414 
	C556.477295,88.947876 555.659180,88.998024 554.095459,89.038414 
	C552.554077,88.706917 551.758179,88.385170 550.962280,88.063431 
	C550.962280,88.063431 551.100281,87.875916 551.167236,87.778503 
	C553.047058,87.921768 554.859863,88.162430 556.984131,88.650414 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M643.174194,129.433807 
	C642.515503,129.366394 641.811279,128.993973 641.094116,128.277603 
	C641.763672,128.332047 642.446167,128.730423 643.174194,129.433807 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M577.392517,93.530853 
	C576.882996,93.726570 576.172119,93.600098 575.259216,93.164467 
	C575.768494,92.973091 576.479858,93.090874 577.392517,93.530853 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M631.636597,114.548401 
	C632.091858,114.622841 632.515015,115.005783 632.943726,115.706619 
	C632.500977,115.635323 632.052795,115.246117 631.636597,114.548401 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M607.966125,100.323456 
	C608.259216,100.117226 608.537720,100.281059 608.858643,100.775360 
	C608.648132,100.860443 608.331604,100.723007 607.966125,100.323456 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M633.047058,116.294785 
	C633.313354,116.100746 633.588928,116.266991 633.915161,116.759109 
	C633.728027,116.833374 633.424866,116.690071 633.047058,116.294785 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M365.686707,754.528320 
	C366.529663,756.684082 367.129883,758.494202 367.922760,760.885376 
	C361.251923,760.885376 355.384552,760.885376 347.987457,760.885376 
	C350.100189,757.613647 351.150604,754.125671 353.428284,752.877808 
	C357.245514,750.786560 361.764954,750.757812 365.686707,754.528320 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M493.333099,134.333160 
	C494.553925,130.990326 496.123962,127.670525 497.931763,123.847885 
	C494.971100,123.230263 493.258698,122.873047 491.546295,122.515823 
	C491.451752,122.128044 491.357239,121.740265 491.262695,121.352486 
	C492.709442,120.902069 494.133850,120.352554 495.607361,120.020950 
	C499.142700,119.225334 502.757935,118.722122 506.217499,117.687988 
	C507.334747,117.354012 508.067444,115.733559 509.260895,114.046066 
	C517.565857,113.163956 525.585999,112.937386 533.606140,112.710815 
	C533.606140,112.710815 533.855835,112.674507 533.931396,113.152847 
	C537.975891,119.979927 539.468140,126.854057 539.065552,134.347824 
	C538.409241,146.562363 535.155090,148.522934 523.409058,150.936798 
	C513.715515,152.928848 503.887878,154.269501 494.198883,156.280472 
	C492.331665,156.668015 490.831543,158.824280 489.149963,160.150909 
	C489.137848,160.137207 489.111176,160.115173 488.797852,160.088013 
	C487.991913,160.382492 487.499298,160.704132 487.020660,161.012360 
	C487.034637,160.998932 487.051086,161.034149 486.653015,161.007538 
	C477.493408,163.127960 469.811676,167.056168 463.977661,174.612076 
	C462.281158,175.984116 460.785553,177.107864 458.943542,178.267639 
	C455.978882,180.503387 453.184204,182.529312 450.795227,184.954803 
	C448.673523,187.108994 448.785706,189.289062 451.129425,191.766129 
	C454.710724,195.551208 457.773407,199.826965 460.845764,204.200958 
	C457.151154,209.472305 457.151154,209.472305 452.999023,207.786850 
	C453.425690,207.097763 453.884766,206.489120 454.197693,205.812866 
	C454.498016,205.164017 454.646790,204.445023 454.952881,203.467087 
	C448.250427,205.935654 447.904572,207.194199 452.488525,211.013733 
	C454.874847,213.002075 457.583588,214.703339 459.568420,217.028030 
	C460.448578,218.058899 459.896576,220.312576 459.994659,222.011185 
	C451.985596,212.992249 443.982635,203.967896 435.955688,194.964920 
	C435.325531,194.258163 434.528015,193.700668 433.467255,193.021271 
	C432.985443,192.582260 432.844604,192.195816 432.712280,191.498444 
	C433.810974,186.691864 434.901123,182.196228 435.969238,177.271362 
	C436.198578,176.356674 436.449982,175.871216 437.213806,175.155655 
	C438.151947,174.304657 438.577637,173.683777 439.003357,173.062897 
	C439.003357,173.062897 438.998260,172.998322 439.346436,172.961838 
	C440.124176,172.283813 440.553772,171.642273 440.983337,171.000732 
	C441.476349,170.392578 441.969299,169.784393 443.117554,169.088516 
	C444.801880,167.956604 445.830963,166.912415 446.860016,165.868225 
	C446.860016,165.868225 446.865906,165.926819 447.220795,165.906082 
	C448.021088,165.228439 448.466492,164.571533 448.911896,163.914642 
	C448.911896,163.914627 448.973053,164.003418 449.316833,163.980850 
	C450.452881,163.027390 451.245087,162.096512 452.037323,161.165619 
	C452.037323,161.165619 452.285583,160.918594 452.814270,160.916412 
	C453.835541,160.514038 454.328125,160.113815 454.820709,159.713593 
	C454.820709,159.713593 454.749603,159.780701 455.092224,159.804855 
	C455.907684,159.174881 456.380524,158.520752 456.853363,157.866623 
	C456.853363,157.866623 456.918823,157.869141 457.181580,157.799973 
	C457.656097,157.433945 457.867889,157.137085 458.079651,156.840210 
	C458.079651,156.840210 458.247711,156.714325 458.723724,156.798737 
	C460.072784,156.165192 460.945862,155.447235 461.818939,154.729294 
	C461.818939,154.729294 461.779999,154.867935 462.115540,154.855804 
	C462.928345,154.189774 463.405579,153.535873 463.882812,152.881973 
	C463.882812,152.881973 463.932068,152.886169 464.170532,152.822205 
	C464.586182,152.473465 464.763428,152.188690 464.940674,151.903915 
	C465.377747,151.579910 465.814819,151.255905 466.932556,150.952087 
	C469.373077,149.941589 471.132935,148.910919 472.892761,147.880219 
	C472.892761,147.880219 472.892242,147.914078 473.273438,147.939667 
	C474.440460,147.270920 475.226288,146.576569 476.012146,145.882217 
	C476.012146,145.882217 476.230286,145.714783 476.766174,145.820740 
	C478.499451,145.132889 479.696808,144.339081 480.894165,143.545273 
	C481.365906,143.284836 481.837677,143.024399 482.791016,143.062500 
	C484.186462,142.807953 485.100342,142.254822 486.014221,141.701691 
	C486.014221,141.701706 486.282959,141.622162 486.795593,141.772308 
	C493.812866,140.274612 500.306580,138.582031 506.825745,136.993973 
	C511.511078,135.852646 516.230408,134.850876 520.934570,133.786865 
	C520.934570,133.786865 520.880554,133.904892 521.219849,133.889252 
	C522.341858,132.806747 523.124695,131.739914 523.907471,130.673080 
	C522.876709,130.165955 521.846008,129.658829 520.815247,129.151703 
	C520.815247,129.151703 520.902588,129.076950 520.700928,128.798218 
	C515.294678,129.421844 510.090057,130.324219 504.885437,131.226593 
	C502.614136,131.891327 500.342804,132.556076 497.371368,133.153076 
	C496.120300,133.379562 495.569366,133.673813 495.018402,133.968048 
	C494.573029,134.097412 494.127655,134.226791 493.333099,134.333160 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M520.491577,133.742004 
	C516.230408,134.850876 511.511078,135.852646 506.825745,136.993973 
	C500.306580,138.582031 493.812866,140.274612 486.875214,141.887619 
	C493.240417,139.649887 499.961548,137.150696 506.865417,135.353485 
	C511.115509,134.247101 515.644714,134.212784 520.491577,133.742004 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M466.515015,146.172363 
	C465.058441,144.154831 463.952454,142.130737 462.287537,139.083664 
	C463.559967,138.991745 464.955292,138.603271 466.221039,138.858231 
	C469.028778,139.423782 471.771515,140.312119 474.999451,141.105194 
	C472.593964,142.816299 469.729797,144.491058 466.515015,146.172363 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M505.338440,131.257462 
	C510.090057,130.324219 515.294678,129.421844 520.725952,128.849976 
	C515.898865,129.883072 510.845154,130.585709 505.338440,131.257462 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M484.598022,137.287720 
	C484.404938,130.943466 489.178314,135.241394 492.065186,134.090347 
	C490.023193,135.184647 487.496155,136.254257 484.598022,137.287720 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M446.379211,160.977509 
	C446.379211,157.213684 449.214874,155.966553 452.717346,155.053604 
	C450.962982,157.018707 448.832214,158.951202 446.379211,160.977509 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M520.881104,129.540039 
	C521.846008,129.658829 522.876709,130.165955 523.907471,130.673080 
	C523.124695,131.739914 522.341858,132.806747 521.170776,133.846771 
	C520.837219,132.522751 520.892090,131.225555 520.881104,129.540039 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M472.542969,147.866669 
	C471.132935,148.910919 469.373077,149.941589 467.216736,150.942444 
	C468.611237,149.892776 470.402222,148.872940 472.542969,147.866669 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M476.328918,141.070984 
	C477.453247,140.076370 478.929871,139.080994 480.786255,138.160721 
	C479.671112,139.180618 478.176178,140.125412 476.328918,141.070984 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M446.537842,165.889404 
	C445.830963,166.912415 444.801880,167.956604 443.407898,168.996124 
	C444.100555,167.964493 445.158112,166.937531 446.537842,165.889404 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M456.379730,153.050140 
	C456.887299,152.110443 457.723267,151.141464 458.921448,150.265045 
	C458.425171,151.245361 457.566650,152.133102 456.379730,153.050140 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M462.539612,148.262527 
	C463.243683,147.497711 464.285217,146.772263 465.719971,146.089600 
	C465.034485,146.855560 463.955750,147.578720 462.539612,148.262527 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M485.667725,141.687225 
	C485.100342,142.254822 484.186462,142.807953 483.068481,143.065460 
	C483.683350,142.404175 484.502319,142.038483 485.667725,141.687225 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M480.538055,143.613037 
	C479.696808,144.339081 478.499451,145.132889 476.886475,145.887299 
	C477.707886,145.125534 478.944916,144.403152 480.538055,143.613037 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M451.729797,161.211548 
	C451.245087,162.096512 450.452881,163.027390 449.292603,163.934525 
	C449.757141,163.026352 450.589691,162.141922 451.729797,161.211548 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M475.680969,145.914932 
	C475.226288,146.576569 474.440460,147.270920 473.286804,147.931183 
	C473.729309,147.247299 474.539551,146.597473 475.680969,145.914932 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M459.534363,150.269592 
	C459.932556,149.526627 460.658264,148.798203 461.761932,148.127441 
	C461.380554,148.884766 460.621216,149.584427 459.534363,150.269592 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M453.401062,155.085510 
	C453.834137,154.412201 454.586151,153.711105 455.704041,153.033234 
	C455.286591,153.723541 454.503296,154.390640 453.401062,155.085510 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M461.461487,154.691589 
	C460.945862,155.447235 460.072784,156.165192 458.817322,156.846313 
	C459.324615,156.090942 460.214325,155.372421 461.461487,154.691589 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M456.533752,157.864410 
	C456.380524,158.520752 455.907684,159.174881 455.081970,159.757050 
	C455.224091,159.077438 455.719147,158.469818 456.533752,157.864410 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M463.560730,152.894348 
	C463.405579,153.535873 462.928345,154.189774 462.098846,154.785767 
	C462.243958,154.120819 462.741302,153.513779 463.560730,152.894348 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M448.613892,163.972321 
	C448.466492,164.571533 448.021088,165.228439 447.237976,165.882202 
	C447.372131,165.262711 447.843994,164.646362 448.613892,163.972321 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M438.728149,173.147583 
	C438.577637,173.683777 438.151947,174.304657 437.421204,174.971161 
	C437.561707,174.421951 438.007324,173.827103 438.728149,173.147583 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M481.472595,138.345795 
	C481.887787,137.856720 482.644745,137.458115 483.759125,137.110779 
	C483.349152,137.586792 482.581757,138.011520 481.472595,138.345795 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M440.700745,171.081635 
	C440.553772,171.642273 440.124176,172.283813 439.378754,172.963898 
	C439.514648,172.389145 439.966400,171.775833 440.700745,171.081635 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M454.486725,159.668335 
	C454.328125,160.113815 453.835541,160.514038 452.987213,160.889069 
	C453.138580,160.450302 453.645660,160.036697 454.486725,159.668335 
z"/>
<path fill="#10B8F8" opacity="1.000000" stroke="none" 
	d="
M495.342041,133.997803 
	C495.569366,133.673813 496.120300,133.379562 497.006592,133.115829 
	C496.783203,133.440063 496.224426,133.733795 495.342041,133.997803 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M464.685211,151.979889 
	C464.763428,152.188690 464.586182,152.473465 464.165833,152.846481 
	C464.091736,152.641769 464.260742,152.348831 464.685211,151.979889 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M457.779236,156.882660 
	C457.867889,157.137085 457.656097,157.433945 457.187378,157.832214 
	C457.113220,157.597443 457.296021,157.261261 457.779236,156.882660 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M664.432739,764.797485 
	C662.926331,773.027222 659.060547,776.073425 652.255432,774.771118 
	C648.242004,774.002991 645.708374,771.670593 645.090027,767.571838 
	C644.306824,762.381042 643.562622,756.438049 648.640503,753.592285 
	C651.685730,751.885803 656.851257,752.153442 660.323792,753.476318 
	C664.665894,755.130371 664.371216,760.186768 664.432739,764.797485 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M218.284531,754.188660 
	C223.825348,751.056641 229.386673,751.196777 232.432709,754.240173 
	C235.509399,757.314331 236.373825,765.825195 233.896698,770.301086 
	C231.550354,774.540649 227.551773,775.439819 223.192078,774.770081 
	C216.310089,773.712708 213.201141,765.876465 216.225250,757.435669 
	C216.605499,756.374268 217.413559,755.466187 218.284531,754.188660 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M802.263611,758.819397 
	C805.272095,752.573914 809.050537,750.760010 815.303040,752.200500 
	C819.488403,753.164795 821.638123,756.659607 821.771912,762.717041 
	C821.945190,770.556580 819.276001,774.320435 813.125793,774.908691 
	C806.553955,775.537231 802.285034,771.877197 801.961060,765.207275 
	C801.864624,763.221375 802.088684,761.219849 802.263611,758.819397 
z"/>
<path fill="#F5F5F5" opacity="1.000000" stroke="none" 
	d="
M550.960938,769.594482 
	C549.746338,770.359375 548.708862,770.906006 547.603821,771.190735 
	C542.240479,772.572449 537.289856,770.791626 535.685608,766.976746 
	C533.747925,762.368835 534.979492,755.432617 538.126038,753.233276 
	C542.000122,750.524963 548.580994,751.713989 552.611206,756.003540 
	C553.755676,760.758057 554.682739,765.316895 550.960938,769.594482 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M558.883911,184.494781 
	C558.823364,185.067291 558.396484,185.754288 558.151367,185.694626 
	C552.797058,184.391388 547.463074,183.005142 542.050781,181.230255 
	C547.518311,181.970947 553.060242,183.116226 558.883911,184.494781 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M561.001709,180.521790 
	C564.193237,181.594467 567.536072,182.984009 570.900635,184.735657 
	C567.665894,183.678070 564.409485,182.258362 561.001709,180.521790 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M549.873108,177.384552 
	C553.243896,177.931961 556.913391,178.741440 560.732910,179.898102 
	C557.312500,179.379059 553.742188,178.512863 549.873108,177.384552 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M541.454468,176.818604 
	C543.384888,171.452759 546.099182,176.859421 548.856750,176.837448 
	C546.686646,177.030869 544.292908,176.938126 541.454468,176.818604 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M540.376404,179.520935 
	C539.669189,179.448730 538.937866,179.036484 538.206543,178.624237 
	C538.692871,178.122635 539.179260,177.621048 540.050232,177.130096 
	C540.407349,177.820816 540.379822,178.500854 540.376404,179.520935 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M238.810730,667.790771 
	C236.601776,669.363159 234.163330,670.648254 231.360001,671.925415 
	C231.781006,667.450806 234.883865,666.966248 238.810730,667.790771 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M320.003723,241.469940 
	C320.200531,243.505814 320.386047,245.982315 320.286743,248.721939 
	C319.998779,246.626892 319.995575,244.268723 320.003723,241.469940 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M594.913330,420.843994 
	C594.913330,420.843994 594.887512,421.061279 594.412842,421.155487 
	C593.684326,421.890198 593.430420,422.530701 593.176514,423.171204 
	C593.176575,423.171234 593.178955,423.470154 593.135254,423.924377 
	C593.730286,424.591553 594.369019,424.804504 595.420044,425.015015 
	C600.221863,423.662933 604.611389,422.313232 609.208923,421.220032 
	C611.665039,422.112885 613.913208,422.749268 616.161316,423.385651 
	C613.180298,427.277740 617.707947,429.733856 617.613770,433.001587 
	C616.219543,433.514771 615.211426,434.026398 614.203369,434.537994 
	C614.595337,434.972351 614.987244,435.406708 615.379150,435.841125 
	C616.252808,434.894073 617.126404,433.947052 618.415588,432.999664 
	C621.900024,434.009094 624.968811,435.018890 628.037659,436.028656 
	C622.471375,439.034180 616.901855,442.033630 611.342957,445.052795 
	C610.638794,445.435242 609.990540,445.920746 609.150635,446.667023 
	C605.895813,447.957947 602.806641,448.940308 599.316406,449.587128 
	C597.563416,444.484039 594.573547,444.079468 590.531799,445.950409 
	C583.865051,449.036530 580.744995,447.006042 579.869629,439.822906 
	C579.550354,437.203125 578.353333,434.146759 576.500671,432.427856 
	C574.993713,431.029724 571.910339,431.330597 569.273560,430.830139 
	C569.615173,430.376129 569.993164,429.900818 570.341980,429.404938 
	C571.354309,427.965607 572.354858,426.518066 573.360046,425.073761 
	C571.864746,425.522827 570.285156,425.794128 568.890808,426.455933 
	C565.394104,428.115540 562.196045,426.791351 561.262695,422.824097 
	C562.683411,421.073853 563.833374,419.517883 565.116089,417.667847 
	C565.577637,416.581512 565.906433,415.789276 566.507507,414.978333 
	C567.183655,414.629089 567.587646,414.298523 568.113220,413.708801 
	C568.801392,412.954773 569.367920,412.459900 570.116577,411.899902 
	C570.375366,411.733368 570.538391,411.577423 570.514526,411.539612 
	C570.385864,411.336121 570.210815,411.161957 570.057922,410.648651 
	C570.047791,409.881409 570.031494,409.443176 570.007568,409.003387 
	C570.000000,409.001831 570.008484,409.015839 570.329468,409.022095 
	C571.077820,409.006805 571.505310,408.985260 572.195862,408.817200 
	C572.630249,408.111298 572.801514,407.551910 572.985229,406.993805 
	C572.997620,406.995087 572.981384,406.975342 573.257202,406.861389 
	C573.753296,405.818329 573.973633,404.889252 574.138489,403.703064 
	C574.049377,403.273895 574.015869,403.101776 574.033875,402.651947 
	C574.365479,401.924225 574.645630,401.474243 575.284668,400.989929 
	C576.016418,400.212036 576.389160,399.468567 576.799255,398.787750 
	C576.836609,398.850403 576.691162,398.861816 577.019165,398.755798 
	C577.481934,398.017822 577.616638,397.385895 577.788696,396.875061 
	C577.826050,396.996155 577.712830,396.769379 578.056152,396.820068 
	C578.952637,396.256592 579.505859,395.642456 580.271973,394.909271 
	C580.748718,394.575317 580.915344,394.301270 581.117065,393.688965 
	C582.152466,392.594513 583.055542,391.779205 584.022766,391.305847 
	C585.907593,394.314545 587.728271,396.981323 589.193909,399.127991 
	C591.559753,398.392761 593.558289,397.771698 596.140015,396.969421 
	C595.090637,393.604797 594.019470,390.170227 592.948242,386.735626 
	C593.426697,386.567017 593.905090,386.398438 594.383545,386.229828 
	C594.990295,386.949402 595.609436,387.658997 596.201477,388.390472 
	C597.754395,390.309296 599.181030,393.753845 600.862549,393.880768 
	C606.119141,394.277649 603.735107,396.805206 603.061707,399.132843 
	C602.887207,399.735748 603.082764,400.445709 603.048828,401.458984 
	C602.306030,403.858582 601.622925,405.905701 600.604065,408.022766 
	C599.835449,409.052856 599.402527,410.013000 598.707764,411.200500 
	C597.268433,414.566620 596.090881,417.705322 594.913330,420.843994 
M609.530518,428.354218 
	C608.651001,429.548370 607.771423,430.742523 606.891907,431.936676 
	C607.432373,432.265106 607.972839,432.593567 608.513306,432.921997 
	C609.065979,431.399811 609.618652,429.877655 609.530518,428.354218 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M560.992004,423.018311 
	C562.196045,426.791351 565.394104,428.115540 568.890808,426.455933 
	C570.285156,425.794128 571.864746,425.522827 573.360046,425.073761 
	C572.354858,426.518066 571.354309,427.965607 570.341980,429.404938 
	C569.993164,429.900818 569.615173,430.376129 569.273560,430.830139 
	C571.910339,431.330597 574.993713,431.029724 576.500671,432.427856 
	C578.353333,434.146759 579.550354,437.203125 579.869629,439.822906 
	C580.744995,447.006042 583.865051,449.036530 590.531799,445.950409 
	C594.573547,444.079468 597.563416,444.484039 598.950317,449.623962 
	C591.983826,451.853333 585.008545,453.818665 577.971008,455.527252 
	C573.666504,456.572327 569.266296,457.223114 564.277344,457.793579 
	C562.769409,458.020630 561.893311,458.505127 561.017151,458.989624 
	C560.581665,459.007965 560.146240,459.026306 559.385315,459.055634 
	C556.792908,458.293518 554.525940,457.520355 552.259033,456.747192 
	C552.309143,456.306122 552.359314,455.865051 552.409485,455.423981 
	C555.055298,455.161163 557.701050,454.898346 560.346863,454.635529 
	C560.372620,454.245728 560.398315,453.855927 560.424072,453.466125 
	C555.562805,453.466125 550.701538,453.466125 545.581116,453.466125 
	C547.966003,445.940643 547.313110,446.311096 541.750183,442.438629 
	C537.604736,439.552917 534.906006,434.546387 531.680786,430.392883 
	C528.426758,426.202301 525.359680,425.598938 521.096924,428.364441 
	C521.763062,429.644836 522.420227,430.907898 523.077332,432.170990 
	C522.666565,432.493073 522.255737,432.815125 521.844971,433.137207 
	C520.755737,432.185791 519.666504,431.234375 518.267883,430.113251 
	C518.001221,420.622101 518.044006,411.300690 518.487061,401.511963 
	C520.024719,402.495667 521.162231,403.946655 522.070862,405.105804 
	C523.050903,403.529388 524.096375,401.847626 525.141907,400.165833 
	C526.848328,401.362823 528.689148,402.410767 530.225708,403.796143 
	C531.978210,405.376190 533.459534,407.256897 535.291321,409.261536 
	C536.637329,407.180145 537.622437,405.656891 538.964233,403.581970 
	C539.597473,404.905273 540.049133,405.444092 540.101074,406.018982 
	C540.767883,413.408905 541.497803,420.796753 541.940918,428.201385 
	C542.111145,431.046448 542.437073,433.237122 546.189575,433.208984 
	C549.234802,432.298004 552.677429,431.877136 552.823853,427.774597 
	C552.844727,427.828552 552.732849,427.798920 553.151733,427.873657 
	C554.699402,427.946014 555.828247,427.943634 557.265869,428.085938 
	C558.520508,428.407471 559.466492,428.584229 560.845398,428.841919 
	C560.033081,427.157654 559.510803,426.074768 558.981079,424.596161 
	C558.885315,423.680084 558.796997,423.159698 558.732056,422.704559 
	C558.755493,422.769775 558.634033,422.702972 559.020813,422.799072 
	C559.935730,422.936188 560.463867,422.977264 560.992004,423.018311 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M561.352844,458.991211 
	C561.893311,458.505127 562.769409,458.020630 563.845337,457.800415 
	C563.259583,458.374115 562.474060,458.683472 561.352844,458.991211 
z"/>
<path fill="#18A7F6" opacity="1.000000" stroke="none" 
	d="
M460.025391,222.339111 
	C459.896576,220.312576 460.448578,218.058899 459.568420,217.028030 
	C457.583588,214.703339 454.874847,213.002075 452.488525,211.013733 
	C447.904572,207.194199 448.250427,205.935654 454.952881,203.467087 
	C454.646790,204.445023 454.498016,205.164017 454.197693,205.812866 
	C453.884766,206.489120 453.425690,207.097763 452.999023,207.786850 
	C457.151154,209.472305 457.151154,209.472305 461.234070,204.223602 
	C462.874268,203.972031 463.915680,204.004333 464.971191,204.035446 
	C464.985291,204.034256 464.963898,204.047928 465.003784,204.370209 
	C465.697998,205.127975 466.352325,205.563446 467.006683,205.998932 
	C467.006683,205.998932 467.000000,206.000076 467.012604,206.320557 
	C467.484161,206.982346 467.943146,207.323624 468.402100,207.664917 
	C468.402100,207.664917 468.417755,207.799988 468.284302,208.050323 
	C468.104645,208.528198 468.058441,208.755707 468.015137,209.353027 
	C468.994141,210.483963 469.970184,211.245102 471.139771,212.251984 
	C471.922516,212.640930 472.511719,212.784103 473.055817,212.957718 
	C473.010742,212.988159 473.074066,212.899719 473.042236,213.274933 
	C473.026917,214.401627 473.043457,215.153137 473.141876,216.143280 
	C473.490570,216.574036 473.757385,216.766159 474.025208,216.976822 
	C474.026215,216.995361 474.044495,216.959534 474.148804,217.201172 
	C474.536987,217.643005 474.820831,217.843201 475.204742,218.242249 
	C475.488159,218.686401 475.723602,218.861710 476.008392,219.223907 
	C476.005829,219.651794 476.005981,219.822845 476.003235,219.996765 
	C476.000366,219.999649 475.991791,220.005859 475.991791,220.005859 
	C474.023010,220.901901 470.526184,221.632965 470.407867,222.728790 
	C470.171417,224.918900 471.983856,227.260101 472.570618,229.632797 
	C472.992737,231.339706 478.023529,233.116287 472.350067,235.005249 
	C471.549774,235.006714 471.121246,234.995392 470.372253,234.965790 
	C470.051758,234.947479 470.011658,234.471893 469.993896,234.233826 
	C468.406860,231.872513 466.837555,229.749268 465.267944,227.033813 
	C465.756104,225.525665 466.244568,224.609726 466.733032,223.693771 
	C466.379822,223.463287 466.026611,223.232803 465.673401,223.002319 
	C464.779633,224.002930 463.885834,225.003525 462.992035,226.004135 
	C462.013397,224.891769 461.034729,223.779404 460.025391,222.339111 
M464.296143,214.430405 
	C464.010101,214.703644 463.724060,214.976883 463.438019,215.250137 
	C463.551208,215.368591 463.664398,215.487061 463.777588,215.605515 
	C464.063568,215.332001 464.349548,215.058487 464.296143,214.430405 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M463.161011,226.248474 
	C463.885834,225.003525 464.779633,224.002930 465.673401,223.002319 
	C466.026611,223.232803 466.379822,223.463287 466.733032,223.693771 
	C466.244568,224.609726 465.756104,225.525665 465.130798,226.729706 
	C464.439240,226.842804 463.884613,226.667801 463.161011,226.248474 
z"/>
<path fill="#1020BA" opacity="1.000000" stroke="none" 
	d="
M668.984375,378.970795 
	C673.642822,380.160370 672.052124,384.907959 673.894714,387.730560 
	C675.087219,389.557281 675.576843,391.842804 676.231262,393.534607 
	C679.581909,394.666473 681.376221,388.757080 684.753296,392.887817 
	C683.678528,395.382294 682.298340,397.727417 680.833679,400.411438 
	C678.849182,402.236572 676.949158,403.722809 674.955933,405.536377 
	C674.233154,406.357147 673.603638,406.850555 672.845398,407.637695 
	C670.823853,409.364075 668.931091,410.796753 666.974854,412.567871 
	C665.288757,414.020203 663.666138,415.134094 661.984314,416.595581 
	C661.623840,417.061127 661.372742,417.246765 661.054138,417.704803 
	C659.983276,418.364502 659.029907,418.819427 658.002563,419.608887 
	C657.628967,420.061584 657.379517,420.247253 657.063477,420.704559 
	C655.833130,421.178101 654.719604,421.447510 653.158813,421.540283 
	C650.431152,419.881592 649.321167,413.644775 645.069641,419.443085 
	C644.988464,419.553741 641.446594,418.056030 641.249329,416.988922 
	C640.459717,412.717224 640.353577,408.319153 640.042236,403.628479 
	C647.001587,398.165344 652.676086,391.150970 661.885864,388.828430 
	C662.185303,387.651672 662.583069,386.088684 663.060669,384.212189 
	C666.091675,385.790710 667.206665,384.590973 667.108704,381.332367 
	C667.085632,380.564636 668.325500,379.758972 668.984375,378.970795 
z"/>
<path fill="#1029CA" opacity="1.000000" stroke="none" 
	d="
M639.994690,403.967834 
	C640.353577,408.319153 640.459717,412.717224 641.249329,416.988922 
	C641.446594,418.056030 644.988464,419.553741 645.069641,419.443085 
	C649.321167,413.644775 650.431152,419.881592 652.834229,421.664093 
	C646.998474,426.056885 641.040039,430.149231 635.007324,434.583801 
	C632.896118,435.288757 630.859192,435.651428 628.429932,436.021362 
	C624.968811,435.018890 621.900024,434.009094 618.415588,432.999634 
	C617.707947,429.733856 613.180298,427.277740 616.161316,423.385651 
	C613.913208,422.749268 611.665039,422.112885 609.431763,420.957397 
	C616.038086,417.217896 622.656250,414.049713 629.188293,410.712921 
	C630.029236,410.283386 630.423645,408.979767 631.369812,408.010559 
	C634.474731,406.615997 637.234680,405.291901 639.994690,403.967834 
M616.588135,420.517334 
	C616.588135,420.517334 616.476318,420.584106 616.588135,420.517334 
z"/>
<path fill="#326EF4" opacity="1.000000" stroke="none" 
	d="
M59.951775,648.120361 
	C62.174107,648.454407 64.391266,649.031555 66.619705,649.078979 
	C74.742722,649.252014 82.869507,649.248169 91.262901,649.533936 
	C87.978157,649.785278 84.425369,649.813660 80.407120,649.852783 
	C73.630020,649.863403 67.318398,649.863281 60.267487,649.746277 
	C58.707653,649.026794 57.887108,648.424133 57.066570,647.821472 
	C56.761059,630.507874 56.455547,613.194214 56.150406,595.450073 
	C56.635857,575.561279 57.120934,556.102966 58.171204,536.613159 
	C58.773376,571.203125 58.787220,605.824707 58.894592,640.446045 
	C58.902531,643.005188 59.583626,645.562317 59.951775,648.120361 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M57.042305,648.221436 
	C57.887108,648.424133 58.707653,649.026794 59.804375,649.761230 
	C59.509178,650.533203 58.937801,651.173279 58.366417,651.813354 
	C57.916950,650.749329 57.467495,649.685364 57.042305,648.221436 
z"/>
<path fill="#FCFDFE" opacity="1.000000" stroke="none" 
	d="
M60.422844,648.072083 
	C59.583626,645.562317 58.902531,643.005188 58.894592,640.446045 
	C58.787220,605.824707 58.773376,571.203125 58.433594,536.296631 
	C58.102787,535.833191 58.074776,535.654602 58.038445,535.204102 
	C75.965118,534.689331 94.030128,532.865173 111.676666,537.239746 
	C117.479759,538.678284 122.749023,542.270264 128.597031,544.972473 
	C139.771851,556.564270 142.652908,570.811584 142.934067,585.909241 
	C143.157242,597.893860 142.886414,609.810242 138.890198,621.359680 
	C133.932648,635.687378 123.625504,644.014709 109.102768,645.940063 
	C96.324333,647.634277 83.291451,647.428223 70.368111,647.996826 
	C67.215752,648.135559 64.052277,648.021606 60.422844,648.072083 
z"/>
<path fill="#1027C6" opacity="1.000000" stroke="none" 
	d="
M88.528725,532.041809 
	C86.110374,532.378235 83.228554,532.915405 80.337807,532.968750 
	C73.215492,533.100159 66.089081,533.009155 58.499458,533.011963 
	C58.765553,532.436401 59.503742,531.343994 60.226364,531.354187 
	C69.505936,531.485229 78.783279,531.773743 88.528725,532.041809 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M106.999481,665.998840 
	C106.650513,666.248047 106.302063,666.498352 105.953598,666.748657 
	C105.866180,666.624939 105.778770,666.501282 105.691368,666.377563 
	C105.967674,666.261047 106.243973,666.144531 106.760941,666.015259 
	C107.001610,666.002441 107.000000,666.000000 106.999481,665.998840 
z"/>
<path fill="#1029CA" opacity="1.000000" stroke="none" 
	d="
M97.989319,529.246582 
	C98.098076,529.410950 98.044678,529.576721 97.991295,529.742554 
	C97.936584,529.577698 97.881874,529.412842 97.989319,529.246582 
z"/>
<path fill="#1FAFF2" opacity="1.000000" stroke="none" 
	d="
M949.318970,573.458496 
	C950.377136,571.620605 951.243164,570.099548 952.146973,568.512085 
	C953.968506,571.175720 952.936035,574.302917 947.899353,579.642273 
	C948.490906,576.814758 948.808838,575.295044 949.318970,573.458496 
z"/>
<path fill="#1FAFF2" opacity="1.000000" stroke="none" 
	d="
M976.389893,668.456116 
	C975.006531,668.029663 973.879517,667.365540 972.752502,666.701477 
	C973.238098,666.146118 973.723633,665.590698 974.209167,665.035339 
	C975.021545,666.096375 975.833862,667.157471 976.389893,668.456116 
z"/>
<path fill="#1FAFF2" opacity="1.000000" stroke="none" 
	d="
M991.769470,662.281860 
	C989.938660,663.251221 987.854248,664.028259 985.769897,664.805359 
	C985.628418,664.296204 985.486877,663.787048 985.345398,663.277893 
	C987.402283,662.881775 989.459106,662.485657 991.769470,662.281860 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M1003.569336,562.617371 
	C1005.370972,562.651123 1006.922058,562.957703 1008.920410,563.352661 
	C1005.261108,565.566650 1005.195984,565.556519 1003.569336,562.617371 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M266.120300,648.738892 
	C266.848389,647.676025 268.050018,646.251282 268.357361,646.423401 
	C269.670654,647.158875 270.835388,648.276245 271.766327,649.477539 
	C271.948181,649.712219 270.506805,651.654358 270.393341,651.599976 
	C268.928528,650.898193 267.568726,649.977234 266.120300,648.738892 
z"/>
<path fill="#19168E" opacity="1.000000" stroke="none" 
	d="
M624.646851,261.140900 
	C625.026123,261.265961 625.073425,261.540253 625.208862,262.222595 
	C620.129517,265.169098 614.962036,267.707550 609.487793,270.396667 
	C610.145203,270.938171 610.935120,271.588928 611.909424,272.591431 
	C611.327087,272.979340 610.560425,273.015533 609.410950,273.063721 
	C609.716919,271.037964 609.804260,267.988525 611.208313,267.153748 
	C615.294678,264.724152 619.908081,263.180939 624.646851,261.140900 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M660.334412,240.955765 
	C659.992676,239.309219 659.992676,237.623108 659.992676,235.879669 
	C662.361145,235.300842 664.899292,234.680542 667.814331,234.102127 
	C665.686218,236.401428 663.181213,238.658813 660.334412,240.955765 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M669.525391,233.232208 
	C670.580017,231.849457 671.980225,230.444748 673.762085,229.072754 
	C672.719421,230.473740 671.295166,231.841980 669.525391,233.232208 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M674.372925,229.042557 
	C674.684204,228.248978 675.261597,227.353088 676.173950,226.676086 
	C675.885620,227.576721 675.262329,228.258484 674.372925,229.042557 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M657.299316,243.020630 
	C657.248657,242.818344 657.458862,242.534592 657.927368,242.167267 
	C657.977173,242.368851 657.768677,242.654022 657.299316,243.020630 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M668.388184,234.136383 
	C668.312073,233.901733 668.511475,233.592911 668.988403,233.215302 
	C669.065186,233.451752 668.864441,233.756989 668.388184,234.136383 
z"/>
<path fill="#20116F" opacity="1.000000" stroke="none" 
	d="
M658.360657,242.106705 
	C658.306641,241.875961 658.538940,241.567352 659.058777,241.180359 
	C659.113159,241.410934 658.880066,241.719879 658.360657,242.106705 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M603.108398,401.106506 
	C603.082764,400.445709 602.887207,399.735748 603.061707,399.132843 
	C603.735107,396.805206 606.119141,394.277649 600.862549,393.880768 
	C599.181030,393.753845 597.754395,390.309296 596.201477,388.390472 
	C595.609436,387.658997 594.990295,386.949402 594.383545,386.229828 
	C593.905090,386.398438 593.426697,386.567017 592.948242,386.735626 
	C594.019470,390.170227 595.090637,393.604797 596.140015,396.969421 
	C593.558289,397.771698 591.559753,398.392761 589.193909,399.127991 
	C587.728271,396.981323 585.907593,394.314545 584.034912,391.319580 
	C583.982849,390.991394 583.959717,390.987579 584.195862,390.853149 
	C584.285583,390.149658 584.139099,389.580597 583.986694,388.602295 
	C584.254028,386.316345 584.527283,384.439606 584.789734,382.387115 
	C584.778931,382.211334 585.036621,381.971222 585.349854,381.826904 
	C587.440308,378.066315 589.477295,374.548706 590.901428,370.798340 
	C591.815247,368.391724 591.826172,365.642242 592.208008,362.762146 
	C592.419128,361.990265 592.664001,361.502716 593.268494,360.959167 
	C594.462585,359.578888 595.622375,358.356445 596.074158,356.912445 
	C597.824768,351.316895 599.349976,345.650818 601.367126,340.014984 
	C604.125244,340.553467 606.475464,341.087952 609.132507,341.692200 
	C608.924866,345.205109 604.090820,351.119568 612.864075,349.651398 
	C613.124817,349.607758 613.581360,350.296539 613.834290,350.712219 
	C616.285706,354.740845 618.009338,352.723907 619.517517,349.959808 
	C620.071228,351.059052 620.567078,352.043579 621.062988,353.028107 
	C618.986450,360.814453 617.280823,368.725067 614.725464,376.350922 
	C612.335510,383.482941 609.032227,390.307983 606.185974,397.290588 
	C606.043945,397.638824 606.426147,398.200684 606.853394,398.858856 
	C606.958679,399.612457 606.774963,400.170258 605.957642,400.861633 
	C604.585571,401.032318 603.846985,401.069427 603.108398,401.106506 
z"/>
<path fill="#181B9C" opacity="1.000000" stroke="none" 
	d="
M640.042236,403.628479 
	C637.234680,405.291901 634.474731,406.615997 631.342896,407.670654 
	C630.946899,407.175842 630.922729,406.950439 630.898621,406.725067 
	C641.440796,398.062408 652.113342,389.551544 662.458923,380.660187 
	C666.432861,377.244812 669.661560,372.962341 673.544556,368.943604 
	C672.261780,371.966827 670.664307,375.121429 669.025635,378.623413 
	C668.325500,379.758972 667.085632,380.564636 667.108704,381.332367 
	C667.206665,384.590973 666.091675,385.790710 663.060669,384.212189 
	C662.583069,386.088684 662.185303,387.651672 661.885864,388.828430 
	C652.676086,391.150970 647.001587,398.165344 640.042236,403.628479 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M630.542114,406.794800 
	C630.922729,406.950439 630.946899,407.175842 630.997925,407.741150 
	C630.423645,408.979767 630.029236,410.283386 629.188293,410.712921 
	C622.656250,414.049713 616.038086,417.217896 609.223755,420.700897 
	C604.611389,422.313232 600.221863,423.662933 595.424927,424.789124 
	C595.017578,424.565582 595.039551,424.114502 595.039551,424.114502 
	C606.754944,418.364532 618.470276,412.614563 630.542114,406.794800 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M595.215637,420.699707 
	C596.090881,417.705322 597.268433,414.566620 598.971436,411.115570 
	C599.965027,410.510559 600.433044,410.217896 601.014771,410.027222 
	C601.128479,410.129242 601.352783,410.336792 601.352783,410.336792 
	C599.407837,413.743011 597.462891,417.149200 595.215637,420.699707 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M603.048828,401.458984 
	C603.846985,401.069427 604.585571,401.032318 605.695496,401.007355 
	C604.673645,403.981720 603.280396,406.943970 601.619995,410.121490 
	C601.352783,410.336792 601.128479,410.129242 601.043091,409.699860 
	C600.951660,408.831268 600.945679,408.392059 600.939758,407.952850 
	C601.622925,405.905701 602.306030,403.858582 603.048828,401.458984 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M679.674316,361.126221 
	C678.562073,363.611023 677.156311,366.241608 675.357605,368.555023 
	C674.934570,367.995819 674.904541,367.753815 674.874451,367.511841 
	C676.376587,365.431885 677.878723,363.351929 679.674316,361.126221 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M594.733032,423.990479 
	C595.039551,424.114502 595.017578,424.565582 595.012695,424.791504 
	C594.369019,424.804504 593.730286,424.591553 593.100952,424.069824 
	C593.549072,423.796204 593.987793,423.831329 594.733032,423.990479 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M593.487305,423.044098 
	C593.430420,422.530701 593.684326,421.890198 594.311035,421.195679 
	C594.388672,421.733429 594.093323,422.325195 593.487305,423.044098 
z"/>
<path fill="#181B9C" opacity="1.000000" stroke="none" 
	d="
M674.604004,367.648590 
	C674.904541,367.753815 674.934570,367.995819 675.017822,368.599243 
	C674.861206,368.957062 674.651367,368.953461 674.129639,368.904785 
	C673.989624,368.501526 674.161621,368.143433 674.604004,367.648590 
z"/>
<path fill="#1521AA" opacity="1.000000" stroke="none" 
	d="
M621.326843,352.778503 
	C620.567078,352.043579 620.071228,351.059052 619.517517,349.959808 
	C618.009338,352.723907 616.285706,354.740845 613.834290,350.712219 
	C613.581360,350.296539 613.124817,349.607758 612.864075,349.651398 
	C604.090820,351.119568 608.924866,345.205109 609.132507,341.692200 
	C606.475464,341.087952 604.125244,340.553467 601.368652,339.592529 
	C602.106079,332.855927 603.195129,326.535187 604.409851,320.238708 
	C605.521545,314.476044 606.769226,308.739563 607.956970,302.991547 
	C608.299988,304.524597 608.827515,306.043365 608.947632,307.593689 
	C609.195740,310.796600 608.918823,314.074860 609.512024,317.197876 
	C609.723328,318.310333 611.727966,319.636444 613.087524,319.874695 
	C617.544739,320.655670 622.081604,320.981598 626.818054,321.218750 
	C626.938538,324.797119 627.335510,328.738129 626.608704,332.459595 
	C625.288635,339.218231 623.300171,345.846313 621.326843,352.778503 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M607.960327,302.595703 
	C606.769226,308.739563 605.521545,314.476044 604.409851,320.238708 
	C603.195129,326.535187 602.106079,332.855927 600.960693,339.588531 
	C599.349976,345.650818 597.824768,351.316895 596.074158,356.912445 
	C595.622375,358.356445 594.462585,359.578888 593.277710,360.556793 
	C597.046448,341.406464 601.137817,322.596283 605.311890,303.804474 
	C606.020447,300.614624 605.024048,298.852020 601.867493,297.907379 
	C590.758240,294.582855 579.791931,290.719055 568.566284,287.866760 
	C561.101196,285.969940 553.286865,285.447296 545.099670,284.230988 
	C545.099670,286.824799 545.099854,288.458435 545.099670,290.092102 
	C545.096924,311.234894 545.120667,332.377686 545.084045,353.520447 
	C545.048035,374.325165 544.950806,395.129761 544.605286,415.630371 
	C544.213074,400.222778 544.095947,385.119232 543.995056,369.545746 
	C544.001953,353.720978 543.992554,338.366150 543.983215,323.011353 
	C543.657959,312.416779 543.249939,301.823914 543.034180,291.227112 
	C542.869568,283.140930 543.534790,282.678040 551.363342,283.844543 
	C553.967346,284.232574 556.605530,284.391510 559.867249,284.818298 
	C560.679749,284.979126 560.853088,284.976807 561.026428,284.974548 
	C562.080933,284.988892 563.135437,285.003235 564.584595,285.015503 
	C565.422119,285.024994 565.865051,285.036560 566.814941,285.286621 
	C567.869873,285.679718 568.417847,285.834259 568.965820,285.988831 
	C580.411987,289.403229 591.846436,292.857697 603.312073,296.205505 
	C606.422302,297.113708 608.241943,298.780701 607.960327,302.595703 
z"/>
<path fill="#1223B4" opacity="1.000000" stroke="none" 
	d="
M568.944702,285.694092 
	C568.417847,285.834259 567.869873,285.679718 567.146912,285.292725 
	C567.622498,285.173370 568.273071,285.286377 568.944702,285.694092 
z"/>
<path fill="#FEFEFE" opacity="1.000000" stroke="none" 
	d="
M519.078369,91.982170 
	C517.381531,93.174751 515.507202,94.182968 514.030396,95.604210 
	C511.945374,97.610847 510.154266,99.922951 508.022614,102.349007 
	C508.290649,102.822685 508.990997,104.060356 509.691345,105.298019 
	C508.107269,105.865227 506.555450,106.559029 504.932190,106.972267 
	C501.918213,107.739540 496.992767,104.841042 496.735413,111.916290 
	C493.623840,113.684464 490.785706,114.769989 487.695129,115.966934 
	C487.081909,116.140854 486.721161,116.203369 485.940094,116.174255 
	C484.034424,116.749535 482.549072,117.416443 481.028992,118.054214 
	C480.994263,118.025070 481.066742,118.071770 480.660767,118.033005 
	C474.558685,118.997147 469.094604,120.501358 465.025818,125.026039 
	C465.017548,125.013733 465.031921,125.033348 464.650299,125.006126 
	C463.192535,125.680023 462.116394,126.381134 460.867615,127.278870 
	C460.376709,127.610725 460.058472,127.745964 459.362671,127.894653 
	C457.321014,128.940872 455.656982,129.973633 453.871063,131.113037 
	C453.749176,131.219681 453.433685,131.293060 453.047394,131.221115 
	C445.914917,134.675201 438.698944,137.602798 434.839539,145.253693 
	C434.068329,145.973923 433.459015,146.452835 432.496399,146.942017 
	C429.801605,149.345490 427.460114,151.738693 425.105225,154.133118 
	C425.091858,154.134338 425.124481,154.153671 424.711853,154.105988 
	C422.157990,153.742905 420.016754,153.427521 417.896912,153.055542 
	C417.918304,152.998932 417.909912,153.119537 417.840454,152.866837 
	C417.486816,152.428299 417.202667,152.242462 416.958618,152.032654 
	C416.998688,152.008698 416.937317,152.079193 416.876526,151.819626 
	C416.521912,151.382355 416.228088,151.204651 415.718689,150.932068 
	C415.234039,150.651596 415.049316,150.403229 414.971619,149.721313 
	C410.947205,145.290207 406.900177,141.229904 402.873596,137.162018 
	C402.893982,137.154434 402.868408,137.145752 402.883331,136.806320 
	C402.251465,135.992126 401.604706,135.517365 400.979309,135.025040 
	C401.000702,135.007477 400.954498,135.032898 400.981750,134.662933 
	C399.028107,132.516006 397.047180,130.739029 395.036682,128.980530 
	C395.007141,128.999008 395.035553,128.935349 395.033875,128.568832 
	C394.863312,127.574615 394.900970,126.705185 394.493317,126.356873 
	C390.690826,123.107582 392.437653,120.724892 395.319763,117.986931 
	C397.477844,115.936783 399.031128,113.250069 400.925293,110.920639 
	C400.996887,110.994469 400.843414,110.857704 401.186157,110.868530 
	C401.994385,110.223953 402.459839,109.568542 402.961365,108.956062 
	C402.997437,108.998985 402.914001,108.923935 403.249634,108.928574 
	C404.059296,108.281227 404.533264,107.629234 405.118042,106.869507 
	C405.228851,106.761765 405.526215,106.677406 405.901245,106.752846 
	C407.853455,105.226067 409.430603,103.623856 411.003265,102.010254 
	C410.998779,101.998871 411.021027,102.008926 411.389130,102.020332 
	C412.806793,100.963341 413.856384,99.894943 415.031799,98.633240 
	C415.366425,98.198517 415.626160,98.041389 416.326080,97.993073 
	C421.727753,96.263908 426.683258,94.432655 428.997650,89.000259 
	C429.000000,88.999390 429.002991,88.996193 429.351166,89.017502 
	C430.484802,88.396797 431.270294,87.754791 432.201965,87.005074 
	C432.348145,86.897362 432.698181,86.800667 433.121918,86.859497 
	C440.376343,86.846390 444.379211,81.886131 449.185028,77.769035 
	C449.920776,77.396309 450.464661,77.236755 451.222961,77.317856 
	C461.479858,74.241791 471.536102,70.965355 481.543213,67.545143 
	C482.527954,67.208588 483.211365,65.990440 484.351715,65.101685 
	C485.102936,64.998161 485.537109,64.974457 485.971252,64.950752 
	C486.354614,65.247871 486.792114,65.853088 487.113342,65.796654 
	C493.661133,64.646187 500.153931,63.047722 506.744110,62.310642 
	C513.745544,61.527561 520.835876,61.516869 527.891479,61.304890 
	C528.245056,61.294262 528.637451,62.575966 529.046753,63.661743 
	C529.081726,64.067551 529.083435,64.088142 528.695312,64.055069 
	C522.438232,65.007744 519.387512,69.258614 521.137268,74.458740 
	C521.066833,76.926865 520.989075,78.959007 520.648193,81.261620 
	C519.949463,85.015450 519.513916,88.498810 519.078369,91.982170 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M520.911255,80.991142 
	C520.989075,78.959007 521.066833,76.926865 521.371094,74.213318 
	C524.092834,70.383987 526.588135,67.236061 529.083435,64.088135 
	C529.083435,64.088142 529.081726,64.067551 529.088745,64.085197 
	C546.410950,62.175842 563.213379,65.386589 579.719849,69.918190 
	C589.598816,72.630318 599.086365,76.768349 608.921753,80.568085 
	C608.005249,80.912384 606.916870,80.954063 605.212158,80.734146 
	C603.734680,80.308136 602.873413,80.143730 602.012207,79.979324 
	C596.568298,77.749771 591.124329,75.520226 584.956116,73.098000 
	C581.141479,72.577827 578.051208,72.250328 574.960876,71.922836 
	C574.546387,71.930382 574.131958,71.937927 573.408447,71.948227 
	C570.100220,70.631081 567.181458,69.060272 564.078247,68.064308 
	C561.180603,67.134346 558.110229,66.537537 555.074951,66.284126 
	C548.677124,65.749985 542.180908,64.768745 535.854492,65.358948 
	C524.397888,66.427734 523.574463,72.293083 523.113708,80.570961 
	C522.804199,86.131638 523.565979,91.751953 523.440491,97.554245 
	C522.325439,92.172325 521.618347,86.581734 520.911255,80.991142 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M486.232422,64.682556 
	C485.537109,64.974457 485.102936,64.998161 483.962982,65.039536 
	C479.614288,66.093231 475.866791,66.859360 472.350403,68.221840 
	C465.170624,71.003746 458.115204,74.106575 451.008545,77.077209 
	C450.464661,77.236755 449.920776,77.396309 448.818268,77.780060 
	C446.202515,78.801842 444.145416,79.599419 442.088318,80.397003 
	C443.115265,79.269493 443.934814,77.745148 445.204285,77.081665 
	C451.419983,73.832985 457.642914,70.546288 464.096405,67.826164 
	C467.315430,66.469376 471.033844,66.297440 474.773560,65.323029 
	C475.875793,64.888947 476.730835,64.721390 477.796753,64.300842 
	C480.119537,63.477463 482.231445,62.907066 485.061310,62.185963 
	C487.520325,61.689796 489.261383,61.344334 491.002441,60.998878 
	C491.445038,60.999355 491.887634,60.999832 492.661865,60.987278 
	C502.027740,59.978954 511.061981,58.983662 520.550781,58.254566 
	C521.001709,58.697609 520.998047,58.874451 520.994324,59.051292 
	C509.494080,60.838978 497.993835,62.626663 486.232422,64.682556 
z"/>
<path fill="#1FAFF2" opacity="1.000000" stroke="none" 
	d="
M521.460449,59.105141 
	C520.998047,58.874451 521.001709,58.697609 521.008789,58.255348 
	C523.705505,57.980148 526.398743,57.970375 529.919128,57.966766 
	C531.502747,57.956554 532.259277,57.940178 533.015808,57.923805 
	C535.708252,57.942501 538.400757,57.961201 541.541504,57.972588 
	C553.429260,59.216129 564.870056,60.455307 576.300171,61.786732 
	C576.578796,61.819191 576.737854,62.877361 576.904785,63.813622 
	C564.359680,62.635254 551.880310,60.926430 539.357300,59.643112 
	C533.591064,59.052216 527.739502,59.294502 521.460449,59.105141 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M616.000000,89.999550 
	C614.618164,90.943069 613.248901,91.906281 611.850403,92.824928 
	C610.719299,93.567963 609.616943,94.434387 608.371216,94.885223 
	C607.857605,95.071091 607.002380,94.312836 606.302490,93.983994 
	C606.935669,92.875473 607.496033,91.716766 608.217773,90.669273 
	C609.574951,88.699501 611.025452,86.793953 612.236206,85.136696 
	C611.205566,83.974052 610.158569,82.793022 609.091248,81.265854 
	C611.599915,82.039360 614.128967,83.159004 616.838989,84.842857 
	C617.011719,85.602173 617.003418,85.797287 616.676453,86.040100 
	C616.238525,87.392212 616.119263,88.696625 616.000000,90.001038 
	C616.000000,90.001045 616.000000,90.000000 616.000000,89.999550 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M616.995117,85.992401 
	C617.003418,85.797287 617.011719,85.602173 617.045898,85.114944 
	C618.301392,85.317856 619.531006,85.812897 620.913574,86.607323 
	C620.304443,86.935219 619.542358,86.963722 618.284424,86.771751 
	C617.583740,86.280304 617.319275,86.094017 616.995117,85.992401 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M627.416687,90.534973 
	C626.749268,90.470589 625.939514,90.099365 625.081421,89.365051 
	C625.780212,89.410690 626.527283,89.819412 627.416687,90.534973 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M623.023987,87.661194 
	C622.592346,87.772736 621.980469,87.618271 621.211670,87.178802 
	C621.651123,87.060921 622.247437,87.228058 623.023987,87.661194 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M624.918091,88.749634 
	C624.700684,88.927361 624.454224,88.799973 624.136536,88.410248 
	C624.360046,88.152870 624.634644,88.251740 624.918091,88.749634 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M616.676453,86.040100 
	C617.319275,86.094017 617.583740,86.280304 617.902954,86.768661 
	C617.574768,87.954689 617.132263,88.923340 616.344849,89.946518 
	C616.119263,88.696625 616.238525,87.392212 616.676453,86.040100 
z"/>
<path fill="#2096F5" opacity="1.000000" stroke="none" 
	d="
M640.467651,115.451309 
	C640.522339,115.418945 640.413025,115.483673 640.467651,115.451309 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M489.162048,160.164612 
	C490.831543,158.824280 492.331665,156.668015 494.198883,156.280472 
	C503.887878,154.269501 513.715515,152.928848 523.409058,150.936798 
	C535.155090,148.522934 538.409241,146.562363 539.065552,134.347824 
	C539.468140,126.854057 537.975891,119.979927 534.015503,113.246918 
	C534.193420,112.833679 534.362976,112.804703 534.902466,113.080505 
	C536.169067,114.279602 537.065613,115.173927 537.962158,116.068237 
	C538.014648,116.369354 538.145996,116.630898 538.574402,117.395386 
	C539.549072,121.626534 540.305481,125.315170 541.061890,129.003799 
	C541.049927,130.393845 541.037964,131.783875 540.983643,133.999100 
	C539.523560,139.484299 538.105835,144.144318 536.688171,148.804337 
	C536.212219,149.038559 535.736328,149.272781 534.746216,149.791779 
	C531.697388,150.785126 529.199463,151.697281 526.620911,152.163544 
	C519.343994,153.479446 512.033936,154.612305 504.736908,155.817307 
	C504.736908,155.817307 504.355560,155.815125 503.783752,155.838867 
	C500.378876,156.841965 497.545837,157.821320 494.712799,158.800659 
	C493.971191,159.072250 493.229614,159.343842 491.883545,159.763336 
	C490.573395,159.995667 489.867737,160.080139 489.162048,160.164612 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M523.663574,203.028839 
	C533.875916,202.772079 543.744995,202.759491 553.223145,207.460571 
	C548.260437,206.818832 543.043091,205.894577 536.940369,204.967743 
	C527.134460,204.731415 518.243652,204.690567 510.016052,208.966202 
	C508.367340,209.740372 506.718658,210.514542 504.985016,211.629013 
	C501.146332,213.633133 497.392578,215.296967 493.317810,216.642151 
	C493.134583,215.972809 493.272400,215.622101 493.410217,215.271393 
	C493.410217,215.271393 493.730133,215.214996 494.131592,215.077545 
	C494.762817,214.671310 494.992584,214.402542 495.222382,214.133789 
	C500.963623,211.292847 506.704865,208.451889 513.089233,205.383057 
	C514.593140,204.845032 515.453918,204.534897 516.314636,204.224747 
	C516.314636,204.224747 516.671509,204.248734 517.217651,204.216064 
	C519.189758,203.836121 520.615601,203.488846 522.041504,203.141541 
	C522.476074,203.128418 522.910583,203.115295 523.663574,203.028839 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M549.891479,200.798492 
	C545.499878,200.711136 540.776306,200.851059 536.402710,199.681839 
	C532.291687,198.582809 528.550171,196.101624 524.331970,194.090836 
	C523.945618,193.647903 523.795837,193.387238 523.341309,192.676300 
	C522.431030,190.424622 521.747925,188.667755 521.064819,186.910889 
	C521.035889,186.493500 521.006958,186.076111 520.985168,184.886292 
	C521.007202,178.742233 521.022156,173.370636 521.037170,167.999039 
	C521.037170,167.999039 521.148499,167.608688 521.382996,167.149078 
	C522.923706,164.655701 524.229980,162.621918 525.536194,160.588135 
	C524.675598,167.609177 523.325134,174.612411 523.069092,181.655426 
	C522.683228,192.265594 528.585754,197.672440 539.319763,198.138428 
	C542.810852,198.289993 546.249634,199.647949 549.891479,200.798492 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M486.360413,116.265884 
	C486.721161,116.203369 487.081909,116.140854 488.090942,116.015221 
	C495.822052,114.268219 502.904846,112.584335 509.987671,110.900452 
	C510.723419,110.872475 511.459137,110.844482 512.971680,110.811531 
	C520.126038,110.961372 526.503540,111.116180 532.881104,111.270996 
	C533.062683,111.581367 533.244324,111.891739 533.515991,112.456467 
	C525.585999,112.937386 517.565857,113.163956 509.262726,113.664597 
	C499.719116,116.092323 490.460114,118.253113 481.195343,120.388824 
	C480.244049,120.608124 479.258118,120.677299 478.117645,120.488152 
	C478.986908,119.464035 480.026825,118.767899 481.066742,118.071762 
	C481.066742,118.071770 480.994263,118.025070 481.386749,118.059341 
	C483.306274,117.484375 484.833344,116.875130 486.360413,116.265884 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M640.992981,151.879959 
	C644.528809,152.168106 647.693909,155.866455 646.739075,160.368668 
	C646.151123,163.141052 643.445129,165.771774 641.072754,167.710815 
	C640.086914,168.516602 637.491821,167.353592 635.438965,166.729401 
	C636.685974,164.095734 638.122620,161.818527 639.559265,159.541306 
	C639.559265,159.541306 639.778564,159.624771 640.019104,159.597839 
	C640.445190,158.937119 640.630737,158.303314 640.816284,157.669510 
	C640.892212,157.327438 640.968140,156.985352 641.227844,156.269058 
	C641.466919,155.769440 641.602905,155.597900 641.562439,155.527145 
	C641.436401,155.306427 641.243591,155.123886 641.076660,154.926529 
	C641.076660,154.926529 641.004089,155.000763 641.096680,154.653534 
	C641.116821,153.885071 641.044373,153.463852 640.987915,152.752136 
	C641.000244,152.267776 640.996582,152.073868 640.992981,151.879959 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M464.957062,204.036621 
	C463.915680,204.004333 462.874268,203.972031 461.444580,203.917084 
	C457.773407,199.826965 454.710724,195.551208 451.129425,191.766129 
	C448.785706,189.289062 448.673523,187.108994 450.795227,184.954803 
	C453.184204,182.529312 455.978882,180.503387 458.906830,178.606003 
	C459.119904,179.584625 459.023254,180.260895 458.926575,180.937164 
	C458.926575,180.937164 458.995758,181.001389 458.652008,180.998199 
	C457.823151,181.617111 457.338074,182.239227 456.852966,182.861328 
	C456.852966,182.861328 457.007965,182.980148 456.587708,182.965332 
	C450.405945,187.506729 450.380005,188.448288 455.884979,193.207993 
	C455.984985,193.490433 456.154022,193.722626 456.667603,194.342041 
	C459.614441,197.865234 462.285767,200.950928 464.957062,204.036621 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M552.530090,105.016724 
	C558.192505,106.494980 563.386841,107.974312 568.361023,109.778519 
	C562.078430,109.090538 556.015869,108.077682 549.953369,107.064827 
	C549.532227,106.998299 549.111084,106.931778 548.033264,106.633270 
	C540.600952,105.582916 533.825378,104.764549 527.049744,103.946182 
	C527.049744,103.946182 527.002441,103.984444 526.937988,103.728729 
	C526.576782,103.322639 526.280090,103.172256 525.983398,103.021866 
	C525.963074,102.851799 525.942688,102.681725 525.967285,102.258743 
	C527.789246,102.391945 529.554871,102.845886 531.345886,103.150139 
	C535.135132,103.793861 538.920654,104.536118 542.739929,104.901367 
	C545.822815,105.196198 548.952820,104.997536 552.530090,105.016724 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M505.202179,155.889679 
	C512.033936,154.612305 519.343994,153.479446 526.620911,152.163544 
	C529.199463,151.697281 531.697388,150.785126 534.610107,150.023926 
	C533.295959,151.254684 531.721924,153.424805 529.891479,153.668274 
	C521.857239,154.737000 513.748901,155.248520 505.202179,155.889679 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M527.084839,104.319077 
	C533.825378,104.764549 540.600952,105.582916 547.713013,106.642136 
	C540.799805,108.761536 533.715515,109.061096 527.084839,104.319077 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M509.722198,110.638809 
	C502.904846,112.584335 495.822052,114.268219 488.343384,115.903809 
	C490.785706,114.769989 493.623840,113.684464 496.739471,112.332962 
	C501.163544,111.503716 505.310150,110.940437 509.722198,110.638809 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M555.828125,158.701111 
	C550.614990,158.365891 545.220886,157.690323 539.391174,157.078415 
	C537.583740,157.082657 536.211853,157.023239 534.165222,156.968506 
	C533.319824,157.028656 533.149170,157.084076 532.978577,157.139496 
	C532.796997,157.086136 532.615479,157.032761 532.187500,156.838120 
	C532.333618,156.211380 532.784363,155.266602 533.110229,155.307877 
	C540.630737,156.260651 548.137634,157.320663 555.828125,158.701111 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M550.048584,107.431221 
	C556.015869,108.077682 562.078430,109.090538 568.548767,110.080818 
	C568.956604,110.058243 568.966125,110.020348 568.988647,110.258545 
	C569.011169,110.496750 569.103821,110.952187 569.103821,110.952187 
	C562.783813,109.900658 556.463745,108.849136 550.048584,107.431221 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M606.431152,125.397003 
	C612.881836,126.223389 617.401794,130.192276 621.250000,134.996643 
	C620.295227,135.165833 619.676208,135.046326 619.057190,134.926819 
	C619.057190,134.926819 618.992859,134.983566 618.893188,134.654694 
	C616.900635,133.145584 615.007751,131.965347 613.114807,130.785095 
	C612.337402,130.195801 611.560059,129.606506 610.352051,128.736267 
	C609.602783,128.326004 609.283997,128.196686 608.965271,128.067352 
	C608.965271,128.067352 608.994812,128.000732 608.842285,127.741287 
	C607.936890,126.786896 607.184021,126.091949 606.431152,125.397003 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M459.280426,180.938385 
	C459.023254,180.260895 459.119904,179.584625 459.253296,178.569992 
	C460.785553,177.107864 462.281158,175.984116 464.325928,174.551025 
	C466.901001,173.143097 468.926880,172.044495 471.022156,171.025116 
	C471.091522,171.104324 471.224915,171.267303 471.224915,171.267303 
	C470.766602,172.302872 470.593414,173.736450 469.800262,174.304535 
	C466.513580,176.658615 463.039520,178.751083 459.280426,180.938385 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M520.648193,81.261620 
	C521.618347,86.581734 522.325439,92.172325 523.318237,97.985687 
	C523.603882,98.208466 523.684814,98.206841 523.706055,98.589508 
	C523.815186,99.496658 523.903076,100.021133 523.990967,100.545609 
	C522.526123,97.861305 521.061218,95.177002 519.337402,92.237442 
	C519.513916,88.498810 519.949463,85.015450 520.648193,81.261620 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M532.734497,110.924065 
	C526.503540,111.116180 520.126038,110.961372 513.326477,110.711014 
	C519.459351,107.380013 526.019775,107.004562 532.734497,110.924065 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M569.324097,110.975853 
	C569.103821,110.952187 569.011169,110.496750 568.975159,110.267288 
	C574.704529,109.709595 580.114197,110.744080 584.933716,114.621109 
	C584.976257,115.040260 584.836609,115.214890 584.462097,115.180099 
	C583.092102,115.006645 582.096680,114.867966 581.101196,114.729279 
	C580.628174,114.479843 580.155212,114.230415 579.283020,113.747070 
	C578.599304,113.293793 578.293274,113.257919 577.965698,113.405556 
	C577.539490,113.269730 577.113220,113.133904 576.105530,112.709030 
	C573.670288,111.994934 571.816589,111.569893 569.962830,111.144852 
	C569.962830,111.144852 569.544312,110.999519 569.324097,110.975853 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M493.029541,215.208023 
	C493.272400,215.622101 493.134583,215.972809 492.940735,216.600250 
	C492.884735,216.876984 492.906067,216.904114 492.594849,216.939377 
	C491.720062,217.587296 491.156464,218.199966 490.592865,218.812622 
	C490.592865,218.812607 490.288849,218.837738 489.895264,218.981018 
	C489.322815,219.431091 489.144012,219.737885 488.965210,220.044678 
	C488.965179,220.044678 488.780426,220.198257 488.450195,220.440109 
	C487.568359,221.353531 487.016754,222.025101 486.465149,222.696671 
	C485.564453,222.751389 484.663757,222.806122 483.314514,222.588547 
	C482.621277,221.862259 482.376617,221.408279 482.131927,220.954285 
	C483.937012,219.972794 485.747101,219.000381 487.545685,218.007172 
	C489.253021,217.064346 490.948273,216.099701 493.029541,215.208023 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M464.971191,204.035431 
	C462.285767,200.950928 459.614441,197.865234 456.857697,194.448273 
	C461.612030,195.415558 464.243164,198.931595 465.743011,203.697693 
	C465.306061,204.068512 465.134979,204.058228 464.963898,204.047928 
	C464.963898,204.047928 464.985291,204.034256 464.971191,204.035431 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M585.047241,114.953903 
	C585.833130,114.907364 586.548035,114.947189 587.621704,114.985336 
	C591.306152,116.759102 594.631836,118.534546 597.634888,120.655838 
	C595.940491,120.596642 594.568787,120.191605 593.197083,119.786575 
	C593.197083,119.786568 593.196716,119.914375 593.014648,119.557495 
	C591.579163,118.767761 590.325745,118.334915 589.072327,117.902077 
	C589.072327,117.902077 588.988159,117.951447 588.922974,117.723892 
	C588.598999,117.245407 588.296997,117.171875 587.951843,117.275749 
	C587.951843,117.275749 587.553711,117.049103 587.165527,116.755257 
	C586.130432,116.045906 585.483521,115.630394 584.836609,115.214890 
	C584.836609,115.214890 584.976257,115.040260 585.047241,114.953903 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M520.743286,168.281067 
	C521.022156,173.370636 521.007202,178.742233 520.916748,184.579742 
	C520.245483,183.399994 519.107971,181.736237 519.162231,180.112274 
	C519.291199,176.252518 519.984253,172.411621 520.743286,168.281067 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M525.583923,160.594940 
	C524.229980,162.621918 522.923706,164.655701 521.377319,166.946198 
	C520.883911,160.418213 522.541321,158.051041 528.503479,157.275421 
	C527.715637,158.590363 526.720215,159.608627 525.724731,160.626907 
	C525.724731,160.626907 525.631653,160.601730 525.583923,160.594940 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M537.073975,148.836273 
	C538.105835,144.144318 539.523560,139.484299 541.042419,134.412430 
	C543.016479,140.124329 541.565735,145.979416 537.073975,148.836273 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M455.922150,192.827484 
	C450.380005,188.448288 450.405945,187.506729 456.513580,182.900604 
	C456.100983,184.425598 455.342194,186.000534 454.400146,187.955917 
	C454.815399,189.152008 455.387360,190.799484 455.922150,192.827484 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M481.192383,165.226105 
	C479.784058,169.807709 475.571472,169.989822 471.591522,171.189697 
	C471.224915,171.267303 471.091522,171.104324 471.230225,170.763702 
	C474.537537,168.560730 477.706177,166.698364 480.954224,164.933533 
	C481.033630,165.031052 481.192383,165.226105 481.192383,165.226105 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M481.891998,220.851074 
	C482.376617,221.408279 482.621277,221.862259 482.976532,222.554688 
	C482.614807,223.155869 482.142517,223.518616 481.211182,223.605408 
	C479.511841,222.216141 478.271515,221.102829 476.923462,219.763626 
	C476.547546,219.347504 476.279358,219.157288 476.011139,218.967056 
	C475.723602,218.861710 475.488159,218.686401 475.402802,218.120728 
	C475.747955,217.591843 475.907745,217.330002 476.130066,216.885895 
	C476.279999,216.757004 476.562408,216.480209 476.855652,216.604004 
	C478.453186,217.787430 479.757477,218.847046 481.061798,219.906647 
	C481.258545,220.187057 481.455292,220.467468 481.891998,220.851074 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M593.186035,120.148254 
	C594.568787,120.191605 595.940491,120.596642 597.677856,121.019814 
	C598.043518,121.037949 598.306885,121.188087 598.629517,121.438721 
	C599.655518,122.333557 600.358948,122.977768 601.062439,123.621964 
	C598.433289,122.584618 595.804077,121.547272 593.186035,120.148254 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M541.332764,128.731888 
	C540.305481,125.315170 539.549072,121.626534 538.749390,117.531982 
	C539.671997,120.904037 540.637817,124.682007 541.332764,128.731888 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M481.057068,219.549072 
	C479.757477,218.847046 478.453186,217.787430 476.736847,216.322647 
	C475.795837,215.033310 475.266846,214.149124 474.843567,213.159058 
	C474.949249,213.053177 475.161896,212.842743 475.161896,212.842743 
	C477.125366,214.959000 479.088837,217.075241 481.057068,219.549072 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M639.285889,159.565094 
	C638.122620,161.818527 636.685974,164.095734 635.092041,166.667877 
	C634.534790,166.992722 634.134766,167.022629 633.439087,167.102310 
	C633.143494,167.152069 632.928101,166.931839 632.899780,166.548828 
	C632.945496,165.793182 633.019531,165.420547 633.093628,165.047913 
	C634.253601,163.843903 635.413635,162.639877 637.031982,161.140900 
	C637.656006,160.561508 637.821594,160.277069 637.987183,159.992615 
	C638.328979,159.858017 638.670715,159.723450 639.285889,159.565094 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M495.138062,158.874695 
	C497.545837,157.821320 500.378876,156.841965 503.605042,155.905365 
	C501.186554,156.948318 498.374939,157.948517 495.138062,158.874695 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M632.949829,145.041931 
	C634.981018,146.748505 637.012268,148.455078 638.776245,150.564789 
	C638.327454,150.995697 638.145813,151.023468 637.964233,151.051239 
	C637.139160,150.141922 636.314087,149.232605 635.133545,147.885513 
	C634.168640,146.645813 633.559265,145.843872 632.949829,145.041931 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M632.966370,145.034103 
	C633.559265,145.843872 634.168640,146.645813 634.858704,147.743469 
	C632.966431,146.628174 630.993469,145.217163 629.027161,143.379425 
	C629.033752,142.952682 629.206360,142.767746 629.500122,142.882080 
	C630.851929,143.680756 631.909973,144.365097 632.968079,145.049438 
	C632.968079,145.049454 632.982910,145.026276 632.966370,145.034103 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M601.369751,123.737503 
	C600.358948,122.977768 599.655518,122.333557 598.780884,121.444946 
	C601.052734,122.275986 603.495789,123.351425 605.639771,124.767708 
	C604.516479,124.871498 603.692200,124.634445 602.867981,124.397400 
	C602.471008,124.215950 602.074097,124.034485 601.369751,123.737503 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M487.209015,162.240891 
	C486.936859,165.898483 484.033386,164.726913 481.580872,165.160980 
	C481.192383,165.226105 481.033630,165.031052 481.176788,164.683319 
	C483.165680,163.508865 485.011414,162.682144 486.946655,161.950378 
	C487.036194,162.045319 487.209015,162.240891 487.209015,162.240891 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M602.954285,124.721138 
	C603.692200,124.634445 604.516479,124.871498 605.666565,125.146416 
	C605.992432,125.184280 606.201721,125.316360 606.316406,125.356682 
	C607.184021,126.091949 607.936890,126.786896 608.814941,127.765411 
	C606.973633,127.047623 605.007080,126.046249 602.954285,124.721138 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M613.118652,131.147003 
	C615.007751,131.965347 616.900635,133.145584 618.928711,134.632767 
	C617.083435,133.796097 615.103027,132.652496 613.118652,131.147003 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M627.865479,142.152649 
	C626.249146,141.053024 624.632874,139.953415 623.016968,138.413910 
	C623.017456,137.974030 623.203247,137.757507 623.507935,137.865250 
	C625.273376,139.248032 626.734131,140.523087 628.111877,141.886169 
	C628.028931,141.974228 627.865479,142.152634 627.865479,142.152649 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M570.137390,111.429306 
	C571.816589,111.569893 573.670288,111.994934 575.772217,112.669189 
	C574.117615,112.516861 572.214783,112.115318 570.137390,111.429306 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M619.044617,135.307068 
	C619.676208,135.046326 620.295227,135.165833 621.223267,135.340240 
	C621.532349,135.395172 621.620300,135.373978 621.693970,135.645935 
	C622.246155,136.531113 622.724731,137.144318 623.203247,137.757507 
	C623.203247,137.757507 623.017456,137.974030 622.923462,138.084015 
	C621.563599,137.358429 620.297852,136.522873 619.044617,135.307068 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M537.990173,115.696350 
	C537.065613,115.173927 536.169067,114.279602 535.160400,113.083405 
	C538.156860,111.154839 537.885376,113.475769 537.990173,115.696350 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M526.067871,160.725433 
	C526.720215,159.608627 527.715637,158.590363 528.892334,157.304184 
	C529.243408,157.055603 529.413269,157.074921 529.807922,157.217422 
	C528.825500,158.501724 527.618286,159.662827 526.067871,160.725433 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M637.966370,151.413879 
	C638.145813,151.023468 638.327454,150.995697 638.782104,150.929108 
	C639.055237,150.890274 639.035278,150.961731 639.135864,151.191589 
	C639.414001,151.724609 639.677063,151.898911 640.025513,151.944351 
	C640.025513,151.944351 639.999084,151.997574 640.074341,152.232574 
	C640.423767,152.659256 640.697876,152.850937 640.971985,153.042618 
	C641.044373,153.463852 641.116821,153.885071 641.138672,154.622803 
	C640.048218,153.885040 639.008362,152.830780 637.966370,151.413879 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M489.149963,160.150909 
	C489.867737,160.080139 490.573395,159.995667 491.635040,159.929245 
	C490.644714,160.665253 489.298431,161.383209 487.580597,162.171036 
	C487.209015,162.240891 487.036194,162.045319 486.998047,161.745926 
	C486.959869,161.446533 487.051086,161.034149 487.051086,161.034149 
	C487.051086,161.034149 487.034637,160.998932 487.333496,161.029236 
	C488.125275,160.744736 488.618225,160.429947 489.111176,160.115173 
	C489.111176,160.115173 489.137848,160.137207 489.149963,160.150909 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M521.024536,187.330872 
	C521.747925,188.667755 522.431030,190.424622 523.156433,192.542557 
	C522.460571,191.186035 521.722351,189.468445 521.024536,187.330872 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M584.462097,115.180099 
	C585.483521,115.630394 586.130432,116.045906 586.942871,116.701447 
	C585.204468,116.432434 583.300476,115.923378 581.248901,115.071808 
	C582.096680,114.867966 583.092102,115.006645 584.462097,115.180099 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M521.847900,202.864410 
	C520.615601,203.488846 519.189758,203.836121 517.383118,204.148102 
	C518.553040,203.604309 520.103699,203.095779 521.847900,202.864410 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M589.112122,118.275589 
	C590.325745,118.334915 591.579163,118.767761 592.995117,119.496674 
	C591.822388,119.411530 590.487183,119.030319 589.112122,118.275589 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M457.193726,182.870972 
	C457.338074,182.239227 457.823151,181.617111 458.623108,180.961151 
	C458.470184,181.578384 458.002350,182.229492 457.193726,182.870972 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M473.171722,210.835327 
	C473.773193,211.313049 474.374664,211.790771 475.069031,212.555603 
	C475.161896,212.842743 474.949249,213.053177 474.570496,213.079071 
	C473.819183,213.036560 473.446625,212.968140 473.074066,212.899719 
	C473.074066,212.899719 473.010742,212.988159 473.037781,212.666107 
	C472.972534,211.965622 472.880188,211.587158 472.883575,211.115097 
	C472.979279,211.021484 473.171722,210.835327 473.171722,210.835327 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M470.957489,209.040665 
	C471.627533,209.438950 472.297577,209.837250 473.069672,210.535431 
	C473.171722,210.835327 472.979279,211.021484 472.613708,211.031036 
	C471.886871,210.932861 471.525604,210.825165 471.107910,210.525604 
	C471.051483,210.333740 470.790649,210.030563 470.841278,209.784592 
	C470.913788,209.372635 470.935638,209.206650 470.957489,209.040665 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M626.828979,171.144165 
	C627.110901,171.538254 627.043091,171.913681 626.912109,172.569458 
	C626.479370,172.942902 626.109924,173.035995 625.456726,173.156891 
	C625.608459,172.498306 626.043884,171.811890 626.828979,171.144165 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M524.222595,100.643890 
	C523.903076,100.021133 523.815186,99.496658 523.744019,98.575676 
	C524.467529,99.036926 525.174194,99.894684 525.822998,101.125557 
	C525.328186,101.246506 524.891174,100.994339 524.222595,100.643890 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M628.876953,170.528656 
	C628.445557,170.913315 628.084656,171.019867 627.443359,171.152466 
	C627.591858,170.489838 628.020752,169.801147 628.797607,169.119324 
	C629.079529,169.500977 629.013489,169.875778 628.876953,170.528656 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M632.758545,165.064896 
	C633.019531,165.420547 632.945496,165.793182 632.790771,166.440018 
	C632.341187,166.814667 631.972229,166.915115 631.316589,167.027954 
	C631.494385,166.387512 631.958923,165.734695 632.758545,165.064896 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M630.833374,168.483871 
	C630.409058,168.878937 630.048462,168.986191 629.407471,169.119202 
	C629.539856,168.453644 629.952637,167.762314 630.702881,167.050354 
	C630.992615,167.418503 630.944885,167.807281 630.833374,168.483871 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M515.940491,204.144836 
	C515.453918,204.534897 514.593140,204.845032 513.379883,205.164337 
	C513.873718,204.803970 514.720032,204.434448 515.940491,204.144836 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M465.003784,204.370209 
	C465.134979,204.058228 465.306061,204.068512 465.734070,204.066910 
	C466.292969,204.520782 466.594910,204.986557 466.951782,205.725632 
	C466.352325,205.563446 465.697998,205.127975 465.003784,204.370209 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M559.774719,159.781616 
	C559.083313,159.926651 558.179688,159.779129 557.012451,159.403290 
	C557.686768,159.279648 558.624634,159.384354 559.774719,159.781616 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M470.971497,209.031036 
	C470.935638,209.206650 470.913788,209.372635 470.569153,209.760025 
	C469.822418,209.666107 469.398438,209.350784 469.093353,208.917038 
	C469.212280,208.798615 469.451782,208.563431 469.451782,208.563431 
	C469.451782,208.563431 469.739380,208.478333 470.075317,208.682785 
	C470.596863,208.937805 470.782410,208.988373 470.967987,209.038940 
	C470.967987,209.038940 470.985535,209.021423 470.971497,209.031036 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M468.355469,207.374176 
	C467.943146,207.323624 467.484161,206.982346 467.013184,206.317169 
	C467.437073,206.356659 467.872955,206.720047 468.355469,207.374176 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M640.550049,157.826904 
	C640.630737,158.303314 640.445190,158.937119 639.901855,159.592926 
	C639.790710,159.071381 640.037292,158.527832 640.550049,157.826904 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M578.119141,113.572525 
	C578.293274,113.257919 578.599304,113.293793 579.013000,113.717522 
	C579.142151,113.921883 578.687195,113.926765 578.687195,113.926765 
	C578.687195,113.926765 578.272583,113.739494 578.119141,113.572525 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M637.757568,160.078888 
	C637.821594,160.277069 637.656006,160.561508 637.237793,160.903320 
	C637.063904,160.625809 637.244873,160.360611 637.757568,160.078888 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M526.076172,103.259750 
	C526.280090,103.172256 526.576782,103.322639 526.961060,103.714127 
	C526.755371,103.802696 526.462219,103.650162 526.076172,103.259750 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M588.085205,117.465286 
	C588.296997,117.171875 588.598999,117.245407 588.969971,117.711960 
	C588.752441,117.969040 588.464600,117.878120 588.085205,117.465286 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M609.092041,128.304413 
	C609.283997,128.196686 609.602783,128.326004 610.059082,128.680054 
	C609.870789,128.783707 609.544800,128.662598 609.092041,128.304413 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M628.945068,143.042847 
	C628.580444,142.977081 628.340698,142.777695 628.001221,142.343765 
	C627.865479,142.152634 628.028931,141.974228 628.322266,141.999725 
	C628.877747,142.220795 629.074707,142.468292 629.206360,142.767746 
	C629.206360,142.767746 629.033752,142.952682 628.945068,143.042847 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M641.028687,155.223541 
	C641.243591,155.123886 641.436401,155.306427 641.562439,155.527145 
	C641.602905,155.597900 641.466919,155.769440 641.237122,156.005768 
	C641.035278,155.917969 641.008057,155.719254 641.028687,155.223541 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M619.725586,179.062119 
	C619.790894,179.256073 619.627197,179.539032 619.218994,179.889984 
	C619.035034,179.615524 619.209106,179.346573 619.725586,179.062119 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M618.727356,180.065750 
	C618.796692,180.261566 618.635681,180.548355 618.230408,180.906921 
	C618.037842,180.631012 618.208191,180.357010 618.727356,180.065750 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M617.742493,181.076233 
	C617.812561,181.268677 617.646606,181.550980 617.243164,181.923370 
	C617.172668,181.731018 617.339539,181.448578 617.742493,181.076233 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M468.012207,208.983231 
	C468.058441,208.755707 468.104645,208.528198 468.312378,207.988403 
	C468.810242,207.701950 469.067902,207.857864 469.349365,208.353668 
	C469.451782,208.563431 469.212280,208.798615 468.853058,208.901276 
	C468.493835,209.003922 468.012207,208.983231 468.012207,208.983231 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M470.943176,208.859741 
	C470.782410,208.988373 470.596863,208.937805 470.224457,208.666794 
	C470.331207,208.524414 470.624817,208.602478 470.943176,208.859741 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M494.949768,214.170197 
	C494.992584,214.402542 494.762817,214.671310 494.265076,214.985016 
	C494.223755,214.755508 494.450439,214.481064 494.949768,214.170197 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M533.082764,157.290726 
	C533.149170,157.084076 533.319824,157.028656 533.755371,157.021820 
	C533.742432,157.194275 533.464661,157.318115 533.082764,157.290726 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M629.500122,142.882080 
	C629.074707,142.468292 628.877747,142.220795 628.405151,141.911682 
	C626.734131,140.523087 625.273376,139.248032 623.507935,137.865250 
	C622.724731,137.144318 622.246155,136.531113 621.736572,135.630646 
	C626.419006,137.132324 631.355896,138.639938 632.978821,144.677856 
	C631.909973,144.365097 630.851929,143.680756 629.500122,142.882080 
z"/>
<path fill="#2192F6" opacity="1.000000" stroke="none" 
	d="
M646.453308,139.561127 
	C646.419006,140.393417 646.154419,140.992447 645.889832,141.591476 
	C645.398621,141.094620 644.907410,140.597763 644.416199,140.100906 
	C645.018494,139.843216 645.620789,139.585541 646.453308,139.561127 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M639.942078,151.722672 
	C639.677063,151.898911 639.414001,151.724609 639.172363,151.186203 
	C639.427734,151.039703 639.677856,151.223053 639.942078,151.722672 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M640.987915,152.752136 
	C640.697876,152.850937 640.423767,152.659256 640.099609,152.216797 
	C640.049500,151.966003 640.521179,151.923035 640.757080,151.901489 
	C640.996582,152.073868 641.000244,152.267776 640.987915,152.752136 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M510.067902,209.323425 
	C518.243652,204.690567 527.134460,204.731415 536.520752,204.974564 
	C533.627808,205.498947 530.214600,205.788483 526.923035,206.582092 
	C522.071167,207.751923 517.321106,209.339905 512.485962,210.588196 
	C511.819702,210.760208 510.914398,210.006027 510.067902,209.323425 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M512.999268,219.999298 
	C513.521545,216.025742 515.814636,217.419922 518.382690,218.472824 
	C516.601135,219.025955 515.158569,219.473831 513.358948,219.961548 
	C513.001892,220.001373 513.000000,220.000000 512.999268,219.999298 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M554.115295,208.116272 
	C554.561646,207.998337 555.162476,208.170029 555.919373,208.617188 
	C555.473572,208.730423 554.871643,208.568146 554.115295,208.116272 
z"/>
<path fill="#18A7F6" opacity="1.000000" stroke="none" 
	d="
M477.031189,219.989532 
	C478.271515,221.102829 479.511841,222.216141 480.896851,223.624344 
	C481.041504,226.145615 481.041504,228.371979 481.041504,230.784225 
	C472.298065,228.732849 471.407776,226.767487 475.835938,220.320496 
	C475.991791,220.005859 476.000366,219.999649 476.259460,219.997192 
	C476.689423,219.992996 476.860321,219.991257 477.031189,219.989532 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M486.872192,222.746201 
	C487.016754,222.025101 487.568359,221.353531 488.431244,220.558731 
	C488.254730,221.222244 487.766968,222.008987 486.872192,222.746201 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M490.959351,218.842346 
	C491.156464,218.199966 491.720062,217.587296 492.605835,216.936707 
	C492.393951,217.556534 491.859894,218.214310 490.959351,218.842346 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M489.203003,219.940704 
	C489.144012,219.737885 489.322815,219.431091 489.769043,219.066635 
	C489.837891,219.284882 489.639343,219.560791 489.203003,219.940704 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M533.010498,57.521442 
	C532.259277,57.940178 531.502747,57.956554 530.368103,57.954849 
	C529.817505,56.508770 529.645020,55.080765 529.457947,53.532192 
	C533.305237,52.202518 532.969849,54.845604 533.010498,57.521442 
z"/>
<path fill="#FAFBFD" opacity="1.000000" stroke="none" 
	d="
M387.658600,312.846283 
	C384.695129,315.192932 381.472290,317.281921 378.827026,319.944702 
	C374.518341,324.281830 370.587006,328.993835 365.799561,334.323395 
	C343.235046,288.283966 339.781891,241.538666 354.715851,191.989044 
	C380.179504,227.972488 412.552307,253.817413 453.650269,270.436615 
	C428.613647,281.039642 406.216644,293.810364 387.563873,312.504456 
	C387.740936,312.889069 387.658600,312.846283 387.658600,312.846283 
z"/>
<path fill="#0E2CD7" opacity="1.000000" stroke="none" 
	d="
M443.966980,359.998505 
	C446.431915,368.652008 448.211945,377.579346 451.535034,385.889679 
	C455.690796,396.282318 461.115265,406.167633 465.696960,416.651642 
	C465.200562,417.048004 464.999237,417.064667 464.494873,416.929962 
	C445.312012,385.229706 436.036835,350.399414 429.793671,312.735687 
	C419.560333,321.254761 410.284607,328.431824 401.669952,336.329956 
	C395.390198,342.087372 389.982208,348.795654 383.936859,354.966736 
	C383.444885,354.589691 383.201630,354.325989 382.958374,354.062317 
	C385.543762,350.912903 388.129120,347.763489 391.166534,344.157318 
	C397.747314,337.469116 403.876038,331.237701 410.002380,325.003143 
	C410.000000,325.000000 410.006317,325.004883 410.287048,324.874573 
	C411.383148,323.830750 412.198547,322.917206 413.365997,321.966675 
	C420.098175,317.542023 427.588074,314.232910 429.177734,304.968140 
	C425.312836,301.136047 423.386017,308.152405 420.519836,307.668213 
	C418.492371,306.141022 416.926483,304.961517 415.308105,303.742493 
	C416.141693,302.712891 416.917480,301.754669 417.693268,300.796448 
	C417.408539,300.340729 417.123840,299.884979 416.839111,299.429260 
	C415.268311,300.203857 413.697510,300.978424 411.831543,301.898529 
	C411.216431,300.253845 410.608215,298.627686 410.000000,297.001526 
	C420.490387,290.789978 430.943787,284.514069 441.510101,278.434479 
	C443.142426,277.495300 445.292999,277.456757 447.601929,277.000854 
	C448.000000,280.119843 448.000000,283.239685 448.000000,287.639893 
	C451.063293,288.167480 455.054871,289.673889 458.849213,289.280151 
	C462.710815,288.879364 463.211456,290.170868 463.003937,293.593933 
	C462.222473,294.326996 461.436432,294.645508 460.191284,294.668335 
	C459.190979,293.361572 458.649780,292.350464 458.108551,291.339386 
	C457.678345,291.759796 457.248169,292.180206 456.817963,292.600616 
	C457.883392,293.403198 458.948822,294.205780 460.014282,295.008362 
	C460.014282,295.008331 460.013977,295.496399 459.622986,295.751587 
	C455.352112,296.427490 453.586456,298.757446 454.139954,302.444855 
	C455.136414,309.082825 456.399231,315.682434 457.634460,322.282440 
	C459.051239,329.852600 460.559082,337.405701 461.645691,344.976868 
	C460.138550,344.850281 458.265533,345.066986 458.006470,344.519684 
	C456.011230,340.304932 454.328949,335.942017 452.278320,330.966980 
	C448.672638,332.240692 445.155914,333.483002 441.656677,334.719116 
	C440.003937,326.600555 439.454620,325.950043 434.952545,325.933167 
	C436.285461,332.538757 437.385406,339.307922 439.075958,345.926270 
	C440.300629,350.720642 442.308411,355.314972 443.966980,359.998505 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M409.657532,297.043335 
	C410.608215,298.627686 411.216431,300.253845 411.831543,301.898529 
	C413.697510,300.978424 415.268311,300.203857 416.839111,299.429260 
	C417.123840,299.884979 417.408539,300.340729 417.693268,300.796448 
	C416.917480,301.754669 416.141693,302.712891 415.308105,303.742493 
	C416.926483,304.961517 418.492371,306.141022 420.519836,307.668213 
	C423.386017,308.152405 425.312836,301.136047 429.177734,304.968140 
	C427.588074,314.232910 420.098175,317.542023 412.992554,321.972412 
	C411.513489,323.011749 410.759918,324.008301 410.006317,325.004883 
	C410.006317,325.004883 410.000000,325.000000 409.582458,324.999573 
	C407.195007,325.508484 404.779602,325.473480 403.350159,326.643280 
	C400.580292,328.910004 398.723663,328.062714 396.521240,326.058838 
	C394.011963,323.775665 395.482666,321.959869 397.005005,320.147461 
	C398.221344,318.699371 399.579071,317.370026 400.874023,315.987946 
	C396.416229,316.028931 393.955444,322.471802 389.001038,318.001221 
	C389.000000,318.000000 388.997559,317.997803 389.049957,317.652771 
	C391.111694,316.014435 393.578949,315.100006 395.030518,313.345306 
	C397.668915,310.155853 399.703613,306.467041 401.990265,302.986633 
	C404.431854,301.019470 406.873474,299.052277 409.657532,297.043335 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M401.682373,303.127563 
	C399.703613,306.467041 397.668915,310.155853 395.030518,313.345306 
	C393.578949,315.100006 391.111694,316.014435 388.911530,317.706970 
	C388.814514,318.071625 388.908295,318.037018 389.002106,318.002441 
	C393.955444,322.471802 396.416229,316.028931 400.874023,315.987946 
	C399.579071,317.370026 398.221344,318.699371 397.005005,320.147461 
	C395.482666,321.959869 394.011963,323.775665 396.521240,326.058838 
	C398.723663,328.062714 400.580292,328.910004 403.350159,326.643280 
	C404.779602,325.473480 407.195007,325.508484 409.584869,325.002686 
	C403.876038,331.237701 397.747314,337.469116 391.317444,343.860291 
	C388.230530,338.752472 380.747772,335.430908 375.191193,336.678345 
	C374.070068,336.930023 372.660187,335.895325 371.383423,335.453674 
	C372.061676,334.428650 372.527344,333.092682 373.467133,332.450134 
	C374.944183,331.440308 377.764435,331.652100 376.098328,328.240051 
	C379.398254,323.912567 382.845673,319.915009 386.254974,315.885193 
	C386.859100,315.171112 387.304626,314.322876 387.740997,313.191467 
	C387.658600,312.846283 387.740936,312.889069 387.720184,312.849060 
	C391.475128,310.195374 395.252747,307.584564 399.024933,304.965820 
	C399.818451,304.414917 400.591827,303.834991 401.682373,303.127563 
z"/>
<path fill="#1223B4" opacity="1.000000" stroke="none" 
	d="
M481.988586,255.244415 
	C482.098663,255.410751 482.044708,255.578629 481.990723,255.746490 
	C481.935333,255.579636 481.879944,255.412781 481.988586,255.244415 
z"/>
<path fill="#0E2CD7" opacity="1.000000" stroke="none" 
	d="
M448.139221,276.946045 
	C448.092804,276.964020 448.185608,276.928040 448.139221,276.946045 
z"/>
<path fill="#2343D5" opacity="1.000000" stroke="none" 
	d="
M382.693207,354.289246 
	C383.201630,354.325989 383.444885,354.589691 383.823242,355.300049 
	C381.626953,358.575562 382.486298,360.906219 384.598389,363.593719 
	C401.723602,385.384521 422.470856,402.758911 447.261658,415.186035 
	C453.858337,418.492828 460.710724,421.289459 467.445679,424.320343 
	C467.642120,424.010162 467.838593,423.699982 468.035034,423.389801 
	C467.326904,421.951111 466.618774,420.512451 466.003815,418.995148 
	C466.096954,418.916504 466.288757,418.766052 466.288757,418.766052 
	C467.503265,419.545471 469.743652,420.355133 469.721741,421.098114 
	C469.674103,422.713898 468.472443,424.295685 467.365143,425.950317 
	C461.504852,423.900024 455.831818,422.179626 450.574249,419.609406 
	C434.196045,411.602570 418.837341,401.847473 405.464050,389.457367 
	C396.987610,381.604156 389.547607,372.620697 381.779205,364.020844 
	C378.892761,360.825500 381.268402,357.627930 382.693207,354.289246 
z"/>
<path fill="#FBFCFD" opacity="1.000000" stroke="none" 
	d="
M517.119263,429.505768 
	C517.119629,430.130066 517.045898,430.294189 516.590332,430.739319 
	C514.065491,429.793549 511.814941,428.720825 509.797699,427.313629 
	C500.395691,420.755035 493.805573,411.706482 487.999023,402.055542 
	C470.172028,372.425751 462.299622,339.576263 456.886688,305.901764 
	C456.117188,301.114563 457.230927,298.270020 461.909027,296.981445 
	C479.396393,290.027802 497.558472,286.082153 517.045166,284.443115 
	C517.045166,332.865448 517.045166,380.955536 517.119263,429.505768 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M543.978760,370.015717 
	C544.095947,385.119232 544.213074,400.222778 544.552368,416.053589 
	C544.063904,421.902313 542.543091,427.087799 545.973389,432.165009 
	C545.978271,432.636139 545.977966,432.824615 545.977722,433.013092 
	C542.437073,433.237122 542.111145,431.046448 541.940918,428.201385 
	C541.497803,420.796753 540.767883,413.408905 540.101074,406.018982 
	C540.049133,405.444092 539.597473,404.905273 538.964233,403.581970 
	C537.622437,405.656891 536.637329,407.180145 535.291321,409.261536 
	C533.459534,407.256897 531.978210,405.376190 530.225708,403.796143 
	C528.689148,402.410767 526.848328,401.362823 525.141907,400.165833 
	C524.096375,401.847626 523.050903,403.529388 522.070862,405.105804 
	C521.162231,403.946655 520.024719,402.495667 518.517151,401.047546 
	C518.417480,394.294983 518.913513,387.539032 518.896851,380.784302 
	C518.879150,373.608948 518.083618,366.431519 518.163696,359.260986 
	C518.248352,351.694611 518.965881,344.135254 520.115234,336.281403 
	C528.645813,336.992889 524.752930,329.087677 528.698853,325.939087 
	C530.933472,329.137054 533.071350,332.268433 535.304688,335.330170 
	C535.737793,335.923859 536.674683,336.742493 537.156738,336.615082 
	C541.413513,335.490601 540.896851,338.458130 540.996155,340.916809 
	C541.335693,349.325104 541.587646,357.738373 542.082092,366.137451 
	C542.160095,367.462830 543.319397,368.724579 543.978760,370.015717 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M543.995117,369.545746 
	C543.319397,368.724579 542.160095,367.462830 542.082092,366.137451 
	C541.587646,357.738373 541.335693,349.325104 540.996155,340.916809 
	C540.896851,338.458130 541.413513,335.490601 537.156738,336.615082 
	C536.674683,336.742493 535.737793,335.923859 535.304688,335.330170 
	C533.071350,332.268433 530.933472,329.137054 528.698853,325.939087 
	C524.752930,329.087677 528.645813,336.992889 520.404419,335.978577 
	C519.656128,324.781128 519.235107,313.596619 519.049622,302.408173 
	C518.988220,298.703491 519.288086,294.946320 519.918579,291.298859 
	C520.180664,289.782623 521.739990,288.490601 522.709351,287.096588 
	C523.802002,288.475159 524.894653,289.853729 525.708740,291.651184 
	C525.108459,292.374969 524.786743,292.679932 524.465027,292.984863 
	C524.674316,293.166260 524.883545,293.347656 525.092834,293.529053 
	C525.396057,293.020203 525.699280,292.511322 526.002441,292.002441 
	C527.198181,292.664764 528.673462,293.076996 529.533997,294.039124 
	C531.613892,296.364410 532.579163,300.188629 537.180969,298.553040 
	C537.554626,298.420258 539.657471,301.311371 539.881470,302.944885 
	C540.548035,307.804871 540.515137,312.758087 541.102234,317.633148 
	C541.319275,319.435333 542.501404,321.121307 543.613281,322.935852 
	C543.992554,338.366150 544.001953,353.720978 543.995117,369.545746 
z"/>
<path fill="#213AC3" opacity="1.000000" stroke="none" 
	d="
M461.626465,296.936096 
	C457.230927,298.270020 456.117188,301.114563 456.886688,305.901764 
	C462.299622,339.576263 470.172028,372.425751 487.999023,402.055542 
	C493.805573,411.706482 500.395691,420.755035 509.797699,427.313629 
	C511.814941,428.720825 514.065491,429.793549 516.562866,431.007874 
	C516.068359,431.731079 514.726135,433.257172 514.445984,433.082245 
	C511.070404,430.974457 507.575928,428.916931 504.658691,426.248993 
	C500.545288,422.487030 496.488708,418.527100 493.144287,414.095978 
	C489.355804,409.076477 486.348816,403.462341 483.066895,398.067322 
	C481.520020,395.524506 480.124603,392.889587 478.308197,389.767334 
	C475.580261,383.117828 473.100159,377.035339 470.857391,370.866638 
	C468.463409,364.282043 466.293518,357.615997 464.025970,350.597717 
	C463.360992,348.468079 462.695862,346.726196 462.022644,344.987122 
	C462.014587,344.989868 462.027374,344.966217 462.027374,344.966217 
	C460.559082,337.405701 459.051239,329.852600 457.634460,322.282440 
	C456.399231,315.682434 455.136414,309.082825 454.139954,302.444855 
	C453.586456,298.757446 455.352112,296.427490 459.612518,295.995422 
	C460.443329,296.286255 460.893616,296.588501 461.626465,296.936096 
z"/>
<path fill="#141EA8" opacity="1.000000" stroke="none" 
	d="
M560.931030,284.812866 
	C560.853088,284.976807 560.679749,284.979126 560.246826,284.965271 
	C560.270020,284.849792 560.552795,284.750488 560.931030,284.812866 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M464.025787,350.985504 
	C466.293518,357.615997 468.463409,364.282043 470.857391,370.866638 
	C473.100159,377.035339 475.580261,383.117828 478.007507,389.601471 
	C476.921692,389.890106 475.784180,389.815582 473.829010,389.687439 
	C474.902252,391.520660 475.980743,392.908630 476.533020,394.481384 
	C476.849396,395.382446 476.486237,396.664673 476.053589,397.602020 
	C475.918060,397.895721 473.973175,397.872894 473.847015,397.575134 
	C471.647247,392.384583 466.610901,392.161072 461.258148,391.339508 
	C464.416962,388.552155 466.760712,386.483978 469.565430,384.009064 
	C464.474060,381.069794 461.206696,376.555847 454.959229,377.769897 
	C453.598480,378.034332 450.917206,375.780151 450.187622,374.101868 
	C448.784851,370.875275 445.836761,367.885468 447.572723,363.630829 
	C447.850067,362.951111 445.714386,361.286804 444.330627,360.038086 
	C442.308411,355.314972 440.300629,350.720642 439.075958,345.926270 
	C437.385406,339.307922 436.285461,332.538757 434.952545,325.933167 
	C439.454620,325.950043 440.003937,326.600555 441.656677,334.719116 
	C445.155914,333.483002 448.672638,332.240692 452.278320,330.966980 
	C454.328949,335.942017 456.011230,340.304932 458.006470,344.519684 
	C458.265533,345.066986 460.138550,344.850281 461.645691,344.976868 
	C462.027374,344.966217 462.014587,344.989868 462.012238,345.399109 
	C462.681854,347.534088 463.353821,349.259796 464.025787,350.985504 
z"/>
<path fill="#2343D5" opacity="1.000000" stroke="none" 
	d="
M464.797943,417.081329 
	C464.999237,417.064667 465.200562,417.048004 465.704956,417.024841 
	C466.051575,417.410522 466.095154,417.802765 466.213745,418.480530 
	C466.288757,418.766052 466.096954,418.916504 465.740662,418.846649 
	C465.188904,418.211639 464.993439,417.646484 464.797943,417.081329 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M432.698181,86.800674 
	C432.698181,86.800667 432.348145,86.897362 431.877930,86.976067 
	C430.606140,87.701912 429.804565,88.349052 429.002991,88.996193 
	C429.002991,88.996193 429.000000,88.999390 428.641968,89.015182 
	C425.700745,90.541634 423.117523,92.052292 420.396912,93.418289 
	C420.259491,93.273628 419.984619,92.984375 419.984619,92.984383 
	C423.085205,90.557228 425.992065,87.809013 429.338623,85.789558 
	C432.978729,83.593010 437.027283,82.073463 441.046753,80.623077 
	C438.363190,82.925156 435.530701,84.862915 432.698181,86.800674 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M433.121918,86.859497 
	C435.530701,84.862915 438.363190,82.925156 441.456909,80.759872 
	C441.718079,80.532356 441.904999,80.467285 441.996643,80.432144 
	C444.145416,79.599419 446.202515,78.801842 448.626404,77.993240 
	C444.379211,81.886131 440.376343,86.846390 433.121918,86.859497 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M400.844208,137.149673 
	C398.799103,135.783554 396.753967,134.417450 394.369263,132.999283 
	C393.046326,131.206528 392.062988,129.465836 391.159912,127.240555 
	C391.240143,126.755966 391.512909,126.479706 391.778778,126.636002 
	C393.041626,127.506653 394.038574,128.221008 395.035553,128.935349 
	C395.035553,128.935349 395.007141,128.999008 395.131470,129.296936 
	C397.155365,131.407547 399.054932,133.220215 400.954498,135.032898 
	C400.954498,135.032898 401.000702,135.007477 400.967072,135.329590 
	C401.005280,136.051804 401.077118,136.451889 401.073029,136.926666 
	C400.997101,137.001358 400.844208,137.149673 400.844208,137.149673 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M393.975830,114.980911 
	C396.028839,112.967842 398.081848,110.954781 400.572876,108.977531 
	C401.010895,109.013344 401.180389,109.181610 401.111755,109.459557 
	C400.976562,110.110909 400.910004,110.484306 400.843445,110.857704 
	C400.843414,110.857704 400.996887,110.994469 400.591522,110.965820 
	C398.268066,112.455307 396.349976,113.973442 394.318359,115.363464 
	C394.204865,115.235344 393.975830,114.980911 393.975830,114.980911 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M405.526215,106.677406 
	C405.526215,106.677406 405.228851,106.761765 404.818848,106.863800 
	C404.022217,107.060265 403.635620,107.154701 403.159210,107.159058 
	C403.069427,107.068985 402.890167,106.888512 402.890167,106.888504 
	C404.647400,105.242317 406.404663,103.596123 408.601318,102.004105 
	C409.040680,102.058289 409.209320,102.251312 409.038055,102.524826 
	C407.753265,104.091362 406.639740,105.384384 405.526215,106.677406 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M391.103516,126.893883 
	C389.976257,125.924477 388.985596,124.817154 387.993439,123.105545 
	C387.991974,122.501259 387.982788,122.016907 387.982788,122.016907 
	C388.400543,121.355141 388.818298,120.693382 389.606873,120.006622 
	C389.977661,119.981613 390.111450,120.109253 389.988953,120.416687 
	C390.415283,122.642647 390.964081,124.561180 391.512909,126.479706 
	C391.512909,126.479706 391.240143,126.755966 391.103516,126.893883 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M415.936859,97.968529 
	C415.626160,98.041389 415.366425,98.198517 414.729584,98.651924 
	C413.909760,98.960228 413.518066,99.056526 413.052338,99.066925 
	C412.978333,98.981033 412.830994,98.808678 412.830994,98.808685 
	C413.926361,97.857048 415.021729,96.905396 416.554749,95.977844 
	C416.992432,96.001945 417.128418,96.144798 417.013062,96.397018 
	C416.577454,97.089012 416.257141,97.528770 415.936859,97.968529 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M416.921936,95.932983 
	C417.654449,94.914207 418.457458,93.964394 419.622559,92.999481 
	C419.984619,92.984375 420.259491,93.273628 420.212982,93.668350 
	C419.153778,94.756973 418.141113,95.450890 417.128418,96.144806 
	C417.128418,96.144798 416.992432,96.001945 416.921936,95.932983 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M408.954010,101.963867 
	C409.962463,100.891174 411.057587,99.912895 412.491821,98.871643 
	C412.830994,98.808678 412.978333,98.981033 412.909485,99.331146 
	C412.234070,100.457146 411.627563,101.233032 411.021027,102.008926 
	C411.021027,102.008926 410.998779,101.998871 410.699188,102.002861 
	C410.002838,102.088341 409.606079,102.169830 409.209320,102.251312 
	C409.209320,102.251312 409.040680,102.058289 408.954010,101.963867 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M389.909332,119.919342 
	C390.324463,118.931969 390.807922,118.006866 391.766479,117.144119 
	C392.241577,117.206482 392.532043,117.462410 392.378418,117.722305 
	C391.520355,118.691208 390.815887,119.400223 390.111450,120.109253 
	C390.111450,120.109253 389.977661,119.981613 389.909332,119.919342 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M400.925293,108.930084 
	C401.324158,108.243706 401.808655,107.640594 402.591644,106.962997 
	C402.890167,106.888512 403.069427,107.068985 403.083618,107.434937 
	C403.036530,108.175232 402.975281,108.549583 402.914001,108.923935 
	C402.914001,108.923935 402.997437,108.998985 402.665527,108.956360 
	C401.949188,109.003029 401.564789,109.092316 401.180389,109.181610 
	C401.180389,109.181610 401.010895,109.013344 400.925293,108.930084 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M392.953430,115.961617 
	C393.069153,115.645805 393.264923,115.391647 393.758240,115.090027 
	C393.975830,114.980911 394.204865,115.235344 394.178162,115.544174 
	C393.953125,116.087944 393.731140,116.297829 393.352905,116.351898 
	C393.220398,116.221138 392.953430,115.961617 392.953430,115.961617 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M392.097839,117.076859 
	C392.077942,116.642662 392.270966,116.391197 392.743286,116.077225 
	C392.953430,115.961617 393.220398,116.221138 393.206543,116.526917 
	C392.988617,117.059517 392.768402,117.269424 392.532043,117.462410 
	C392.532043,117.462410 392.241577,117.206482 392.097839,117.076859 
z"/>
<path fill="#10B8F8" opacity="1.000000" stroke="none" 
	d="
M387.819061,122.099899 
	C387.982788,122.016907 387.991974,122.501259 387.980194,122.742935 
	C387.802216,122.821594 387.620544,122.670135 387.482788,122.485855 
	C387.454956,122.448647 387.593506,122.287102 387.819061,122.099899 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M480.660767,118.033005 
	C480.026825,118.767899 478.986908,119.464035 477.700287,120.375839 
	C477.453552,120.591507 477.222626,120.624695 476.766815,120.705719 
	C475.339996,121.407814 474.369019,122.028885 473.398010,122.649963 
	C473.398010,122.649963 473.203613,122.552925 472.774902,122.599182 
	C471.845520,123.006638 471.344818,123.367836 470.844086,123.729034 
	C470.844086,123.729027 470.808594,123.925186 470.437256,123.800339 
	C468.388611,124.129776 466.711365,124.584061 465.034088,125.038345 
	C469.094604,120.501358 474.558685,118.997147 480.660767,118.033005 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M414.949005,150.092102 
	C415.049316,150.403229 415.234039,150.651596 415.800415,151.176956 
	C416.377533,151.704224 416.657440,151.891708 416.937317,152.079193 
	C416.937317,152.079193 416.998688,152.008698 417.009766,152.297760 
	C417.317200,152.764404 417.613556,152.941971 417.909912,153.119537 
	C417.909912,153.119537 417.918304,152.998932 417.972534,153.366592 
	C419.013184,154.852097 419.999542,155.969925 420.985901,157.087753 
	C420.406464,157.292953 419.516968,157.844681 419.297211,157.647995 
	C417.685303,156.205643 416.207581,154.613388 414.345581,153.029968 
	C412.317657,150.592850 410.631653,148.194122 409.260315,145.531982 
	C411.366302,146.876404 413.157654,148.484253 414.949005,150.092102 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M453.433685,131.293060 
	C453.433685,131.293060 453.749176,131.219681 454.193726,131.108871 
	C455.778900,130.471909 456.919556,129.945755 458.060211,129.419617 
	C456.658386,134.622757 451.769104,135.179260 447.442383,137.029404 
	C447.008759,136.995728 446.875092,136.844635 447.056335,136.567169 
	C449.302948,134.624161 451.368317,132.958603 453.433685,131.293060 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M447.078491,137.068604 
	C444.659943,139.398727 442.171631,141.655975 439.179657,143.791336 
	C438.675964,143.669449 438.360535,143.343964 438.561035,143.084641 
	C441.466064,140.831757 444.170563,138.838181 446.875092,136.844635 
	C446.875092,136.844635 447.008759,136.995728 447.078491,137.068604 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M465.025818,125.026039 
	C466.711365,124.584061 468.388611,124.129776 470.437744,123.700577 
	C467.377991,125.474655 463.946411,127.223663 460.155640,128.679626 
	C459.777710,128.218140 459.758972,128.049683 459.740265,127.881210 
	C460.058472,127.745964 460.376709,127.610725 461.203125,127.261543 
	C462.818176,126.376175 463.925049,125.704758 465.031921,125.033340 
	C465.031921,125.033348 465.017548,125.013733 465.025818,125.026039 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M421.326477,157.074738 
	C419.999542,155.969925 419.013184,154.852097 417.951172,153.423187 
	C420.016754,153.427521 422.157990,153.742905 424.607941,154.394012 
	C424.287567,155.519974 423.658447,156.310211 423.029297,157.100433 
	C422.575226,157.087524 422.121124,157.074615 421.326477,157.074738 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M432.849701,146.931747 
	C433.459015,146.452835 434.068329,145.973923 435.135315,145.245499 
	C435.970856,144.884872 436.348755,144.773743 436.819031,144.773880 
	C436.911377,144.885132 437.098999,145.105194 437.098999,145.105194 
	C435.633636,146.716339 434.168274,148.327484 432.209961,149.828766 
	C431.717010,149.718887 431.406555,149.408249 431.572754,149.172379 
	C432.109222,148.268265 432.479462,147.600006 432.849701,146.931747 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M423.229584,157.334900 
	C423.658447,156.310211 424.287567,155.519974 425.020569,154.441711 
	C425.124481,154.153671 425.091858,154.134338 425.403687,154.087814 
	C426.395447,153.609360 427.075348,153.177429 427.848267,152.842209 
	C427.941315,152.938919 428.126709,153.132950 428.126709,153.132950 
	C426.561096,154.611755 424.995483,156.090561 423.229584,157.334900 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M459.362671,127.894669 
	C459.758972,128.049683 459.777710,128.218140 459.767426,128.639130 
	C459.356262,128.980515 458.974152,129.069351 458.326111,129.288879 
	C456.919556,129.945755 455.778900,130.471909 454.315613,131.002228 
	C455.656982,129.973633 457.321014,128.940872 459.362671,127.894669 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M473.778625,122.739090 
	C474.369019,122.028885 475.339996,121.407814 476.681458,120.785225 
	C476.087677,121.465218 475.123444,122.146713 473.778625,122.739090 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M431.871735,149.874695 
	C431.527954,150.607239 431.029480,151.183975 430.107056,151.720764 
	C429.683136,151.680817 429.360352,151.354172 429.522461,151.117554 
	C430.258545,150.390045 430.832550,149.899139 431.406555,149.408234 
	C431.406555,149.408249 431.717010,149.718887 431.871735,149.874695 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M429.844238,151.844452 
	C429.564880,152.340805 429.124420,152.673508 428.405334,153.069580 
	C428.126709,153.132950 427.941315,152.938919 427.999146,152.618774 
	C428.491425,151.983795 428.925873,151.668976 429.360352,151.354172 
	C429.360352,151.354172 429.683136,151.680817 429.844238,151.844452 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M471.184082,123.793289 
	C471.344818,123.367836 471.845520,123.006638 472.677429,122.647194 
	C472.513824,123.051819 472.018982,123.454681 471.184082,123.793289 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M438.832550,143.833252 
	C438.546875,144.328674 438.104614,144.660294 437.380676,145.048553 
	C437.098999,145.105194 436.911377,144.885132 436.977722,144.559753 
	C437.482880,143.937561 437.921722,143.640762 438.360535,143.343964 
	C438.360535,143.343964 438.675964,143.669449 438.832550,143.833252 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M414.971619,149.721313 
	C413.157654,148.484253 411.366302,146.876404 409.260834,145.170807 
	C408.946716,145.073059 408.974640,145.026947 408.885681,144.778717 
	C408.521454,144.353012 408.246185,144.175537 407.970947,143.998062 
	C407.877747,143.679581 407.696564,143.423294 407.142151,142.826416 
	C406.248627,141.935440 405.640381,141.447266 405.032135,140.959091 
	C405.032135,140.959091 405.000000,141.000000 404.933594,140.696838 
	C404.256836,139.911774 403.646454,139.429871 403.036102,138.947952 
	C403.036102,138.947952 403.008362,139.004608 403.012695,138.683105 
	C402.962433,137.964264 402.907806,137.566925 402.853180,137.169586 
	C406.900177,141.229904 410.947205,145.290207 414.971619,149.721313 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M402.873596,137.162018 
	C402.907806,137.566925 402.962433,137.964264 403.037109,138.662933 
	C402.382782,138.562790 401.708405,138.161316 400.939148,137.454758 
	C400.844208,137.149673 400.997101,137.001358 401.357727,136.990692 
	C402.101715,137.035278 402.485077,137.090515 402.868408,137.145752 
	C402.868408,137.145752 402.893982,137.154434 402.873596,137.162018 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M405.044739,141.289734 
	C405.640381,141.447266 406.248627,141.935440 406.929565,142.717316 
	C406.353943,142.547440 405.705627,142.083908 405.044739,141.289734 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M403.049347,139.282135 
	C403.646454,139.429871 404.256836,139.911774 404.953979,140.680817 
	C404.381409,140.517410 403.721985,140.066879 403.049347,139.282135 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M408.050903,144.233551 
	C408.246185,144.175537 408.521454,144.353012 408.859589,144.780777 
	C408.577667,144.957748 408.313812,144.770401 408.050903,144.233551 
z"/>
<path fill="#07C0FD" opacity="1.000000" stroke="none" 
	d="
M490.953918,60.622398 
	C489.261383,61.344334 487.520325,61.689796 485.392181,62.037773 
	C485.562164,60.720070 486.119232,59.399845 486.676300,58.079620 
	C488.085999,58.801723 489.495667,59.523823 490.953918,60.622398 
z"/>
<path fill="#07C0FD" opacity="1.000000" stroke="none" 
	d="
M483.012268,57.738342 
	C482.823456,58.222942 482.440796,58.510612 482.058167,58.798286 
	C481.880371,58.427166 481.702576,58.056046 481.524750,57.684929 
	C481.955994,57.637093 482.387207,57.589252 483.012268,57.738342 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M550.630371,88.046387 
	C551.758179,88.385170 552.554077,88.706917 553.689331,89.064468 
	C552.878906,93.415504 551.087219,94.007690 544.689697,92.203697 
	C546.105774,91.091545 547.534546,89.969452 548.967163,88.470726 
	C549.413513,88.072502 549.856018,88.050926 550.630371,88.046387 
z"/>
<path fill="#0BB4FD" opacity="1.000000" stroke="none" 
	d="
M575.015686,72.255722 
	C578.051208,72.250328 581.141479,72.577827 584.611328,72.963867 
	C584.076538,73.674667 583.035278,74.999435 582.271484,74.855202 
	C579.816040,74.391525 577.463013,73.385468 575.015686,72.255722 
z"/>
<path fill="#159EFC" opacity="1.000000" stroke="none" 
	d="
M601.892944,80.266129 
	C602.873413,80.143730 603.734680,80.308136 604.804077,80.733276 
	C603.943298,81.539246 602.874390,82.084473 601.805481,82.629707 
	C601.794922,81.937454 601.784363,81.245193 601.892944,80.266129 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M486.653015,161.007538 
	C487.051086,161.034149 486.959869,161.446533 486.908508,161.650970 
	C485.011414,162.682144 483.165680,163.508865 481.097382,164.585800 
	C477.706177,166.698364 474.537537,168.560730 471.160828,170.684494 
	C468.926880,172.044495 466.901001,173.143097 464.526855,174.302750 
	C469.811676,167.056168 477.493408,163.127960 486.653015,161.007538 
z"/>
<path fill="#31B1E9" opacity="1.000000" stroke="none" 
	d="
M488.797852,160.087997 
	C488.618225,160.429947 488.125275,160.744736 487.319519,161.042664 
	C487.499298,160.704132 487.991913,160.382492 488.797852,160.087997 
z"/>
<path fill="#FBFCFD" opacity="1.000000" stroke="none" 
	d="
M545.968079,431.882355 
	C542.543091,427.087799 544.063904,421.902313 544.827393,416.357666 
	C544.950806,395.129761 545.048035,374.325165 545.084045,353.520447 
	C545.120667,332.377686 545.096924,311.234894 545.099670,290.092102 
	C545.099854,288.458435 545.099670,286.824799 545.099670,284.230988 
	C553.286865,285.447296 561.101196,285.969940 568.566284,287.866760 
	C579.791931,290.719055 590.758240,294.582855 601.867493,297.907379 
	C605.024048,298.852020 606.020447,300.614624 605.311890,303.804474 
	C601.137817,322.596283 597.046448,341.406464 592.918091,360.612793 
	C592.664001,361.502716 592.419128,361.990265 591.993530,363.049500 
	C589.554138,369.737854 587.295349,375.854523 585.036621,381.971222 
	C585.036621,381.971222 584.778931,382.211334 584.496948,382.578613 
	C582.047546,387.553711 579.880188,392.161530 577.712830,396.769379 
	C577.712830,396.769379 577.826050,396.996155 577.525146,397.043091 
	C577.046509,397.680634 576.868835,398.271210 576.691162,398.861816 
	C576.691162,398.861816 576.836609,398.850403 576.512451,398.906647 
	C575.767395,399.650024 575.346558,400.337158 574.925720,401.024292 
	C574.645630,401.474243 574.365479,401.924225 573.728271,402.732330 
	C573.170349,403.667603 572.969360,404.244720 572.770264,404.843201 
	C572.772156,404.864532 572.759277,404.831024 572.475098,404.986084 
	C571.463440,406.432739 570.735962,407.724304 570.008484,409.015839 
	C570.008484,409.015839 570.000000,409.001831 569.723022,409.132141 
	C568.612671,410.433014 567.779297,411.603577 566.665588,412.909271 
	C566.259766,413.416840 566.134216,413.789276 565.789673,414.359192 
	C564.604858,415.702667 563.638916,416.848663 562.358093,418.169800 
	C560.906799,419.797607 559.770386,421.250275 558.634033,422.702942 
	C558.634033,422.702972 558.755493,422.769775 558.402161,422.773804 
	C556.276855,424.451538 554.504883,426.125244 552.732849,427.798950 
	C552.732849,427.798920 552.844727,427.828552 552.484009,427.826050 
	C550.071533,429.176514 548.019836,430.529419 545.968079,431.882355 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M578.056152,396.820068 
	C579.880188,392.161530 582.047546,387.553711 584.507751,382.754395 
	C584.527283,384.439606 584.254028,386.316345 583.970825,388.931244 
	C583.960510,390.108826 583.960083,390.548187 583.959717,390.987579 
	C583.959717,390.987579 583.982849,390.991394 583.970703,390.977661 
	C583.055542,391.779205 582.152466,392.594513 580.900879,393.805359 
	C580.282166,394.413574 580.117676,394.689392 580.059021,395.028290 
	C579.505859,395.642456 578.952637,396.256592 578.056152,396.820068 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M559.020813,422.799042 
	C559.770386,421.250275 560.906799,419.797607 562.643616,418.192108 
	C563.823792,418.013489 564.403625,417.987701 564.983398,417.961914 
	C563.833374,419.517883 562.683411,421.073853 561.262695,422.824097 
	C560.463867,422.977264 559.935730,422.936188 559.020813,422.799042 
z"/>
<path fill="#1029CA" opacity="1.000000" stroke="none" 
	d="
M609.850952,428.354858 
	C609.618652,429.877655 609.065979,431.399811 608.513306,432.921997 
	C607.972839,432.593567 607.432373,432.265106 606.891907,431.936676 
	C607.771423,430.742523 608.651001,429.548370 609.850952,428.354858 
z"/>
<path fill="#1029CA" opacity="1.000000" stroke="none" 
	d="
M617.613770,433.001587 
	C617.126404,433.947052 616.252808,434.894073 615.379150,435.841125 
	C614.987244,435.406708 614.595337,434.972351 614.203369,434.537994 
	C615.211426,434.026398 616.219543,433.514771 617.613770,433.001587 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M566.945862,412.774139 
	C567.779297,411.603577 568.612671,410.433014 569.730652,409.133667 
	C570.031494,409.443176 570.047791,409.881409 570.020447,410.894653 
	C569.976807,411.469635 569.934448,411.965057 569.934448,411.965057 
	C569.367920,412.459900 568.801392,412.954773 567.871338,413.576508 
	C567.320435,413.393646 567.133179,413.083893 566.945862,412.774139 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M565.116089,417.667847 
	C564.403625,417.987701 563.823792,418.013489 562.958496,418.016968 
	C563.638916,416.848663 564.604858,415.702667 565.840942,414.569000 
	C566.111084,414.581329 566.235291,414.997040 566.235291,414.997040 
	C565.906433,415.789276 565.577637,416.581512 565.116089,417.667847 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M570.329468,409.022095 
	C570.735962,407.724304 571.463440,406.432739 572.546509,405.339172 
	C572.928467,406.016571 572.954956,406.495941 572.981384,406.975342 
	C572.981384,406.975342 572.997620,406.995087 572.742859,407.159760 
	C572.302979,407.870880 572.117859,408.417297 571.932739,408.963715 
	C571.505310,408.985260 571.077820,409.006805 570.329468,409.022095 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M600.604065,408.022766 
	C600.945679,408.392059 600.951660,408.831268 600.929321,409.597839 
	C600.433044,410.217896 599.965027,410.510559 599.233276,410.888184 
	C599.402527,410.013000 599.835449,409.052856 600.604065,408.022766 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M573.257202,406.861389 
	C572.954956,406.495941 572.928467,406.016571 572.830688,405.184082 
	C572.759277,404.831024 572.772156,404.864532 573.059082,404.807434 
	C573.628662,404.486938 573.911316,404.223541 574.194031,403.960144 
	C573.973633,404.889252 573.753296,405.818329 573.257202,406.861389 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M575.284668,400.989899 
	C575.346558,400.337158 575.767395,399.650024 576.475098,398.843994 
	C576.389160,399.468567 576.016418,400.212036 575.284668,400.989899 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M566.507507,414.978333 
	C566.235291,414.997040 566.111084,414.581329 566.059814,414.371521 
	C566.134216,413.789276 566.259766,413.416840 566.665588,412.909271 
	C567.133179,413.083893 567.320435,413.393646 567.749634,413.835693 
	C567.587646,414.298523 567.183655,414.629089 566.507507,414.978333 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M577.019165,398.755798 
	C576.868835,398.271210 577.046509,397.680634 577.487793,396.921997 
	C577.616638,397.385895 577.481934,398.017822 577.019165,398.755798 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M574.138489,403.703064 
	C573.911316,404.223541 573.628662,404.486938 573.057251,404.786102 
	C572.969360,404.244720 573.170349,403.667603 573.676880,403.010071 
	C574.015869,403.101776 574.049377,403.273895 574.138489,403.703064 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M572.195923,408.817200 
	C572.117859,408.417297 572.302979,407.870880 572.730469,407.158478 
	C572.801514,407.551910 572.630249,408.111298 572.195923,408.817200 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M580.271973,394.909271 
	C580.117676,394.689392 580.282166,394.413574 580.768677,394.084534 
	C580.915344,394.301270 580.748718,394.575317 580.271973,394.909271 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M570.116577,411.899902 
	C569.934448,411.965057 569.976807,411.469635 570.014282,411.223633 
	C570.210815,411.161957 570.385864,411.336121 570.514526,411.539612 
	C570.538391,411.577423 570.375366,411.733368 570.116577,411.899902 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M545.973389,432.165009 
	C548.019836,430.529419 550.071533,429.176514 552.463135,427.772095 
	C552.677429,431.877136 549.234802,432.298004 546.189575,433.208984 
	C545.977966,432.824615 545.978271,432.636139 545.973389,432.165009 
z"/>
<path fill="#1125C0" opacity="1.000000" stroke="none" 
	d="
M553.151733,427.873657 
	C554.504883,426.125244 556.276855,424.451538 558.378784,422.708588 
	C558.796997,423.159698 558.885315,423.680084 558.696411,424.737915 
	C557.931824,426.164032 557.444458,427.052643 556.957092,427.941223 
	C555.828247,427.943634 554.699402,427.946014 553.151733,427.873657 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M557.265869,428.085938 
	C557.444458,427.052643 557.931824,426.164032 558.703857,425.133667 
	C559.510803,426.074768 560.033081,427.157654 560.845398,428.841919 
	C559.466492,428.584229 558.520508,428.407471 557.265869,428.085938 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M473.042236,213.274933 
	C473.446625,212.968140 473.819183,213.036560 474.464813,213.184967 
	C475.266846,214.149124 475.795837,215.033310 476.443604,216.198853 
	C476.562408,216.480209 476.279999,216.757004 475.807739,216.869476 
	C474.905151,216.974472 474.474823,216.967010 474.044495,216.959534 
	C474.044495,216.959534 474.026215,216.995361 473.921875,216.754822 
	C473.619751,216.243027 473.367218,216.039810 473.059998,215.904633 
	C473.043457,215.153137 473.026917,214.401627 473.042236,213.274933 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M468.015137,209.353027 
	C468.012207,208.983231 468.493835,209.003922 468.734131,209.019684 
	C469.398438,209.350784 469.822418,209.666107 470.518524,210.005997 
	C470.790649,210.030563 471.051483,210.333740 471.040161,210.846100 
	C471.001282,211.574402 470.973755,211.790314 470.946228,212.006226 
	C469.970184,211.245102 468.994141,210.483963 468.015137,209.353027 
z"/>
<path fill="#10B0FA" opacity="1.000000" stroke="none" 
	d="
M464.465820,214.607697 
	C464.349548,215.058487 464.063568,215.332001 463.777588,215.605515 
	C463.664398,215.487061 463.551208,215.368591 463.438019,215.250137 
	C463.724060,214.976883 464.010101,214.703644 464.465820,214.607697 
z"/>
<path fill="#41A3E1" opacity="1.000000" stroke="none" 
	d="
M471.139771,212.251999 
	C470.973755,211.790314 471.001282,211.574402 471.096588,211.037964 
	C471.525604,210.825165 471.886871,210.932861 472.518005,211.124634 
	C472.880188,211.587158 472.972534,211.965622 473.082886,212.635681 
	C472.511719,212.784103 471.922516,212.640930 471.139771,212.251999 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M474.148804,217.201172 
	C474.474823,216.967010 474.905151,216.974472 475.657806,216.998383 
	C475.907745,217.330002 475.747955,217.591843 475.302734,217.921875 
	C474.820831,217.843201 474.536987,217.643005 474.148804,217.201172 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M476.923462,219.763641 
	C476.860321,219.991257 476.689423,219.992996 476.262329,219.994324 
	C476.005981,219.822845 476.005829,219.651794 476.008392,219.223907 
	C476.279358,219.157288 476.547546,219.347504 476.923462,219.763641 
z"/>
<path fill="#1DA4F4" opacity="1.000000" stroke="none" 
	d="
M473.141846,216.143280 
	C473.367218,216.039810 473.619751,216.243027 473.920837,216.736282 
	C473.757385,216.766159 473.490570,216.574036 473.141846,216.143280 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M616.532227,420.550720 
	C616.476318,420.584106 616.588135,420.517334 616.532227,420.550720 
z"/>
<path fill="#2C369F" opacity="1.000000" stroke="none" 
	d="
M585.349854,381.826904 
	C587.295349,375.854523 589.554138,369.737854 592.027344,363.333801 
	C591.826172,365.642242 591.815247,368.391724 590.901428,370.798340 
	C589.477295,374.548706 587.440308,378.066315 585.349854,381.826904 
z"/>
<path fill="#0A28D6" opacity="1.000000" stroke="none" 
	d="
M584.195801,390.853149 
	C583.960083,390.548187 583.960510,390.108826 583.976807,389.340454 
	C584.139099,389.580597 584.285583,390.149658 584.195801,390.853149 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M451.222961,77.317856 
	C458.115204,74.106575 465.170624,71.003746 472.350403,68.221840 
	C475.866791,66.859360 479.614288,66.093231 483.645905,65.119354 
	C483.211365,65.990440 482.527954,67.208588 481.543213,67.545143 
	C471.536102,70.965355 461.479858,74.241791 451.222961,77.317856 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M392.378418,117.722305 
	C392.768402,117.269424 392.988617,117.059517 393.339081,116.657677 
	C393.731140,116.297829 393.953125,116.087944 394.291687,115.672287 
	C396.349976,113.973442 398.268066,112.455307 400.519958,110.891991 
	C399.031128,113.250069 397.477844,115.936783 395.319763,117.986931 
	C392.437653,120.724892 390.690826,123.107582 394.493317,126.356873 
	C394.900970,126.705185 394.863312,127.574615 395.033875,128.568832 
	C394.038574,128.221008 393.041626,127.506653 391.778778,126.636002 
	C390.964081,124.561180 390.415283,122.642647 389.988953,120.416679 
	C390.815887,119.400223 391.520355,118.691208 392.378418,117.722305 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M438.561035,143.084641 
	C437.921722,143.640762 437.482880,143.937561 436.885376,144.448486 
	C436.348755,144.773743 435.970856,144.884872 435.297180,145.004181 
	C438.698944,137.602798 445.914917,134.675201 453.047394,131.221100 
	C451.368317,132.958603 449.302948,134.624161 447.056335,136.567169 
	C444.170563,138.838181 441.466064,140.831757 438.561035,143.084641 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M417.013062,96.397026 
	C418.141113,95.450890 419.153778,94.756973 420.350403,93.813011 
	C423.117523,92.052292 425.700745,90.541634 428.639648,89.016052 
	C426.683258,94.432655 421.727753,96.263908 416.326080,97.993073 
	C416.257141,97.528770 416.577454,97.089012 417.013062,96.397026 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M528.695312,64.055061 
	C526.588135,67.236061 524.092834,70.383987 521.363708,73.777328 
	C519.387512,69.258614 522.438232,65.007744 528.695312,64.055061 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M429.522461,151.117554 
	C428.925873,151.668976 428.491425,151.983795 427.906097,152.522064 
	C427.075348,153.177429 426.395447,153.609360 425.417084,154.086594 
	C427.460114,151.738693 429.801605,149.345490 432.496399,146.942017 
	C432.479462,147.600006 432.109222,148.268265 431.572754,149.172363 
	C430.832550,149.899139 430.258545,150.390045 429.522461,151.117554 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M400.981750,134.662933 
	C399.054932,133.220215 397.155365,131.407547 395.161011,129.278442 
	C397.047180,130.739029 399.028107,132.516006 400.981750,134.662933 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M409.038055,102.524826 
	C409.606079,102.169830 410.002838,102.088341 410.703674,102.014252 
	C409.430603,103.623856 407.853455,105.226067 405.901245,106.752846 
	C406.639740,105.384384 407.753265,104.091362 409.038055,102.524826 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M411.389130,102.020332 
	C411.627563,101.233032 412.234070,100.457146 412.983459,99.417038 
	C413.518066,99.056526 413.909760,98.960228 414.603699,98.845230 
	C413.856384,99.894943 412.806793,100.963341 411.389130,102.020332 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M485.940094,116.174255 
	C484.833344,116.875130 483.306274,117.484375 481.421478,118.088486 
	C482.549072,117.416443 484.034424,116.749535 485.940094,116.174255 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M464.650299,125.006119 
	C463.925049,125.704758 462.818176,126.376175 461.375793,127.064926 
	C462.116394,126.381134 463.192535,125.680023 464.650299,125.006119 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M429.351166,89.017502 
	C429.804565,88.349052 430.606140,87.701912 431.731750,87.083778 
	C431.270294,87.754791 430.484802,88.396797 429.351166,89.017502 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M402.883331,136.806320 
	C402.485077,137.090515 402.101715,137.035278 401.433655,136.916016 
	C401.077118,136.451889 401.005280,136.051804 400.945679,135.347153 
	C401.604706,135.517365 402.251465,135.992126 402.883331,136.806320 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M403.249664,108.928581 
	C402.975281,108.549583 403.036530,108.175232 403.173401,107.525009 
	C403.635620,107.154701 404.022217,107.060265 404.708069,106.971542 
	C404.533264,107.629234 404.059296,108.281227 403.249664,108.928581 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M401.111755,109.459557 
	C401.564789,109.092316 401.949188,109.003029 402.629456,108.913437 
	C402.459839,109.568542 401.994385,110.223953 401.186157,110.868530 
	C400.910004,110.484306 400.976562,110.110909 401.111755,109.459557 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M416.876526,151.819626 
	C416.657440,151.891708 416.377533,151.704224 416.015930,151.271851 
	C416.228088,151.204651 416.521912,151.382355 416.876526,151.819626 
z"/>
<path fill="#C8F4FD" opacity="1.000000" stroke="none" 
	d="
M417.840454,152.866837 
	C417.613556,152.941971 417.317200,152.764404 416.969666,152.321716 
	C417.202667,152.242462 417.486816,152.428299 417.840454,152.866837 
z"/>
<path fill="#F9FAFC" opacity="1.000000" stroke="none" 
	d="
M464.494873,416.929962 
	C464.993439,417.646484 465.188904,418.211639 465.647522,418.925293 
	C466.618774,420.512451 467.326904,421.951111 468.035034,423.389801 
	C467.838593,423.699982 467.642120,424.010162 467.445679,424.320343 
	C460.710724,421.289459 453.858337,418.492828 447.261658,415.186035 
	C422.470856,402.758911 401.723602,385.384521 384.598389,363.593719 
	C382.486298,360.906219 381.626953,358.575562 384.071960,355.413391 
	C389.982208,348.795654 395.390198,342.087372 401.669952,336.329956 
	C410.284607,328.431824 419.560333,321.254761 429.793671,312.735687 
	C436.036835,350.399414 445.312012,385.229706 464.494873,416.929962 
z"/>
<path fill="#1027C6" opacity="1.000000" stroke="none" 
	d="
M460.007141,295.004211 
	C458.948822,294.205780 457.883392,293.403198 456.817963,292.600616 
	C457.248169,292.180206 457.678345,291.759796 458.108551,291.339386 
	C458.649780,292.350464 459.190979,293.361572 459.872559,294.693054 
	C460.012878,295.013458 460.000000,295.000092 460.007141,295.004211 
z"/>
<path fill="#042EF2" opacity="1.000000" stroke="none" 
	d="
M410.287048,324.874573 
	C410.759918,324.008301 411.513489,323.011749 412.640503,322.009399 
	C412.198547,322.917206 411.383148,323.830750 410.287048,324.874573 
z"/>
<path fill="#062BE5" opacity="1.000000" stroke="none" 
	d="
M389.001038,318.001221 
	C388.908295,318.037018 388.814514,318.071625 388.859131,318.052002 
	C388.997559,317.997803 389.000000,318.000000 389.001038,318.001221 
z"/>
<path fill="#1223B4" opacity="1.000000" stroke="none" 
	d="
M526.001221,292.001221 
	C525.699280,292.511322 525.396057,293.020203 525.092834,293.529053 
	C524.883545,293.347656 524.674316,293.166260 524.465027,292.984863 
	C524.786743,292.679932 525.108459,292.374969 525.713867,292.034027 
	C525.997559,291.998047 526.000000,292.000000 526.001221,292.001221 
z"/>
<path fill="#0E2CD7" opacity="1.000000" stroke="none" 
	d="
M464.025970,350.597717 
	C463.353821,349.259796 462.681854,347.534088 462.020325,345.396362 
	C462.695862,346.726196 463.360992,348.468079 464.025970,350.597717 
z"/>
</g>
</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
